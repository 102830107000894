import React from 'react';
import capitalize from 'lodash/capitalize';

import Button from 'react-bootstrap/Button';

function CityLabel(props) {
  const { city, outline = 'confirmed', className } = props;

  return (
    <>
      {
        city
          ? (
            <Button size="sm" className={ `btn-label btn-${outline}-${city} ${className}` } disabled>
              { capitalize(city) }
            </Button>
          )
          : null
      }
    </>
  );
}

export default CityLabel;
