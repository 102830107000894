import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import ForestApi from '../../utils/API';
import buildForestLink from '../../utils/buildForestLink';
import CityLabel from '../CityLabel';
import getLanguageFlag from '../../utils/getLanguageFlag';
import Loader from '../Loader';

import StatusLabel from '../StatusLabel';
import Documents from './tabs/documents';
import MainTab from './tabs/main';
import ApplicationTab from './tabs/application';
import './style.scss';
import GoBackDashboard from '../shared/GoBackDashboard';
import ArrondissementLabel from '../ArrondissementLabel';
import getRentingArrondissement from '../../utils/getRentingArrondissement';

function ShowClient(props) {
  const { clientId, activeTab = '', history } = props;

  const [client, setClient] = useState({});

  async function fetchClient() {
    const response = await new ForestApi().clients().getOne(clientId);

    setClient(response.data);
  }

  useEffect(() => {
    let mounted = true;

    if (!client.id) {
      new ForestApi().clients()
        .getOne(clientId)
        .then((res) => { if (mounted)setClient(res.data); });
    }

    return () => { mounted = false; };
  }, [client.id, clientId]);

  if (!client.id) {
    return (
      <div className="h-100">
        <Loader />
      </div>
    );
  }

  function getRentingCity() {
    const renting = getCurrentRenting();
    return renting ? renting.Room.Apartment.CityId
      : null;
  }

  const getCurrentRenting = () => {
    const { Rentings, subRentings } = client;

    const activeStatus = ['booked', 'checked-in', 'checked-out'];
    const hasRenting = Rentings.length > 0 || subRentings.length > 0;

    let rentingAssociation = 'Rentings';

    if (subRentings.length > 0
      && (Rentings.length === 0 || moment(subRentings[0].bookingDate) > moment(Rentings[0].bookingDate))) {
      rentingAssociation = 'subRentings';
    }

    return hasRenting && activeStatus.includes(client[rentingAssociation][0].status)
      ? client[rentingAssociation][0]
      : null;
  };

  function changeTab(route) {
    history.push(`/clients/${clientId}/${route}${window.location.search}`);
  }

  const forestClientLink = buildForestLink(clientId, 'Client');
  const tenancyFileUrl = client.guarantorCount && client.guarantorCount !== 3
    ? client.tenancyFileUrl
    : null;

  const jotforms = [{
    label: 'Identity record',
    url: client.identityRecordUrl,
  }, {
    label: 'Tenancy file',
    url: tenancyFileUrl,
  }];

  const isBooked = client.status === 'booked';
  let arrondissement;
  if (getRentingCity()?.toLowerCase() === 'paris') {
    arrondissement = getRentingArrondissement(getCurrentRenting());
  }

  return (
    <div className="ShowClient mb-4">
      <div className="d-flex align-items-center justify-content-between my-4 text-center">
        <GoBackDashboard history={ history } defaultUrl="/clients/dashboard" />
        <div className="text-center">
          <h3>
            <span className="mr-2">{ client.fullName }</span>
            { getLanguageFlag(client.preferredLanguage) }
          </h3>
          <div>
            <div className="d-flex align-items-center justify-content-center">
              <CityLabel city={ getRentingCity() } className="mr-2" />
              {arrondissement && <ArrondissementLabel arrondissement={ arrondissement } className="mr-2" />}
              <StatusLabel status={ client.status } />
            </div>
          </div>
        </div>
        <a href={ forestClientLink } rel="noopener noreferrer" target="_blank">
          <i className="icon fa fa-external-link-alt fa-lg ml-2" />
        </a>
      </div>
      <Tabs id="client-tabs" activeKey={ activeTab } onSelect={ (newTab) => changeTab(newTab) }>
        {
          isBooked
            ? (
              <Tab
                eventKey=""
                title={ (
                  <span>
                    <i className="icon fas fa-folder" />
                    Application
                  </span>
                ) }
              >
                <ApplicationTab client={ client } />
              </Tab>

            ) : null
        }
        <Tab
          eventKey={ isBooked ? 'mainTab' : '' }
          title={ (
            <span>
              <i className="icon fas fa-building" />
              Main data
            </span>
          ) }
        >
          <MainTab client={ client } fetchClient={ () => fetchClient() } />
        </Tab>
        <Tab
          eventKey="documents"
          title={ (
            <span>
              <i className="icon far fa-file-alt" />
              Documents
            </span>
          ) }
        >
          <Documents clientId={ clientId } forms={ jotforms } />
        </Tab>
      </Tabs>
    </div>
  );
}

export default ShowClient;
