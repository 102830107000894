const extractFromGarageRenting = (garageRenting) => {
    const {Garage: {Apartment: {addressZip, addressCity}}} = garageRenting;
    return getCityForParis(addressZip, addressCity);
}

const extractFromRenting = (renting) => {
    const {Room: {Apartment: {addressZip, addressCity}}} = renting;
    return getCityForParis(addressZip, addressCity);
}

const getCityForParis = (addressZip, addressCity) => {
    if (!addressZip || !addressCity) return null;
    const last2DigitsZip = addressZip.trim().replace(/^75[01]/, '');
    return /^75[01]\d{2}$/g.test(addressZip.trim()) ?
        (last2DigitsZip + (last2DigitsZip === '01' ? 'er' : 'eme')) : addressCity.trim();
}

export default (renting, isGarage = false) => {
    if (!renting) return null;
    return isGarage ? extractFromGarageRenting(renting) : extractFromRenting(renting)
}
