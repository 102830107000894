import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import lowerCase from 'lodash/lowerCase';
import moment from 'moment';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import EditableField from '../../EditableField';
import RoundButton from '../../RoundButton';
import StatusLabel from '../../StatusLabel';

import { BED_KINDS } from '../../../utils/apartmentsFields';

const FEATURES = ['view', 'noise', 'heating', 'underSlopedRoofArea'];

function Main(props) {
  const { room, updateRoom } = props;

  const [editMode, setEditMode] = useState(false);
  const [fieldsToUpdate, setFieldsToUpdate] = useState({});
  const [displayPastRentings, togglePastRentings] = useState(false);

  const featuresFields = FEATURES.reduce((acc, featureName) => ({
    ...acc,
    [featureName]: room.features.find(({ label }) => label === lowerCase(featureName)),
  }), {});

  function copyText(text) {
    navigator.clipboard.writeText(text);

    toast(`${text} copied to clipboard!`, {
      type: toast.TYPE.SUCCESS,
    });
  }

  function editField(name, value) {
    let newValue = value;

    if (name === 'basePrice') {
      newValue = Math.round(Number(value) * 100);
    }

    setFieldsToUpdate({
      ...fieldsToUpdate,
      [name]: newValue,
    });
  }

  function toggleEditMode() {
    editField({});
    setEditMode(!editMode);
  }

  function editRoom() {
    if (!isEmpty(fieldsToUpdate)) {
      updateRoom(fieldsToUpdate);
    }

    toggleEditMode();
  }

  function getFieldValue(name) {
    if (editMode) {
      if (name in fieldsToUpdate) {
        return fieldsToUpdate[name];
      }

      if (FEATURES.includes(name)) {
        const featureName = `room-features-general-${name}`;

        return fieldsToUpdate[featureName];
      }
    }

    if (FEATURES.includes(name)) {
      return featuresFields[name].value;
    }

    return room[name];
  }

  function mappedPastRentings() {
    const { PastRentings } = room;

    const getCheckinDate = (renting) => get(renting.Events.find(({ type }) => type === 'checkin'), 'startDate', null);
    const getCheckoutDate = (renting) => get(renting.Events.find(({ type }) => type === 'checkout'), 'startDate', null);

    return PastRentings
      .sort((a, b) => new Date(getCheckoutDate(b)) - new Date(getCheckoutDate(a)))
      .filter((renting, index) => {
        if (displayPastRentings) {
          return renting.Client;
        }

        return renting.Client && index <= 2;
      })
      .map((renting) => {
        const {
          Client, status, bookingDate,
        } = renting;

        return (
          <tr key={ bookingDate }>
            <th className="w-25">{Client.fullName}</th>
            <td>
              <StatusLabel status={ status } />
            </td>
            <td>
              <span className="legend">Booking date: </span>
              {
                bookingDate
                  ? moment(bookingDate).format('DD/MM/YYYY')
                  : 'No booking date'
              }
            </td>
            <td>
              <span className="legend">Check-in date: </span>
              {
                getCheckinDate(renting)
                  ? moment(getCheckinDate(renting)).format('DD/MM/YYYY')
                  : 'No checkin date'
              }
            </td>
            <td>
              <span className="legend">Check-out date: </span>
              {
                getCheckoutDate(renting)
                  ? moment(getCheckoutDate(renting)).format('DD/MM/YYYY')
                  : 'No checkout date'
              }
            </td>
            <td className="text-right p-0">
              <Button
                variant="link"
                size="sm"
                title="Copy email"
                onClick={ () => copyText(renting.Client.email) }
              >
                <i className="far fa-copy" />
              </Button>
            </td>
          </tr>
        );
      });
  }

  function renderCards() {
    const {
      Apartment,
      availableAt,
      canonicalUrlFr,
      currentPrice,
      number,
      reference,
      Rentings: [renting],
      status,
      PastRentings,
    } = room;

    const {
      addressStreet,
      addressZip,
      addressCity,
      District: {label},
    } = Apartment;

    const fullAddress = `${addressStreet}, ${addressZip} ${addressCity}`;
    const district = label;
    const isRoomAvailable = ['stock', 'available', 'maintenance'].includes(status);

    const today = moment().endOf('day');
    const formattedAvailableAt = moment(availableAt).format('DD/MM/YYYY');

    const defaultEditableFieldProps = {
      editFieldValue: (name, value) => editField(name, value),
      editMode,
      fieldsToUpdate,
      team: 'SALES',
    };

    const twoOccupantsAllowed = getFieldValue('twoOccupantsAllowed');

    return (
      <>
        <Row>
          <Col>
            <Card className="table-card">
              <Card.Header>
                <strong>Location</strong>
              </Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    <tr>
                      <th>Reference</th>
                      <td>{ reference }</td>
                    </tr>
                    <tr>
                      <th>Apartment</th>
                      <td>
                        <Link to={ `/apartments/${Apartment.id}` } target="_blank">
                          { Apartment.name }
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th>Room number</th>
                      <td>{ number }</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>{ fullAddress }</td>
                    </tr>
                    <tr>
                      <th>Neighborhood</th>
                      <td>
                        <h5>
                          <Button variant="dark" size="sm" className="btn-label" disabled>
                            { district }
                          </Button>
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="table-card">
              <Card.Header>
                <div className="d-flex justify-content-between">
                  <strong>Main data</strong>
                  <a href={ canonicalUrlFr } target="_blank" rel="noopener noreferrer">
                    <i className="icon fas fa-external-link-alt" />
                    Preview
                  </a>
                </div>
              </Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    <tr>
                      <th>Base price</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          name="basePrice"
                          value={ getFieldValue('basePrice') / 100 }
                          isMoney
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Current price</th>
                      <td>
                        { (currentPrice / 100).toFixed(2) }
                        {' '}
                        €
                      </td>
                    </tr>
                    <tr>
                      <th>Availability</th>
                      <td>
                        {
                          isRoomAvailable
                            ? (
                              <span>
                                Available
                                {
                                moment(availableAt).isBefore(today)
                                  ? (
                                    <span>
                                      <strong> now</strong>
                                      {' '}
                                      (since
                                      {' '}
                                      { formattedAvailableAt }
                                      )
                                    </span>
                                  )
                                  : (
                                    <span>
                                      {' '}
                                      on
                                      <strong>{ formattedAvailableAt }</strong>
                                    </span>
                                  )
                              }
                              </span>
                            )
                            : status
                        }
                      </td>
                    </tr>
                    <tr>
                      <th>Floor area</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          additionalText="m²"
                          inputType="number"
                          name="floorArea"
                          value={ getFieldValue('floorArea') }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Under sloped roof area</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="select"
                          name="room-features-general-underSlopedRoofArea"
                          options={ featuresFields.underSlopedRoofArea.options }
                          value={ getFieldValue('underSlopedRoofArea') }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Virtual visit</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          name="virtualVisitUrl"
                          value={ getFieldValue('virtualVisitUrl') }
                          linkLabel={ (
                            <span>
                              <i className="icon fas fa-external-link-alt" />
                              Go to Matterport
                            </span>
                          ) }
                          isLink
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="table-card">
              <Card.Header>
                <strong>
                  Current occupant
                </strong>
              </Card.Header>
              <Card.Body>
                {
                  renting && ['booked', 'checked-in'].includes(renting.status)
                    ? (
                      <Table>
                        <tbody>
                          <tr>
                            <th>Renting price</th>
                            <td>
                              { (renting.price / 100).toFixed(2) }
                              {' '}
                              €
                            </td>
                          </tr>
                          <tr>
                            <th>Client</th>
                            <td>
                              <div className="d-flex align-items-center justify-content-between">
                                { renting.Client.fullName }
                                <Button
                                  variant="link"
                                  size="sm"
                                  title="Copy email"
                                  onClick={ () => copyText(renting.Client.email) }
                                >
                                  <i className="far fa-copy" />
                                </Button>
                              </div>
                            </td>
                          </tr>
                          {
                          renting.SecondaryClientId
                            ? (
                              <>
                                <th>Secondary client</th>
                                <td>
                                  <div className="d-flex align-items-center justify-content-between">
                                    { renting.SecondaryClient.fullName }
                                    <Button
                                      variant="link"
                                      size="sm"
                                      title="Copy email"
                                      onClick={ () => copyText(renting.SecondaryClient.email) }
                                    >
                                      <i className="far fa-copy" />
                                    </Button>
                                  </div>
                                </td>
                              </>
                            )
                            : null
                        }
                          <tr>
                            <th>Renting status</th>
                            <td>
                              <Button size="sm" className={ `btn-label btn-${renting.status}` } disabled>
                                { capitalize(renting.status) }
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )
                    : (
                      <Jumbotron className="text-center">
                        <i className="fas fa-ban fa-2x mb-2" />
                        <h4>No current occupant</h4>
                      </Jumbotron>
                    )
                }
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="table-card">
              <Card.Header>
                <strong>Main features</strong>
              </Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    <tr>
                      <th>
                        <i className="icon fas fa-user-friends fa-lg" />
                        Two occupants allowed
                      </th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="radio"
                          name="twoOccupantsAllowed"
                          label={
                            twoOccupantsAllowed
                              ? <i className="fa fa-check text-success" />
                              : <i className="fa fa-times text-danger" />
                          }
                          options={{ yes: 'true', no: 'false' }}
                          value={ `${twoOccupantsAllowed}` }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Bed kind</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="select"
                          name="beds"
                          options={ BED_KINDS }
                          value={ getFieldValue('beds') }
                          displayAsTag="dark"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>View</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="select"
                          name="room-features-general-view"
                          options={ featuresFields.view.options }
                          value={ getFieldValue('view') }
                          displayAsTag="dark"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Noise</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="select"
                          name="room-features-general-noise"
                          options={ featuresFields.noise.options }
                          value={ getFieldValue('noise') }
                          displayAsTag="dark"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Heating</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="select"
                          name="room-features-general-heating"
                          options={ featuresFields.heating.options }
                          value={ getFieldValue('heating') }
                          displayAsTag="dark"
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="table-card">
              <Card.Header>
                <strong>Past rentings</strong>
              </Card.Header>
              <Card.Body>
                {
                  !isEmpty(PastRentings)
                    ? (
                      <Table>
                        <tbody>
                          { mappedPastRentings()}
                        </tbody>
                      </Table>
                    )
                    : (
                      <Jumbotron className="text-center">
                        <i className="fas fa-ban fa-2x mb-2" />
                        <h4>No past rentings</h4>
                      </Jumbotron>
                    )
                }
                <div className="text-center mb-2">
                  {
                    PastRentings.length > 3
                      ? (
                        <Button variant="link" onClick={ () => togglePastRentings(!displayPastRentings) }>
                          {
                            displayPastRentings
                              ? (
                                <>
                                  See less
                                  <i className="fas fa-chevron-up ml-2" />
                                </>
                              )
                              : (
                                <>
                                  See more
                                  <i className="fas fa-chevron-down ml-2" />
                                </>
                              )
                          }
                        </Button>
                      )
                      : null
                  }
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <div className="main-tab">
      { renderCards() }
      <RoundButton
        editMode={ editMode }
        toggleEditMode={ toggleEditMode }
        save={ editRoom }
      />
    </div>
  );
}

export default Main;
