import React from 'react';
import { Link } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import pick from 'lodash/pick';
import trim from 'lodash/trim';
import uniq from 'lodash/uniqBy';
import { toast } from 'react-toastify';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../utils/API';
import { getApartmentCompletion } from '../../utils/getCompletion';
import truncateFirstname from '../../utils/truncateFirstname';

import Dashboard from '../Dashboard';
import NewApartment from './new';
import './style.scss';
import extractParamsQuery from '../../utils/extractParamsQuery';
import fetchCitiesFilters from '../../utils/fetchCitiesFilters';
import getFromQuery from '../../utils/getFromQuery';

const APARTMENT_STATUS = [
  'active',
  'maintenance',
  'archived',
];

class ApartmentsDashboard extends React.Component {
  constructor(props) {
    super(props);

    const { status } = this.props;
    const filters = extractParamsQuery({
      search: {
        type: 'search',
        placeholder: 'Filter by name...',
        currentValue: '',
      },
      mainSignatory: {
        type: 'tags',
        label: 'Main signatory',
        currentValue: [],
        values: [],
      },
      city: {
        type: 'checkbox',
        label: 'City',
        currentValue: ['lyon'],
        values: [],
      },
      status: {
        type: 'checkbox',
        label: 'Status',
        currentValue: [status],
        values: APARTMENT_STATUS,
      },
    }, window.location.search);

    this.state = {
      apartments: [],
      filters,
      completion: {
        global: 'incomplete',
        immo: 'incomplete Immo',
        dandq: 'incomplete D&Q',
        sales: 'incomplete Sales',
        complete: 'Complete',
      },
      showCompletion: 'global',
      displayModal: false,
      isLoading: false,
      isDashboardLoading: true,
    };
  }

  async componentDidMount() {
    await this.fetchCities();
    this.fetchApartments();
  }

  getFiltersByStatus() {
    const { filters } = this.state;
    const status = filters.status.currentValue;

    return status.includes('draft')
      ? pick(filters, ['search', 'mainSignatory', 'city'])
      : pick(filters, ['search', 'city', 'status', 'mainSignatory']);
  }

  handleSortingChange = (completion) => {
    this.setState({
      showCompletion: completion,
    });
  }

  setFilteredApartments = (apartment) => {
    const { filters, showCompletion } = this.state;
    const {
      search, city, mainSignatory, status,
    } = filters;

    const { currentValue: currentSearch } = search;
    const { currentValue: currentSignatory } = mainSignatory;

    const {
      name, CityId, immo, dandq, sales,
    } = apartment;

    const filter = [];

    if (currentSearch) {
      const regex = new RegExp(currentSearch, 'i');

      const isApartmentMatching = regex.test(name);

      filter.push(isApartmentMatching);
    }

    if (currentSignatory.length > 0) {
      const isMainSignatorySelected = currentSignatory
        .some((item) => truncateFirstname(trim(apartment.mainSignatory))
          .includes(item));

      filter.push(isMainSignatorySelected);
    }

    if (status.currentValue.includes('draft')) {
      const isDraftApartment = this.filterByCompletion(immo, dandq, sales, showCompletion)
      && city.currentValue.includes(CityId);

      filter.push(isDraftApartment);
    }

    return filter.every(Boolean);
  }

  parseApartmentCard = (apartment) => {
    const {
      id,
      name,
      addressZip,
      addressCity,
      CityId,
      District: {label},
      status,
      mainSignatory,
      immo,
      dandq,
      sales,
    } = apartment;

    const cityAddress = (CityId === 'paris' && addressCity.toLowerCase() !== 'paris')
      ? `${addressZip} ${capitalize(CityId)} / ${capitalize(addressCity)}`
      : `${addressZip} ${capitalize(addressCity)}`;

    const district = label;
    const completion = Math.floor((immo + dandq + sales) / 3);

    const tags = status === 'draft'
      ? [
        <Button key={ district } variant="dark" size="sm" className="btn-label mr-2 mt-1" disabled>
          { district }
        </Button>,
        mainSignatory && (
        <Button key={ mainSignatory } variant="primary" size="sm" className="mt-1" disabled>
          { mainSignatory }
        </Button>
        ),
      ]
      : [
        <Button key={ status } className={ `btn-label btn-${status} mr-2 mt-1` } size="sm" disabled>
          { capitalize(status) }
        </Button>,
        <Button key={ district } variant="dark" size="sm" className="btn-label mt-1 mr-2" disabled>
          { district }
        </Button>,
        mainSignatory && (
        <Button key={ mainSignatory } variant="primary" size="sm" className="mt-1" disabled>
          { mainSignatory }
        </Button>
        ),
      ];

    const description = status === 'draft'
      ? (
        <>
          <hr />
          <div className="d-flex flex-column align-items-center">
            {
              ['immo', 'dandq', 'sales'].map((team) => (
                <div className={ `w-100 d-flex align-items-center ${team}` } key={ `apartment-completion-${team}` }>
                  <Badge className={ `team-${team}` }>{team.toUpperCase().replace('AND', '&')}</Badge>
                  <hr style={{ width: '1rem' }} />
                  <div className="progressBarContainer">
                    <ProgressBar now={ apartment[team] } />
                    <p>
                      {apartment[team]}
                      % completed
                    </p>
                  </div>
                </div>
              ))
            }
            {
              completion === 100
                ? (
                  <Button variant="success" className="w-50 mt-3" onClick={ () => this.updateApartments(id) }>
                    Activate
                  </Button>
                )
                : (
                  <Button variant="success" className="w-50 mt-4" onClick={ () => this.showApartment(id) }>
                    Configure
                  </Button>
                )
            }
          </div>
        </>
      )
      : null;

    return {
      id,
      title: name,
      subtitle: cityAddress,
      tags,
      status,
      description,
    };
  }

  fetchApartments() {
    const { filters } = this.state;
    const { city, status, mainSignatory } = filters;

    this.setState({ isLoading: true });

    const query = [
      status.currentValue && `status=${status.currentValue.join(',')}`,
      city.currentValue && `city=${city.currentValue.join(',')}`,
    ].filter(Boolean).join('&');

    new ForestApi().apartments()
      .getAll(!status.currentValue.includes('draft') && query)
      .then((res) => {
        let apartments = res.data;

        if (status.currentValue.includes('draft')) {
          apartments = apartments
            .filter((item) => 'name' in item)
            .map((item) => ({ ...item, ...getApartmentCompletion(item) }));
          this.handleSortingChange('global');
        }

        const users = uniq(apartments
          .filter((item) => item.mainSignatory)
          .map((item) => truncateFirstname(item.mainSignatory.trim())));

        this.setState({
          apartments,
          isLoading: false,
          filters: {
            ...filters,
            mainSignatory: {
              ...mainSignatory,
              values: users,
            },
          },
        });
      });
  }

  async fetchCities() {
    const { filters } = this.state;
    this.setState({
      ...await fetchCitiesFilters(filters),
      isDashboardLoading: false,
    });
  }

  handleSearch(name) {
    if (name.trim().length < 3) {
      return this.setState({ apartments: [] });
    }

    const data = `name=${name.trim()}`;

    return new ForestApi().apartments()
      .search(data)
      .then((res) => {
        this.setState({ apartments: res.data });
      });
  }

  handleFilterChange(name, value) {
    const { filters } = this.state;

    if (name === 'city' && value.length === 0) {
      return null;
    }

    const refetch = !filters.status.currentValue.includes('draft')
      && ['city', 'status'].includes(name);

    return this.setState({
      filters: {
        ...filters,
        [name]: {
          ...filters[name],
          currentValue: value,
        },
      },
    }, refetch ? this.fetchApartments : null);
  }

  toggleModal() {
    const { displayModal } = this.state;

    this.setState({
      displayModal: !displayModal,
    });
  }

  showApartment(id) {
    const { history } = this.props;

    history.push(`/apartments/${id}?${getFromQuery(window.location)}`);
  }

  updateApartments(id) {
    new ForestApi().apartments()
      .update(id, { status: 'active' })
      .then(() => {
        toast('Apartment updated!', {
          type: toast.TYPE.SUCCESS,
        });
        this.fetchApartments();
      });
  }

  filterByCompletion(immo, dandq, sales, completion) {
    const result = {
      global: Math.floor((immo + dandq + sales) / 3) < 100,
      immo: immo < 100,
      dandq: dandq < 100,
      sales: sales < 100,
      complete: Math.floor((immo + dandq + sales) / 3) >= 100,
    };

    return result[completion];
  }

  renderPlaceholder() {
    return (
      <Row>
        <Col>
          <Jumbotron className="text-center">
            <h1>No apartments</h1>
            <p>
              No apartments found matching these filters.
            </p>
            <p>
              <Button variant="success" onClick={ () => this.toggleModal() }>
                <i className="icon fa fa-plus" />
                Create a new one
              </Button>
            </p>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  render() {
    const {
      apartments,
      filters,
      completion,
      displayModal,
      isLoading,
      isDashboardLoading,
    } = this.state;

    const query = [
      `search=${filters.search.currentValue}`,
      `mainSignatory=${filters.mainSignatory.currentValue.join(',')}`,
      `city=${filters.city.currentValue.join(',')}`,
      `status=${filters.status.currentValue.join(',')}`,
    ].join('&');
    window.history.replaceState(null, null, `${window.location.pathname}?${query}`);

    const { userTeam } = this.props;
    const status = filters.status.currentValue;

    const userTeamList = [
      status.includes('draft') && 'business-development',
      'design-and-quality',
      'sales',
    ].filter(Boolean);

    const userHomePage = userTeamList.includes(userTeam) ? 'home' : 'search';
    const homePageUrl = userHomePage === 'home' ? '' : 'apartments';

    const filteredApartments = apartments
      .filter((apartment) => this.setFilteredApartments(apartment))
      .map(this.parseApartmentCard);

    return (
      <div className="Apartments">
        <div className="d-flex justify-content-between flex-wrap
        flex-md-nowrap align-items-center pt-3 pb-2 border-bottom"
        >
          <Link to={ `/${homePageUrl}` }>
            <i className="icon fas fa-arrow-circle-left" />
            {`Back to ${userHomePage}`}
          </Link>
          <h3>
            Apartments
            {
              status.includes('draft')
                ? ' - Configuration in progress'
                : ' Dashboard'
            }
          </h3>
          <Button variant="success" onClick={ () => this.toggleModal() }>
            <i className="icon fa fa-plus" />
            New
          </Button>
        </div>
        {
          isDashboardLoading
            ? (
              <div className="h-100 mt-5 d-flex align-items-center justify-content-center">
                <i className="fas fa-spinner fa-spin fa-3x" />
              </div>
            )
            : (
              <Dashboard
                model="apartment"
                filters={ this.getFiltersByStatus() }
                sorting={ status.includes('draft') ? completion : null }
                items={ filteredApartments }
                handleFilterChange={ (name, value) => this.handleFilterChange(name, value) }
                handleElementClick={ (id) => (!status.includes('draft') ? this.showApartment(id) : null) }
                handleSortingChange={ (value) => this.handleSortingChange(value) }
                renderPlaceholder={ () => this.renderPlaceholder() }
                isLoading={ isLoading }
              />
            )
        }
        {
          displayModal
            ? (
              <NewApartment
                show={ displayModal }
                handleClose={ () => this.toggleModal() }
                fetchApartments={ () => this.fetchApartments() }
              />
            )
            : null
        }
      </div>
    );
  }
}

export default ApartmentsDashboard;
