import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import moment from 'moment';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../utils/API';
import CityLabel from '../CityLabel';
import Dashboard from '../Dashboard';
import NewIntervention from './new';
import './style.scss';
import extractParamsQuery from '../../utils/extractParamsQuery';
import fetchCitiesFilters from '../../utils/fetchCitiesFilters';
import getFromQuery from '../../utils/getFromQuery';

const INTERVENTION_STATUS = [
  'draft',
  'assessed',
  'scheduled',
  'ongoing',
  'done',
  'archived',
  'cancelled',
];

class Interventions extends React.Component {
  constructor(props) {
    super(props);

    const filters = extractParamsQuery({
      search: {
        type: 'search',
        placeholder: 'Filter by name...',
        currentValue: '',
      },
      city: {
        type: 'checkbox',
        label: 'City',
        currentValue: ['lyon'],
        values: [],
      },
      status: {
        type: 'checkbox',
        label: 'Status',
        currentValue: ['draft'],
        values: INTERVENTION_STATUS,
      },
      interventionTypes: {
        type: 'checkbox',
        label: 'Intervention',
        currentValue: [],
        values: [],
      },
    }, window.location.search);

    this.state = {
      isLoading: false,
      filters,
      interventions: [],
      interventionTypes: [],
      propertyManagers: [],
      cities: [],
      displayModal: false,
    };
  }

  async componentDidMount() {
    await this.fetchTypes();
    await this.fetchCities();
    await this.fetchPropertyManagers();
    await this.fetchInterventions();
  }

  fetchInterventions() {
    const { filters } = this.state;
    const { status, city } = filters;

    this.setState({ isLoading: true });

    const query = [
      status.currentValue && `status=${status.currentValue.join(',')}`,
      city.currentValue && `city=${city.currentValue.join(',')}`,
    ].filter(Boolean).join('&');

    new ForestApi().interventions()
      .getAll(query)
      .then((res) => {
        const interventions = res.data;
        this.setState({ interventions, isLoading: false });
      });
  }

  async fetchTypes() {
    const { filters } = this.state;
    const { interventionTypes } = filters;

    const response = await new ForestApi().interventions().getTypes();
    const types = response.data;

    this.setState({
      interventionTypes: types,
      filters: {
        ...filters,
        interventionTypes: {
          ...interventionTypes,
          values: types,
        },
      },
    });
  }

  async fetchCities() {
    const { filters } = this.state;
    this.setState(await fetchCitiesFilters(filters));
  }

  async fetchPropertyManagers() {
    const query = 'team=property-manager';
    const response = await new ForestApi().users().getAll(query);

    this.setState({
      propertyManagers: response.data.map(({ firstName }) => firstName),
    });
  }

  handleFilterChange(name, value) {
    const { filters } = this.state;

    if (name === 'city' && value.length === 0) {
      return null;
    }

    const refetch = [
      'city',
      'status',
    ].includes(name);

    return this.setState({
      filters: {
        ...filters,
        [name]: {
          ...filters[name],
          currentValue: value,
        },
      },
    }, refetch ? this.fetchInterventions : null);
  }

  toggleModal() {
    const { displayModal } = this.state;

    this.setState({
      displayModal: !displayModal,
    });
  }

  createIntervention(fields) {
    const data = fields.reduce((acc, field) => {
      acc[field.name] = field.value;

      return acc;
    }, {});

    this.toggleModal();

    new ForestApi().interventions()
      .create(data)
      .then(() => {
        toast('Intervention created!', {
          type: toast.TYPE.SUCCESS,
        });
        this.fetchInterventions();
      });
  }

  showIntervention(id) {
    const { history } = this.props;
    history.push(`/interventions/${id}?${getFromQuery(window.location)}`);
  }

  parseInterventionCard(intervention) {
    const {
      Apartment,
      description,
      expectedStartDate,
      expectedTimeSlot,
      id,
      propertyManagerName,
      status,
      type,
    } = intervention;

    const timeSlots = {
      morning: 'AM',
      afternoon: 'PM',
      day: 'DAY',
    };

    const { CityId } = Apartment;

    const subtitle = !['draft', 'assessed'].includes(status)
      ? (
        <>
          <i className="icon far fa-clock" />
          <span>
            { moment(expectedStartDate).format('DD/MM/YYYY') }
            {' '}
            { timeSlots[expectedTimeSlot] }
          </span>
        </>
      )
      : null;

    const tags = [
      <CityLabel key={ CityId } city={ CityId } className="mr-2 mb-2" />,
      <hr key={ `${id} tab` } className="my-2 border-0" />,
      <Button key={ status } className={ `btn-label btn-${status} mr-2` } size="sm" disabled>
        { capitalize(status) }
      </Button>,
      <Button key={ type } variant="dark" size="sm" className="btn-label mr-2" disabled>
        { capitalize(type) }
      </Button>,
      <Button key={ propertyManagerName } variant="secondary" size="sm" className="btn-label" disabled>
        { capitalize(propertyManagerName) }
      </Button>,
    ];

    return {
      id,
      title: Apartment.name,
      subtitle,
      tags,
      description: truncate(description, {
        length: 140,
      }),
    };
  }

  renderPlaceholder() {
    return (
      <Row>
        <Col>
          <Jumbotron className="text-center">
            <h1>No interventions</h1>
            <p>
              No interventions found.
            </p>
            <p>
              <Button variant="success" onClick={ () => this.toggleModal() }>
                <i className="icon fa fa-plus" />
                Create a new one
              </Button>
            </p>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  render() {
    const {
      interventions,
      interventionTypes,
      propertyManagers,
      cities,
      filters,
      displayDashboard,
      displayModal,
      isLoading,
    } = this.state;

    const { userTeam } = this.props;

    const query = [
      `search=${filters.search.currentValue}`,
      `city=${filters.city.currentValue.join(',')}`,
      `status=${filters.status.currentValue.join(',')}`,
      `interventionTypes=${filters.interventionTypes.currentValue.join(',')}`,
    ].join('&');
    window.history.replaceState(null, null, `${window.location.pathname}?${query}`);

    const filteredInterventions = interventions
      .filter(({ Apartment: { name } }) => {
        const { search } = filters;

        if (!search.currentValue) {
          return true;
        }

        const regex = new RegExp(search.currentValue, 'i');

        return regex.test(name);
      })
      .filter(({ type }) => {
        const { interventionTypes: types } = filters;

        if (isEmpty(types.currentValue)) {
          return true;
        }

        return types.currentValue.includes(type);
      })
      .map(this.parseInterventionCard);

    return (
      <div className="Interventions">
        <div className="d-flex justify-content-between flex-wrap
         flex-md-nowrap align-items-center pt-3 pb-2 border-bottom"
        >
          {
            userTeam === 'property-manager'
              ? (
                <Link to="/">
                  <i className="icon fas fa-arrow-circle-left" />
                  Back to home
                </Link>
              )
              : (
                <Link to="/interventions">
                  <i className="icon fas fa-arrow-circle-left" />
                  Back to search
                </Link>
              )
          }
          <h3>
            Interventions
            { displayDashboard ? ' Dashboard' : '' }
          </h3>
          <Button variant="success" onClick={ () => this.toggleModal() }>
            <i className="icon fa fa-plus" />
            New
          </Button>
        </div>
        <Dashboard
          model="intervention"
          filters={ filters }
          items={ filteredInterventions }
          handleFilterChange={ (name, value) => this.handleFilterChange(name, value) }
          handleElementClick={ (id) => this.showIntervention(id) }
          renderPlaceholder={ () => this.renderPlaceholder() }
          isLoading={ isLoading }
        />
        {
          displayModal
            ? (
              <NewIntervention
                show={ displayModal }
                handleClose={ () => this.toggleModal() }
                handleSubmit={ (data) => this.createIntervention(data) }
                interventionTypes={ interventionTypes }
                cities={ cities }
                propertyManagers={ propertyManagers }
              />
            )
            : null
        }
      </div>
    );
  }
}

export default Interventions;
