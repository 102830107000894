import React, { useCallback, useState, useEffect } from 'react';
import capitalize from 'lodash/capitalize';
import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import EditableField from '../../EditableField';
import RoundButton from '../../RoundButton';

import { GLOBAL_ROOM_FEATURES, BED_KINDS } from '../../../utils/apartmentsFields';

const HIDDEN_FEATURES = [
  'underSlopedRoofArea',
];

const SWITCHED_CATEGORY_FEATURES = {
  sofaBed: 'sleep',
};

const SPECIAL_FEATURE_NAME = {
  garden: 'Garden Access',
};

const BOOLEAN_OPTIONS = { yes: 'true', no: 'false' };

function getIcon(field) {
  const icon = field.replace('room-features-', '');

  return require(`../../../assets/icons/room/${icon}.png`); // eslint-disable-line
}

function FeatureElement(props) {
  const {
    label,
    editMode,
    field,
    options,
    type,
    updateField,
    value,
  } = props;

  const [featureValue, setFeatureValue] = useState();

  const isBoolean = type === 'Boolean';
  const inputType = (
    (isBoolean && 'radio')
    || (type === 'Number' && 'number')
    || (type === 'Enum' && 'select')
  );
  const parsedValue = (isBoolean && value) || value;

  const fieldOptions = (
    (isBoolean && BOOLEAN_OPTIONS)
    || options
  );

  const htmlProps = inputType === 'number'
    ? { min: 0 }
    : null;

  const renderEditableField = editMode
    ? (
      <EditableField
        inputType={ inputType }
        name={ field }
        value={ parsedValue }
        options={ fieldOptions }
        htmlProps={ htmlProps }
        editFieldValue={ updateField }
        editMode
      />
    )
    : null;

  const hideValue = useCallback(() => {
    if ((isBoolean && !value) || value == null) {
      return setFeatureValue(<i className="fas fa-lg fa-times-circle text-danger" />);
    }

    if (isBoolean) {
      return setFeatureValue(<i className="fas fa-lg fa-check-circle text-success" />);
    }

    return setFeatureValue(<i className="fas fa-lg fa-info-circle text-success" />);
  }, [isBoolean, value]);

  const showValue = () => {
    if (isBoolean || value == null) {
      return null;
    }

    if (inputType === 'number') {
      const variant = value === 0 ? 'danger' : 'info';

      return setFeatureValue(<h5 className="m-0"><Badge variant={ variant } size="lg" pill>{ value }</Badge></h5>);
    }

    return setFeatureValue(<Button size="sm" variant="dark" className="btn-label" disabled>{ value }</Button>);
  };

  useEffect(() => {
    hideValue();
  }, [hideValue]);

  return (
    <li
      className={ `feature-item ${editMode ? 'edit-mode' : ''}` }
      onMouseEnter={ () => showValue() }
      onMouseLeave={ () => hideValue() }
    >
      <img src={ getIcon(field) } alt={ label } className="feature-icon mr-3" />
      <div className="feature-value">
        <span className="feature-label">{ SPECIAL_FEATURE_NAME[label] || label }</span>
        <div>
          { editMode ? renderEditableField : featureValue }
        </div>
      </div>
    </li>
  );
}

function FeaturesTab(props) {
  const {
    room,
    updateRoom,
  } = props;

  const { features } = room;

  const [editMode, setEditMode] = useState(false);
  const [fieldsToUpdate, setFieldsToUpdate] = useState({});

  function toggleEditMode() {
    if (editMode) {
      setFieldsToUpdate({});
    }

    setEditMode(!editMode);
  }

  function editRoom() {
    if (!isEmpty(fieldsToUpdate)) {
      updateRoom(fieldsToUpdate);
    }

    toggleEditMode();
  }

  function updateField(name, value) {
    setFieldsToUpdate({
      ...fieldsToUpdate,
      [name]: value,
    });
  }

  function renderFeaturesList(kind) {
    const categoryFeatures = GLOBAL_ROOM_FEATURES[kind];

    const switchedCategoryFeatures = Object.values(SWITCHED_CATEGORY_FEATURES)
      .filter((category) => category === kind)
      .map((name) => Object.keys(SWITCHED_CATEGORY_FEATURES).find((key) => SWITCHED_CATEGORY_FEATURES[key] === name))
      .filter(Boolean);

    const mappedFeatures = [...categoryFeatures, ...switchedCategoryFeatures]
      .filter((name) => {
        if (Object.keys(SWITCHED_CATEGORY_FEATURES).includes(name)) {
          return SWITCHED_CATEGORY_FEATURES[name] === kind;
        }
        return !HIDDEN_FEATURES.includes(name);
      })
      .sort()
      .map((name) => {
        const feature = features.find(({ label }) => name === camelCase(label));

        if (!feature) {
          return null;
        }

        const {
          field,
          label,
          value,
          type,
          options,
        } = feature;

        const featureValue = fieldsToUpdate[field] !== undefined
          ? fieldsToUpdate[field]
          : value;

        return (
          <FeatureElement
            key={ field }
            field={ field }
            label={ label }
            value={ featureValue }
            type={ type }
            options={ options }
            editMode={ editMode }
            updateField={ updateField }
          />
        );
      })
      .filter(Boolean);

    return (
      <Col lg={ kind === 'general' ? '3' : '4' } key={ kind }>
        <Card>
          <Card.Header className="d-flex align-items-center justify-content-between">
            <strong>{ capitalize(kind) }</strong>
            <Badge className="team-sales">SALES</Badge>
          </Card.Header>
          <Card.Body>
            <ul className="features">
              {
                kind === 'sleep'
                  ? (
                    <FeatureElement
                      key="beds"
                      field="beds"
                      label="Bed kind"
                      value={ fieldsToUpdate.beds || room.beds }
                      type="Enum"
                      options={ BED_KINDS }
                      editMode={ editMode }
                      updateField={ updateField }
                    />
                  )
                  : null
              }
              { mappedFeatures }
            </ul>
          </Card.Body>
        </Card>
      </Col>
    );
  }

  const categories = ['sleep', 'work', 'bathroom', 'dress'];

  const renderColumns = categories.map((kind) => renderFeaturesList(kind));

  return (
    <div className="features-tab">
      <Row>
        {renderFeaturesList('general')}
        <Col lg="9">
          <Row>
            { renderColumns }
          </Row>
        </Col>
        <RoundButton
          editMode={ editMode }
          toggleEditMode={ toggleEditMode }
          save={ editRoom }
        />
      </Row>
    </div>
  );
}

export default FeaturesTab;
