import React from 'react';
import Alert from 'react-bootstrap/Alert';

function ProblemAssessed() {
  return (
    <div>
      <Alert variant="info">Are you sure ?</Alert>
      <ul>
        <li>Did you check the pictures ?</li>
        <li>Did you confirm with the clients that an intervention is needed ?</li>
      </ul>
      <p>
        If so, you can go on to the
        {' '}
        <strong>scheduling</strong>
        {' '}
        step by clicking on the Confirm button below.
      </p>
    </div>
  );
}

export default ProblemAssessed;
