import React from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ImageViewer from '../../ImageViewer';

function Pictures(props) {
  const { pictures } = props;

  const sortedPictures = pictures
    .filter(({ order }) => order)
    .sort((a, b) => a.order - b.order)
    .concat(pictures.filter(({ order }) => !order));

  return (
    <div className="pictures-tab">
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <ImageViewer
                pictures={ sortedPictures }
                types={ [] }
                showOnly
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Pictures;
