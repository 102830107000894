import React from 'react';
import isEmpty from 'lodash/isEmpty';
import snakeCase from 'lodash/snakeCase';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

class EmailsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      flags: {
        es: '🇪🇸',
        fr: '🇫🇷',
        en: '🇬🇧',
      },
      displayAttrs: false,
      attrs: {},
    };
  }

  toggleAttrs(attrs) {
    const { displayAttrs } = this.state;

    this.setState({
      displayAttrs: !displayAttrs,
      attrs,
    });
  }

  renderPlaceholder() {
    return (
      <Jumbotron className="text-center">
        <h1>No emails</h1>
        <p>
          No email sent.
        </p>
      </Jumbotron>
    );
  }

  renderEmailLanguage(lang) {
    const { flags } = this.state;

    return flags[lang];
  }

  renderEmailsTable() {
    const { emails } = this.props;

    const rows = sortBy(emails, ['createdAt', 'template']).reverse().map((email) => {
      const {
        attrs,
        createdAt,
        emails: eMails,
        id,
        sendingVendorId,
        template,
      } = email;
      const [templateName, language] = template.split('.');

      return (
        <tr key={ id }>
          <td>{ templateName }</td>
          <td>{ this.renderEmailLanguage(language) }</td>
          <td>{ eMails }</td>
          <td>
            {
              sendingVendorId
                ? <i className="fas fa-check text-success" />
                : <i className="fas fa-times text-danger" />
            }
          </td>
          <td>{ moment(createdAt).format('DD/MM/YYYY HH:mm') }</td>
          <td>
            <Button variant="dark" size="sm" onClick={ () => this.toggleAttrs(attrs) }>
              <i className="fas fa-eye" />
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <Table bordered hover>
        <thead>
          <tr>
            <th>Template</th>
            <th>Language</th>
            <th>Sent to</th>
            <th>Sent ?</th>
            <th>Created at</th>
            <th>Attrs</th>
          </tr>
        </thead>
        <tbody>
          { rows }
        </tbody>
      </Table>
    );
  }

  render() {
    const { emails } = this.props;
    const { displayAttrs, attrs } = this.state;

    const formattedAttrs = Object.keys(attrs).map((key) => (
      <li key={ key }>
        {`{{ params.${snakeCase(key).toUpperCase()} }}`}
        {' '}
        <i className="fas fa-long-arrow-alt-right" />
        {' '}
        <strong>{ isEmpty(attrs[key]) ? 'N/A' : attrs[key].toString() }</strong>
      </li>
    ));

    return (
      <div className="Intervention-EmailsTab">
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <strong>Emails</strong>
              </Card.Header>
              <Card.Body>
                {
                  emails.length === 0
                    ? this.renderPlaceholder()
                    : this.renderEmailsTable()
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal show={ displayAttrs } size="lg" onHide={ () => this.toggleAttrs({}) }>
          <Modal.Header>
            <Modal.Title>Email attributes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              { formattedAttrs }
            </ul>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default EmailsTab;
