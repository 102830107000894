export default {
  API_BASE_URL: ((environment) => {
    switch (environment) {
      case 'production':
        return 'https://ucyldwzph9.execute-api.eu-west-1.amazonaws.com/production';
      case 'staging':
        return 'https://ucyldwzph9.execute-api.eu-west-1.amazonaws.com/staging';
      // return 'https://ucyldwzph9.execute-api.eu-west-1.amazonaws.com/integration_jk'; // integration_jk
      default:
        return 'http://localhost:3000';
    }
  })(process.env.REACT_APP_ENV),
  FOREST_BASE_URL: ((environment) => {
    switch (environment) {
      case 'production':
        return 'https://app.forestadmin.com/Chez Nestor/Production/Chez Nestor';
      case 'staging':
        return 'https://app.forestadmin.com/Chez Nestor/Staging/Chez Nestor';
      default:
      case 'development':
        return 'https://app.forestadmin.com/Chez Nestor/Development JK/Chez Nestor';
    }
  })(process.env.REACT_APP_ENV),
  FOREST_EVENT_ID: ((environment) => {
    switch (environment) {
      case 'production':
        return '344756';
      case 'staging':
        return '337713';
      default:
      case 'development':
        return '1424394';
    }
  })(process.env.REACT_APP_ENV),
  FOREST_RENTING_ID: ((environment) => {
    switch (environment) {
      case 'production':
        return '320260';
      case 'staging':
        return '320679';
        // return '1883707'; // integration_jk
      default:
      case 'development':
        return '1424406';
    }
  })(process.env.REACT_APP_ENV),
  FOREST_APARTMENT_ID: ((environment) => {
    switch (environment) {
      case 'production':
        return '320264';
      case 'staging':
        return '320683';
        // return '1883707'; // integration_jk
      default:
      case 'development':
        return '1424387';
    }
  })(process.env.REACT_APP_ENV),
  FOREST_INTERVENTION_ID: ((environment) => {
    switch (environment) {
      case 'production':
        return '2243457';
      case 'staging':
        return '2234633';
      default:
      case 'development':
        return '2186893';
    }
  })(process.env.REACT_APP_ENV),
  FOREST_CLIENT_ID: ((environment) => {
    switch (environment) {
      case 'production':
        return '320266';
      case 'staging':
        return '320685';
      default:
      case 'development':
        return '1444901';
    }
  })(process.env.REACT_APP_ENV),
  FOREST_WORKFLOW_ID: ((environment) => {
    switch (environment) {
      case 'production':
        return '2243458';
      case 'staging':
        return '2234634';
      default:
      case 'development':
        return '2186894';
    }
  })(process.env.REACT_APP_ENV),
  FOREST_GARAGE_ID: ((environment) => {
    switch (environment) {
      case 'production':
        return '1846534';
      case 'staging':
        return '1843199';
      default:
      case 'development':
        return '1852277';
    }
  })(process.env.REACT_APP_ENV),
  FOREST_CONTACT_ID: ((environment) => {
    switch (environment) {
      case 'production':
        return '1995529';
      case 'staging':
        return '1977722';
      default:
      case 'development':
        return '1996433';
    }
  })(process.env.REACT_APP_ENV),
  FOREST_CONTRACTOR_ID: ((environment) => {
    switch (environment) {
      case 'production':
        return '1514403';
      case 'staging':
        return '1508451';
      default:
      case 'development':
        return '1514100';
    }
  })(process.env.REACT_APP_ENV),
  FOREST_ROOM_ID: ((environment) => {
    switch (environment) {
      case 'production':
        return '320259';
      case 'staging':
        return '320678';
      default:
      case 'development':
        return '1424407';
    }
  })(process.env.REACT_APP_ENV),
  FOREST_ORDER_ID: ((environment) => {
    switch (environment) {
      case 'production':
        return '320265';
      case 'staging':
        return '320684';
      default:
      case 'development':
        return '1424400';
    }
  })(process.env.REACT_APP_ENV),
};
