import React, { useCallback, useEffect, useState } from 'react';
import { countries } from 'countries-list';
import { toast } from 'react-toastify';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import { capitalize } from 'lodash';
import Loader from '../../Loader';
import ForestApi from '../../../utils/API';
import openDocument from '../../../utils/openDocument';
import EditableField from '../../EditableField';

const documentIcons = {
  'image/jpeg': 'image',
  'image/png': 'image',
  'application/pdf': 'pdf',
  'text/xml': 'excel',
};

function serializeCountry(nationality) {
  return countries[nationality]
    ? countries[nationality].name
    : nationality;
}

function serializeAddress(address) {
  const [fullAdress, nationality] = address
    ? address.split(/\(|\)/)
    : '';

  const country = serializeCountry(nationality);

  return `${fullAdress} (${country})`;
}

const taskDocuments = {
  'Selfie & ID': [
    'client-identity',
    'client-selfie',
  ],
  'Selfie & ID - Couple': [
    'client-identity',
    'client-selfie',
  ],
  'Emergency contact': [
    'emergency-contact',
  ],
  'Client tenancy file': [
    'client-finances',
  ],
  'Guarantor tenancy file': [
    'client-guarantor-finances',
    'client-guarantor-identity',
  ],
  'Garantme tenancy file': [
    'client-garantme-finances',
  ],
  'Check-in date': [],
  'Send signing link': [],
  Signature: [],
};

const contractDocuments = [{
  name: 'pdfLeaseUrl',
  label: 'Generate lease',
},{
  name: 'client-garantme-finances',
  label: 'Garantme certificate',
}];

function ApplicationModal(props) {
  const {
    show,
    handleClose,
    rentingData,
    applicationList,
    rentings,
    fetchRentings,
  } = props;

  const [isLoading, setLoader] = useState(true);

  const findCurrentIndex = useCallback(() => applicationList
    .findIndex(({ id, task }) => id === rentingData.id && task === rentingData.task), [applicationList, rentingData]);

  const [currentApplication, setCurrentApplication] = useState({
    renting: {
      Client: {},
      Room: {
        Apartment: {},
      },
    },
    applicationTask: rentingData.task,
    applicationIndex: findCurrentIndex(),
    selectedDocuments: {
      main: {},
      secondary: {},
    },
    clientDocuments: {
      main: [],
      secondary: [],
    },
  });

  const {
    renting,
    applicationTask,
    applicationIndex,
    selectedDocuments,
    clientDocuments,
  } = currentApplication;

  const [signingLinkUrl, setSigningLinkUrl] = useState();
  const [guarantorSigningLinkUrl, setGuarantorSigningLinkUrl] = useState();
  const [client, setClient] = useState({});

  const fetchClient = useCallback(async (id) => {
    if (id || renting.Client.id) {
      const response = await new ForestApi().clients().getOne(id || renting.Client.id);

      setClient(response.data);
    }
  }, [renting.Client.id]);

  const getRenting = useCallback(async (index) => {
    setLoader(true);
    setClient({});
    const newRenting = rentings.find(({ ClientId }) => ClientId === applicationList[index].id);
    const { task } = applicationList[index];

    setCurrentApplication({
      renting: newRenting,
      applicationTask: task,
      applicationIndex: index,
      selectedDocuments: {
        main: {},
        secondary: {},
      },
      clientDocuments: {
        main: newRenting.Client.Documents
          .filter(({ type }) => taskDocuments[task].includes(type)),
        secondary: newRenting.SecondaryClient
          ? newRenting.SecondaryClient.Documents.filter(({ type }) => taskDocuments[task].includes(type))
          : [],
      },

    });

    await fetchClient(newRenting.Client.id);
    setLoader(false);
  }, [rentings, applicationList, fetchClient]);

  useEffect(() => {
    if (typeof applicationIndex === 'number')
      getRenting(applicationIndex);
  }, [applicationIndex, getRenting]);

  const isSecondaryClient = Boolean(renting.SecondaryClient);

  const {
    guarantorCount,
    signingUrl,
    signingTimestamp,
    guarantorSigningUrl,
    guarantorSigningTimestamp,
  } = client;
  const hasGuarantor = guarantorCount === 1;

  function getGuarantor() {
    if (guarantorCount == null) {
      return 'No guarantor needed';
    }

    const isFemale = client.gender === 'female';

    const displayGuarantorMessage = [
      `Documents provided by ${isFemale ? 'herself' : 'himself'}`,
      'One guarantor',
      'Two guarantors',
      'Garantme',
    ];

    return displayGuarantorMessage[guarantorCount];
  }

  const clientDataFields = [{
    name: 'garantMe',
    label: 'Guarantor name',
    type: 'string',
    value: 'Garantme',
  }, {
    name: 'guarantorCount',
    label: 'Guarantor',
    type: 'string',
    serializer: getGuarantor,
  }, {
    name: 'firstName',
    label: 'First name',
    type: 'string',
  }, {
    name: 'lastName',
    label: 'Last name',
    type: 'string',
  }, {
    name: 'nationality',
    label: 'Nationality',
    type: 'string',
    serializer: serializeCountry,
  }, {
    name: 'birthDate',
    label: 'Birth date',
    type: 'date',
  }, {
    name: 'birthPlace',
    label: 'Birth place',
    type: 'string',
  }, {
    name: 'previousAddress',
    label: 'Previous address',
    type: 'string',
  }, {
    name: 'email',
    label: 'Email',
    type: 'string',
  }, {
    name: 'phoneNumber',
    label: 'Phone number',
    type: 'string',
  }, {
    name: 'guarantorFullName',
    label: 'Guarantor name',
    type: 'string',
  }, {
    name: 'guarantorEmail',
    label: 'Guarantor email',
    type: 'string',
  }, {
    name: 'guarantorPhoneNumber',
    label: 'Guarantor phone number',
    type: 'string',
  }, {
    name: 'guarantorBirthDate',
    label: 'Guarantor birth date',
    type: 'date',
  }, {
    name: 'guarantorBirthPlace',
    label: 'Guarantor birth place',
    type: 'string',
  }, {
    name: 'guarantorAddress',
    label: 'Guarantor address',
    type: 'string',
    serializer: serializeAddress,
  }, {
    name: 'CityId',
    label: 'Renting City',
    type: 'string',
    value: capitalize(renting.Room.Apartment.CityId),
  }, {
    name: 'tax',
    label: 'Tax notice minimum amount',
    type: 'string',
    value: renting.Room.Apartment.CityId === 'paris'
      ? '2000€'
      : '1500€',
  }, {
    name: 'payslip',
    label: 'Payslip minimum amount',
    type: 'string',
    value: renting.Room.Apartment.CityId === 'paris'
      ? '25000€'
      : '20000€',
  }, {
    name: 'emergencyContactKind',
    label: 'Contact Kind',
    type: 'string',
  }, {
    name: 'emergencyFullName',
    label: 'Contact Name',
    type: 'string',
  }, {
    name: 'emergencyEmail',
    label: 'Email',
    type: 'string',
  }, {
    name: 'emergencyPhoneNumber',
    label: 'Phone number',
    type: 'string',
  }];

  const headerIcon = (
    <i className="fas fa-folder fa-lg card-counter-container">
      <Badge pill variant="danger">
        { applicationList.length }
      </Badge>
    </i>
  );

  useEffect(() => {
    if (!applicationList) {
      getRenting(applicationIndex);
    }
  }, [applicationList, getRenting, applicationIndex]);

  function getSelectedDocumentsCount() {
    return Object.keys(selectedDocuments)
      .reduce((acc, clientType) => acc + Object.keys(selectedDocuments[clientType]).length, 0);
  }

  function setRejectedDocuments({ id, type }, clientType) {
    const sameTypeDocuments = clientDocuments[clientType]
      .filter((document) => document.type === type)
      .reduce((acc, document) => ({ ...acc, [document.id]: document.type }), {});

    const rejectedDocuments = id in selectedDocuments[clientType]
      ? Object.keys(selectedDocuments[clientType])
        .filter((key) => !(key in sameTypeDocuments))
        .reduce((acc, key) => ({ ...acc, [key]: selectedDocuments[clientType][key] }), {})
      : { ...selectedDocuments[clientType], ...sameTypeDocuments };

    return setCurrentApplication({
      ...currentApplication,
      selectedDocuments: {
        ...selectedDocuments,
        [clientType]: rejectedDocuments,
      },
    });
  }

  function getFieldValue(field, secondaryClient) {
    const { serializer } = field;

    const value = get(secondaryClient || client, field.name, null);

    return serializer ? serializer(value) : value;
  }

  function getContractKind() {
    const clientNumber = isSecondaryClient ? 'Couple' : 'Client seul';

    const guarantorType = {
      1: ' avec garant',
      3: ' avec Garantme',
    };

    return clientNumber + (guarantorType[guarantorCount] || ' sans garant');
  }

  async function validateTask() {
    const data = {
      ...(['Selfie & ID', 'Selfie & ID - Couple'].includes(applicationTask) && { idChecked: true }),
      ...(['Client tenancy file',
        'Guarantor tenancy file',
        'Garantme tenancy file'].includes(applicationTask) && { tenancyFileApproved: true }),
      ...(applicationTask === 'Emergency contact' && { emergencyContactChecked: true }),
      ...(applicationTask === 'Send signing link' && {
        leaseSent: true,
        signingLinkUrl,
        guarantorSigningLinkUrl: hasGuarantor ? guarantorSigningLinkUrl : null,
      }),
      ...(applicationTask === 'Signature' && { leaseSigned: true }),
    };

    await new ForestApi().rentings().update(renting.id, data);

    toast(`${applicationTask}: checked`, {
      type: toast.TYPE.SUCCESS,
    });

    setLoader(true);
    fetchRentings();

    if (applicationIndex < applicationList.length - 1) {
      setSigningLinkUrl();
      setGuarantorSigningLinkUrl();
      fetchClient();
    } else {
      handleClose();
    }
  }

  async function sendSigningLinkReminder(renderGuarantorLink = false) {
    const data = {};
    if (renderGuarantorLink) {
      data.guarantorSigningLinkUrl = guarantorSigningUrl;
    } else {
      data.signingLinkUrl = signingUrl;
    }
    await new ForestApi().rentings().update(renting.id, data);

    toast('Reminder sent!', {
      type: toast.TYPE.SUCCESS,
    });

    if (applicationIndex < applicationList.length - 1) {
      setSigningLinkUrl();
      setGuarantorSigningLinkUrl();
      setCurrentApplication({
        ...currentApplication,
        applicationIndex: applicationIndex + 1,
      });
    } else {
      handleClose();
    }
  }

  async function updateClient() {
    const data = {
      ...(applicationTask === 'Emergency contact' && {
        emergencyContactKind: null,
        emergencyFullName: null,
        emergencyEmail: null,
        emergencyPhoneNumber: null,
      }),
    };

    if (Object.keys(data).length === 0) {
      return null;
    }

    const response = await new ForestApi().clients().update(client.id, data);

    return response;
  }

  async function rejectDocument() {
    function getQuery(clientType) {
      const documents = Object.keys(selectedDocuments[clientType])
        .map((document) => selectedDocuments[clientType][document]);

      return `types=${documents.join(',')}&clientType=${clientType}`;
    }

    if (isEmpty(selectedDocuments.main) && isEmpty(selectedDocuments.secondary)) {
      const documents = taskDocuments[applicationTask];

      const query = `types=${documents.join(',')}&clientType=${isSecondaryClient ? 'both' : 'main'}`;

      await new ForestApi().rentings().rejectDocuments(renting.id, query);
    } else {
      if (!isEmpty(selectedDocuments.main)) {
        await new ForestApi().rentings().rejectDocuments(renting.id, getQuery('main'));
      }

      if (!isEmpty(selectedDocuments.secondary)) {
        await new ForestApi().rentings().rejectDocuments(renting.id, getQuery('secondary'));
      }
    }

    await updateClient();

    toast('documents rejected', {
      type: toast.TYPE.SUCCESS,
    });

    if (applicationIndex >= applicationList.length - 1) {
      handleClose();
    }

    setLoader(true);
    fetchRentings();
  }

  function parseContactData(filteredData, secondaryClient) {
    return (
      clientDataFields
        .filter(({ name }) => filteredData.includes(name))
        .map((field) => {
          const {
            name,
            type,
            label,
            value,
          } = field;

          const isDate = type === 'date';

          return (
            <tr key={ name }>
              <th>{ label }</th>
              <td>
                <EditableField
                  editMode={ false }
                  inputType={ isDate ? 'date' : 'string' }
                  name={ name }
                  label={ label }
                  value={ value || getFieldValue(field, secondaryClient) }
                />
              </td>
            </tr>
          );
        })
    );
  }

  function renderTableBody(title, body, titleElement = null) {
    return (
      <div className="table-body" key={ title }>
        <Table>
          <thead>
            <tr>
              <td colSpan="2">
                { titleElement || title }
              </td>
            </tr>
          </thead>
          <tbody>
            { body }
          </tbody>
        </Table>
      </div>
    );
  }

  function renderDocuments(status) {
    const clientType = status === 'Client'
      ? 'main'
      : 'secondary';

    const clientDocumentsCount = groupBy(clientDocuments[clientType].map(({ type }) => type));
    let multipleDocuments = Object.keys(clientDocumentsCount)
      .filter((type) => clientDocumentsCount[type].length > 1)
      .reduce((acc, type) => ({
        ...acc,
        [type]: clientDocumentsCount[type].length,
      }), {});

    return (
      <tr>
        <td colSpan="2">
          <div>
            <p className="table-head">
              Documents
            </p>
            {
              clientDocuments[clientType]
                .map((document) => {
                  const {
                    id,
                    mimeType,
                    type,
                  } = document;

                  const fileIcon = documentIcons[mimeType];

                  const isChecked = id in selectedDocuments[clientType];

                  let name = type.replace('client-', '');

                  if (type in multipleDocuments) {
                    name += `-${multipleDocuments[type]}`;
                    multipleDocuments = {
                      ...multipleDocuments,
                      [type]: multipleDocuments[type] -= 1,
                    };
                  }

                  return (
                    <div key={ name } className="document">
                      {
                      taskDocuments[applicationTask].length > 1
                        ? (
                          <i
                            className={ `mr-2 far fa-${isChecked ? 'check-' : ''}square checkbox fa-lg` }
                            onClick={ () => setRejectedDocuments(document, clientType) }
                          />
                        ) : null
                    }
                      <Button
                        variant={ `${isChecked ? 'success' : 'dark'}` }
                        size="sm"
                        className="btn-label document-link"
                        onClick={ () => openDocument(id) }
                      >
                        <i className={ `far fa-file-${fileIcon} fa-lg text-light` } />
                        <p>{ name }</p>
                      </Button>
                    </div>
                  );
                })
                .sort((a, b) => ((a.key <= b.key) ? -1 : 1))
            }
          </div>
        </td>
      </tr>
    );
  }

  function renderContactData(status) {
    const secondaryClient = status === 'SecondaryClient'
      ? renting.SecondaryClient
      : null;

    const displayDocuments = applicationTask
    && taskDocuments[applicationTask].length > 0
    && applicationTask !== 'Emergency contact'
    && !(applicationTask === 'Guarantor tenancy file' && status === 'Client');

    const clientMainData = [
      'firstName',
      'lastName',
      'nationality',
      'birthDate',
      'birthPlace',
    ];

    const guarantorMainData = guarantorCount === 1
      ? [
        'guarantorFullName',
        'guarantorEmail',
        'guarantorPhoneNumber',
        'guarantorBirthDate',
        'guarantorBirthPlace',
        'guarantorAddress',
      ]
      : ['guarantMe'];

    const emergencyMainData = [
      'emergencyContactKind',
      'emergencyFullName',
      'emergencyEmail',
      'emergencyPhoneNumber',
    ];

    const filteredDataFields = {
      'Client tenancy file': [
        ...clientMainData, 'guarantorCount', 'previousAddress', 'CityId', 'tax', 'payslip',
      ],
      'Guarantor tenancy file': [
        ...(status === 'Guarantor' ? [...guarantorMainData, 'guarantorCount', 'CityId', 'tax', 'payslip'] : ['email']),
      ],
      'Garantme tenancy file': [
        ...guarantorMainData, ...clientMainData, 'previousAddress', 'CityId',
      ],
      'Emergency contact': [
        ...(status === 'Emergency contact' ? emergencyMainData : ['firstName', 'lastName', 'email', 'phoneNumber']),
      ],
      'Send signing link': [
        ...(status === 'Guarantor' ? guarantorMainData : [...clientMainData,
          'previousAddress', 'email', 'phoneNumber']),
      ],
    };

    const title = `${status} information`;
    const body = (
      <>
        { parseContactData(filteredDataFields[applicationTask] || clientMainData, secondaryClient) }
        { displayDocuments ? renderDocuments(status !== 'Guarantor' ? status : 'Client') : null }
      </>
    );

    return renderTableBody(title, body);
  }
  function renderLink(link, label, name) {
    if (name === 'client-garantme-finances') {
      const divStyle = {
        color: '#4e73df',
        cursor: 'pointer',
      };
      return (
        <React.Fragment key={ label }>
          <span style={ divStyle } onClick={ () => openDocument(link) }>{label}</span>
          <br />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment key={ label }>
        <a href={ link } rel="noopener noreferrer" target="_blank">{label}</a>
        <br />
      </React.Fragment>
    );
  }

  function renderGenerateDocuments() {
    const title = 'Generate Documents';

    const body = (
      <>
        <tr>
          <th>Contract kind</th>
          <td>{ getContractKind() }</td>
        </tr>
        <tr>
          <td colSpan="2" className="py-3 text-center">
            {
              contractDocuments
                .filter(({ name }) => {
                  if (!guarantorCount) {
                    return !['pdfDepositClauseUrl', 'client-garantme-finances'].includes(name);
                  }
                  if (guarantorCount === 1) {
                    return name !== 'client-garantme-finances';
                  }

                  return name !== 'pdfDepositClauseUrl';
                })
                .map(({ name, label, value }) => {
                  let getDocumentLink;
                  let link = {};
                  if (name !== 'client-garantme-finances') {
                    getDocumentLink = () => client.Documents.find(({ type }) => name === type).url;
                  } else {
                    getDocumentLink = () => client.Documents.find(({ type }) => name === type).id;
                  }

                  link = value || get(client.Rentings.find(renting => renting.status === 'booked'), name, null) || getDocumentLink();

                  return renderLink(link, label, name);
                })
              }
          </td>
        </tr>
      </>
    );

    return renderTableBody(title, body);
  }

  function renderSigningData(renderGuarantorData = false) {
    const title = `${renderGuarantorData ? 'Guarantor ' : ''}Signing link Data`;

    const body = (
      <>
        <tr>
          <th>Sent on</th>
          {
            !renderGuarantorData || guarantorSigningTimestamp
              ? (
                <td>
                  {moment(renderGuarantorData
                    ? guarantorSigningTimestamp
                    : signingTimestamp).format('DD/MM/YYYY')}
                </td>
              )
              : (
                <td><i>No date.</i></td>
              )
          }
        </tr>
        {
          renderGuarantorData
            ? null
            : (
              <tr>
                <th>Contract kind</th>
                <td>{ getContractKind() }</td>
              </tr>
            )
        }
      </>
    );

    return renderTableBody(title, body);
  }

  function renderSigningUrl(renderGuarantorLink = false) {
    const mapLinks = {
      false: signingUrl,
      true: guarantorSigningUrl,
    };
    const title = `${renderGuarantorLink ? 'Guarantor ' : ''}Signing link Url`;
    const titleEl = (
      <div className="d-flex align-items-center justify-content-between">
        <span>
          { `${renderGuarantorLink ? 'Guarantor ' : ''}Signing link Url` }
        </span>
        {
          mapLinks[renderGuarantorLink]
            ? (
              <Button variant="primary" onClick={ () => sendSigningLinkReminder(renderGuarantorLink) }>
                Send reminder
              </Button>
            ) : ''
        }
      </div>
    );
    const body = (
      <tr>
        <td colSpan="2">
          {applicationTask === 'Send signing link'
            ? (
              <input
                className="w-100"
                type="text"
                placeholder="copy past your link here"
                onChange={ (e) => (renderGuarantorLink
                  ? setGuarantorSigningLinkUrl(e.target.value)
                  : setSigningLinkUrl(e.target.value)) }
              />
            )
            : (
              <>
                {
                  !renderGuarantorLink || guarantorSigningUrl
                    ? (
                      <a
                        href={ renderGuarantorLink ? guarantorSigningUrl : signingUrl }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {renderGuarantorLink ? guarantorSigningUrl : signingUrl}
                      </a>
                    )
                    : (<i>No link</i>)
                  }
              </>
            )}
        </td>
      </tr>
    );

    return renderTableBody(title, body, titleEl);
  }

  function setModalBody(task) {
    const modalBodyContent = {
      'Selfie & ID - Couple': () => [
        isSecondaryClient && renderContactData('SecondaryClient'),
      ],
      'Emergency contact': () => [
        renderContactData('Emergency contact'),
      ],
      'Guarantor tenancy file': () => [
        renderContactData('Guarantor'),
      ],
      'Send signing link': () => [
        isSecondaryClient && renderContactData('SecondaryClient'),
        renderSigningUrl(),
        [1, 3].includes(client.guarantorCount) && renderContactData('Guarantor'),
        hasGuarantor ? renderSigningUrl(true) : false,
        renderGenerateDocuments(),
      ],
      Signature: () => [
        renderSigningData(),
        renderSigningUrl(),
        hasGuarantor ? renderSigningData(true) : false,
        hasGuarantor ? renderSigningUrl(true) : false,
      ],
    };

    return task in modalBodyContent ? modalBodyContent[task]() : null;
  }

  function renderModalBody() {
    return (
      <>
        {renderContactData('Client')}
        { setModalBody(applicationTask) }
      </>
    );
  }

  function renderActionsButtons() {
    const documentsCount = Object.keys(clientDocuments).reduce((acc, type) => acc + clientDocuments[type].length, 0);

    const canRejectAllDocuments = [0, documentsCount].includes(getSelectedDocumentsCount());

    if (applicationTask === 'Send signing link') {
      return (
        <Col sm={{ span: 6, offset: 3 }}>
          <Button
            variant="success"
            className="w-100"
            onClick={ () => validateTask() }
            disabled={ !signingLinkUrl || (hasGuarantor && !guarantorSigningLinkUrl) }
          >
            Send
          </Button>
        </Col>
      );
    }

    if (applicationTask === 'Signature') {
      return (
        <>
          <Col sm="6" className="m-auto">
            <Button variant="success" className="w-100" onClick={ () => validateTask() }>
              Validate
            </Button>
          </Col>
        </>
      );
    }

    return (
      <>
        <Col sm="6">
          <Button
            variant="danger"
            className="w-100"
            onClick={ () => rejectDocument() }
          >
            {
              documentsCount <= 1
                ? 'Reject'
                : `Reject ${canRejectAllDocuments ? 'all' : 'selected'}`
            }
          </Button>
        </Col>
        <Col sm="6">
          <Button
            variant="success"
            className="w-100"
            onClick={ () => validateTask() }
            disabled={ !canRejectAllDocuments }
          >
            {`Validate ${documentsCount <= 1 ? '' : 'all'}`}
          </Button>
        </Col>
      </>
    );
  }

  function renderCheckinTimeslot() {
    const { checkinDate } = renting;
    const formattedCheckinDate = checkinDate ? moment(checkinDate).format('DD/MM/YYYY') : null;

    const NINE_AM = moment(checkinDate).set({ hours: 9, minutes: 0 });
    const SIX_PM = moment(checkinDate).set({ hours: 18, minutes: 0 });

    const isDayTimeslot = moment(checkinDate).isBetween(NINE_AM, SIX_PM);

    return (
      <span>
        <div className="ml-3">
          {
            formattedCheckinDate
              ? (
                <>
                  {`${formattedCheckinDate} - `}
                  <i className={ `icon fas fa-${isDayTimeslot ? 'sun' : 'moon'} ml-1` } />
                  { isDayTimeslot ? 'Day' : 'Night'}
                </>
              )
              : 'No checkin date'
          }
        </div>
      </span>
    );
  }

  return (
    <Modal size="lg" show={ show } onHide={ handleClose }>
      <Modal.Header>
        <Button
          variant="dark"
          onClick={ () => getRenting(applicationIndex - 1) }
          disabled={ applicationIndex === 0 }
        >
          prev
        </Button>
        <Modal.Title>
          {headerIcon}
          Application
        </Modal.Title>
        <Button
          variant="dark"
          onClick={ () => getRenting(applicationIndex + 1) }
          disabled={ applicationIndex >= applicationList.length - 1 }
        >
          next
        </Button>
      </Modal.Header>
      {
        isLoading
          ? <Loader />
          : (
            <>
              <Modal.Body>
                <div className="w-100 d-flex justify-content-between">
                  <div className="card-infos">
                    <h6>Element :</h6>
                    <Button variant="danger" size="sm" disabled>{applicationTask}</Button>
                  </div>
                  <div className="card-infos">
                    <h6>C/I date :</h6>
                    {renderCheckinTimeslot()}
                  </div>
                  <div className="card-infos">
                    <h6>Room :</h6>
                    <p className="mb-0 ml-3">{renting.Room.name}</p>
                  </div>
                </div>
                {renderModalBody()}
              </Modal.Body>
              <Modal.Footer>
                <Row className="w-100 m-auto">
                  {renderActionsButtons()}
                </Row>
              </Modal.Footer>
            </>
          )
      }
    </Modal>
  );
}

export default ApplicationModal;
