import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import capitalize from 'lodash/capitalize';
import moment from 'moment';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import ForestApi from '../../../../utils/API';
import copyText from '../../../../utils/copyText';
import NewRoom from '../../newRoom';
import RoundButton from '../../../RoundButton';

const LANGS = ['en', 'es', 'fr'];

function RoomsTab(props) {
  const { rooms, rentings, bookedRentings } = props;
  const [displayModal, toggleModal] = useState(false);

  function createRoom(data) {
    const { apartmentId, fetchApartment } = props;

    const room = {
      ...data,
      ApartmentId: apartmentId,
      basePrice: data.basePrice * 100,
    };

    toggleModal(false);

    new ForestApi().rooms()
      .create(room)
      .then(() => {
        toast('Room created!', {
          type: toast.TYPE.SUCCESS,
        });
        fetchApartment();
      });
  }

  if (!rooms || rooms.length === 0) {
    return (
      <Row>
        <Col>
          <Jumbotron className="text-center">
            <h1>No rooms</h1>
            <p>
              There are no rooms for this apartment yet.
            </p>
            <p>
              <Button variant="success" onClick={ () => toggleModal(true) }>
                <i className="icon fa fa-plus" />
                Create the first one
              </Button>
            </p>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  const isAvailable = (status) => ['stock', 'available', 'maintenance'].includes(status);
  const isOccupied = (status) => ['booked', 'occupied'].includes(status);
  const displayRooms = rooms.map((room) => {
    const isRoomAvailable = isAvailable(room.status);
    const isRoomOccupied = isOccupied(room.status);
    const isRoomBooked = room.status === 'booked';

    const basePrice = `${(room.basePrice / 100).toFixed(2)} €`;
    const floorArea = `${room.floorArea} m²`;
    let currentPrice = 'N/A';
    let availableAt = 'N/A';
    let ciAt = 'N/A';
    if (isRoomBooked) {
      const checkinDate = bookedRentings.find(renting => renting.Room.id === room.id)?.Events[0]?.startDate;
      if (checkinDate) {
        const NINE_AM = moment(checkinDate).set({
          hours: 9,
          minutes: 0,
        });
        const SIX_PM = moment(checkinDate).set({
          hours: 18,
          minutes: 0,
        });
        const isDay = moment(checkinDate).isBetween(NINE_AM, SIX_PM);
        ciAt = <span>
                  <i className={`icon fas fa-${isDay ? 'sun' : 'moon'}`}/>
                  {moment(checkinDate)?.format('DD/MM/YYYY')}
              </span>;
      }
    }

    if (isRoomAvailable) {
      currentPrice = `${(room.currentPrice / 100).toFixed(2)} €`;
      availableAt = moment(room.availableAt).format('DD/MM/YYYY');
    }

    if (isRoomOccupied) {
      const rentingPrice = room.Rentings.length > 0 ? room.Rentings[0].price : 0;

      currentPrice = `${(rentingPrice / 100).toFixed(2)} €`;
    }

    return (
      <tr key={ room.id } className={ isRoomAvailable ? 'font-weight-bold' : undefined }>
        <td>{ room.name }</td>
        <td>
          <Button size="sm" className={ `btn-label btn-${room.status}` } disabled>
            { capitalize(room.status) }
          </Button>
        </td>
        <td>{ basePrice }</td>
        <td>{ currentPrice }</td>
        <td>{ availableAt }</td>
        <td>{ ciAt }</td>
        <td>{ floorArea }</td>
        <td className="text-right">
          <a
            href={ room.canonicalUrlFr }
            className="btn btn-sm btn-dark mr-2"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="icon fas fa-eye" />
            Preview
          </a>
          <Link
            to={ `/rooms/${room.id}` }
            className="btn btn-sm btn-primary"
            rel="noopener noreferrer"
            target="_blank"
          >
            Go to room
            <i className="fas fa-arrow-right ml-2" />
          </Link>
        </td>
      </tr>
    );
  });

  const roommates = rooms.map((room) => {
    if (room.Rentings.length === 0) {
      return null;
    }

    const { Client: client, status } = room.Rentings[0];
    const {
      email,
      preferredLanguage,
      secondaryEmail,
    } = client;

    if (!['booked', 'checked-in'].includes(status)) {
      return null;
    }

    return {
      email,
      preferredLanguage,
      secondaryEmail,
    };
  }).filter(Boolean);

  const getRoommatesEmailsByLanguage = (language) => roommates
    .filter(({ preferredLanguage }) => preferredLanguage === language)
    .reduce((acc, roommate) => [...acc, roommate.email, roommate.secondaryEmail], [])
    .filter(Boolean)
    .join(',');

  const roommatesEmails = LANGS.reduce((acc, language) => ({
    ...acc,
    [language]: getRoommatesEmailsByLanguage(language),
  }), {});

  const allRoommatesEmails = Object.values(roommatesEmails).filter(Boolean).join(',');
  const CIRoommatesEmails = rentings.map(({Client: {email, secondaryEmail}}) => {
    return secondaryEmail ? `${email},${secondaryEmail}` : email;
  });

  const copyLanguagesEmailsItems = LANGS.map((lang) => {
    const emails = roommatesEmails[lang];

    if (emails.length === 0) {
      return null;
    }

    return (
      <Dropdown.Item
        key={ `${lang}-dropdown-item` }
        onClick={ () => copyText(emails) }
      >
        { lang.toUpperCase() }
        {' '}
        only
      </Dropdown.Item>
    );
  }).filter(Boolean);

  return (
    <div className="rooms-tab">
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <div className="d-flex align-items-center justify-content-between">
                <strong>Rooms</strong>
                <Dropdown as={ ButtonGroup }>
                  <Button size="sm" variant="secondary" onClick={ () => copyText(CIRoommatesEmails) }>
                    <i className="icon far fa-copy" />
                    Copy checked-in roommates emails
                  </Button>
                  <Button size="sm" variant="secondary" onClick={ () => copyText(allRoommatesEmails) }>
                    <i className="icon far fa-copy" />
                    Copy roommates emails
                  </Button>
                  <Dropdown.Toggle split variant="secondary" size="sm" id="copy-emails-dropdown">
                    <Dropdown.Menu alignRight>
                      { copyLanguagesEmailsItems }
                    </Dropdown.Menu>
                  </Dropdown.Toggle>
                </Dropdown>
              </div>
            </Card.Header>
            <Table hover>
              <thead>
                <tr>
                  <th>Room name - #number</th>
                  <th>Status</th>
                  <th>Base price</th>
                  <th>Current price</th>
                  <th>Available at</th>
                  <th>CI at (booked only)</th>
                  <th>Floor area</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                { displayRooms }
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
      <RoundButton
        handleClick={ () => toggleModal(true) }
      />
      {
        displayModal
          ? (
            <NewRoom
              show
              handleClose={ () => toggleModal(false) }
              handleSubmit={ createRoom }
            />
          )
          : null
      }
    </div>
  );
}

export default RoomsTab;
