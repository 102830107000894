import React, { useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../../utils/API';
import openDocument from '../../../utils/openDocument';
import Loader from '../../Loader';

function Documents(props) {
  const { clientId, forms } = props;
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const query = `documentable=Client&DocumentableId=${clientId}`;

    new ForestApi().documents()
      .getAll(query)
      .then((res) => setDocuments(res.data));
  }, [clientId]);

  const documentIcons = {
    'image/jpeg': 'image',
    'image/png': 'image',
    'application/pdf': 'pdf',
    'text/xml': 'excel',
  };

  function renderDocument(document) {
    const {
      fileName,
      mimeType,
      type,
    } = document;

    const fileIcon = documentIcons[mimeType];

    return (
      <li key={ document.id }>
        <Card className="document" onClick={ () => openDocument(document.id) }>
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between">
              <i className={ `far fa-file-${fileIcon} fa-lg text-dark` } />
              { fileName }
              <Button variant="dark" size="sm" className="btn-label" disabled>
                { type.replace('client-', '') }
              </Button>
            </div>
          </Card.Body>
        </Card>
      </li>
    );
  }

  function renderForm(form) {
    const {
      label,
      url,
    } = form;

    if (!url) {
      return null;
    }

    return (
      <li key={ form.label }>
        <a href={ url } target="_blank" rel="noopener noreferrer">
          <Card className="document">
            <Card.Body>
              <div className="d-flex align-items-center justify-content-between">
                <i className="mr-4 fas fa-clipboard-list fa-lg text-dark" />
                { label }
                <Button variant="dark" size="sm" className="btn-label" disabled>
                  jotform
                </Button>
              </div>
            </Card.Body>
          </Card>
        </a>
      </li>
    );
  }

  function renderDocumentsPlaceholder() {
    return (
      <Jumbotron className="text-center">
        <h1>No documents</h1>
        <p>
          This client did not upload any document yet.
        </p>
      </Jumbotron>
    );
  }

  function returnFormsPlaceholder() {
    return (
      <Jumbotron className="text-center">
        <h1>No jotforms</h1>
        <p>
          This client did not fill any Jotform yet.
        </p>
      </Jumbotron>
    );
  }

  const formsUrls = forms.map(({ url }) => url).filter(Boolean);

  const documentList = documents.length === 0
    ? renderDocumentsPlaceholder()
    : (
      <ul className="documents-list">
        { documents.map(renderDocument) }
      </ul>
    );

  return (
    <div className="Documents">
      <Row className="mt-4">
        <Col lg="6">
          <Card>
            <Card.Header>
              User documents
            </Card.Header>
            <Card.Body>
              {
                documents
                  ? documentList
                  : <Loader />
              }
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6">
          <Card>
            <Card.Header>
              Jotforms
            </Card.Header>
            <Card.Body>
              {
                formsUrls.length > 0
                  ? (
                    <ul className="documents-list">
                      { forms.map(renderForm) }
                    </ul>
                  )
                  : returnFormsPlaceholder()
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Documents;
