import axios from 'axios';
import _const from '../const';

import store from '../store';

const { API_BASE_URL } = _const;

const API = axios.create({
  baseURL: `${API_BASE_URL}/jungle`,
  responseType: 'json',
}) || axios;

const BLOB_API = axios.create({
  baseURL: `${API_BASE_URL}/jungle`,
  responseType: 'blob',
});

class ForestApi {
  headers = () => ({
    headers: {
      Authorization: `JWT ${store.getState().user.token}`,
    },
  });

  get = (endpoint) => API.get(endpoint, this.headers());

  post = (endpoint, data) => API.post(endpoint, data, this.headers());

  put = (endpoint, data) => API.put(endpoint, data, this.headers());

  delete = (endpoint, data) => API.delete(endpoint, { ...this.headers(), data });

  getBlob = (endpoint) => BLOB_API.get(endpoint, this.headers());

  postBlob = (endpoint, data) => BLOB_API.post(endpoint, data, this.headers());

  apartments = () => ({
    getAll: (data) => this.get(`/apartments${data ? `?${data}` : ''}`),
    search: (data) => this.get(`/apartments/search?${data}`),
    getOne: (id, withCurrentRentings) => this.get(`/apartments/${id}${withCurrentRentings ? '?currentRenting=1' : ''}`),
    getRooms: (id) => this.get(`/apartments/${id}/rooms`),
    create: (data) => this.post('/apartments', data),
    update: (id, data) => this.put(`/apartments/${id}`, data),
    assignContact: (id, data) => this.post(`/apartments/${id}/contact`, data),
    unassignContact: (id, data) => this.delete(`/apartments/${id}/contact`, data),
    toggleFeature: (id, data) => this.put(`/apartments/${id}/term`, data),
    updateTranslations: (id, data) => this.put(`/apartments/${id}/i18ns`, data),
  });

  cities = () => ({
    getAll: () => this.get('/cities'),
    getApartments: (id) => this.get(`/cities/${id}/apartments`),
    getNeighborhoods: (id) => this.get(`/cities/${id}/neighborhoods`),
  });

  clients = () => ({
    getBookings: () => this.get('/clients/bookings'),
    searchBookings: (data) => this.get(`/clients/bookings/search?${data}`),
    search: (data) => this.get(`/clients/search?${data}`),
    getOne: (id) => this.get(`/clients/${id}`),
    update: (id, data) => this.put(`/clients/${id}`, data),
  });

  contacts = () => ({
    search: (data) => this.get(`/contacts/search?${data}`),
    getOne: (id) => this.get(`/contacts/${id}`),
    create: (data) => this.post('/contacts', data),
    update: (id, data) => this.put(`/contacts/${id}`, data),
  });

  contractorEvents = () => ({
    getAll: (data) => this.get(`/contractor-events?${data}`),
    getRefunds: () => this.get('/contractor-events/refunds'),
    search: (data) => this.get(`/contractor-events/refunds/search?${data}`),
    update: (id, data) => this.put(`/contractor-events/${id}`, data),
  });

  contractors = () => ({
    getAll: (data) => this.get(`/contractors${data ? `?${data}` : ''}`),
    getOne: (id) => this.get(`/contractors/${id}`),
    create: (data) => this.post('/contractors', data),
    update: (id, data) => this.put(`/contractors/${id}`, data),
    activate: (id) => this.get(`/contractors/${id}/activate`),
    sendInvitations: (data) => this.post('/contractors/send-invitations', data),
  });

  depositRefunds = () => ({
    getProducts: (data) => this.get(`/deposit-refunds/products?${data}`),
    create: (data) => this.post('/deposit-refunds', data),
    sync: (id, data) => this.post(`/deposit-refunds/${id}/sync`, data),
    activate: (id) => this.get(`/deposit-refunds/${id}/activate`),
  });

  documents = () => ({
    getAll: (data) => this.get(`/documents?${data}`),
    getOne: (id) => this.getBlob(`/documents/${id}`),
  });

  events = () => ({
    getAll: (data) => this.get(`/events?${data}`),
    search: (data) => this.get(`/events/search?${data}`),
    getOne: (id) => this.get(`/events/${id}`),
    getOneCalendarDescription: (id) => this.get(`/events/${id}/calendar-description`),
  });

  interventions = () => ({
    getAll: (data) => this.get(`/interventions${data ? `?${data}` : ''}`),
    search: (data) => this.get(`/interventions/search?${data}`),
    getTypes: (data) => this.get(`/interventions/types${data ? `?${data}` : ''}`),
    getOne: (id) => this.get(`/interventions/${id}`),
    create: (data) => this.post('/interventions', data),
    update: (id, data) => this.put(`/interventions/${id}`, data),
    triggerNode: (id, data) => this.post(`/interventions/${id}/triggerCurrentNode`, data),
  });

  login = (email, password) => API.post('/login', { email, password });

  neighborhoods = () => ({
    getAll: () => this.get('/neighborhoods'),
  });

  orders = () => ({
    getAll: (data) => this.get(`/orders?${data}`),
    getDownloadUrl: (id) => this.get(`orders/${id}/download-url`),
  });

  pictures = () => ({
    create: (data) => this.post('/pictures', data),
    update: (id, data) => this.put(`/pictures/${id}`, data),
    delete: (id) => this.delete(`/pictures/${id}`),
    bulkUpdate: (data) => this.post('/pictures/bulkUpdate', data),
  });

  rentings = () => ({
    getAll: (data) => this.get(`/rentings${data ? `?${data}` : ''}`),
    update: (id, data) => this.put(`/rentings/${id}`, data),
    rejectDocuments: (id, data) => this.get(`/rentings/${id}/reject-documents?${data}`),
  });

  rooms = () => ({
    getAll: (data) => this.get(`/rooms${data ? `?${data}` : ''}`),
    getOne: (id) => this.get(`/rooms/${id}`),
    search: (data) => this.get(`/rooms/search?${data}`),
    create: (data) => this.post('/rooms', data),
    update: (id, data) => this.put(`/rooms/${id}`, data),
    toggleFeature: (id, data) => this.put(`/rooms/${id}/term`, data),
  });

  transactions = () => ({
    getAll: (data) => this.get(`/transactions${data ? `?${data}` : ''}`),
    getOne: (id) => this.get(`/transactions/${id}`),
    delete: (id) => this.delete(`/transactions/${id}`),
    generateXml: (data) => this.postBlob('/transactions/generate-xml', data),
    refund: (id, data) => this.post(`/transactions/${id}/refund`, data),
    bulkRefund: (data) => this.post('/transactions/bulk-refund', data),
  });

  users = () => ({
    getAll: (data) => this.get(`/users${data ? `?${data}` : ''}`),
    resetPasswordRequest: (data) => this.post('/users/reset-password', data),
    updatePassword: (data) => this.post('/users/update-password', data),
  });
}

export default ForestApi;
