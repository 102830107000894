import React from 'react';
import {
  Router,
  Route,
  Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

import history from './utils/history';
import PrivateRoute from './components/PrivateRoute';
import App from './App';
import LoginPage from './LoginPage';
import ResetPasswordPage from './ResetPasswordPage';
import * as userActions from './redux/actions/userActions';
import ContractorEvent from './components/ContractorEvent';

function Routes({ store, setSession, setUser }) {
  const { role } = store;

  const getTokenExpiration = () => {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const isAuthenticated = moment().valueOf() < expiresAt;

    if (isAuthenticated && !role) {
      setUser({
        token: localStorage.getItem('access_token'),
        role: localStorage.getItem('role'),
        team: localStorage.getItem('team'),
        email: localStorage.getItem('email'),
      });
    }

    return isAuthenticated;
  };

  return (
    <Router history={ history }>
      <Switch>
        <Route
          path="/reset-password"
          render={ (props) => <ResetPasswordPage { ...props } /> }
        />
        <Route
          path="/login"
          render={ (props) => (
            <LoginPage
              isAuthenticated={ () => getTokenExpiration() }
              login={ (email, password) => setSession(email, password) }
              { ...props }
            />
          ) }
        />
        <Route
          path="/contractor-event/:id/:status"
          render={ (props) => <ContractorEvent { ...props } /> }
        />
        <PrivateRoute
          path="/"
          isAuthenticated={ () => getTokenExpiration() }
          component={ (props) => <App { ...props } /> }
        />
      </Switch>
      <ToastContainer position={ toast.POSITION.BOTTOM_RIGHT } />
    </Router>
  );
}

const mapStateToProps = (store) => ({
  store: store.user,
});

const mapDispatchToProps = (dispatch) => ({
  setSession: (email, password) => dispatch(userActions.setSession(email, password)),
  setUser: (localStorageData) => dispatch(userActions.setUser(localStorageData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
