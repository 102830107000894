import React from 'react';
import startCase from 'lodash/startCase';

import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import Modal from 'react-bootstrap/Modal';

function I18nModal(props) {
  const {
    i18nKey,
    fieldsToUpdate,
    editField,
    closeModal,
    updateTranslations,
    updatedTranslations,
  } = props;

  const fieldsGroup = Object.keys(fieldsToUpdate)
    .sort().reverse()
    .map((key) => (
      <FormGroup key={ key }>
        <FormLabel>{ key.split('.')[1].toUpperCase() }</FormLabel>
        <FormControl
          as="textarea"
          value={ fieldsToUpdate[key] }
          onChange={ (e) => editField({
            ...fieldsToUpdate,
            [key]: e.target.value,
          }) }
        />
      </FormGroup>
    ));

  return (
    <Modal size="lg" show={ Boolean(i18nKey) } onHide={ () => closeModal() }>
      <Modal.Header closeButton>{ startCase(i18nKey) }</Modal.Header>
      <Modal.Body>
        { fieldsGroup }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ () => closeModal() }>Cancel</Button>
        <Button disabled={ !updatedTranslations() } onClick={ () => updateTranslations() }>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default I18nModal;
