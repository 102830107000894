import React from 'react';
import capitalize from 'lodash/capitalize';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { EMAIL_REGEX } from '../../utils/emailsHelpers';

function FormField(props) {
  const {
    field,
    handleChange,
  } = props;

  const {
    type,
    name,
    options,
    label,
    value,
    required,
  } = field;

  const displayLabel = `${label} ${required ? '*' : ''}`;

  function renderTextField() {
    return (
      <>
        <Form.Label>{ displayLabel }</Form.Label>
        <Form.Control
          type={ type }
          value={ value }
          onChange={ (e) => handleChange(name, e.target.value) }
          pattern={ type === 'email' ? EMAIL_REGEX.source : null }
          required={ required }
        />
      </>
    );
  }

  function renderCheckboxField() {
    return (
      <div className="form-check">
        <input
          id={ name }
          type="checkbox"
          name={ name }
          className="form-check-input"
          checked={ value }
          onChange={ () => handleChange(name, !value) }
          required={ required }
        />
        <label className="form-check-label" htmlFor={ name }>
          { displayLabel }
        </label>
      </div>
    );
  }

  function renderRadioField() {
    const radioButtons = options.map((option, index) => (
      <div key={ option } className="form-check">
        <input
          id={ `${name}-${index}` }
          type="radio"
          name={ name }
          className="form-check-input"
          checked={ value === option }
          onChange={ () => handleChange(name, option) }
          required={ required }
        />
        <label className="form-check-label" htmlFor={ `${name}-${index}` }>
          { option }
        </label>
      </div>
    ));

    return (
      <>
        <Form.Label>{ displayLabel }</Form.Label>
        { radioButtons }
      </>
    );
  }

  function renderSelectField() {
    if (options.length === 0) {
      return null;
    }

    const renderOptions = options.map((option) => (
      <option key={ option.value } value={ option.value }>
        { option.label }
      </option>
    ));

    return (
      <>
        <Form.Label>{ displayLabel }</Form.Label>
        <Form.Control
          as="select"
          onChange={ (e) => handleChange(name, e.target.value) }
          required={ required }
        >
          <option value="">Pick one...</option>
          { renderOptions }
        </Form.Control>
      </>
    );
  }

  function renderTagField() {
    if (options.length === 0) {
      return null;
    }

    const renderTags = options.map((option) => (
      <Button
        key={ option }
        variant="secondary"
        size="sm"
        className="mr-2 mt-2"
        onClick={ () => handleChange(name, option) }
      >
        { capitalize(option) }
      </Button>
    ));

    return (
      <>
        <Form.Label className="mr-2">{ displayLabel }</Form.Label>
        {
          value
            ? (
              <Button variant="success" size="sm" onClick={ () => handleChange(name, '') }>
                <span className="mr-4">{ capitalize(value) }</span>
                <i className="fas fa-times" />
              </Button>
            )
            : (
              <div className="d-flex flex-wrap align-items-center align-content-around">
                { renderTags }
              </div>
            )
        }
      </>
    );
  }

  function renderTagsField() {
    if (options.length === 0) {
      return null;
    }

    const renderTags = options.map((option) => {
      const tags = value.split(',');
      const isSelected = tags.includes(option);

      const newTags = isSelected
        ? tags.filter((t) => t !== option).join(',')
        : [...tags, option].filter(Boolean).join(',');

      return (
        <Button
          key={ option }
          variant={ isSelected ? 'success' : 'secondary' }
          size="sm"
          className="mr-2 mt-2"
          onClick={ () => handleChange(name, newTags) }
        >
          <span className={ isSelected ? 'mr-4' : '' }>{ option }</span>
          { isSelected && <i className="fas fa-times" /> }
        </Button>
      );
    });

    return (
      <>
        <Form.Label>{ displayLabel }</Form.Label>
        <div className="d-flex flex-wrap align-items-center align-content-around">
          { renderTags }
        </div>
      </>
    );
  }

  function renderTextareaField() {
    return (
      <>
        <Form.Label>{ displayLabel }</Form.Label>
        <Form.Control
          as="textarea"
          value={ value }
          rows="4"
          onChange={ (e) => handleChange(name, e.target.value) }
        />
      </>
    );
  }

  function renderField() {
    switch (type) {
      case 'checkbox':
        return renderCheckboxField();
      case 'radio':
        return renderRadioField();
      case 'select':
        return renderSelectField();
      case 'tag':
        return renderTagField();
      case 'tags':
        return renderTagsField();
      case 'textarea':
        return renderTextareaField();
      default:
        return renderTextField();
    }
  }

  return (
    <Form.Group as={ Col }>
      { renderField() }
    </Form.Group>
  );
}

export default FormField;
