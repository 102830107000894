import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import { toast } from 'react-toastify';
import { Promise } from 'bluebird';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import ForestApi from '../../../../utils/API';
import {
  APARTMENT_FEATURES_FIELDS,
  dynamicFields,
  DANDQ as getDandqForm,
  IMMO as getImmoForm,
  ROOM_FEATURES_FIELDS,
  SALES as getSalesForm,
} from '../../../../utils/apartmentsFields';
import { getCategoryCompletion } from '../../../../utils/getCompletion';
import EditableField from '../../../EditableField';
import RoundButton from '../../../RoundButton';

import CategoryCard from './categoryCard';
import ContactCard from './contactCard';
import ContactForm from './contactForm';
import I18nModal from './i18nModal';
import Pictures from './pictures';

const MONEY_FIELDS = [
  'agencyFees',
  'deposit',
  'rentDiscount',
  'commissionPayment',
  'firstMonthRent',
  'rentExcludingCharges',
  'rentCharges',
  'estimatedWorkCost',
  'furnishingCost',
  'realWorkCost',
  'dismantlingWorkCost',
  'basePrice',
];

const LYON_FIELDS = [
  'waterCompanyLogin',
  'waterCompanyPassword',
];

const LOCATIONS_BUTTONS = [
  { key: 'apartment-access', label: 'Access' },
  { key: 'garbage-room-access', label: 'Access' },
  { key: 'bike-shelter-access', label: 'Access' },
  { key: 'electricity-meter-location', label: 'Electric meter kind' },
  { key: 'circuit-breaker-location', label: 'Circuit breaker' },
  { key: 'switchboard-location', label: 'Switchboard' },
  { key: 'internet-modem-location', label: 'Internet modem location' },
  { key: 'water-meter-location', label: 'Water meter location' },
  { key: 'hot-water-meter-location', label: 'Hot water meter location' },
  { key: 'gas-meter-location', label: 'Gas meter' },
  { key: 'cellarAccess', label: 'Cellar location' },
];

function ConfigurationTab(props) {
  const { apartment, team, fetchApartment } = props;
  const [editMode, toggleEditMode] = useState(false);
  const [apartmentFieldsToUpdate, updateApartmentFields] = useState({});
  const [roomsFieldsToUpdate, updateRoomsFields] = useState({});
  const [i18nKey, setI18nKey] = useState(null);
  const [activeView, switchActiveView] = useState('apartment');
  const [cellarAccessModal, toggleCellarAccessModal] = useState(false);
  const [displayContactModal, toggleContactModal] = useState(false);
  const [contactToEdit, setContactToEdit] = useState();
  const [activeCard, setActiveCard] = useState({
    category: '',
    roomId: '',
  });

  const formattedTeamCase = team.toLowerCase().replace('&', 'and');

  const globalCompletion = apartment[formattedTeamCase];
  const apartmentCategoryCompletion = getCategoryCompletion(formattedTeamCase, apartment);

  const isRoomView = activeView === 'room';
  const room = isRoomView && activeCard.roomId
    ? apartment.Rooms.find(({ id }) => id === activeCard.roomId)
    : null;

  const roomsCategoryCompletion = isRoomView
    ? apartment.Rooms.reduce((acc, aptRoom) => ({
      ...acc,
      [aptRoom.id]: getCategoryCompletion(formattedTeamCase, aptRoom),
    }), {})
    : null;

  const selectedTeamForm = {
    IMMO: () => getImmoForm(apartment),
    'D&Q': () => getDandqForm(apartment),
    SALES: () => getSalesForm(apartment),
  }[team]();

  async function updateApartment(fields) {
    await new ForestApi().apartments()
      .update(apartment.id, fields || apartmentFieldsToUpdate);

    updateApartmentFields({});
  }

  async function updateRooms(fields) {
    const roomsIds = fields
      ? Object.keys(fields)
      : Object.keys(roomsFieldsToUpdate);

    await Promise.map(roomsIds, (roomId) => {
      const roomFields = (fields && fields[roomId]) || roomsFieldsToUpdate[roomId];

      new ForestApi().rooms().update(roomId, roomFields);
    });

    updateRoomsFields({});
  }

  async function updateAll() {
    if (!isEmpty(apartmentFieldsToUpdate)) {
      await updateApartment();
    }

    if (!isEmpty(roomsFieldsToUpdate)) {
      await updateRooms();
    }

    toast('Apartment updated!', {
      type: toast.TYPE.SUCCESS,
    });

    if (cellarAccessModal) toggleCellarAccessModal(false);

    toggleEditMode();
    fetchApartment();
  }

  async function toggleFeatures(roomId) {
    if (isRoomView && roomId) {
      const { featuresChecked } = apartment.Rooms.find(({ id }) => id === roomId);

      await updateRooms({
        [roomId]: {
          featuresChecked: !featuresChecked,
        },
      });
    } else {
      await updateApartment({
        featuresCompleted: !apartment.featuresCompleted,
      });
    }

    toast('Apartment updated!', {
      type: toast.TYPE.SUCCESS,
    });

    fetchApartment();
  }

  const parseFieldValue = (field) => {
    const {
      isMoney,
      name,
      options,
    } = field;

    const model = isRoomView ? room : apartment;
    const fieldValue = model[name];

    const isFeature = isRoomView
      ? ROOM_FEATURES_FIELDS.includes(name)
      : APARTMENT_FEATURES_FIELDS.includes(name);

    if (editMode) {
      const fieldsToUpdate = isRoomView
        ? roomsFieldsToUpdate[room.id]
        : apartmentFieldsToUpdate;

      if (fieldsToUpdate && name in fieldsToUpdate) {
        const updatedValue = fieldsToUpdate[name];

        return isMoney ? updatedValue / 100 : `${updatedValue}`;
      }

      if (isFeature) {
        const feature = model.features.find((dbFeature) => dbFeature.field === name);

        return feature.value;
      }
    }

    if (isFeature) {
      const feature = model.features.find((dbFeature) => dbFeature.field === name);

      if (feature.type === 'Boolean') {
        return feature.value;
      }

      if (feature.type === 'Enum') {
        if (feature.value) {
          const option = options.find((opt) => {
            if (typeof opt === 'string') {
              return opt === feature.value;
            }

            return Object.keys(opt)[0] === feature.value;
          });

          return typeof option === 'string' ? option : option[feature.value];
        }

        return null;
      }

      return feature.value || '';
    }

    if (fieldValue === null) {
      return null;
    }

    if (isMoney) {
      return fieldValue / 100;
    }

    return fieldValue;
  };

  const filterDynamicFields = (field) => {
    const dynamicField = dynamicFields[field.name];

    if (APARTMENT_FEATURES_FIELDS.includes(dynamicField)) {
      return Boolean(apartment.features.find((item) => item.field === dynamicField).value);
    }

    if (field.name === 'hotWaterMeterSerialNumber') {
      return apartment[dynamicField] === 'hot and cold';
    }

    if (field.name === 'heatingKind') {
      return Boolean(room[dynamicField]);
    }

    if (field.name === 'heatingKind') {
      return true;
    }

    return Boolean(apartment[dynamicField]);
  };

  const editableFieldProps = {
    editFieldValue: (name, value) => {
      let parsedValue = value;

      if (isRoomView) {
        const [fieldName, roomId] = name.split('_');

        if (MONEY_FIELDS.includes(fieldName)) {
          parsedValue = Math.round(Number(value) * 100);
        }

        const roomToUpdate = {
          [roomId]: {
            ...roomsFieldsToUpdate[roomId],
            [fieldName]: parsedValue,
          },
        };

        return updateRoomsFields({
          ...roomsFieldsToUpdate,
          ...roomToUpdate,
        });
      }

      if (MONEY_FIELDS.includes(name)) {
        parsedValue = Math.round(Number(value) * 100);
      }

      return updateApartmentFields({
        ...apartmentFieldsToUpdate,
        [name]: parsedValue,
      });
    },
    editMode,
    fieldsToUpdate: isRoomView ? roomsFieldsToUpdate : apartmentFieldsToUpdate,
  };

  const getI18ns = (key) => apartment.I18ns
    .filter((i18n) => i18n.key === key)
    .reduce((acc, i18n) => {
      const lang = i18n.locale.split('-')[0];
      acc[`${i18n.key}.${lang}`] = i18n.value || '';

      return acc;
    }, {});

  const openModal = (key) => {
    updateApartmentFields(getI18ns(key));
    setI18nKey(key);
  };

  const closeModal = () => {
    updateApartmentFields({});
    setI18nKey(null);
  };

  const updateTranslations = () => {
    new ForestApi().apartments()
      .updateTranslations(apartment.id, apartmentFieldsToUpdate)
      .then(() => {
        toast('Translations updated!', {
          type: toast.TYPE.SUCCESS,
        });
        fetchApartment();
        closeModal();
      });
  };

  const updatedTranslations = () => {
    const initialTranslations = getI18ns(i18nKey);

    return Object.keys(apartmentFieldsToUpdate)
      .filter((key) => apartmentFieldsToUpdate[key] !== initialTranslations[key])
      .length > 0;
  };

  async function unassignContact(contactId, type) {
    const data = { contactId, type };

    await new ForestApi().apartments()
      .unassignContact(apartment.id, data)
      .then(() => {
        toast('Contact unassigned!', {
          type: toast.TYPE.SUCCESS,
        });
      });

    if (contactId === apartment.mainContact) {
      await new ForestApi().apartments().update(apartment.id, { mainContact: null });
    }

    fetchApartment();
  }

  const handleEditContact = (contact, type) => {
    setContactToEdit(contact ? { contact, type } : null);
    toggleContactModal(true);
  };

  const getFeatureColor = (field, value) => {
    const { name } = field;

    if (!name || team !== 'SALES') {
      return null;
    }

    const dangerDisplayedValues = ['0', 'nonexistent'];

    return value && !dangerDisplayedValues.includes(value)
      ? 'text-success'
      : 'text-danger';
  };

  const openCellarAccessModal = () => {
    updateApartmentFields({
      cellarAccess: apartment.cellarAccess || '',
    });
    toggleCellarAccessModal(true);
  };

  const closeCellarAccessModal = () => {
    updateApartmentFields({});
    toggleCellarAccessModal(false);
  };

  const renderDefaultField = ({ defaultName, toReturn }) => (
    <tr key={ defaultName }>
      <th className="w-25">{defaultName}</th>
      <td>{ toReturn }</td>
    </tr>
  );

  const renderLocationButton = (field) => {
    const isCellarAccess = field.name === 'cellarAccess';

    const isCompleted = isCellarAccess
      ? !isEmpty(apartment.cellarAccess)
      : (
        apartment.I18ns.filter((item) => item.key === field.name && !isEmpty(item.value)).length === 3
      );

    const displayModal = isCellarAccess ? () => openCellarAccessModal() : () => openModal(field.name);

    return (
      <tr key={ field.name }>
        <td colSpan="2">
          <div className="d-flex align-items-center">
            {
              !isCompleted
                ? <i className="fas fa-exclamation-circle text-danger mr-2" />
                : null
            }
            <Button
              key={ field.name }
              variant="secondary"
              block
              onClick={ displayModal }
            >
              { LOCATIONS_BUTTONS.find((item) => item.key === field.name).label }
            </Button>
          </div>
        </td>
      </tr>
    );
  };

  const renderContacts = () => {
    const { ApartmentContacts, mainContact } = apartment;

    return (
      <ContactCard
        ApartmentContacts={ ApartmentContacts }
        mainContact={ mainContact }
        handleEditContact={ handleEditContact }
        unassignContact={ unassignContact }
        key="Contacts"
      />
    );
  };

  const renderRoomField = (field) => {
    if (field.toReturn) {
      return renderDefaultField(field);
    }

    const roomValue = parseFieldValue(field);

    return (
      <tr key={ field.defaultName }>
        <th className="w-25">{ field.defaultName }</th>
        <td className={ !editMode ? getFeatureColor(field, roomValue) : '' }>
          {
            field.defaultName === 'Reference'
              ? room.reference
              : (
                <EditableField
                  { ...editableFieldProps }
                  { ...field }
                  name={ `${field.name}_${room.id}` }
                  label={ roomValue }
                  linkLabel={ roomValue }
                  value={ roomValue }
                />
              )
            }
        </td>
      </tr>
    );
  };

  const renderApartmentField = (field) => {
    const apartmentValue = parseFieldValue(field);

    return (
      <tr key={ field.defaultName }>
        <th className="w-25">{ field.defaultName }</th>
        <td className={ !editMode ? getFeatureColor(field, apartmentValue) : null }>
          <EditableField
            { ...editableFieldProps }
            { ...field }
            label={ apartmentValue }
            value={ apartmentValue }
          />
        </td>
      </tr>
    );
  };

  const renderField = (field) => {
    if (field.toReturn) {
      return renderDefaultField(field);
    }

    if (LOCATIONS_BUTTONS.find((item) => item.key === field.name)) {
      return renderLocationButton(field);
    }

    if (field.defaultName === 'Contacts') {
      return renderContacts();
    }

    if (isRoomView) {
      return renderRoomField(field);
    }

    return renderApartmentField(field);
  };

  const renderCategoryCardBody = (category) => {
    if (/pictures/i.test(category)) {
      return (
        <Pictures
          pictures={ apartment.Pictures }
          apartmentId={ apartment.id }
          room={ room }
          fetchApartment={ () => fetchApartment() }
        />
      );
    }

    const mappedCategoryFields = selectedTeamForm[category]
      .filter((field) => {
        if (apartment.CityId !== 'lyon') {
          return !LYON_FIELDS.includes(field.name);
        }

        if (field.name in dynamicFields) {
          return filterDynamicFields(field, isRoomView ? room : null);
        }

        return true;
      })
      .map(renderField);

    return (
      <Card.Body className="p-0">
        <Table className="m-0">
          <tbody>
            { mappedCategoryFields }
          </tbody>
        </Table>
      </Card.Body>
    );
  };

  const handleFieldChange = (name, value) => updateApartmentFields({
    ...apartmentFieldsToUpdate,
    [name]: value,
  });

  const handleCategoryClick = (name, roomId) => {
    setActiveCard({ name, roomId });
  };

  const renderCellarAccessModal = () => {
    const isSaveDisabled = isEmpty(apartmentFieldsToUpdate.cellarAccess);

    return (
      <Modal size="lg" show={ cellarAccessModal } onHide={ () => closeCellarAccessModal() }>
        <Modal.Header closeButton>Cellar Access</Modal.Header>
        <Modal.Body>
          <FormGroup key="Cellar Access">
            <FormControl
              as="textarea"
              value={ apartmentFieldsToUpdate.cellarAccess }
              onChange={ (e) => handleFieldChange('cellarAccess', e.target.value) }
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ () => closeCellarAccessModal() }>Cancel</Button>
          <Button disabled={ isSaveDisabled } onClick={ () => updateAll() }>Save</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const switchButton = (
    <Col sm={{ span: 12, order: 2 }} lg={{ span: 2, order: 3 }} className="mt-4">
      <ButtonGroup aria-label="Basic example" size="lg">
        <Button
          className="switchButton"
          variant={ !isRoomView ? 'primary' : 'secondary' }
          onClick={ () => switchActiveView('apartment') }
        >
          <i className="fas fa-building" />
        </Button>
        <Button
          className="switchButton"
          variant={ isRoomView ? 'primary' : 'secondary' }
          onClick={ () => switchActiveView('room') }
        >
          <i className="fas fa-bed" />
        </Button>
      </ButtonGroup>
    </Col>
  );

  const mapCategories = (roomId, roomNumber) => Object.keys(selectedTeamForm)
    .filter((category) => {
      const isRoomCategory = category.startsWith('room');

      return isRoomView ? isRoomCategory : !isRoomCategory;
    })
    .map((category) => {
      const isFeature = /features/i.test(category);
      let header = startCase(category);
      let categoryCompletion = isFeature
        ? apartmentCategoryCompletion.features
        : apartmentCategoryCompletion[category];

      if (isRoomView) {
        if (team === 'SALES') {
          header = header.replace(/room/i, '');
        }

        if (team === 'D&Q') {
          header = `${header} - ${roomNumber}`;
        }

        categoryCompletion = isFeature
          ? roomsCategoryCompletion[roomId].roomFeatures
          : roomsCategoryCompletion[roomId][category];
      }

      const isCompleted = categoryCompletion === 100;

      return (
        <CategoryCard
          key={ category }
          activeCard={ activeCard }
          category={ category }
          checkFeatures={ () => (isRoomView ? toggleFeatures(roomId) : toggleFeatures()) }
          header={ header }
          isCompleted={ isCompleted }
          isRoom={ isRoomView }
          roomId={ roomId }
          setActiveCard={ (card) => handleCategoryClick(card, roomId || null) }
        >
          {
            activeCard.name === category
              ? renderCategoryCardBody(category, roomId)
              : <div />
          }
        </CategoryCard>
      );
    });

  const mappedRooms = isRoomView
    ? (
      apartment.Rooms.map(({ id, name }) => {
        const [, roomNumber] = name.split('-');

        if (team === 'D&Q') {
          return mapCategories(id, roomNumber);
        }

        return (
          <div key={ id } className="mb-4">
            <div className="w-100 mb-4 d-flex justify-content-center align-items-center">
              <hr className="w-50" />
              <h5 className="w-25 text-center">
                Room
                { roomNumber }
              </h5>
              <hr className="w-50" />
            </div>
            { mapCategories(id) }
          </div>
        );
      })
    )
    : null;

  return (
    <div className="configuration-tab">
      <Row>
        <Col
          sm={{ span: 12, order: 1 }}
          lg={{ span: 2, order: 1 }}
          className={ `progress-card ${formattedTeamCase}` }
        >
          <CircularProgressbar value={ globalCompletion } text={ `${globalCompletion}%` } />
        </Col>
        <Col sm={{ span: 12, order: 3 }} lg={{ span: 8, order: 2 }} className="mt-4 mb-5">
          <Accordion>
            { isRoomView ? mappedRooms : mapCategories() }
          </Accordion>
        </Col>
        {
          team !== 'IMMO'
            ? switchButton
            : null
        }
      </Row>
      {
        i18nKey
          ? (
            <I18nModal
              i18nKey={ i18nKey }
              fieldsToUpdate={ apartmentFieldsToUpdate }
              editField={ updateApartmentFields }
              closeModal={ closeModal }
              updateTranslations={ updateTranslations }
              updatedTranslations={ updatedTranslations }
            />
          )
          : null
      }
      {
        cellarAccessModal
          ? renderCellarAccessModal()
          : null
      }
      {
        displayContactModal
          ? (
            <ContactForm
              show={ displayContactModal }
              apartmentId={ apartment.id }
              apartmentContacts={ apartment.ApartmentContacts }
              mainContact={ apartment.mainContact }
              fetchApartment={ () => fetchApartment() }
              contact={ contactToEdit }
              handleClose={ () => toggleContactModal(false) }
            />
          )
          : null
      }
      <RoundButton
        editMode={ editMode }
        toggleEditMode={ toggleEditMode }
        save={ updateAll }
      />
    </div>
  );
}

export default ConfigurationTab;
