import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import times from 'lodash/times';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import ForestApi from '../../../utils/API';
import EditableField from '../../EditableField';
import Loader from '../../Loader';

function AttributeKeysModal(props) {
  const {
    show,
    handleClose,
    rentingData,
    attributeKeysList,
    fetchRentings,
  } = props;

  const [isLoading, setLoader] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(attributeKeysList.findIndex(({ id }) => id === rentingData.id));
  const [currentRenting, setCurrentRenting] = useState(attributeKeysList.find(({ id }) => id === rentingData.id));
  const [safeNumber, setSafeNumber] = useState(1);

  const headerIcon = (
    <i className="fas fa-key fa-lg card-counter-container">
      <Badge pill variant="danger">
        { attributeKeysList.length }
      </Badge>
    </i>
  );

  const getRenting = useCallback((index) => {
    setSafeNumber(1);
    setCurrentIndex(index);
    setCurrentRenting(attributeKeysList.find((renting, i) => i === index));
  }, [attributeKeysList]);

  useEffect(() => {
    if (!attributeKeysList.current) {
      getRenting(currentIndex);
      setLoader(false);
    }
  }, [attributeKeysList, getRenting, currentIndex]);

  const clientDataFields = [{
    name: 'Client.firstName',
    label: 'First name',
    type: 'string',
  }, {
    name: 'Client.lastName',
    label: 'Last name',
    type: 'string',
  }, {
    name: 'Room.name',
    label: 'Apt - Room #',
    type: 'string',
  }, {
    name: 'Room.Apartment.CityId',
    label: 'City',
    type: 'string',
  }, {
    name: 'bookingDate',
    label: 'Booking date',
    type: 'date',
  }, {
    name: 'Events[0].startDate',
    label: 'Check-in date',
    type: 'date',
  }];

  function updateRenting() {
    const { id } = currentRenting;

    new ForestApi().rentings()
      .update(id, { checkinSpotSafeNumber: safeNumber })
      .then(() => {
        toast('Renting updated!', {
          type: toast.TYPE.SUCCESS,
        });

        setLoader(true);
        fetchRentings();
        if (currentIndex >= attributeKeysList.length - 1) {
          handleClose();
        }
      });
  }

  function renderCheckinTimeslot(checkinDate) {
    const NINE_AM = moment(checkinDate).set({ hours: 9, minutes: 0 });
    const SIX_PM = moment(checkinDate).set({ hours: 18, minutes: 0 });

    const isDayTimeslot = moment(checkinDate).isBetween(NINE_AM, SIX_PM);

    return (
      <span className="ml-2">
        {' - '}
        <i className={ `icon fas fa-${isDayTimeslot ? 'sun' : 'moon'} ml-1` } />
        { isDayTimeslot ? 'Day' : 'Night'}
      </span>
    );
  }

  function renderSafeNumberSelection() {
    const options = times(12, (value) => <option key={ value } value={ value + 1 }>{ value + 1}</option>);

    return (
      <tr>
        <th className="align-middle">Safe number</th>
        <td className="d-flex w-100">
          <FormControl
            className="w-50"
            as="select"
            onChange={ (e) => setSafeNumber(e.target.value) }
            value={ safeNumber }
          >
            { options }
            <option value="-1">KeyNest</option>
            <option value="-2">E-locks</option>
            <option value="-3">Keynest / E-locks</option>
          </FormControl>
        </td>
      </tr>
    );
  }

  function renderTableBody(title, body) {
    return (
      <div className="table-body">
        <Table>
          <thead>
            <tr>
              <td colSpan="2">
                {title}
              </td>
            </tr>
          </thead>
          <tbody>
            { body }
            { renderSafeNumberSelection() }
          </tbody>
        </Table>
      </div>
    );
  }

  function parseContactData() {
    const title = 'Renting informations';

    const body = (
      clientDataFields
        .map((field) => {
          const {
            name,
            type,
            label,
          } = field;

          const isDate = type === 'date';

          const value = get(currentRenting, name, null);

          return (
            <tr key={ name }>
              <th>{ label }</th>
              <td className="d-flex w-100">
                <EditableField
                  editMode={ false }
                  inputType={ isDate ? 'date' : 'string' }
                  name={ name }
                  label={ label }
                  value={ label === 'City' ? capitalize(value) : value }
                />
                {
                  label === 'Check-in date'
                    ? (
                      renderCheckinTimeslot(value)
                    )
                    : null
                }
              </td>
            </tr>
          );
        })
    );

    return renderTableBody(title, body);
  }

  return (
    <Modal size="lg" show={ show } onHide={ handleClose }>
      <Modal.Header>
        <Button
          variant="dark"
          onClick={ () => getRenting(currentIndex - 1) }
          disabled={ currentIndex === 0 }
        >
          prev
        </Button>
        <Modal.Title>
          {headerIcon}
          Attribute keys
        </Modal.Title>
        <Button
          variant="dark"
          onClick={ () => getRenting(currentIndex + 1) }
          disabled={ currentIndex >= attributeKeysList.length - 1 }
        >
          next
        </Button>
      </Modal.Header>
      {
        isLoading
          ? <Loader />
          : (
            <>
              <Modal.Body>
                {parseContactData()}
              </Modal.Body>
              <Modal.Footer>
                <Row className="w-100 m-auto">
                  <Col sm={{ span: 6, offset: 3 }}>
                    <Button
                      variant="success"
                      className="w-100"
                      onClick={ () => updateRenting() }
                    >
                      Attribute
                    </Button>
                  </Col>
                </Row>
              </Modal.Footer>
            </>
          )
      }
    </Modal>
  );
}

export default AttributeKeysModal;
