import React from 'react';
import moment from 'moment';

export function getDateStatus(checkinDate, checkinDateApproved) {
  const today = moment();
  const parsedDate = moment(checkinDate);

  if (!checkinDate) {
    return 'danger';
  }
  if (checkinDateApproved) {
    return 'success';
  }
  if (parsedDate.isBefore(today)) {
    return 'danger';
  }
  if (parsedDate.diff(today, 'days') <= 7) {
    return 'warning';
  }

  return 'dark';
}

export function renderCheckinTimeslot(checkinDate) {
  const NINE_AM = moment(checkinDate).set({
    hours: 9,
    minutes: 0,
  });
  const SIX_PM = moment(checkinDate).set({
    hours: 18,
    minutes: 0,
  });

  const isDay = moment(checkinDate).isBetween(NINE_AM, SIX_PM);

  return (
    <span>
      <i className={ `icon fas fa-${isDay ? 'sun' : 'moon'}` } />
      { isDay ? 'Day' : 'Night'}
    </span>
  );
}

export function renderCheckinDateFormat(checkinDate, checkinDateApproved) {
  const formattedCheckinDate = moment(checkinDate).format('DD/MM/YYYY');

  const dateStatus = getDateStatus(checkinDate, checkinDateApproved);

  return (
    <span className={ `text-secondary text-${dateStatus}` }>
      { formattedCheckinDate }
      {' - '}
      { renderCheckinTimeslot(checkinDate) }
    </span>
  );
}
