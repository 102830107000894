import React from 'react';
import sortBy from 'lodash/sortBy';
import { toast } from 'react-toastify';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import copyText from '../../../../utils/copyText';
import ForestApi from '../../../../utils/API';
import RoundButton from '../../../RoundButton';
import ContactForm from '../../contactForm';

class ContactsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayModal: false,
      displayNote: false,
      notes: '',
      contactToEdit: null,
    };
  }

  toggleModal(contact) {
    const { displayModal } = this.state;

    this.setState({
      displayModal: !displayModal,
      contactToEdit: contact,
    });
  }

  toggleNote(notes) {
    const { displayNote } = this.state;

    this.setState({
      displayNote: !displayNote,
      notes,
    });
  }

  parseContactFields(fields) {
    return fields.reduce((acc, field) => {
      acc[field.name] = field.value;

      return acc;
    }, {});
  }

  createContact(fields) {
    const { apartmentId, fetchApartment } = this.props;
    const data = this.parseContactFields(fields);

    data.apartmentId = apartmentId;

    this.toggleModal();

    new ForestApi().contacts()
      .create(data)
      .then(() => {
        toast('Contact created!', {
          type: toast.TYPE.SUCCESS,
        });
        fetchApartment();
      });
  }

  updateContact(fields) {
    const { apartmentId, fetchApartment } = this.props;
    const { contactToEdit } = this.state;
    const data = this.parseContactFields(fields);
    const contactId = contactToEdit.id;

    data.apartmentId = apartmentId;

    this.toggleModal();

    new ForestApi().contacts()
      .update(contactId, data)
      .then(() => {
        toast('Contact updated!', {
          type: toast.TYPE.SUCCESS,
        });
        fetchApartment();
      });
  }

  changeMainContact(contactId) {
    const { apartmentId, fetchApartment } = this.props;

    const data = { mainContact: contactId };

    new ForestApi().apartments()
      .update(apartmentId, data)
      .then(() => {
        toast('Main contact changed!', {
          type: toast.TYPE.SUCCESS,
        });
        fetchApartment();
      });
  }

  unassignContact(contactId, type) {
    const { apartmentId, fetchApartment } = this.props;

    const data = { contactId, type };

    new ForestApi().apartments()
      .unassignContact(apartmentId, data)
      .then(() => {
        toast('Contact unassigned!', {
          type: toast.TYPE.SUCCESS,
        });
        fetchApartment();
      });
  }

  renderPlaceholder() {
    return (
      <Row>
        <Col>
          <Jumbotron className="text-center">
            <h1>No contacts</h1>
            <p>
              There are no contacts for this apartment yet.
            </p>
            <p>
              <Button variant="success" onClick={ () => this.toggleModal() }>
                <i className="icon fa fa-plus" />
                Create the first one
              </Button>
            </p>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  renderContacts() {
    const { contacts, mainContact } = this.props;
    const headers = ['Name', 'Company', 'Type', 'Phone number', 'Postal address', 'Notes', 'Actions'];
    const mappedHeaders = headers.map((header) => <th key={ header }>{ header }</th>);

    const sortedContacts = sortBy(contacts, 'name');
    const contactsRows = sortedContacts.map((contact) => {
      const isMainContact = mainContact === contact.id;

      return (
        <tr key={ contact.id } className={ isMainContact ? 'table-success' : '' }>
          <td>
            <i className={ `icon fas fa-${contact.gender}` } />
            {`${contact.firstName} ${contact.lastName}`}
          </td>
          <td>
            { contact.company || null}
          </td>
          <td>
            <Button size="sm" variant="dark" className="btn-label" disabled>
              { contact.type }
            </Button>
          </td>
          <td>{ contact.phoneNumber }</td>
          <td>{ contact.postalAddress }</td>
          <td>
            {
              contact.notes
                ? (
                  <Button variant="dark" size="sm" onClick={ () => this.toggleNote(contact.notes) }>
                    <i className="fas fa-eye" />
                  </Button>
                )
                : <span className="text-secondary">None</span>
            }
          </td>
          <td className="text-right">
            {
              contact.email
                ? (
                  <Button variant="link" size="sm" title="Copy email" onClick={ () => copyText(contact.email) }>
                    <i className="far fa-copy" />
                  </Button>
                )
                : null
            }
            <Button
              size="sm"
              variant="link"
              title={ `${isMainContact ? 'Unset' : 'Set as'} main contact` }
              onClick={ () => (isMainContact ? this.changeMainContact(null) : this.changeMainContact(contact.id)) }
            >
              <i className={ `${isMainContact ? 'fas' : 'far'} fa-star` } />
            </Button>
            <Button
              size="sm"
              variant="link"
              title="Edit contact"
              onClick={ () => this.toggleModal(contact) }
            >
              <i className="fas fa-edit" />
            </Button>
            <Button
              size="sm"
              variant="danger"
              title="Unassign contact"
              onClick={ () => this.unassignContact(contact.id, contact.type) }
              className="mr-2"
            >
              <i className="fas fa-user-minus" />
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <Row>
        <Col>
          <Card>
            <Table>
              <thead>
                <tr>{ mappedHeaders }</tr>
              </thead>
              <tbody>
                { contactsRows }
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    );
  }

  render() {
    const { contacts, apartmentId, fetchApartment } = this.props;
    const {
      displayModal, displayNote, notes, contactToEdit,
    } = this.state;

    return (
      <div className="contacts-tab">
        {
          (!contacts || contacts.length === 0)
            ? this.renderPlaceholder()
            : this.renderContacts()
        }
        {
          displayModal
            ? (
              <ContactForm
                show={ displayModal }
                apartmentId={ apartmentId }
                fetchApartment={ () => fetchApartment() }
                contact={ contactToEdit }
                handleClose={ () => this.toggleModal() }
                handleSubmit={ (data) => (contactToEdit ? this.updateContact(data) : this.createContact(data)) }
              />
            )
            : null
        }
        <Modal show={ displayNote } onHide={ () => this.toggleNote() } centered>
          <Modal.Header>
            <Modal.Title>Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { notes }
          </Modal.Body>
        </Modal>
        <RoundButton handleClick={ () => this.toggleModal() } />
      </div>
    );
  }
}

export default ContactsTab;
