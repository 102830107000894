import inRange from 'lodash/inRange';

export default function checkIban(iban) {
  if (!iban) {
    return null;
  }

  const parseIban = iban.trim().replace(/\s/g, '').toUpperCase();

  return {
    iban: parseIban,
    isMalformed: !inRange(parseIban.length, 14, 34),
  };
}
