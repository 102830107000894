import React from 'react';

import './style.scss';

function RoundButton(props) {
  const {
    editMode,
    toggleEditMode,
    save,
    handleClick,
  } = props;
  if (localStorage && localStorage.role === 'contractor') {
    return <div/>;
  }

  function renderButton(color, icon, action) {
    return (
      <span className={ `button ${color} fa-stack fa-2x` } onClick={ action }>
        <i className="fas fa-circle fa-stack-2x" />
        <i className={ `fas fa-${icon} fa-stack-1x fa-inverse` } />
      </span>
    );
  }

  if ('editMode' in props) {
    return (
      <div id="round-button">
        {
          editMode
            ? (
              <>
                { renderButton('secondary', 'times', () => toggleEditMode(false)) }
                {
                save
                  ? renderButton('success', 'check', () => save())
                  : null
              }
              </>
            )
            : renderButton('primary', 'pencil-alt', () => toggleEditMode(true))
        }
      </div>
    );
  }

  return (
    <div id="round-button">
      { renderButton('success', 'plus', () => handleClick()) }
    </div>
  );
}

export default RoundButton;
