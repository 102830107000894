import { getGlobalCompletion } from '../../utils/getCompletion';
import ForestApi from '../../utils/API';

export const setCount = (apartments) => ({
  type: 'SET_COUNT',
  count: apartments.find((item) => 'count' in item).count,
});

export const setTeamCompletion = (apartments) => ({
  type: 'SET_TEAM_COMPLETION',
  teamCompletion: getGlobalCompletion(apartments.filter((item) => 'name' in item)),
});

export const fetchStatistics = () => async (dispatch) => {
  const response = await new ForestApi().apartments().getAll();
  const apartments = response.data;
  dispatch(setCount(apartments));
  dispatch(setTeamCompletion(apartments));
};
