import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

import Button from 'react-bootstrap/Button';
import FormCheck from 'react-bootstrap/FormCheck';
import FormControl from 'react-bootstrap/FormControl';
import Table from 'react-bootstrap/Table';

import ForestApi from '../../utils/API';
import EditableField from '../EditableField';
import Loader from '../Loader';
import './style.scss';

const NO_VAT_PRODUCTS = ['late-notice', 'special-checkout', 'rent'];

const getDefaultNewItem = (RentingId) => ({
  createdAt: null,
  id: moment().valueOf(),
  label: '',
  quantity: 1,
  RentingId,
  unitPrice: null,
  vatRate: '0.2',
});

function OrderItemsTable(props) {
  const {
    order,
    newItems,
    newItemsAdded,
    preferredLanguage,
    pendingChanges,
    setPendingChanges,
    syncChanges,
  } = props;

  const [isLoading, setLoader] = useState(false);
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState({});
  const [products, setProducts] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const orderIsDraft = order.status === 'draft';

  useEffect(() => {
    const { OrderItems: orderItems } = order;

    setItems(orderItems);
    setNewItem(getDefaultNewItem(orderItems[0].RentingId));
  }, [order]);

  useEffect(() => {
    if (newItems && newItems.length > 0) {
      const itemsToAdd = newItems
        .filter((newItemToAdd) => {
          const similarItem = items.find(({ id, ProductId, unitPrice }) => (
            id === newItemToAdd.id
            || (ProductId === newItemToAdd.ProductId
              && unitPrice === -1 * newItemToAdd.unitPrice
            )
          ));

          return !similarItem;
        })
        .map((item) => ({
          ...item,
          unitPrice: -1 * item.unitPrice,
        }));

      if (itemsToAdd.length > 0) {
        setItems([...items, ...itemsToAdd]);
      }

      newItemsAdded();
    }
  }, [newItems, newItemsAdded, items]);

  useEffect(() => {
    async function fetchProducts(lang) {
      await new ForestApi().depositRefunds()
        .getProducts(`lang=${lang}`)
        .then((res) => {
          const productList = res.data
            .filter(({ id }) => !NO_VAT_PRODUCTS.includes(id))
            .map((product) => ({
              label: product.label,
              unitPrice: -1 * (product.price || 0),
              vatRate: '0.2',
              ProductId: product.id,
            }));

          setProducts(productList);
          setLoader(false);
        })
        .catch(() => setProducts([]));
    }

    setLoader(true);

    fetchProducts(preferredLanguage);
  }, [preferredLanguage]);

  function setCategory(value) {
    const category = products.find(({ ProductId }) => ProductId === value);

    setNewItem({
      ...newItem,
      ...category,
    });
  }

  function setItemValue(id, name, value) {
    let itemValue = value;

    if (name === 'unitPrice' && Number(value) > 0) {
      itemValue = -1 * value;
    }

    if (id === newItem.id) {
      return setNewItem({
        ...newItem,
        [name]: itemValue,
      });
    }

    const existingItem = items.find((item) => id === item.id);

    if (!pendingChanges) {
      setPendingChanges(true);
    }

    return setItems([
      ...items.filter((item) => id !== item.id),
      {
        ...existingItem,
        [name]: itemValue,
      },
    ]);
  }

  function addItem() {
    setItems([
      ...items,
      {
        ...newItem,
        createdAt: moment().valueOf(),
      },
    ]);
    setNewItem(getDefaultNewItem(items[0].RentingId));

    if (!pendingChanges) {
      setPendingChanges(true);
    }
  }

  function removeItem(id) {
    setItems(items.filter((item) => item.id !== id));

    if (!pendingChanges) {
      setPendingChanges(true);
    }
  }

  function syncRefundOrder() {
    setLoader(true);

    const data = { items };

    new ForestApi().depositRefunds()
      .sync(order.id, data)
      .then(() => {
        setPendingChanges(false);
        setEditMode(false);
        setLoader(false);

        if (syncChanges) {
          syncChanges();
        }
      })
      .catch((error) => toast(error, {
        type: toast.TYPE.ERROR,
      }));
  }

  function renderTableBody() {
    const sortedItems = items.sort((a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf());

    const allItems = [
      ...sortedItems,
      orderIsDraft && newItem,
    ].filter(Boolean);

    const categories = products
      ? products.map((item) => (
        <option key={ item.ProductId }>
          { item.ProductId }
        </option>
      ))
      : [];

    const isAddButtonDisabled = !(newItem.label && newItem.unitPrice && newItem.quantity);

    return allItems.map((item) => {
      const {
        createdAt,
        id,
        label,
        ProductId,
        quantity,
        unitPrice,
        vatRate,
      } = item;

      const isNew = !createdAt;
      const isNotDeposit = ProductId !== 'deposit';
      const isEditable = isNotDeposit && (editMode || isNew);
      const variant = isNotDeposit ? 'dark' : 'primary';

      return (
        <tr key={ `${order.id}-${id}` }>
          <td>
            {
              isNew
                ? (
                  <FormControl
                    as="select"
                    onChange={ (e) => setCategory(e.target.value) }
                  >
                    <option>Pick a category...</option>
                    { categories }
                  </FormControl>
                )
                : (
                  <Button size="sm" variant={ variant } className="btn-label" disabled>
                    { ProductId || 'Undefined' }
                  </Button>
                )
            }
          </td>
          <td>
            <EditableField
              editFieldValue={ (name, value) => setItemValue(item.id, name, value) }
              editMode={ isEditable }
              name="label"
              value={ label }
            />
          </td>
          <td>
            <EditableField
              editFieldValue={ (name, value) => setItemValue(item.id, name, value) }
              editMode={ isEditable }
              fieldType="number"
              name="quantity"
              value={ quantity }
            />
          </td>
          <td>
            { vatRate * 100 }
            %
          </td>
          <td className="text-right">
            <EditableField
              editFieldValue={ (name, value) => setItemValue(item.id, name, value * 100) }
              editMode={ isEditable }
              fieldType="number"
              name="unitPrice"
              value={ unitPrice / 100 }
              isMoney
            />
          </td>
          <td className="text-right">
            {
              isNew
                ? (
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={ () => addItem() }
                    disabled={ isAddButtonDisabled }
                  >
                    <i className="icon fas fa-plus" />
                    Add
                  </Button>
                )
                : null
            }
            {
              isNotDeposit && orderIsDraft && !isNew
                ? (
                  <Button variant="link text-danger" onClick={ () => removeItem(item.id) }>
                    <i className="fas fa-trash" />
                  </Button>
                )
                : null
            }
          </td>
        </tr>
      );
    });
  }

  const totalItems = items.length > 0
    ? items.reduce((sum, item) => sum + ((Number(item.unitPrice) * Number(item.quantity)) / 100), 0)
    : 0;
  const refundAmount = newItem.unitPrice && newItem.quantity
    ? totalItems + ((newItem.unitPrice * newItem.quantity) / 100)
    : totalItems;

  if (isLoading || !products) {
    return <Loader />;
  }

  return (
    <div className="OrderItemsTable">
      <Table>
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Category</th>
            <th style={{ width: '40%' }}>Label</th>
            <th style={{ width: '10%' }}>Quantity</th>
            <th style={{ width: '10%' }}>VAT rate</th>
            <th style={{ width: '10%' }}>Unit price</th>
            <th style={{ width: '10%' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          { renderTableBody() }
        </tbody>
        <tfoot className="text-right">
          <tr>
            <th colSpan="4">Amount to refund</th>
            <td>
              { refundAmount }
              {' '}
              €
            </td>
            <td />
          </tr>
          {
            orderIsDraft
              ? (
                <tr>
                  <td colSpan="2" className="text-left text-secondary">
                    Last update:
                    {' '}
                    { moment(order.updatedAt).format('DD/MM/YYYY HH:mm:ss') }
                  </td>
                  <td colSpan="2">
                    <FormCheck
                      id="edit-mode-switch"
                      type="switch"
                      label="Edit mode"
                      value={ editMode }
                      onChange={ () => setEditMode(!editMode) }
                      className="mr-2"
                    />
                  </td>
                  <td colSpan="2">
                    {
                      refundAmount < 0
                        ? (
                          <Button variant="warning" disabled>
                            <i className="icon fas fa-exclamation-triangle" />
                            Negative amount!
                          </Button>
                        )
                        : (
                          <Button onClick={ () => syncRefundOrder() } disabled={ !pendingChanges }>
                            <i className="icon fas fa-save" />
                            Save changes
                          </Button>
                        )
                    }
                  </td>
                </tr>
              )
              : null
          }
        </tfoot>
      </Table>
    </div>
  );
}

export default OrderItemsTable;
