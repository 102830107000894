import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import throttle from 'lodash/throttle';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../utils/API';
import SearchBar from '../SearchBar';
import ApplicationTab from './tabs/applicationTab';
import AttributeKeysTab from './tabs/attributeKeysTab';
import ApplicationModal from './modals/applicationModal';
import AttributeKeysModal from './modals/attributeKeysModal';

import './style.scss';
import extractParamsQuery from '../../utils/extractParamsQuery';

class BookingsList extends React.Component {
  constructor(props) {
    super(props);

    const cities = ['lyon', 'paris', 'lille', 'toulouse', 'montpellier', 'bordeaux'];
    const { city } = extractParamsQuery({
      city: {
        name: 'city',
        options: cities,
        currentValue: 'all',
      },
    }, window.location.search);
    this.state = {
      clients: [],
      rentings: [],
      currentSearch: null,
      filters: [city],
      displayModal: false,
      activeModal: null,
      currentRenting: {},
      applicationList: [],
      attributeKeysList: [],
      bookingsIds: [],
      isLoading: false,
      refreshSearch: false,
    };
    this.fetchRentingsThrottled = throttle(this.fetchRentings.bind(this), 1000);
  }

  async componentDidMount() {
    await this.fetchRentings();
    this.getBookingsIds();
  }

  getDateStatus(checkinDate, checkinDateApproved) {
    const today = moment();
    const parsedDate = moment(checkinDate);

    if (!checkinDate) {
      return 'danger';
    }
    if (checkinDateApproved) {
      return 'success';
    }
    if (parsedDate.isBefore(today)) {
      return 'danger';
    }
    if (parsedDate.diff(today, 'days') <= 7) {
      return 'warning';
    }

    return 'dark';
  }

  getBookingsIds() {
    const { applicationList, attributeKeysList } = this.state;

    const applicationIds = applicationList.map(({ id }) => id);
    const attributeKeysIds = attributeKeysList.map(({ ClientId }) => ClientId);

    const bookingsIds = uniq([...applicationIds, ...attributeKeysIds]);

    return this.setState({ bookingsIds });
  }

  getRentingStatus(renting) {
    const {
      Checkin: {
        depositPaid,
      },
      checkinDate,
      checkinDateApproved,
      checkinSpotSafeNumber,
      idChecked,
      tenancyFileApproved,
      emergencyContactChecked,
      leaseSent,
      leaseSigned,
    } = renting;

    const isCheckinReady = [
      idChecked,
      tenancyFileApproved,
      emergencyContactChecked,
      leaseSent,
      leaseSigned,
    ].every(Boolean);

    const isPaymentsDone = depositPaid;
    const isCheckinOk = checkinDate && isCheckinReady && isPaymentsDone;

    let color = 'success';
    let label = 'Ready to checkin';

    if (!checkinSpotSafeNumber) {
      label = 'Ready to get keys';
    }

    if (!isCheckinOk) {
      if (!isCheckinReady) {
        label = 'Missing elements';
      } else {
        label = !isPaymentsDone ? 'Missing payments' : 'No checkin date';
      }

      color = 'dark';
    }

    if (checkinDate && isCheckinReady && !checkinDateApproved && isPaymentsDone) {
      label = 'Ready to validate';
    }

    return {
      label,
      color,
    };
  }

  openAttributeKeysModal(attributeKeysList, id) {
    if (!id) {
      return this.setState({
        attributeKeysList,
      }, () => this.getBookingsIds());
    }

    return this.setState({
      displayModal: true,
      activeModal: 'attributeKeys',
      currentRenting: {
        id,
      },
      attributeKeysList,
    });
  }

  openApplicationModal(applicationList, id, task) {
    if (!(id || task)) {
      return this.setState({
        applicationList,
      }, () => this.getBookingsIds());
    }

    return this.setState({
      displayModal: true,
      activeModal: 'application',
      currentRenting: {
        id,
        task,
      },
      applicationList,
    });
  }

  closeModal() {
    this.setState({
      displayModal: false,
    });
  }

  async fetchRentings() {
    const { filters, currentSearch } = this.state;

    this.setState({ isLoading: true });

    const city = filters.find(({ name }) => name === 'city');

    let query = [
      `city=${city.options.join(',')}`,
    ].join('&');

    if (city.currentValue !== 'all') {
      query = [
        `city=${city.currentValue}`,
      ];
    }
    const response = await new ForestApi().rentings().getAll(query);
    const rentings = response.data;

    const updatedSearch = currentSearch
      ? rentings.filter(({ id }) => currentSearch.find(({ id: rentingId }) => id === rentingId))
      : null;

    return this.setState({
      rentings,
      currentSearch: updatedSearch,
      isLoading: false,
    });
  }

  handleSearch(name) {
    const { bookingsIds, rentings } = this.state;

    const trimmedName = name.trim();

    if (trimmedName.length < 3) {
      return this.setState({
        currentSearch: null,
        clients: [],
      });
    }

    const currentSearch = rentings.filter((renting) => {
      const {
        Client: {
          id,
          firstName,
          lastName,
          email,
        },
      } = renting;

      const regex = new RegExp(trimmedName, 'i');

      const isMatch = [firstName, lastName, email].find((item) => regex.test(item));

      return isMatch && bookingsIds.includes(id);
    });

    return this.setState({
      currentSearch,
      clients: currentSearch.map((renting) => this.parseClientResult(renting)),
    });
  }

  parseClientResult(renting) {
    const {
      Client: {
        id,
        firstName,
        lastName,
        status,
      },
      checkinDate,
      checkinDateApproved,
    } = renting;

    const dateStatus = this.getDateStatus(checkinDate, checkinDateApproved);
    const rentingStatus = this.getRentingStatus(renting);

    const rowData = (
      <Row className="d-flex align-items-center">
        <Col lg="5" className="d-flex align-items-center">
          <span className={ `mr-2 color-${status}` }>
            <i className="fas fa-circle fa-xs" />
          </span>
          <span>
            { firstName }
            {' '}
            { lastName }
          </span>
        </Col>
        <Col lg="3">
          {
            checkinDate
              ? this.renderCheckinDateFormat(checkinDate, dateStatus)
              : <span> Not specified </span>
          }
        </Col>
        <Col lg="3">
          { this.renderCheckinLabel(rentingStatus) }
        </Col>
        <Col lg="1" className="details text-right">
          <i className="fas fa-arrow-right" />
        </Col>
      </Row>
    );

    return {
      id,
      rowData,
    };
  }

  showClient(id) {
    const { history } = this.props;

    history.push(`/clients/${id}`);
  }

  handleFilterClick(city) {
    const { filters, refreshSearch } = this.state;
    const cityFilter = filters.find(({ name }) => name === 'city');

    this.setState({
      refreshSearch: !refreshSearch,
      clients: [],
      currentSearch: null,
      filters: [{
        ...cityFilter,
        currentValue: city,
      }],
    }, () => this.fetchRentings());
  }

  renderFilter(filter) {
    const { name, options, currentValue } = filter;

    if (name !== 'city') {
      return null;
    }

    return options.map((city) => {
      const isActive = currentValue.includes(city);

      return (
        <div key={ city } className="filter">
          <button
            className={ `btn btn-${isActive ? 'confirmed' : 'not-ready'}-${city}` }
            onClick={ () => this.handleFilterClick(city) }
            type="button"
          >
            { startCase(city) }
          </button>
        </div>
      );
    });
  }

  renderCheckinTimeslot(checkinDate) {
    const NINE_AM = moment(checkinDate).set({ hours: 9, minutes: 0 });
    const SIX_PM = moment(checkinDate).set({ hours: 18, minutes: 0 });

    const isDayTimeslot = moment(checkinDate).isBetween(NINE_AM, SIX_PM);

    return (
      <span>
        -
        <i className={ `icon fas fa-${isDayTimeslot ? 'sun' : 'moon'} ml-1` } />
        { isDayTimeslot ? 'Day' : 'Night'}
      </span>
    );
  }

  renderCheckinDateFormat(checkinDate, dateStatus) {
    const formattedCheckinDate = moment(checkinDate).format('DD/MM/YYYY');

    return (
      <span className={ `text-secondary text-${dateStatus}` }>
        { formattedCheckinDate }
        { this.renderCheckinTimeslot(checkinDate) }
      </span>
    );
  }

  renderCheckinLabel(rentingStatus) {
    const isNotReady = rentingStatus.label !== 'Ready to validate';

    return (
      <Button
        className={ isNotReady ? 'btn-label' : null }
        variant={ rentingStatus.color }
        size="sm"
        disabled
      >
        { rentingStatus.label }
      </Button>
    );
  }

  renderModal() {
    const {
      rentings,
      displayModal,
      activeModal,
      currentRenting,
      applicationList,
      attributeKeysList,
    } = this.state;

    return activeModal === 'attributeKeys'
      ? (
        <AttributeKeysModal
          show={ displayModal }
          handleClose={ () => this.closeModal() }
          rentingData={ currentRenting }
          attributeKeysList={ attributeKeysList }
          fetchRentings={ () => this.fetchRentings() }
        />
      )
      : (
        <ApplicationModal
          show={ displayModal }
          handleClose={ () => this.closeModal() }
          rentingData={ currentRenting }
          applicationList={ applicationList }
          rentings={ rentings }
          fetchRentings={ () => this.fetchRentings() }
        />
      );
  }

  render() {
    const {
      clients,
      filters,
      rentings,
      currentSearch,
      displayModal,
      isLoading,
      refreshSearch,
    } = this.state;
    const [currentCity] = filters;
    const query = [
      `city=${currentCity.currentValue}`,
    ].join('&');
    window.history.replaceState(null, null, `${window.location.pathname}?${query}`);

    const renderFilters = filters.map((filter) => this.renderFilter(filter));
    const city = filters.find(({ name }) => name === 'city').currentValue;
    const isAllSelected = city === 'all';
    const selectAllCitiesButtonVariant = isAllSelected
      ? 'primary'
      : 'link';

    return (
      <div className="BookingsList">
        <div className="d-flex justify-content-between flex-wrap
        flex-md-nowrap align-items-center pt-3 pb-2 border-bottom"
        >
          <Link to="/">
            <i className="icon fas fa-arrow-circle-left" />
            Back to home
          </Link>
          <h3>Check-in process</h3>
          <div />
        </div>
        <div className="BookingsTable">
          <div className="mb-3 d-flex align-items-center justify-content-between flex-wrap">
            <div className="d-flex my-1">
              { renderFilters }
              <div className="mx-3 mt-2">
                <Button
                  variant={ selectAllCitiesButtonVariant }
                  onClick={ () => this.handleFilterClick('all') }
                >
                  All cities
                </Button>
              </div>
            </div>
            <div className="search-bar-container my-2">
              <SearchBar
                icon="fas fa-user"
                placeholder="Search by first name, last name or email..."
                results={ clients }
                handleSearch={ (name) => this.handleSearch(name) }
                handleClick={ (id) => this.showClient(id) }
                refresh={ refreshSearch }
              />
            </div>
          </div>
          <Row>
            <ApplicationTab
              rentings={ currentSearch || rentings }
              city={ city }
              handleModal={ (applications, id, task) => this.openApplicationModal(applications, id, task) }
              isLoading={ isLoading }
            />
            <AttributeKeysTab
              rentings={ currentSearch || rentings }
              city={ city }
              handleModal={ (attributeKeys, id) => this.openAttributeKeysModal(attributeKeys, id) }
              isLoading={ isLoading }
            />
          </Row>
        </div>
        {
          displayModal
            ? this.renderModal()
            : null
        }
      </div>
    );
  }
}

export default BookingsList;
