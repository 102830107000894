import { combineReducers } from 'redux';

import apartmentsReducer from './apartmentsReducer';
import userReducer from './userReducer';
import citiesReducer from './citiesReducer';

export default combineReducers({
  apartments: apartmentsReducer,
  user: userReducer,
  cities: citiesReducer,
});
