import React from 'react';
import moment from 'moment';

import Col from 'react-bootstrap/Col';

import CityLabel from '../../CityLabel';
import ColumnCardsContainer from '../../ColumnCardsContainer';
import getRentingArrondissement from '../../../utils/getRentingArrondissement';
import ArrondissementLabel from '../../ArrondissementLabel';

class AttributeKeys extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cards: [],
    };
  }

  componentDidMount() {
    this.getReadyToCheckin();
  }

  componentDidUpdate(prevProps) {
    const { rentings } = this.props;

    if (rentings !== prevProps.rentings) {
      this.getReadyToCheckin();
    }
  }

  async getReadyToCheckin() {
    const { rentings, handleModal } = this.props;

    this.setState({
      cards: [],
    });

    const attributeKeysReady = rentings.filter((renting) => {
      const {
        checkinSpotSafeNumber,
        checkinDateApproved,
      } = renting;

      return !checkinSpotSafeNumber && checkinDateApproved;
    });

    handleModal(attributeKeysReady);

    return this.setState({
      cards: this.parseEventCard(attributeKeysReady),
    });
  }

  sortApplication(clientA, clientB) {
    const getCheckinDate = (client) => {
      const { checkinDate } = client;

      return checkinDate ? moment(checkinDate) : Infinity;
    };

    return getCheckinDate(clientA) - getCheckinDate(clientB);
  }

  parseEventCard(attributeKeysReady) {
    const {
      city: activeCity,
      handleModal,
    } = this.props;

    const cards = attributeKeysReady
      .sort((clientA, clientB) => this.sortApplication(clientA, clientB))
      .reduce((acc, renting) => {
        const { id, checkinDate, Room } = renting;
        const formattedCheckinDate = checkinDate ? moment(checkinDate).format('DD/MM/YYYY') : null;
        const city = Room.Apartment.CityId;
        const roomName = Room.name;
        let arrondissement;
        if (city?.toLowerCase() === 'paris') {
          arrondissement = getRentingArrondissement(renting);
        }

        const card = (
          <div
            key={ `${id}` }
            className="column-card"
            onClick={ () => handleModal(attributeKeysReady, id) }
          >
            <div className="mb-2 pb-2 d-flex align-items-center card-title">
              <h6 className="m-0">{roomName}</h6>
              {arrondissement && <ArrondissementLabel arrondissement={ arrondissement } className="ml-auto p-1" />}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="note">
                <div className="d-flex align-items-center">
                  {
                checkinDate
                  ? (
                    <>
                      <span className="mr-1">
                        {`C/I date : ${formattedCheckinDate} - `}
                        {this.renderCheckinTimeslot(checkinDate)}
                      </span>
                    </>
                  )
                  : <span className="font-italic text-muted">not specified</span>
              }
                </div>
              </div>
              {
                activeCity === 'all'
                  ? <CityLabel city={ city } />
                  : null
              }
            </div>
          </div>
        );

        return [...acc, card];
      }, []);

    return cards;
  }

  renderCheckinTimeslot(checkinDate) {
    const NINE_AM = moment(checkinDate).set({ hours: 9, minutes: 0 });
    const SIX_PM = moment(checkinDate).set({ hours: 18, minutes: 0 });

    const isDayTimeslot = moment(checkinDate).isBetween(NINE_AM, SIX_PM);

    return (
      <span>
        <i className={ `icon fas fa-${isDayTimeslot ? 'sun' : 'moon'} ml-1` } />
        { isDayTimeslot ? 'Day' : 'Night'}
      </span>
    );
  }

  render() {
    const { cards } = this.state;
    const { isLoading } = this.props;

    return (
      <Col lg="4">
        <ColumnCardsContainer
          title="Attribute keys"
          cards={ cards }
          handleFilterChange={ (value) => this.handleFilterChange(value) }
          icon="fas fa-key fa-lg"
          toggle
          style
          isLoading={ isLoading }
        />
      </Col>
    );
  }
}

export default AttributeKeys;
