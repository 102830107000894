import React from 'react';
import capitalize from 'lodash/capitalize';
import { toast } from 'react-toastify';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import Loader from '../Loader';
import ForestApi from '../../utils/API';
import FormField from '../FormField';

class NewApartment extends React.Component {
  constructor(props) {
    super(props);

    const categories = [{
      value: false,
      label: 'Standard',
    }, {
      value: true,
      label: 'Premium',
    }];

    this.state = {
      fields: [{
        type: 'text',
        name: 'addressStreet',
        label: 'Address',
        value: '',
        required: true,
      }, {
        type: 'text',
        name: 'addressZip',
        label: 'Zip code',
        value: '',
        required: true,
      }, {
        type: 'text',
        name: 'addressCity',
        label: 'City',
        value: '',
        required: true,
      }, {
        type: 'select',
        name: 'DistrictId',
        options: [{ value: null, label: 'select a city' }],
        label: 'Neighborhood',
        value: '',
        required: true,
      }, {
        type: 'tag',
        name: 'CityId',
        options: [],
        label: 'Reference city',
        value: '',
        required: true,
      }, {
        type: 'text',
        name: 'reference',
        label: 'Reference',
        value: '',
        required: true,
      }, {
        type: 'text',
        name: 'name',
        label: 'Name',
        value: '',
        required: true,
      }, {
        type: 'number',
        name: 'floor',
        label: 'Floor',
        value: 0,
      }, {
        type: 'number',
        name: 'floorArea',
        label: 'Area',
        value: 0,
      }, {
        type: 'text',
        name: 'initialTopology',
        label: 'Initial topology',
        value: '',
      }, {
        type: 'number',
        name: 'roomCount',
        label: 'Room count',
        value: 0,
      }, {
        type: 'number',
        name: 'bedroomCount',
        label: 'Bedroom count',
        value: '',
        required: true,
      }, {
        type: 'text',
        name: 'cityZone',
        label: 'Zone',
        value: '',
      }, {
        type: 'select',
        name: 'isPremium',
        options: categories,
        label: 'Category',
        value: null,
      }, {
        type: 'select',
        name: 'mainSignatory',
        options: [],
        label: 'Main signatory',
        value: '',
      }],
      isLoading: false,
    };
  }

  async componentDidMount() {
    await this.fetchCities();
    await this.fetchUsers();
  }

  setOptions(name, options) {
    const { fields } = this.state;

    const field = fields.find((f) => f.name === name);
    const otherFields = fields.filter((f) => f.name !== name);

    const newField = { ...field, options };

    this.setState({ fields: [...otherFields, newField] });
  }

  async handleChange(name, value) {
    const { fields } = this.state;
    let options = [];

    if (name === 'CityId') {
      if (value) {
        options = await this.fetchNeighborhoods(value);
      } else {
        options = [{ value: null, label: 'select a city' }];
      }
    }

    const districtField = fields.find((f) => f.name === 'DistrictId');
    const field = fields.find((f) => f.name === name);
    const otherFields = options.length > 0
      ? fields.filter((f) => f.name !== name && f.name !== 'DistrictId')
      : fields.filter((f) => f.name !== name);

    const newFields = [
      { ...field, value },
      options.length > 0 && { ...districtField, options },
    ].filter(Boolean);

    this.setState({ fields: [...otherFields, ...newFields] });
  }

  async handleSubmit(fields) {
    const {
      handleClose,
      fetchApartments,
      fetchStatistics,
      showApartment,
      history,
    } = this.props;

    this.setState({ isLoading: true });

    const data = fields.reduce((acc, field) => {
      acc[field.name] = field.value;

      return acc;
    }, {});

    // TODO: change this when we open a city abroad
    data.addressCountry = 'france';

    try {
      const response = await new ForestApi().apartments().create(data);

      const apartment = response.data;

      toast('Apartment created!', {
        type: toast.TYPE.SUCCESS,
      });

      if (fetchApartments || fetchStatistics) {
        return fetchApartments ? fetchApartments() : fetchStatistics();
      }

      if (showApartment) {
        return history.push(`/apartments/${apartment.id}`);
      }

      return handleClose();
    } catch (error) {
      toast('Request failed...', {
        type: toast.TYPE.DANGER,
      });
      return handleClose();
    }
  }

  async fetchCities() {
    const response = await new ForestApi().cities().getAll();

    const cities = response.data.map(({ id }) => id);

    this.setOptions('CityId', cities);
  }

  async fetchNeighborhoods(city) {
    const response = await new ForestApi().cities().getNeighborhoods(city);

    const neighborhoods = response.data
      .map(({ id, label }) => ({
        value: id,
        label,
      }));

    return neighborhoods;
  }

  async fetchUsers() {
    const query = 'team=business-development';

    const response = await new ForestApi().users().getAll(query);

    const users = response.data.map((user) => ({
      value: `${user.firstName} ${capitalize(user.lastName)}`,
      label: `${user.firstName} ${capitalize(user.lastName)}`,
    }));

    this.setOptions('mainSignatory', users);
  }

  renderField(name) {
    const { fields } = this.state;
    const field = fields.find((f) => f.name === name);

    if (field.options && field.options.length === 0) {
      return (
        <div className="h-100 w-50 d-flex flex-column align-items-start">
          <Form.Label>{ field.name }</Form.Label>
          <i className="fas fa-spinner fa-spin fa-lg m-2" />
        </div>
      );
    }

    return (
      <FormField
        field={ field }
        handleChange={ (fieldName, value) => this.handleChange(fieldName, value) }
      />
    );
  }

  render() {
    const { show, handleClose } = this.props;
    const { fields, isLoading } = this.state;

    const requiredFields = fields.filter(({ required }) => required);
    const requiredFieldsFilled = requiredFields.filter(({ value }) => Boolean(value)).length === requiredFields.length;

    return (
      <Modal size="lg" className="NewApartment" show={ show } onHide={ handleClose }>
        {
          isLoading
            ? (
              <div className="d-flex flex-column justify-content-center align-items-center py-5">
                <Loader />
                <h4>Configuration in progress...</h4>
              </div>
            )
            : (
              <>
                <Modal.Header closeButton>
                  <Modal.Title>New apartment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Row>
                      { this.renderField('CityId') }
                      { this.renderField('DistrictId') }
                    </Form.Row>
                    <Form.Row>
                      { this.renderField('addressStreet') }
                    </Form.Row>
                    <Form.Row>
                      { this.renderField('addressZip') }
                      { this.renderField('addressCity') }
                    </Form.Row>
                    <Form.Row>
                      { this.renderField('reference') }
                      { this.renderField('name') }
                    </Form.Row>
                    <Form.Row>
                      { this.renderField('floor') }
                      { this.renderField('floorArea') }
                    </Form.Row>
                    <Form.Row>
                      { this.renderField('initialTopology') }
                      { this.renderField('roomCount') }
                      { this.renderField('bedroomCount') }
                    </Form.Row>
                    <Form.Row>
                      { this.renderField('cityZone') }
                      { this.renderField('isPremium') }
                    </Form.Row>
                    <Form.Row>
                      { this.renderField('mainSignatory') }
                    </Form.Row>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={ handleClose }>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    disabled={ !requiredFieldsFilled }
                    onClick={ () => this.handleSubmit(fields) }
                  >
                    <span className="icon fa fa-check" />
                    Add
                  </Button>
                </Modal.Footer>
              </>
            )
        }
      </Modal>
    );
  }
}

export default NewApartment;
