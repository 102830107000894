import moment from 'moment';

export default function extractParamsQuery(defaultParams, search) {
  const params = { ...defaultParams };
  if (search !== '') {
    decodeURIComponent(search.replace('?', ''))
      .split('&')
      .forEach((param) => {
        const [key, valueQuery] = param.split('=');
        if (Object.keys(params)
          .includes(key)) {
          let value;
          if (/,/g.test(valueQuery)) {
            value = valueQuery.split(',');
          } else if (valueQuery !== '') {
            if (!Object.keys(params[key])
              .includes('type')) {
              value = valueQuery;
            } else {
              switch (params[key].type) {
                case 'date':
                  value = moment(valueQuery);
                  break;
                case 'search':
                  value = valueQuery;
                  break;
                default:
                  value = [valueQuery];
                  break;
              }
            }
          } else {
            return;
          }
          params[key].currentValue = value;
        }
      });
  }
  return params;
}
