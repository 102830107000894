import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import { toast } from 'react-toastify';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import ForestApi from '../../../../utils/API';
import EditableField from '../../../EditableField';
import RoundButton from '../../../RoundButton';

const ELECTRIC_METER_OPTIONS = ['linky', 'old'];
const ELEC_PRICING_OPTIONS = ['CU', 'MUDT'];
const HEATING_OPTIONS = ['collective', 'individual electric', 'individual boiler'];
const SWITCHBOARD_OPTIONS = ['fusible', 'breaker'];
const WATER_METER_OPTIONS = ['cold', 'hot and cold'];
const WATER_CHARGES_OPTIONS = ['individual', 'community'];
const GAS_METER_OPTIONS = ['connected', 'old'];
const GAS_PRICING_OPTIONS = ['T1', 'T2'];
const INTERNET_KIND = ['fiber optic', 'vdsl'];
const MAIL_DISTRIBUTION_KIND = ['postman', 'caretaker'];

const ACCESS_BUTTONS = [
  { key: 'apartment-access', label: 'Apartment' },
  { key: 'bike-shelter-access', label: 'Bike shelter' },
  { key: 'garbage-room-access', label: 'Garbage room' },
];

const LOCATIONS_BUTTONS = [
  { key: 'electricity-meter-location', label: 'Electric meter' },
  { key: 'circuit-breaker-location', label: 'Circuit breaker' },
  { key: 'switchboard-location', label: 'Switchboard' },
  { key: 'internet-modem-location', label: 'Internet modem' },
  { key: 'water-meter-location', label: 'Water meter' },
  { key: 'gas-meter-location', label: 'Gas meter' },
];

function ConfigurationTab(props) {
  const { apartment, fetchApartment } = props;
  const [editMode, toggleEditMode] = useState(false);
  const [fieldsToUpdate, editField] = useState({});
  const [i18nKey, setI18nKey] = useState(null);

  const defaultEditableFieldProps = {
    editFieldValue: (name, value) => editField({ ...fieldsToUpdate, [name]: value }),
    editMode,
    fieldsToUpdate,
    team: 'D&Q',
  };

  const getValue = (field) => {
    if (editMode && field in fieldsToUpdate) {
      return fieldsToUpdate[field];
    }

    return apartment[field];
  };

  const updateApartment = () => {
    if (!isEmpty(fieldsToUpdate)) {
      new ForestApi().apartments()
        .update(apartment.id, fieldsToUpdate)
        .then(() => {
          toast('Apartment updated!', {
            type: toast.TYPE.SUCCESS,
          });
          fetchApartment();
          editField({});
        });
    }

    toggleEditMode();
  };

  const getI18ns = (key) => apartment.I18ns
    .filter((i18n) => i18n.key === key)
    .reduce((acc, i18n) => {
      const lang = i18n.locale.split('-')[0];
      acc[`${i18n.key}.${lang}`] = i18n.value || '';

      return acc;
    }, {});

  const openModal = (key) => {
    editField(getI18ns(key));
    setI18nKey(key);
  };

  const closeModal = () => {
    editField({});
    setI18nKey(null);
  };

  const updateTranslations = () => {
    new ForestApi().apartments()
      .updateTranslations(apartment.id, fieldsToUpdate)
      .then(() => {
        toast('Translations updated!', {
          type: toast.TYPE.SUCCESS,
        });
        fetchApartment();
        closeModal();
      });
  };

  const updatedTranslations = () => {
    const initialTranslations = getI18ns(i18nKey);

    return Object.keys(fieldsToUpdate)
      .filter((key) => fieldsToUpdate[key] !== initialTranslations[key])
      .length > 0;
  };

  const hasLetterBox = getValue('hasLetterBox');
  const internetHasCpl = getValue('internetHasCpl');

  return (
    <div className="configuration-tab">
      <Row>
        <Col lg="6">
          <Card className="table-card">
            <Card.Header>
              <strong>Yield & general knowledge</strong>
            </Card.Header>
            <Card.Body>
              <Table>
                <tbody>
                  <tr>
                    <th>Yield category</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="select"
                        name="yieldCategory"
                        options={ ['1', '2', '3'] }
                        value={ getValue('yieldCategory') }
                        team="SALES"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>KB link</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="knowledgeBaseUrl"
                        value={ getValue('knowledgeBaseUrl') }
                        isLink
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Virtual visit</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="virtualVisitUrl"
                        value={ getValue('virtualVisitUrl') }
                        isLink
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6">
          <Card className="table-card">
            <Card.Header>
              <strong>Electricity</strong>
            </Card.Header>
            <Card.Body>
              <Table>
                <tbody>
                  <tr>
                    <th>Meter kind</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="select"
                        name="elecMeterKind"
                        options={ ELECTRIC_METER_OPTIONS }
                        value={ getValue('elecMeterKind') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Power</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="number"
                        name="elecPower"
                        value={ getValue('elecPower') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Supplier</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="elecSupplier"
                        value={ getValue('elecSupplier') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Pricing option</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="select"
                        name="elecPricingOption"
                        options={ ELEC_PRICING_OPTIONS }
                        value={ getValue('elecPricingOption') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Contract start</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="date"
                        name="elecContractStartDate"
                        value={ getValue('elecContractStartDate') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Serial number</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="elecMeterSerialNumber"
                        value={ getValue('elecMeterSerialNumber') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Switchboard</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="select"
                        name="switchboardKind"
                        options={ SWITCHBOARD_OPTIONS }
                        value={ getValue('switchboardKind') }
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <Card className="table-card">
            <Card.Header>
              <strong>Water</strong>
            </Card.Header>
            <Card.Body>
              <Table>
                <tbody>
                  <tr>
                    <th>Meter kind</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="select"
                        name="waterMeterKind"
                        options={ WATER_METER_OPTIONS }
                        value={ getValue('waterMeterKind') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Cold water serial number</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="waterMeterSerialNumber"
                        value={ getValue('waterMeterSerialNumber') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Hot water serial number</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="hotWaterMeterSerialNumber"
                        value={ getValue('hotWaterMeterSerialNumber') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Water charges</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="select"
                        name="waterMeterCharges"
                        options={ WATER_CHARGES_OPTIONS }
                        value={ getValue('waterMeterCharges') }
                      />
                    </td>
                  </tr>
                  {
                    apartment.CityId === 'lyon'
                      ? (
                        <>
                          <tr>
                            <th>Login</th>
                            <td>
                              <EditableField
                                { ...defaultEditableFieldProps }
                                name="waterCompanyLogin"
                                value={ getValue('waterCompanyLogin') }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>Password</th>
                            <td>
                              <EditableField
                                { ...defaultEditableFieldProps }
                                name="waterCompanyPassword"
                                value={ getValue('waterCompanyPassword') }
                              />
                            </td>
                          </tr>
                        </>
                      )
                      : null
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6">
          <Card className="table-card">
            <Card.Header>
              <strong>Heating</strong>
            </Card.Header>
            <Card.Body>
              <Table>
                <tbody>
                  <tr>
                    <th>Kind</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="select"
                        name="heatingKind"
                        options={ HEATING_OPTIONS }
                        value={ getValue('heatingKind') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Boiler supplier</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="boilerSupplier"
                        value={ getValue('boilerSupplier') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Boiler contract start</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="date"
                        name="boilerContractStartDate"
                        value={ getValue('boilerContractStartDate') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Boiler brand</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="boilerBrand"
                        value={ getValue('boilerBrand') }
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <Card className="table-card">
            <Card.Header>
              <strong>Gas</strong>
            </Card.Header>
            <Card.Body>
              <Table>
                <tbody>
                  <tr>
                    <th>Meter kind</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="select"
                        name="gasMeterKind"
                        options={ GAS_METER_OPTIONS }
                        value={ getValue('gasMeterKind') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Supplier</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="gasSupplier"
                        value={ getValue('gasSupplier') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Pricing option</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="select"
                        name="gasPricingOption"
                        options={ GAS_PRICING_OPTIONS }
                        value={ getValue('gasPricingOption') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Contract start</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="date"
                        name="gasContractStartDate"
                        value={ getValue('gasContractStartDate') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Serial number</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="gasMeterSerialNumber"
                        value={ getValue('gasMeterSerialNumber') }
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6">
          <Card className="table-card">
            <Card.Header>
              <strong>Letter box</strong>
            </Card.Header>
            <Card.Body>
              <Table>
                <tbody>
                  <tr>
                    <th>Presence</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="radio"
                        name="hasLetterBox"
                        label={ hasLetterBox !== null && (hasLetterBox ? 'Yes' : 'No') }
                        options={{ yes: 'true', no: 'false' }}
                        value={ `${hasLetterBox}` }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Distribution kind</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="select"
                        name="mailDistribution"
                        options={ MAIL_DISTRIBUTION_KIND }
                        value={ getValue('mailDistribution') }
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <Card className="table-card">
            <Card.Header>
              <strong>Internet</strong>
            </Card.Header>
            <Card.Body>
              <Table>
                <tbody>
                  <tr>
                    <th>Line number</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="internetLineNumber"
                        value={ getValue('internetLineNumber') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Password</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="internetPassword"
                        value={ getValue('internetPassword') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>CPL</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="radio"
                        name="internetHasCpl"
                        label={ internetHasCpl !== null && (internetHasCpl ? 'Yes' : 'No') }
                        options={{ yes: 'true', no: 'false' }}
                        value={ `${internetHasCpl}` }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Kind</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="select"
                        name="internetKind"
                        options={ INTERNET_KIND }
                        value={ getValue('internetKind') }
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <Card>
            <Card.Header>
              <strong>Access information</strong>
            </Card.Header>
            <Card.Body>
              { ACCESS_BUTTONS.map(({ key, label }) => (
                <Button
                  key={ key }
                  variant="secondary"
                  block
                  onClick={ () => openModal(key) }
                >
                  { label }
                </Button>
              ))}
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6">
          <Card>
            <Card.Header>
              <strong>Meters location</strong>
            </Card.Header>
            <Card.Body>
              { LOCATIONS_BUTTONS.map(({ key, label }) => (
                <Button
                  key={ key }
                  variant="secondary"
                  block
                  onClick={ () => openModal(key) }
                >
                  { label }
                </Button>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {
        i18nKey
          ? (
            <Modal size="lg" show={ Boolean(i18nKey) } onHide={ () => closeModal() }>
              <Modal.Header closeButton>{ startCase(i18nKey) }</Modal.Header>
              <Modal.Body>
                {
                Object.keys(fieldsToUpdate)
                  .sort().reverse()
                  .map((key) => (
                    <FormGroup key={ key }>
                      <FormLabel>{ key.split('.')[1].toUpperCase() }</FormLabel>
                      <FormControl
                        as="textarea"
                        value={ fieldsToUpdate[key] }
                        onChange={ (e) => editField({ ...fieldsToUpdate, [key]: e.target.value }) }
                      />
                    </FormGroup>
                  ))
              }
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={ () => closeModal() }>Cancel</Button>
                <Button disabled={ !updatedTranslations() } onClick={ () => updateTranslations() }>Save</Button>
              </Modal.Footer>
            </Modal>
          )
          : null
      }
      <RoundButton
        editMode={ editMode }
        toggleEditMode={ toggleEditMode }
        save={ updateApartment }
      />
    </div>
  );
}

export default ConfigurationTab;
