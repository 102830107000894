import React from 'react';
import moment from 'moment';
import startCase from 'lodash/startCase';
import arrayFilter from 'lodash/filter';
import find from 'lodash/find';

import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';

import buildForestLink from '../../utils/buildForestLink';
import getLanguageFlag from '../../utils/getLanguageFlag';
import ForestApi from '../../utils/API';
import CheckinProcess from '../CheckinProcess';
import DepositSteps from '../DepositSteps';
import './style.scss';
import copyFormattedText from '../../utils/copyFormattedText';

const DEPOSIT_REFUND_DELAY = 60;

class EventPopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refundType: 'bank-transfer', // eslint-disable-line
      isLoading: false,
    };
  }

  handleRefundType(value) {
    this.setState({ refundType: value }); // eslint-disable-line
  }

  downloadInvoice(orderId) {
    this.setState({ isLoading: true });
    new ForestApi().orders()
      .getDownloadUrl(orderId)
      .then((response) => {
        this.setState({ isLoading: false });

        window.open(response.data.url);
      });
  }

  renderCheckoutAction() {
    const {
      event,
      displayStatus,
      contractorEvent,
      city,
      toggleModal,
      handleAssignmentCancelation,
      handleAcceptContractor,
      handleRefusedContractor,
      history,
    } = this.props;
    const statusContractor = arrayFilter(event.ContractorEvents, { status: 'draft' });
    const depositStartDate = moment(event.startDate).add(DEPOSIT_REFUND_DELAY, 'days').format('YYYY-MM-DD');
    let contractorBlock;
    if (displayStatus !== 'confirmed') {
      if (statusContractor.length > 0) {
        const { id } = statusContractor[0];
        contractorBlock = (
          <>
            <li>
              <span className="fa-li">
                <i className="fas fa-paper-plane" />
              </span>
              {`Invitation sent on ${moment(event.ContractorEvents.createdAt).format('DD/MM/YYYY')}`}
            </li>
            <li className="mt-4">
              <Button size="sm" onClick={ () => handleAcceptContractor(id) } block variant="success">
                <i className="icon far fa-envelope" />
                Accept for him
              </Button>
              <Button size="sm" onClick={ () => handleRefusedContractor(id) } block variant="danger">
                <i className="icon far fa-envelope" />
                Refused for him
              </Button>
            </li>
            <li className="mt-4">
              <Button size="sm" onClick={ () => toggleModal() } block>
                <i className="icon far fa-envelope" />
                See invitation
              </Button>
            </li>
          </>
        );
      } else {
        const lastEvent = event.ContractorEvents.length > 0 ? event.ContractorEvents[0] : null;
        const inviteButton = (
          <li className="mt-4">
            <Button size="sm" onClick={ () => toggleModal() } block>
              <i className="icon far fa-envelope" />
              Invite contractors
            </Button>
          </li>
        );
        if (lastEvent) {
          contractorBlock = (
            <>
              <li>
                <span className="fa-li">
                  <i className="fas fa-times color-cancelled" />
                </span>
                {`Last invitation refused (updated on ${moment(lastEvent.updatedAt)
                  .format('DD/MM/YYYY')})`}
              </li>
              {inviteButton}
            </>
          );
        } else {
          contractorBlock = inviteButton;
        }
      }
    } else {
      const callDescription = (id) => {
        new ForestApi().events()
          .getOneCalendarDescription(id)
          .then((response) => {
            if (response.status === 200) {
              copyFormattedText(response.data.description);
            }
          });
      };
      const { id, Contractor: contractor, EventId } = contractorEvent;
      contractorBlock = (
        <li>
          <span className="fa-li">
            <i className="fas fa-check" />
          </span>
          Assigned contractor:
          <br />
          <strong>{ contractor.fullName }</strong>
          {' ─ '}
          <i className="fas fa-phone" />
          {' '}
          <a href={ `tel:${contractor.phoneNumber}` }>{ contractor.phoneNumber }</a>
          <div className="mt-4 text-center">
            <Button size="sm" variant="secondary" onClick={ () => callDescription(EventId) }>
              <i className="icon fa fa-clipboard" />
              Copy description
            </Button>
          </div>
          <div className="mt-2 text-center">
            <Button size="sm" variant="danger" onClick={ () => handleAssignmentCancelation(id) }>
              <i className="icon fa fa-times" />
              Cancel assignment
            </Button>
          </div>
        </li>
      );
    }

    return (
      <>
        { contractorBlock }
        <li className="mt-2">
          <Button
            size="sm"
            variant="info"
            onClick={ () => history.push(`/deposit-refunds?start=${depositStartDate}&city=${city}`) }
            block
          >
            <i className="icon far fa-calendar-check" />
            Go to deposit refund
          </Button>
        </li>
      </>
    );
  }

  renderDepositRefundAction() {
    const { event, displayStatus, toggleModal } = this.props;
    const { isLoading } = this.state;
    const { Renting } = event;
    let action;

    if (displayStatus === 'notReady') {
      const isRoomEvent = Boolean(Renting);
      const { Client } = Renting;
      const refundOrder = isRoomEvent && Client.Orders.find((order) => order.type === 'credit'
        && find(order.OrderItems, { RentingId: event.Renting.id }));
      const hasRefundOrder = Boolean(refundOrder);

      action = (
        <Button size="sm" onClick={ () => toggleModal() } block>
          <i className="icon fas fa-cogs" />
          { hasRefundOrder ? 'See comparison' : 'Compare' }
        </Button>
      );
    } else if (displayStatus === 'ready') {
      action = (
        <div className="mt-2">
          <Button variant="secondary" size="sm" disabled block>
            <i className="icon far fa-clock" />
            Pending refund
          </Button>
        </div>
      );
    } else if (displayStatus === 'confirmed') {
      const retainedItemsOrder = Renting.Client.Orders.find(({ label }) => label === 'Items retained from Deposit');

      action = (
        <>
          <Button variant="success" size="sm" className="btn-label" disabled block>
            <i className="icon fas fa-thumbs-up" />
            Client refunded
          </Button>
          {
            retainedItemsOrder
              ? (
                <Button
                  variant="link"
                  onClick={ () => this.downloadInvoice(retainedItemsOrder.id) }
                  disabled={ isLoading }
                >
                  {
                  isLoading
                    ? <i className="icon fas fa-spinner fa-spin" />
                    : <i className="icon fas fa-file-pdf" />
                }
                  Download invoice
                </Button>
              )
              : null
          }
        </>
      );
    } else {
      action = (
        <Button variant="danger" size="sm" disabled block>
          Unknown status
        </Button>
      );
    }

    return (
      <li className="mt-4 text-center">
        <DepositSteps renting={ Renting } />
        <div className="mt-2">
          { action }
        </div>
      </li>
    );
  }

  renderAction() {
    const { event, fetchEvents, updateSafeNumber } = this.props;
    const { Renting, type } = event;

    if (type === 'checkin') {
      return (
        <li className="mt-4 text-center">
          <CheckinProcess
            renting={ Renting }
            updateSafeNumber={ updateSafeNumber }
            updateView={ fetchEvents }
          />
        </li>
      );
    }

    if (type === 'checkout') {
      return this.renderCheckoutAction();
    }

    if (type === 'deposit-refund') {
      return this.renderDepositRefundAction();
    }

    return null;
  }

  render() {
    const {
      event,
      displayStatus,
      contractorEvent,
      handleCheckinReady,
      toggleModal,
      handleAssignmentCancelation,
      handleAcceptContractor,
      handleRefusedContractor,
      updateSafeNumber,
      fetchEvents,
      eventType,
      history,
      params,
      ...rest
    } = this.props;

    const {
      type,
      startDate,
      location,
    } = event;

    let rentingId;
    let Client;
    let Room;
    let Garage;
    let bookingDate;

    const isRoomEvent = Boolean(event.Renting);

    if (event.Renting) {
      rentingId = event.Renting.id;
      Client = event.Renting.Client;
      Room = event.Renting.Room;
      bookingDate = event.Renting.bookingDate;
    } else if (event.GarageRenting) {
      Client = event.GarageRenting.Client;
      Garage = event.GarageRenting.Garage;
    }

    const knowledgeBaseUrl = isRoomEvent
      ? Room.Apartment.knowledgeBaseUrl
      : Garage.Apartment.knowledgeBaseUrl;

    const roomOrGarageName = isRoomEvent
      ? `Room ${Room.name.split('#')[1]}`
      : 'Garage';

    const forestRentingLink = buildForestLink(rentingId, 'renting');
    const forestCollabLink = buildForestLink(Client.id, 'client').replace('summary', 'collaboration');

    const depositOrder = isRoomEvent && Client.Orders.find((order) => order.type === 'deposit'
      && find(order.OrderItems, { RentingId: event.Renting.id }));
    const depositAmount = depositOrder ? depositOrder.amount / 100 : 0;
    const refundOrder = isRoomEvent && Client.Orders.find((order) => order.type === 'credit'
      && find(order.OrderItems, { RentingId: event.Renting.id }));
    const hasRefundOrder = Boolean(refundOrder);
    let refundAmount = 0;

    if (hasRefundOrder) {
      refundAmount = refundOrder.status === 'draft'
        ? refundOrder.OrderItems
          .reduce((sum, item) => sum + ((Number(item.unitPrice) * Number(item.quantity)) / 100), 0)
        : refundOrder.amount / 100;
    }

    const format = type === 'deposit-refund' ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm';

    // We pass 'rest' as props to Popover to avoid passing unnecessary props to underlying components
    return (
      <Popover { ...rest }>
        <Popover.Title className="d-flex align-items-center justify-content-between">
          <div>
            { startCase(type) }
          </div>
          <div>
            <a href={ forestRentingLink } rel="noopener noreferrer" target="_blank">
              <i className="fa fa-external-link-alt" />
            </a>
          </div>
        </Popover.Title>
        <Popover.Content>
          <ul className="fa-ul">
            <li>
              <span className="fa-li">
                <i className="fa fa-user" />
              </span>
              <strong>{ Client.fullName }</strong>
              { ' ' }
              { getLanguageFlag(Client.preferredLanguage) }
            </li>
            <li>
              <span className="fa-li">
                <i className="far fa-calendar-alt" />
              </span>
              { moment(startDate).format(format) }
            </li>
            <li>
              <span className="fa-li">
                <i className="fa fa-home" />
              </span>
              <a href={ knowledgeBaseUrl } rel="noopener noreferrer" target="_blank">
                { location }
              </a>
            </li>
            <li>
              <span className="fa-li">
                <i className="fa fa-bed" />
              </span>
              { roomOrGarageName }
            </li>
            {
              type !== 'deposit-refund'
                ? (
                  <li>
                    <span className="fa-li">
                      <i className="fa fa-phone" />
                    </span>
                    <a href={ `tel:${Client.phoneNumber}` }>{ Client.phoneNumber }</a>
                  </li>
                )
                : null
            }
            <li>
              <span className="fa-li">
                <i className="far fa-envelope" />
              </span>
              <a href={ `mailto:${Client.email}` }>{ Client.email }</a>
            </li>
            {
              type !== 'deposit-refund'
                ? (
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-file-contract" />
                    </span>
                    {`Booked on ${moment(bookingDate).format('DD/MM/YYYY')}`}
                  </li>
                )
                : null
            }
            {
              type === 'deposit-refund'
                ? (
                  <>
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-comments" />
                      </span>
                      <a href={ forestCollabLink } rel="noopener noreferrer" target="_blank">
                        Forest collaboration
                      </a>
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-euro-sign" />
                      </span>
                      {`Deposit amount: ${depositAmount} €`}
                    </li>
                    {
                    hasRefundOrder
                      ? (
                        <li>
                          <span className="fa-li">
                            <i className="fas fa-hand-holding-usd" />
                          </span>
                          {`Amount to refund: ${refundAmount} €`}
                        </li>
                      )
                      : null
                  }
                  </>
                )
                : null
            }
            {
              isRoomEvent
                ? this.renderAction()
                : null
            }
          </ul>
        </Popover.Content>
      </Popover>
    );
  }
}

export default EventPopover;
