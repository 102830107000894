import React, { useState } from 'react';
import { countries } from 'countries-list';
import moment from 'moment';
import get from 'lodash/get';
import inRange from 'lodash/inRange';
import startCase from 'lodash/startCase';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import getLanguageFlag from '../../../utils/getLanguageFlag';
import EditableField from '../../EditableField';
import StatusLabel from '../../StatusLabel';

function serializeCountry(nationality) {
  return countries[nationality]
    ? countries[nationality].name
    : nationality;
}

function renderStatus(status) {
  return <StatusLabel status={ status } />;
}

function MainTab(props) {
  const { client } = props;
  const { Rentings, subRentings } = client;

  const hasRenting = Rentings.length > 0 || subRentings.length > 0;

  const [modalKind, toggleModal] = useState(false);

  function getGuarantor(guarantorCount) {
    if (guarantorCount == null) {
      return 'No guarantor needed';
    }

    if (!inRange(guarantorCount, 0, 4)) {
      return 'Wrong number';
    }

    const isFemale = client.gender === 'female';

    const displayGuarantorMessage = [
      `Documents provided by ${isFemale ? 'herself' : 'himself'}`,
      'One guarantor',
      'Two guarantors',
      'Garantme',
    ];

    if (guarantorCount !== 1) {
      return displayGuarantorMessage[guarantorCount];
    }

    return (
      <div className="d-flex align-items-center justify-content-between">
        <span>One guarantor</span>
        <Button variant="link" size="sm" onClick={ () => toggleModal('guarantor') }>
          <i className="fas fa-eye text-dark" />
        </Button>
      </div>
    );
  }

  function getEmergencyContact(kind) {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <span>{ kind || 'Undefined' }</span>
        <Button variant="link" size="sm" onClick={ () => toggleModal('emergencyContact') }>
          <i className="fas fa-eye text-dark" />
        </Button>
      </div>
    );
  }

  function getRenting() {
    let rentingAssociation = 'Rentings';

    if (subRentings.length > 0
      && (Rentings.length === 0 || moment(subRentings[0].bookingDate) > moment(Rentings[0].bookingDate))) {
      rentingAssociation = 'subRentings';
    }
    const { hasTwoOccupants } = hasRenting && client[rentingAssociation][0];
    const isMainClient = rentingAssociation === 'Rentings';

    const fields = hasRenting
      ? [{
        name: `${rentingAssociation}.0.Room.reference`,
        label: 'Reference',
        type: 'string',
        required: true,
      }, {
        name: `${rentingAssociation}.0.Room.name`,
        label: 'Room',
        type: 'string',
        required: true,
      }, {
        name: `${rentingAssociation}.0.status`,
        label: 'Status',
        type: 'string',
        required: true,
        serializer: renderStatus,
      }, {
        name: `${rentingAssociation}.0.bookingDate`,
        label: 'Booking date',
        type: 'date',
        required: true,
      }, {
        name: `${rentingAssociation}.0.checkinDate`,
        label: 'Checkin date',
        type: 'date',
        required: false,
      }, {
        name: `${rentingAssociation}.0.checkoutDate`,
        label: 'Checkout date',
        type: 'date',
        required: false,
      }, {
        name: 'hasTwoOccupants',
        label: 'Two occupants',
        type: 'string',
        value: hasTwoOccupants ? 'yes' : 'no',
        required: false,
      }]
      : [];

    if (hasTwoOccupants) {
      fields.push({
        name: 'principalClient',
        label: 'Principal client',
        type: 'string',
        isLink: !isMainClient,
        linkLabel: isMainClient ? 'yes' : subRentings[0].Client.fullName,
        value: !isMainClient ? `/clients/${subRentings[0].Client.id}` : 'yes',
        required: false,
      }, {
        name: 'secondaryClient',
        label: 'Secondary client',
        type: 'string',
        isLink: isMainClient,
        linkLabel: isMainClient ? Rentings[0].SecondaryClient.fullName : 'yes',
        value: isMainClient ? `/clients/${Rentings[0].SecondaryClient.id}` : 'yes',
        required: false,
      });
    }

    return fields;
  }

  const initialState = [{
    header: 'Identity',
    fields: [{
      name: 'firstName',
      label: 'First name',
      type: 'string',
      required: true,
    }, {
      name: 'lastName',
      label: 'Last name',
      type: 'string',
      required: true,
    }, {
      name: 'email',
      label: 'Email',
      type: 'string',
      required: true,
    }, {
      name: 'phoneNumber',
      label: 'Phone number',
      type: 'string',
      required: true,
    }, {
      name: 'nationality',
      label: 'Nationality',
      type: 'string',
      required: true,
      serializer: serializeCountry,
    }, {
      name: 'gender',
      label: 'Gender',
      type: 'string',
      required: false,
    }, {
      name: 'preferredLanguage',
      label: 'Preferred language',
      type: 'string',
      required: false,
      serializer: getLanguageFlag,
    }, {
      name: 'secondaryEmail',
      label: 'Secondary email',
      type: 'string',
      required: false,
    }, {
      name: 'birthDate',
      label: 'Birth date',
      type: 'date',
      required: false,
    }, {
      name: 'birthPlace',
      label: 'Birth place',
      type: 'string',
      required: false,
    }, {
      name: 'previousAddress',
      label: 'Previous address',
      type: 'string',
      required: false,
    }],
    showAdditionalFields: false,
  }, {
    header: 'Latest Renting',
    fields: getRenting(),
    showAdditionalFields: false,
  }, {
    header: 'Application',
    fields: [{
      name: 'guarantorCount',
      label: 'Guarantor',
      type: 'string',
      required: true,
      serializer: getGuarantor,
    }, {
      name: 'emergencyContactKind',
      label: 'Emergency contact',
      type: 'string',
      required: true,
      serializer: getEmergencyContact,
    }],
  }];

  const [cards, setCards] = useState(initialState);

  function toggleAdditionalFields(headerName) {
    const newCards = cards.map((card) => {
      if (card.header === headerName) {
        return {
          ...card,
          showAdditionalFields: !card.showAdditionalFields,
        };
      }

      return card;
    });

    setCards(newCards);
  }

  function getFieldValue(field) {
    const { serializer } = field;
    const value = get(client, field.name, null);

    return serializer ? serializer(value) : value;
  }

  function renderGuarantor() {
    return (
      <>
        <Row>
          <Col>
            <dl>
              <dt>Full name</dt>
              <dd>{ client.guarantorFullName || 'Undefined' }</dd>
            </dl>
          </Col>
        </Row>
        <Row>
          <Col>
            <dl>
              <dt>Email</dt>
              <dd>{ client.guarantorEmail || 'Undefined' }</dd>
              <dt>Birth date</dt>
              <dd>{ moment(client.guarantorBirthDate).format('DD/MM/YYYY') || 'Undefined' }</dd>
            </dl>
          </Col>
          <Col>
            <dl>
              <dt>Phone number</dt>
              <dd>{ client.guarantorPhoneNumber || 'Undefined' }</dd>
              <dt>Birth place</dt>
              <dd>{ client.guarantorBirthPlace || 'Undefined' }</dd>
            </dl>
          </Col>
        </Row>
      </>
    );
  }

  function renderEmergencyContact() {
    return (
      <>
        <Row>
          <Col>
            <dl>
              <dt>Full name</dt>
              <dd>{ client.emergencyFullName || 'Undefined' }</dd>
            </dl>
          </Col>
          <Col>
            <dl>
              <dt>Relationship</dt>
              <dd>{ client.emergencyContactKind || 'Undefined' }</dd>
            </dl>
          </Col>
        </Row>
        <Row>
          <Col>
            <dl>
              <dt>Email</dt>
              <dd>{ client.emergencyEmail || 'Undefined' }</dd>
            </dl>
          </Col>
          <Col>
            <dl>
              <dt>Phone number</dt>
              <dd>{ client.emergencyPhoneNumber || 'Undefined' }</dd>
            </dl>
          </Col>
        </Row>
      </>
    );
  }

  function renderCardFields(fields, displayAdditionalFields) {
    return fields
      .filter((field) => {
        if (displayAdditionalFields) {
          return true;
        }

        return field.required;
      })
      .map((field) => {
        const {
          name,
          type,
          label,
          value,
          isLink,
        } = field;

        const fieldValue = value || getFieldValue(field);

        return (
          <tr key={ label }>
            <th>{ label }</th>
            <td>
              <EditableField
                editMode={ false }
                team="HAPPI"
                inputType={ type }
                name={ name }
                label={ label }
                value={ fieldValue }
                isLink={ isLink }
              />
            </td>
          </tr>
        );
      });
  }

  const mappedCards = cards.map((card) => {
    const hasAdditionalFields = card.fields.filter((field) => !field.required).length > 0;

    const isLatestRenting = card.header === 'Latest Renting';

    return (
      <Col key={ card.header } lg="6">
        <Card className="table-card">
          <Card.Header>
            <strong>{ card.header }</strong>
          </Card.Header>
          <Card.Body>
            {
                !isLatestRenting || (isLatestRenting && hasRenting)
                  ? (
                    <Table>
                      <tbody>
                        { renderCardFields(card.fields, card.showAdditionalFields) }
                      </tbody>
                    </Table>

                  )
                  : (
                    <h5 className="empty-card">No renting yet</h5>
                  )
              }

            <div className="text-center mb-2">
              {
                hasAdditionalFields
                  ? (
                    <Button variant="link" onClick={ () => toggleAdditionalFields(card.header) }>
                      {
                      card.showAdditionalFields
                        ? (
                          <>
                            See less
                            <i className="fas fa-chevron-up ml-2" />
                          </>
                        )
                        : (
                          <>
                            See more
                            <i className="fas fa-chevron-down ml-2" />
                          </>
                        )
                    }
                    </Button>
                  )
                  : null
              }
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  });

  return (
    <div className="main-tab">
      <Row>
        { mappedCards }
      </Row>
      {
        modalKind
          ? (
            <Modal show={ Boolean(modalKind) } onHide={ () => toggleModal(null) }>
              <Modal.Header closeButton>
                <strong>
                  { startCase(modalKind) }
                </strong>
              </Modal.Header>
              <Modal.Body>
                {
                modalKind === 'guarantor'
                  ? renderGuarantor()
                  : renderEmergencyContact()
              }
              </Modal.Body>
            </Modal>
          )
          : null
      }
    </div>
  );
}

export default MainTab;
