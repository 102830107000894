import React from 'react';
import moment from 'moment';

import Col from 'react-bootstrap/Col';

import CityLabel from '../../CityLabel';
import ColumnCardsContainer from '../../ColumnCardsContainer';
import getRentingArrondissement from '../../../utils/getRentingArrondissement';
import ArrondissementLabel from '../../ArrondissementLabel';

const TASKS = {
  'Selfie & ID': 'fas fa-address-card',
  'Selfie & ID - Couple': 'fas fa-clone',
  'Emergency contact': 'fas fa-first-aid',
  'Client tenancy file': 'fas fa-file-alt',
  'Guarantor tenancy file': 'fas fa-user-tie',
  'Garantme tenancy file': 'fab fa-google',
  'Send signing link': 'fas fa-file-export',
  Signature: 'fas fa-file-signature',
};

class ClientApplication extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        currentValue: [],
        values: Object.keys(TASKS),
      },
      cards: [],
    };
  }

  componentDidMount() {
    this.getApplication();
  }

  componentDidUpdate(prevProps) {
    const { rentings } = this.props;

    if (rentings !== prevProps.rentings) {
      this.getApplication();
    }
  }

  getApplication() {
    const { rentings, handleModal } = this.props;

    const application = rentings
      .filter(({ Client }) => Client.status === 'booked')
      .reduce((acc, renting) => {
        const client = this.mapRentingApplication(renting);
        if (Object.values(client)[0].length === 0) {
          return acc;
        }

        acc.push(client);

        return acc;
      }, []);

    handleModal(this.setApplicationList(application));

    return this.setState({
      cards: this.parseEventCard(application),
    });
  }

  setApplicationList(application) {
    const applicationList = application
      .map((client) => this.filterApplication(client))
      .sort((clientA, clientB) => this.sortApplication(clientA, clientB))
      .reduce((acc, client) => {
        const id = Object.keys(client)[0];
        client[id].forEach((task) => acc.push({ id, task }));

        return acc;
      }, []);

    return applicationList;
  }

  handleFilterChange(value) {
    const { filters } = this.state;

    return this.setState({
      filters: {
        ...filters,
        currentValue: value,
      },
    }, () => this.getApplication());
  }

  mapRentingApplication(renting) {
    const {
      Client,
      ClientId: id,
      idChecked,
      tenancyFileApproved,
      emergencyContactChecked,
      SecondaryClientId,
      leaseSent,
      leaseSigned,
    } = renting;

    const { depositPaid } = renting.Checkin;

    const { Orders } = Client;

    const debitOrders = (Orders.filter((order) => order.type === 'debit'));
    const lastDebitOrders = debitOrders.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));

    const isFirstOrderPaid = lastDebitOrders[0]?.receiptNumber !== null;

    const documents = Client.Documents;

    function findDocument(docType) {
      return documents.find(({ type }) => type === docType);
    }

    function getSecondaryClientDocuments(docType) {
      return renting.SecondaryClient.Documents.find(({ type }) => type === docType);
    }

    const applicationTasks = [];

    if (!idChecked && !SecondaryClientId && findDocument('client-selfie') && findDocument('client-identity')) {
      applicationTasks.push('Selfie & ID');
    }

    if (!idChecked && Boolean(SecondaryClientId)
      && findDocument('client-selfie') && findDocument('client-identity')
      && getSecondaryClientDocuments('client-identity')) {
      applicationTasks.push('Selfie & ID - Couple');
    }

    if (!emergencyContactChecked && Boolean(Client.emergencyContactKind)) {
      applicationTasks.push('Emergency contact');
    }

    if (!tenancyFileApproved) {
      const isClientTenancy = Client.guarantorCount === 0 && findDocument('client-finances');
      const isGuarantorTenancy = Client.guarantorCount === 1;
      const isGarantmeTenancy = Client.guarantorCount === 3;

      if (isClientTenancy) {
        applicationTasks.push('Client tenancy file');
      }

      if (isGuarantorTenancy
        && findDocument('client-guarantor-finances') && findDocument('client-guarantor-identity')) {
        applicationTasks.push('Guarantor tenancy file');
      }

      if (isGarantmeTenancy && findDocument('client-garantme-finances')) {
        applicationTasks.push('Garantme tenancy file');
      }
    }

    if (!leaseSent && idChecked && tenancyFileApproved && isFirstOrderPaid && depositPaid) {
      applicationTasks.push('Send signing link');
    }

    if (leaseSent && !leaseSigned) {
      applicationTasks.push('Signature');
    }

    return { clientId: id, applicationTasks };
  }

  filterApplication(client) {
    const { filters } = this.state;

    const { clientId: id, applicationTasks } = client;

    const tasks = applicationTasks
      .filter((task) => filters.currentValue.length === 0 || filters.currentValue.includes(task));

    return { [id]: tasks };
  }

  sortApplication(clientA, clientB) {
    const { rentings } = this.props;

    const getCheckinDate = (client) => {
      const { checkinDate } = rentings.find((renting) => renting.ClientId === Object.keys(client)[0]) || {};

      return checkinDate ? moment(checkinDate) : Infinity;
    };

    return getCheckinDate(clientA) - getCheckinDate(clientB);
  }

  parseEventCard(application) {
    const { city: activeCity, rentings, handleModal } = this.props;

    const cards = application
      .map((client) => this.filterApplication(client))
      .sort((clientA, clientB) => this.sortApplication(clientA, clientB))
      .reduce((acc, client) => {
        const id = Object.keys(client)[0];
        const clientRenting = rentings.find((renting) => renting.ClientId === id);
        const { checkinDate, Room } = clientRenting;
        const formattedCheckinDate = checkinDate ? moment(checkinDate).format('DD/MM/YYYY') : null;
        const city = Room.Apartment.CityId;
        let arrondissement;
        if (city?.toLowerCase() === 'paris') {
          arrondissement = getRentingArrondissement(clientRenting);
        }
        const roomName = Room.name;

        const clientTasks = client[id].map((task) => (
          <div
            key={ `${id}/${task}` }
            className="column-card"
            onClick={ () => handleModal(this.setApplicationList(application), id, task) }
          >
            <div className="mb-2 pb-2 d-flex align-items-center card-title">
              <i className={ `${TASKS[task]} mr-2` } />
              <h6 className="m-0">{task}</h6>
              {arrondissement && <ArrondissementLabel arrondissement={ arrondissement } className="ml-auto p-1" />}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="note">
                <p>
                  {roomName}
                </p>
                <div className="d-flex align-items-center">
                  {
                checkinDate
                  ? (
                    <>
                      <span className="mr-1">
                        {`C/I date : ${formattedCheckinDate} - `}
                        {this.renderCheckinTimeslot(checkinDate)}
                      </span>
                    </>
                  )
                  : <span className="font-italic text-muted">not specified</span>
              }
                </div>
              </div>
              {
                activeCity === 'all'
                  ? <CityLabel city={ city } />
                  : null
              }
            </div>
          </div>
        ));

        return [...acc, ...clientTasks];
      }, []);

    return cards;
  }

  renderCheckinTimeslot(checkinDate) {
    const NINE_AM = moment(checkinDate).set({ hours: 9, minutes: 0 });
    const SIX_PM = moment(checkinDate).set({ hours: 18, minutes: 0 });

    const isDayTimeslot = moment(checkinDate).isBetween(NINE_AM, SIX_PM);

    return (
      <span>
        <i className={ `icon fas fa-${isDayTimeslot ? 'sun' : 'moon'} ml-1` } />
        { isDayTimeslot ? 'Day' : 'Night'}
      </span>
    );
  }

  render() {
    const { cards, filters } = this.state;
    const { isLoading } = this.props;

    return (
      <Col lg="4">
        <ColumnCardsContainer
          title="Application"
          cards={ cards }
          filters={ filters }
          handleFilterChange={ (value) => this.handleFilterChange(value) }
          icon="fas fa-folder fa-lg"
          toggle
          style
          isLoading={ isLoading }
        />
      </Col>
    );
  }
}

export default ClientApplication;
