import React from 'react';
import Alert from 'react-bootstrap/Alert';

function ContractorPaid() {
  return (
    <div>
      <Alert variant="info">Are you sure ?</Alert>
      <ul>
        <li>Did you receive the contractor's invoice ?</li>
        <li>Did you proceed to the payment of the invoice ?</li>
      </ul>
      <p>
        If so, you can
        {' '}
        <strong>archive</strong>
        {' '}
        this intervention by clicking on the Confirm button below.
      </p>
    </div>
  );
}

export default ContractorPaid;
