import React from 'react';
import moment from 'moment';
import { countries } from 'countries-list';
import isEmpty from 'lodash/isEmpty';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../utils/API';
import getLanguageFlag from '../../utils/getLanguageFlag';
import CityLabel from '../CityLabel';
import Dashboard from '../Dashboard';
import StatusLabel from '../StatusLabel';
import extractParamsQuery from '../../utils/extractParamsQuery';
import fetchCitiesFilters from '../../utils/fetchCitiesFilters';
import getFromQuery from '../../utils/getFromQuery';

const CLIENT_STATUS = [
  'draft',
  'booked',
  'checked-in',
  'switching',
  'checked-out',
  'archived',
];

class ClientsDashboard extends React.Component {
  constructor(props) {
    super(props);
    const filters = extractParamsQuery({
      search: {
        type: 'search',
        placeholder: 'Filter by name...',
        currentValue: '',
      },
      city: {
        type: 'checkbox',
        label: 'City',
        currentValue: ['lyon'],
        values: [],
      },
      status: {
        type: 'checkbox',
        label: 'Status',
        currentValue: ['booked'],
        values: CLIENT_STATUS,
      },
    }, window.location.search);

    this.state = {
      isLoading: false,
      filters,
      rentings: [],
    };
  }

  async componentDidMount() {
    await this.fetchCities();
    await this.fetchRentings();
  }

  componentWillUnmount() {
    this.setState = () => {};
  }

  async fetchRentings() {
    const { filters } = this.state;
    const { city, status } = filters;

    this.setState({ isLoading: true });

    const query = [
      status.currentValue && `status=${status.currentValue.join(',')}`,
      city.currentValue && `city=${city.currentValue.join(',')}`,
    ].join('&');

    const response = await new ForestApi().rentings().getAll(query);

    const rentings = response.data;

    this.setState({
      rentings,
      isLoading: false,
    });
  }

  async fetchCities() {
    const { filters } = this.state;
    this.setState(await fetchCitiesFilters(filters));
  }

  showIndex() {
    const { history } = this.props;

    history.push('/clients');
  }

  showClient(rentingId) {
    const { history } = this.props;
    const { rentings } = this.state;
    const { id: ClientId } = rentings.find(({ id }) => id === rentingId).Client;
    history.push(`/clients/${ClientId}?${getFromQuery(window.location)}`);
  }

  handleFilterChange(name, value) {
    const { filters } = this.state;

    if (name === 'city' && value.length === 0) {
      return;
    }

    const refetch = [
      'city',
      'status',
    ].includes(name);

    this.setState({
      filters: {
        ...filters,
        [name]: {
          ...filters[name],
          currentValue: value,
        },
      },
    }, refetch ? this.fetchRentings : null);
  }

  parseClientCard(renting) {
    const {
      id,
      status,
    } = renting;

    const {
      firstName,
      lastName,
      nationality,
      preferredLanguage,
    } = renting.Client;

    const {
      Apartment,
      name: roomName,
      reference: roomReference,
    } = renting.Room;

    const { CityId } = Apartment;

    const title = (
      <span>
        { firstName }
        {' '}
        { lastName }
        {' '}
        { getLanguageFlag(preferredLanguage) }
        {
          renting.SecondaryClientId
            ? (
              <span>
                {' '}
                <i className="fas fa-user-friends" />
              </span>
            )
            : null
        }
      </span>
    );

    let country = 'Unknown';

    if (!isEmpty(nationality)) {
      country = countries[nationality] ? countries[nationality].name : nationality;
    }

    const subtitle = <span>{ country }</span>;

    const tags = [
      <StatusLabel key={ id } status={ status } className="mr-2 mb-2" />,
      <hr key={ `${id} tab` } className="my-2 border-0" />,
      <CityLabel key={ `${id} ${CityId}` } city={ CityId } className="mr-2 mb-2" />,
      <Button key={ roomReference } variant="dark" className="btn-label mr-2 mb-2" size="sm" disabled>
        { roomReference }
      </Button>,
      <Button key={ roomName } variant="dark" className="btn-label mb-2" size="sm" disabled>
        <i className="icon fas fa-bed" />
        { roomName }
      </Button>,
    ];

    const description = (
      <div className="d-flex align-items-center" title="Booking date" data-toggle="tooltip">
        <i className="icon fas fa-file-signature fa-lg" />
        { moment(renting.bookingDate).format('DD/MM/YYYY') }
      </div>
    );

    return {
      id,
      title,
      subtitle,
      tags,
      description,
    };
  }

  renderPlaceholder() {
    return (
      <Row>
        <Col>
          <Jumbotron className="text-center">
            <h1>No clients</h1>
            <p>
              No clients found matching these filters.
            </p>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  render() {
    const { isLoading, filters, rentings } = this.state;

    const query = [
      `search=${filters.search.currentValue}`,
      `city=${filters.city.currentValue.join(',')}`,
      `status=${filters.status.currentValue.join(',')}`,
    ].join('&');
    window.history.replaceState(null, null, `${window.location.pathname}?${query}`);

    const filteredRentings = rentings
      .filter((renting) => {
        const { firstName, lastName } = renting.Client;
        const { search } = filters;

        if (!search.currentValue) {
          return true;
        }

        const regex = new RegExp(search.currentValue, 'i');

        return regex.test(`${firstName} ${lastName}`);
      })
      .map(this.parseClientCard);

    return (
      <div className="Clients">
        <div className="d-flex justify-content-between flex-wrap
        flex-md-nowrap align-items-center pt-3 pb-2 border-bottom"
        >
          <Button variant="link" onClick={ () => this.showIndex() }>
            <i className="icon fas fa-arrow-circle-left" />
            Back to search
          </Button>
          <h3>Clients Dashboard</h3>
          <div />
        </div>
        <Dashboard
          model="client"
          filters={ filters }
          items={ filteredRentings }
          handleFilterChange={ (name, value) => this.handleFilterChange(name, value) }
          handleElementClick={ (id) => this.showClient(id) }
          renderPlaceholder={ () => this.renderPlaceholder() }
          isLoading={ isLoading }
        />
      </div>
    );
  }
}

export default ClientsDashboard;
