import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { toast } from 'react-toastify';
import ForestApi from '../utils/API';

function ResetPasswordPage(props) {
  const { history, location } = props;
  const [password, setPassword] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState(null);

  const queryString = location.search.replace('?', '');
  const [, token] = queryString.split('=');

  function handleSubmit(event) {
    event.preventDefault();

    const params = {
      password,
      passwordConfirmation,
      token,
    };

    new ForestApi().users()
      .updatePassword(params)
      .then(() => {
        history.push('/');
        toast('Request sent!', {
          type: toast.TYPE.SUCCESS,
        });
      });
  }

  const validateMatchingPasswords = password && passwordConfirmation && password === passwordConfirmation;

  return (
    <div className="container">
      <div className="ResetPasswordPage text-center">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="w-100 p-5">
                      <div className="text-center">
                        <h4 className="text-gray-900 mb-4">Reset your password</h4>
                      </div>
                      <Form className="login" onSubmit={ handleSubmit }>
                        <Form.Group>
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={ (event) => setPassword(event.target.value) }
                            required
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Control
                            type="password"
                            name="passwordConfirmation"
                            placeholder="Confirm password"
                            onChange={ (event) => setPasswordConfirmation(event.target.value) }
                            isInvalid={ passwordConfirmation && !validateMatchingPasswords }
                            required
                          />
                        </Form.Group>
                        {
                          passwordConfirmation && !validateMatchingPasswords
                            ? <p className="text-danger">Password confirmation does not match password</p>
                            : null
                        }
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={ !validateMatchingPasswords }
                          block
                        >
                          Reset password
                        </Button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
