import _const from '../const';

const {
  FOREST_BASE_URL,
} = _const;

function getRecordId(model) {
  return _const[`FOREST_${model.toUpperCase()}_ID`];
}

function buildForestLink(recordId, model) {
  const forestRecordId = getRecordId(model);

  return [
    FOREST_BASE_URL,
    `/data/${forestRecordId}/index`,
    `/record/${forestRecordId}/${recordId}`,
    '/summary',
  ].join('');
}

export default buildForestLink;
