import React, { useState } from 'react';
import times from 'lodash/times';

import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';

import CheckinSteps from '../CheckinSteps';

function CheckinProcess(props) {
  const { renting, updateView, updateSafeNumber } = props;
  const [safeNumber, setSafeNumber] = useState(1);

  const {
    checkinDateApproved,
    checkinSpotSafeNumber,
    Checkin,
    id,
  } = renting;

  function renderSafeNumberSelection() {
    const options = times(12, (value) => <option key={ value } value={ value + 1 }>{ value + 1}</option>);

    return (
      <div className="d-flex align-items-center justify-content-between">
        <FormControl
          as="select"
          onChange={ (e) => setSafeNumber(e.target.value) }
        >
          { options }
          <option value="-1">KeyNest</option>
        </FormControl>
        <Button
          size="sm"
          variant="info"
          className="ml-2 w-100"
          onClick={ () => updateSafeNumber(id, safeNumber) }
        >
          <i className="icon fas fa-key" />
          Assign safe number
        </Button>
      </div>
    );
  }

  const hasSafeNumber = Boolean(checkinSpotSafeNumber);
  let checkinProcessStatus;

  const checkinReady = Checkin.isCheckinReady && Checkin.depositPaid && checkinDateApproved;

  if (!checkinReady) {
    checkinProcessStatus = 'notReady';
  }

  if (checkinReady && !hasSafeNumber) {
    checkinProcessStatus = 'ready';
  }

  if (checkinReady && hasSafeNumber) {
    checkinProcessStatus = 'confirmed';
  }

  return (
    <div className="CheckinProcess">
      {
        checkinProcessStatus === 'notReady'
          ? <CheckinSteps renting={ renting } updateView={ (rentingId, data) => updateView(rentingId, data) } />
          : null
      }
      {
        checkinProcessStatus === 'ready'
          ? renderSafeNumberSelection()
          : null
      }
      {
        checkinProcessStatus === 'confirmed'
          ? (
            <>
              <i className="icon fas fa-key fa-lg" />
              {
              Number(checkinSpotSafeNumber) === -1
                ? 'KeyNest'
                : `Safe number ${checkinSpotSafeNumber}`
            }
            </>
          )
          : null
      }
    </div>
  );
}

export default CheckinProcess;
