import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import capitalize from 'lodash/capitalize';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';

function InterventionsTab(props) {
  const { interventions } = props;
  const [displayModal, toggleModal] = useState(false);
  const [modalDescription, setDescription] = useState('');
  const [modalNotes, setNotes] = useState('');

  if (!interventions || interventions.length === 0) {
    return (
      <Row>
        <Col>
          <Jumbotron className="text-center">
            <h1>No interventions</h1>
            <p>
              There are no interventions for this apartment yet.
            </p>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  function showDescription(description, notes) {
    setDescription(description);
    setNotes(notes);
    toggleModal(!displayModal);
  }

  const INTERVENTION_STATUS = ['draft', 'assessed', 'scheduled', 'ongoing', 'done', 'archived', 'cancelled'];

  const groupedInterventions = INTERVENTION_STATUS.reduce((acc, status) => (
    acc.concat(interventions.filter((intervention) => intervention.status === status))
  ), []);
  const interventionsRows = groupedInterventions.map((intervention) => {
    const {
      id, status, type, expectedStartDate, description, notes, Contractor,
    } = intervention;

    const startDate = expectedStartDate
      ? moment(expectedStartDate).format('DD/MM/YYYY')
      : 'N/A';

    const contractor = Contractor
      ? (
        <Link to={ `/contractors/${Contractor.id}` } rel="noopener noreferrer" target="_blank">
          { Contractor.firstName }
          {' '}
          { Contractor.lastName }
          {Contractor.company ? ` - ${Contractor.company}` : ''}
        </Link>
      )
      : 'N/A';

    return (
      <tr key={ id }>
        <td>
          <Button className={ `btn-label btn-${status}` } size="sm" disabled>
            { capitalize(status) }
          </Button>
        </td>
        <td>
          <Button variant="dark" size="sm" className="btn-label" disabled>
            { capitalize(type) }
          </Button>
        </td>
        <td>{ startDate }</td>
        <td>
          { contractor }
        </td>
        <td className="text-right">
          <Button size="sm" variant="dark" className="mr-2" onClick={ () => showDescription(description, notes) }>
            <i className="icon fas fa-eye" />
            Details
          </Button>
          <Link
            to={ `/interventions/${id}` }
            rel="noopener noreferrer"
            target="_blank"
            className="btn btn-sm btn-primary"
          >
            <i className="icon fa fa-external-link-alt" />
            Go to intervention
          </Link>
        </td>
      </tr>
    );
  });

  return (
    <div className="interventions-tab">
      <Row>
        <Col>
          <Card>
            <Table hover>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Type</th>
                  <th>Expected start date</th>
                  <th>Contractor</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                { interventionsRows }
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
      {
        displayModal
          ? (
            <Modal
              size="lg"
              show={ displayModal }
              onHide={ () => showDescription('', '') }
            >
              <Modal.Header>
                <Modal.Title>Description & Notes</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h4>Description</h4>
                <p style={{ whiteSpace: 'pre-line' }}>
                  { modalDescription || 'None.' }
                </p>
                <h4>Notes</h4>
                <p>
                  { modalNotes || 'None.' }
                </p>
              </Modal.Body>
            </Modal>
          )
          : null
      }
    </div>
  );
}

export default InterventionsTab;
