import React from 'react';
import moment from 'moment';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

function renderRentsPopover(orders) {
  const getStatusIcon = ({ status, receiptNumber }) => {
    if (status === 'pending') {
      return 'fa-clock text-info';
    }

    return receiptNumber ? 'fa-check text-success' : 'fa-times text-danger';
  };

  const mappedDebitOrders = orders
    .filter(({ type }) => type === 'debit')
    .sort((a, b) => moment(b.dueDate).valueOf() - moment(a.dueDate).valueOf())
    .map((order) => (
      <li key={ order.id }>
        <span className="fa-li">
          <span className={ `fa ${getStatusIcon(order)}` } />
        </span>
        { order.label }
        {' '}
        { moment(order.dueDate).format('YYYY') }
        {' '}
        -
        {' '}
        { order.amount / 100 }
        {' '}
        €
      </li>
    ));

  return (
    <Popover>
      <Popover.Title>
        All orders
      </Popover.Title>
      <Popover.Content>
        <ul className="fa-ul">
          { mappedDebitOrders }
        </ul>
      </Popover.Content>
    </Popover>
  );
}

function DepositSteps(props) {
  const { renting } = props;

  const hasUnpaidRent = renting.Client.Orders
    .filter(({ type, status, receiptNumber }) => (
      type === 'debit' && status === 'active' && !receiptNumber
    ))
    .length > 0;

  const hasPendingRent = renting.Client.Orders
    .filter(({ type, status }) => type === 'debit' && status === 'pending')
    .length > 0;

  const variant = (() => {
    if (hasUnpaidRent) return 'danger';

    return hasPendingRent ? 'info' : 'success';
  })();

  const icon = (() => {
    if (hasUnpaidRent) return 'fa-times';

    return hasPendingRent ? 'fa-clock' : 'fa-check';
  })();

  const ordersStatus = (() => {
    if (hasUnpaidRent) return 'Some orders are left unpaid';

    return hasPendingRent ? 'Some orders are pending' : 'All orders paid';
  })();

  return (
    <div className="DepositSteps">
      <OverlayTrigger
        flip
        placement="auto"
        overlay={ renderRentsPopover(renting.Client.Orders) }
      >
        <Button size="sm" variant={ variant } block>
          <i
            className={ `icon fas ${icon}` }
            data-toggle="tooltip"
            title="Orders paid"
          />
          { ordersStatus }
        </Button>
      </OverlayTrigger>
    </div>
  );
}

export default DepositSteps;
