import React, { useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { checkValidity } from '../../utils/emailsHelpers';
import FormField from '../FormField';

function NewContractor(props) {
  const {
    show,
    interventionTypes,
    cities,
    handleClose,
    handleSubmit,
  } = props;

  const [fields, setFields] = useState([{
    type: 'tag',
    name: 'CityId',
    options: [],
    label: 'City',
    value: '',
    required: true,
  }, {
    type: 'tags',
    name: 'types',
    options: [],
    label: 'Types',
    value: '',
    required: true,
  }, {
    type: 'text',
    name: 'firstName',
    label: 'First name',
    value: '',
    required: true,
  }, {
    type: 'text',
    name: 'lastName',
    label: 'Last name',
    value: '',
    required: true,
  }, {
    type: 'radio',
    name: 'gender',
    options: ['male', 'female'],
    label: 'Gender',
    value: '',
    required: true,
  }, {
    type: 'email',
    name: 'email',
    label: 'Email',
    value: '',
    required: true,
  }, {
    type: 'text',
    name: 'phoneNumber',
    label: 'Phone number',
    value: '',
    required: false,
  }, {
    type: 'text',
    name: 'company',
    label: 'Company (if any)',
    value: '',
    required: false,
  }]);

  useEffect(() => {
    const typesField = fields.find((f) => f.name === 'types');
    const cityField = fields.find((f) => f.name === 'CityId');

    if (interventionTypes.length > 0 && typesField.options.length === 0) {
      const otherFields = fields.filter((f) => f.name !== 'types');
      const newField = { ...typesField, options: interventionTypes };

      setFields(otherFields.concat(newField));
    }

    if (cities.length > 0 && cityField.options.length === 0) {
      const otherFields = fields.filter((f) => f.name !== 'CityId');
      const newField = { ...cityField, options: cities };

      setFields(otherFields.concat(newField));
    }
  }, [interventionTypes, cities, fields]);

  function handleChange(name, value) {
    const field = fields.find((f) => f.name === name);
    const otherFields = fields.filter((f) => f.name !== name);

    const newField = { ...field, value };

    setFields(otherFields.concat(newField));
  }

  function getField(name) {
    return fields.find((f) => f.name === name);
  }

  const requiredFields = fields.filter(({ required }) => required);
  const requiredFieldsFilled = requiredFields.filter(({ value }) => Boolean(value)).length === requiredFields.length;
  const isValidEmail = checkValidity(getField('email').value);

  return (
    <Modal size="lg" className="NewContractor" show={ show } onHide={ handleClose }>
      <Modal.Header closeButton>
        <Modal.Title>New contractor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <FormField
              field={ getField('types') }
              handleChange={ handleChange }
            />
          </Form.Row>
          <Form.Row>
            <FormField
              field={ getField('CityId') }
              handleChange={ handleChange }
            />
          </Form.Row>
          <Form.Row>
            <FormField
              field={ getField('firstName') }
              handleChange={ handleChange }
            />
            <FormField
              field={ getField('lastName') }
              handleChange={ handleChange }
            />
          </Form.Row>
          <Form.Row>
            <FormField
              field={ getField('gender') }
              handleChange={ handleChange }
            />
            <FormField
              field={ getField('email') }
              handleChange={ handleChange }
            />
          </Form.Row>
          <Form.Row>
            <FormField
              field={ getField('phoneNumber') }
              handleChange={ handleChange }
            />
            <FormField
              field={ getField('company') }
              handleChange={ handleChange }
            />
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ handleClose }>
          Close
        </Button>
        <Button
          variant="primary"
          disabled={ !(requiredFieldsFilled && isValidEmail) }
          onClick={ () => handleSubmit(fields) }
        >
          <span className="icon fa fa-check" />
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewContractor;
