import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Apartments from '../components/Apartments';
import ApartmentsDashboard from '../components/Apartments/dashboard';
import BookingsList from '../components/BookingsList';
import CheckRole from '../components/CheckRole';
import Clients from '../components/Clients';
import Contractors from '../components/Contractors';
import Home from '../components/Home';
import Interventions from '../components/Interventions';
import InterventionsDashboard from '../components/Interventions/dashboard';
import Navbar from '../components/Navbar';
import Receipts from '../components/Receipts';
import Rooms from '../components/Rooms';
import RoomsDashboard from '../components/Rooms/dashboard';
import ShowApartment from '../components/Apartments/show';
import ShowClient from '../components/Clients/show';
import ShowContractor from '../components/Contractors/show';
import ShowIntervention from '../components/Interventions/show';
import ShowRoom from '../components/Rooms/show';
import Sidebar from '../components/Sidebar';
import Transactions from '../components/Transactions';
import UserAgenda from '../components/UserAgenda';

import './style.scss';
import ClientsDashboard from '../components/Clients/dashboard';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderCheckRole(userRole, route, component) {
    return (
      <CheckRole to={ userRole } action={ route } component={ () => component } />
    );
  }

  renderUserSwitch(userRole, userTeam) {
    const { location } = this.props;

    const queryString = location ? location.search.replace('?', '') : '';

    const queryParams = queryString.split('&').reduce((params, param) => {
      const [key, value] = param.split('=');

      return { ...params, [key]: value };
    }, {});

    return (
      <Switch>

        <Route
          exact
          path="/"
          render={ (props) => this.renderCheckRole(userRole, 'all',
            <Home history={ props.history } userTeam={ userTeam } />) }
        />
        <Route
          path="/apartments/dashboard/:status"
          render={ (props) => this.renderCheckRole(userRole, 'apartments',
            <ApartmentsDashboard status={ props.match.params.status } userTeam={ userTeam } { ...props } />) }
        />
        <Route
          path="/apartments/:id"
          render={ (props) => this.renderCheckRole(userRole, 'apartments',
            <ShowApartment apartmentId={ props.match.params.id } { ...props } />) }
        />
        <Route
          path="/apartments"
          render={ (props) => this.renderCheckRole(userRole, 'apartments',
            <Apartments { ...props } />) }
        />
        <Route
          path="/bookings"
          render={ (props) => this.renderCheckRole(userRole, 'bookings',
            <BookingsList history={ props.history } />) }
        />
        <Route
          path="/checkins"
          render={ (props) => this.renderCheckRole(userRole, 'checkins',
            <UserAgenda eventType="checkin" history={ props.history } params={ queryParams } />) }
        />
        <Route
          path="/checkouts"
          render={ (props) => this.renderCheckRole(userRole, 'checkouts',
            <UserAgenda eventType="checkout" history={ props.history } params={ queryParams } />) }
        />
        <Route
          path="/clients/dashboard"
          render={ (props) => this.renderCheckRole(userRole, 'clients',
            <ClientsDashboard { ...props } />) }
        />
        <Route
          path="/clients/:id/:tab"
          render={ (props) => this.renderCheckRole(userRole, 'clients',
            <ShowClient clientId={ props.match.params.id } activeTab={ props.match.params.tab } { ...props } />) }
        />
        <Route
          path="/clients/:id"
          render={ (props) => this.renderCheckRole(userRole, 'clients',
            <ShowClient clientId={ props.match.params.id } { ...props } />) }
        />
        <Route
          path="/clients"
          render={ (props) => this.renderCheckRole(userRole, 'clients',
            <Clients { ...props } />) }
        />
        <Route
          path="/contractors/:id"
          render={ (props) => this.renderCheckRole(userRole, 'contractors',
            <ShowContractor contractorId={ props.match.params.id } userRole={ userRole } { ...props } />) }
        />
        <Route
          path="/contractors"
          render={ (props) => this.renderCheckRole(userRole, 'contractors',
            <Contractors userTeam={ userTeam } { ...props } />) }
        />
        <Route
          path="/deposit-refunds"
          render={ (props) => this.renderCheckRole(userRole, 'deposit-refunds',
            <UserAgenda eventType="deposit-refund" history={ props.history } params={ queryParams } />) }
        />
        <Route
          path="/interventions/dashboard"
          render={ (props) => this.renderCheckRole(userRole, 'interventions',
            <InterventionsDashboard userTeam={ userTeam } { ...props } />) }
        />
        <Route
          path="/interventions/:id"
          render={ (props) => this.renderCheckRole(userRole, 'interventions',
            <ShowIntervention interventionId={ props.match.params.id } userRole={ userRole } { ...props } />) }
        />
        <Route
          path="/interventions"
          render={ (props) => this.renderCheckRole(userRole, 'interventions',
            <Interventions { ...props } />) }
        />
        <Route
          path="/transactions"
          render={ (props) => this.renderCheckRole(userRole, 'transactions',
            <Transactions userRole={ userRole } userTeam={ userTeam } { ...props } />) }
        />
        <Route
          path="/receipts"
          render={ (props) => this.renderCheckRole(userRole, 'receipts',
            <Receipts { ...props } />) }
        />
        <Route
          path="/rooms/dashboard"
          render={ (props) => this.renderCheckRole(userRole, 'rooms',
            <RoomsDashboard userTeam={ userTeam } { ...props } />) }
        />
        <Route
          path="/rooms/:id"
          render={ (props) => this.renderCheckRole(userRole, 'rooms',
            <ShowRoom roomId={ props.match.params.id } { ...props } />) }
        />
        <Route
          path="/rooms"
          render={ (props) => this.renderCheckRole(userRole, 'rooms',
            <Rooms { ...props } />) }
        />
      </Switch>
    );
  }

  renderContractorSwitch() {
    return (
      <Switch>
        <Redirect exact from="/" to="/apartments" />

          <Route
              path="/apartments/dashboard/:status"
              render={ (props) => this.renderCheckRole('contractor', 'apartments',
                  <ApartmentsDashboard status={ props.match.params.status } userTeam={ '' } { ...props } />) }
          />
        <Route
            path="/apartments/:id"
            render={ (props) => this.renderCheckRole('contractor', 'apartments',
                <ShowApartment apartmentId={ props.match.params.id } { ...props } />) }
        />
        <Route
          path="/apartments"
          render={ (props) => this.renderCheckRole('contractor', 'apartments',
            <Apartments {...props} />) }
        />
      </Switch>
    );
  }

  render() {
    const { store: { team, role } } = this.props;
    const userRole = role;

    return (
      <div id="wrapper" className="App">
        <Navbar { ...this.props } />
        <Sidebar userRole={ userRole } { ...this.props } />
        <main role="main">
          <div className="Main">
            {
              userRole === 'contractor'
                ? this.renderContractorSwitch()
                : this.renderUserSwitch(userRole, team)
            }
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  store: store.user,
});

export default connect(mapStateToProps)(App);
