import React from 'react';

import Row from 'react-bootstrap/Row';

import FormField from '../../FormField';

const REASONS = [{
  value: 'PROBLEM_SOLVED',
  label: 'The problem has been solved.',
}, {
  value: 'CONTRACTOR_UNAVAILABLE',
  label: 'The contractor is no longer available.',
}, {
  value: 'CONTRACTOR_ENTRANCE_FAILURE',
  label: 'The contractor couldn\'t enter the apartment',
}, {
  value: 'CLIENT_UNAVAILABLE',
  label: 'None of the clients are/were available to open the door to our contractor.',
}];

class CancelIntervention extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: [{
        type: 'select',
        name: 'cancellationReason',
        options: REASONS,
        label: 'Why do you want to cancel this intervention ?',
        value: '',
        required: true,
      }, {
        type: 'checkbox',
        name: 'sendEmails',
        label: 'Send emails to the clients',
        value: true,
        required: false,
      }],
    };
  }

  handleChange(name, value) {
    const { handleFormCompleted, handleIncompleteForm, handleDataChange } = this.props;
    const { fields } = this.state;

    const field = fields.find((f) => f.name === name);
    const otherFields = fields.filter((f) => f.name !== name);

    const newFields = [
      ...otherFields,
      { ...field, value },
    ];

    this.setState({
      fields: newFields,
    });

    const correctValues = REASONS.map((reason) => reason.value);
    const reasonField = newFields.find((newField) => newField.name === 'cancellationReason');

    if (correctValues.includes(reasonField.value)) {
      handleFormCompleted();
    } else {
      handleIncompleteForm();
    }

    handleDataChange(name, value);
  }

  render() {
    const { fields } = this.state;

    const reasonField = fields.find(({ name }) => name === 'cancellationReason');
    const emailsField = fields.find(({ name }) => name === 'sendEmails');

    return (
      <>
        <Row>
          <FormField
            field={ reasonField }
            handleChange={ (name, value) => this.handleChange(name, value) }
          />
        </Row>
        <Row>
          <FormField
            field={ emailsField }
            handleChange={ (name, value) => this.handleChange(name, value) }
          />
        </Row>
      </>
    );
  }
}

export default CancelIntervention;
