import React, { useState } from 'react';
import { toast } from 'react-toastify';
import capitalize from 'lodash/capitalize';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../utils/API';
import FileUpload from '../FileUpload';

function UploadPicturesModal(props) {
  const {
    show, onHide, types, picturable, PicturableId, fetchData, optionalTag,
  } = props;

  const [uploadKind, setUploadKind] = useState(null);
  const [type, setType] = useState(null);

  const [isUploading, toggleLoader] = useState(false);
  const [uploadError, setError] = useState('');

  const [googleDriveUrl, setGDriveUrl] = useState('');
  const [uploadedFiles, setFiles] = useState([]);

  function onUploadReady(data) {
    toggleLoader(false);
    setGDriveUrl('');
    setFiles([...uploadedFiles, data]);
    fetchData();
    toast('Picture uploaded!', {
      type: toast.TYPE.SUCCESS,
    });
  }

  function onUploadError(error) {
    toggleLoader(false);
    setError(error.message);
  }

  function createPicture() {
    const picture = {
      url: googleDriveUrl,
      alt: type,
      picturable,
      PicturableId,
    };

    toggleLoader(true);

    new ForestApi().pictures()
      .create({ picture })
      .then((response) => onUploadReady(response.data))
      .catch((error) => onUploadError(error.response.data));
  }

  function renderError() {
    return (
      <Alert variant="danger">
        Error:
        {' '}
        { uploadError }
      </Alert>
    );
  }

  const mappedTypes = types.map((item) => <option key={ item } value={ item }>{ capitalize(item) }</option>);

  function renderChoices() {
    return (
      <>
        <Row className="mb-4">
          <Col>
            <FormLabel>Select picture source:</FormLabel>
            <div>
              <Button
                variant={ uploadKind === 'computer' ? 'dark' : 'light' }
                onClick={ () => setUploadKind('computer') }
              >
                <i className="icon fas fa-laptop" />
                My computer
              </Button>
              <Button
                variant={ uploadKind === 'drive' ? 'dark' : 'light' }
                onClick={ () => setUploadKind('drive') }
              >
                <i className="icon fab fa-google-drive" />
                Google Drive
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <FormLabel>Please select a tag for your picture.</FormLabel>
            <FormControl as="select" onChange={ (e) => setType(e.target.value) }>
              <option>Pick a tag...</option>
              { mappedTypes}
            </FormControl>
          </Col>
        </Row>
      </>
    );
  }

  function renderUploadForm() {
    return (
      <>
        {
          (optionalTag || type) && uploadKind === 'computer'
            ? (
              <Row className="mb-4">
                <Col>
                  <FileUpload
                    onUploadStart={ () => toggleLoader(true) }
                    onError={ () => setError(true) }
                    onUploadReady={ onUploadReady }
                    alt={ type }
                    picturable={ picturable }
                    PicturableId={ PicturableId }
                  />
                </Col>
              </Row>
            )
            : null
        }
        {
          (optionalTag || type) && uploadKind === 'drive'
            ? (
              <Row className="mb-4">
                <Col>
                  <FormControl
                    placeholder="Paste GDrive URL here..."
                    value={ googleDriveUrl }
                    onChange={ (e) => setGDriveUrl(e.target.value) }
                  />
                </Col>
                <Col lg="2" className="text-center">
                  <Button onClick={ () => createPicture() }>Upload</Button>
                </Col>
              </Row>
            )
            : null
        }
        <Row>
          <Col>
            {
              uploadError
                ? renderError()
                : null
            }
          </Col>
        </Row>
      </>
    );
  }

  function renderUploadedFiles() {
    if (uploadedFiles.length === 0) {
      return null;
    }

    return uploadedFiles.map((file) => (
      <Row key={ file.url } className="align-items-center">
        <Col>
          <img src={ file.url } alt={ file.alt } className="w-100" />
        </Col>
        <Col lg="2">
          {
            file.alt
              ? (
                <Button variant="dark" className="btn-label" disabled>
                  { capitalize(file.alt) }
                </Button>
              )
              : <span>No tag set</span>
          }
        </Col>
        <Col lg="2">
          <i className="icon fas fa-check text-success" />
          Uploaded
        </Col>
      </Row>
    ));
  }

  return (
    <Modal size="lg" show={ show } onHide={ () => onHide() }>
      <Modal.Header closeButton>
        <Modal.Title>Upload pictures</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          isUploading
            ? (
              <Row>
                <Col className="text-center">
                  <h2>
                    <i className="fas fa-spinner fa-spin" />
                  </h2>
                </Col>
              </Row>
            )
            : (
              <>
                { renderChoices() }
                { renderUploadForm() }
                { renderUploadedFiles() }
              </>
            )
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ () => onHide() }>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UploadPicturesModal;
