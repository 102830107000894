import React from 'react';
import { toast } from 'react-toastify';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import ForestApi from '../../utils/API';
import FormField from '../FormField';

const CONTACT_TYPES = ['landlord', 'property-manager', 'building-manager', 'caretaker', 'other'];

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    const existingOrNew = props.contact ? 'new' : 'existing';

    const defaultValues = {
      firstName: '',
      lastName: '',
      gender: '',
      type: '',
      email: '',
      phoneNumber: '',
      postalAddress: '',
      company: '',
      notes: '',
    };
    const contact = props.contact || defaultValues;

    this.state = {
      existingOrNew,
      error: null,
      contacts: [],
      fields: [{
        type: 'text',
        name: 'firstName',
        label: 'First name',
        value: contact.firstName,
        required: true,
      }, {
        type: 'text',
        name: 'lastName',
        label: 'Last name',
        value: contact.lastName,
        required: true,
      }, {
        type: 'radio',
        name: 'gender',
        options: ['male', 'female'],
        label: 'Gender',
        value: contact.gender,
      }, {
        type: 'tag',
        name: 'type',
        label: 'Type',
        options: CONTACT_TYPES,
        value: contact.type,
        required: true,
      }, {
        type: 'email',
        name: 'email',
        label: 'Email',
        value: contact.email,
      }, {
        type: 'text',
        name: 'phoneNumber',
        label: 'Phone number',
        value: contact.phoneNumber,
      }, {
        type: 'text',
        name: 'postalAddress',
        label: 'Postal address',
        value: contact.postalAddress,
      }, {
        type: 'text',
        name: 'company',
        label: 'Company name (if any)',
        value: contact.company,
      }, {
        type: 'textarea',
        name: 'notes',
        label: 'Notes',
        value: contact.notes,
      }],
    };
  }

  handleChoice(choice) {
    this.setState({
      existingOrNew: choice,
    });
  }

  handleChange(name, value) {
    const { fields } = this.state;

    const field = fields.find((f) => f.name === name);
    const otherFields = fields.filter((f) => f.name !== name);

    const newField = { ...field, value };

    this.setState({
      fields: otherFields.concat(newField),
    });
  }

  handleSearch(name) {
    const { apartmentId } = this.props;

    if (name.trim().length < 2) {
      return this.setState({ contacts: [], error: 'lengthError' });
    }

    this.setState({ error: null });

    const queryString = `name=${name.trim()}`;

    return new ForestApi().contacts()
      .search(queryString)
      .then((response) => {
        const contacts = response.data.filter((contact) => {
          const apartments = contact.Apartments.map(({ id }) => id);

          return !apartments.includes(apartmentId);
        });

        this.setState({ contacts });
      })
      .catch(() => {
        this.setState({
          contacts: [],
        });
      });
  }

  assignContact(contactId) {
    const { apartmentId, fetchApartment } = this.props;

    const data = { contactId };

    new ForestApi().apartments()
      .assignContact(apartmentId, data)
      .then(() => {
        fetchApartment();

        toast('Contact assigned!', {
          type: toast.TYPE.SUCCESS,
        });
      });
  }

  renderField(name) {
    const { fields } = this.state;
    const field = fields.find((f) => f.name === name);

    return (
      <FormField
        field={ field }
        handleChange={ (fieldName, value) => this.handleChange(fieldName, value) }
      />
    );
  }

  renderBackButton() {
    return (
      <Button variant="link" onClick={ () => this.handleChoice('existing') } className="mb-2">
        <i className="icon fas fa-arrow-circle-left" />
        Back
      </Button>
    );
  }

  renderNewContactForm() {
    const { contact } = this.props;

    return (
      <>
        { !contact ? this.renderBackButton() : null }
        <Form>
          <Form.Row>
            { this.renderField('type') }
          </Form.Row>
          <Form.Row>
            { this.renderField('firstName') }
            { this.renderField('lastName') }
          </Form.Row>
          <Form.Row>
            { this.renderField('gender')}
            { this.renderField('email') }
          </Form.Row>
          <Form.Row>
            { this.renderField('phoneNumber') }
            { this.renderField('company') }
          </Form.Row>
          <Form.Row>
            { this.renderField('postalAddress') }
          </Form.Row>
          <Form.Row>
            { this.renderField('notes') }
          </Form.Row>
        </Form>
      </>
    );
  }

  renderContacts() {
    const { contacts } = this.state;

    const headers = ['Name', 'Company', 'Type', 'Action'];
    const mappedHeaders = headers.map((header) => <th key={ header }>{ header }</th>);

    const mappedContacts = contacts.map((contact) => (
      <tr key={ contact.id }>
        <td>{`${contact.firstName} ${contact.lastName}`}</td>
        <td>{ contact.company }</td>
        <td>{ contact.type }</td>
        <td>
          <Button variant="primary" onClick={ () => this.assignContact(contact.id) }>
            <span className="icon fa fa-check" />
            Add
          </Button>
        </td>
      </tr>
    ));

    return (
      <Table className="mt-3">
        <thead>
          <tr>{ mappedHeaders }</tr>
        </thead>
        <tbody>
          { mappedContacts }
        </tbody>
      </Table>
    );
  }

  renderExistingForm() {
    const { contacts, error } = this.state;

    return (
      <>
        <div className="mt-3">
          <Form.Control
            placeholder="Search by first name, last name or company"
            aria-label="Search by first name, last name or company"
            onChange={ (e) => this.handleSearch(e.target.value) }
            autoFocus
          />
        </div>
        <p className="mt-3">
          { contacts.length }
          {' '}
          contact(s) found
        </p>
        {
          error && error === 'lengthError'
            ? (
              <Alert variant="info">Please type at least 2 characters</Alert>
            )
            : null
        }
        {
          contacts.length > 0
            ? this.renderContacts()
            : null
        }
        <div className="text-center">
          <Button onClick={ () => this.handleChoice('new') }>Create new contact</Button>
        </div>
      </>
    );
  }

  render() {
    const {
      show, contact, handleClose, handleSubmit,
    } = this.props;
    const { existingOrNew, fields } = this.state;

    const requiredFields = fields.filter(({ required }) => required);
    const requiredFieldsFilled = requiredFields.filter(({ value }) => Boolean(value)).length === requiredFields.length;

    return (
      <Modal size="lg" className="NewContact" show={ show } onHide={ handleClose }>
        <Modal.Header>
          <Modal.Title>
            { contact ? 'Edit ' : 'New ' }
            contact
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            existingOrNew === 'new'
              ? this.renderNewContactForm()
              : null
          }
          {
            existingOrNew === 'existing'
              ? this.renderExistingForm()
              : null
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ handleClose }>
            Close
          </Button>
          {
            existingOrNew === 'new'
              ? (
                <Button variant="primary" disabled={ !requiredFieldsFilled } onClick={ () => handleSubmit(fields) }>
                  <span className="icon fa fa-check" />
                  { contact ? 'Update' : 'Add'}
                </Button>
              )
              : null
          }
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ContactForm;
