import React from 'react';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../../utils/API';
import FormField from '../../FormField';

const TIME_SLOTS = [{
  value: 'morning',
  label: 'Morning',
}, {
  value: 'afternoon',
  label: 'Afternoon',
}, {
  value: 'day',
  label: 'All day',
}];

class RescheduleIntervention extends React.Component {
  constructor(props) {
    super(props);

    const { intervention: { type } } = props;

    this.state = {
      showContractor: false,
      fields: [{
        type: 'date',
        name: 'expectedStartDate',
        label: 'Expected start date',
        value: '',
        required: true,
      }, {
        type: 'select',
        name: 'expectedTimeSlot',
        options: TIME_SLOTS,
        label: 'Expected time slot',
        value: '',
        required: true,
      }, {
        type: 'select',
        name: 'ContractorId',
        options: [],
        label: `${capitalize(type)} Contractor`,
        value: '',
        required: false,
      }, {
        type: 'checkbox',
        name: 'sendEmails',
        label: 'Send emails to the clients',
        value: true,
        required: false,
      }],
      showError: false,
    };
  }

  setOptions(name, options) {
    const { fields } = this.state;

    const field = fields.find((f) => f.name === name);
    const otherFields = fields.filter((f) => f.name !== name);

    const newField = { ...field, options };

    this.setState({ fields: [...otherFields, newField] });
  }

  getContractorsByType() {
    const { intervention: { type, Apartment }, handleError } = this.props;
    const { CityId } = Apartment;

    const queryParams = [
      `type=${type}`,
      `city=${CityId}`,
    ].join('&');

    new ForestApi().contractors()
      .getAll(queryParams)
      .then((response) => {
        const contractors = response.data;

        if (contractors.length === 0) {
          handleError();
          return;
        }

        const options = sortBy(contractors, 'firstName').map((contractor) => ({
          value: contractor.id,
          label: `${contractor.fullName} ${contractor.company ? `- ${contractor.company}` : ''}`,
        }));

        this.setOptions('ContractorId', options);
      });
  }

  toggleContractor() {
    const { handleIncompleteForm, handleDataRemoval } = this.props;
    const { showContractor, fields } = this.state;

    const contractorField = fields.find(({ name }) => name === 'ContractorId');

    // We're toggling from displayed to hidden
    if (showContractor === true) {
      handleDataRemoval('ContractorId');
    } else {
      if (contractorField.options.length === 0) {
        this.getContractorsByType();
      }

      if (!contractorField.value) {
        handleIncompleteForm();
      }
    }

    this.setState({
      showContractor: !showContractor,
    });
  }

  handleChange(name, value) {
    const { handleFormCompleted, handleIncompleteForm, handleDataChange } = this.props;
    const { fields, showContractor } = this.state;

    const field = fields.find((f) => f.name === name);
    const otherFields = fields.filter((f) => f.name !== name);
    let fieldValue = value;

    const newFields = [
      ...otherFields,
      { ...field, value: fieldValue },
    ];

    this.setState({
      fields: newFields,
    });

    const requiredFields = newFields.filter((f) => f.required);
    const contractor = newFields.find((f) => f.name === 'ContractorId').value;

    if (requiredFields.every((f) => Boolean(f.value)) && (!showContractor || contractor)) {
      handleFormCompleted();
    } else {
      handleIncompleteForm();
    }

    if (name === 'expectedStartDate') {
      fieldValue = moment(value).toISOString();
    }

    handleDataChange(name, fieldValue);
  }

  renderField(name) {
    const { fields } = this.state;
    const field = fields.find((f) => f.name === name);

    return (
      <FormField
        field={ field }
        handleChange={ (fieldName, value) => this.handleChange(fieldName, value) }
      />
    );
  }

  render() {
    const { intervention } = this.props;
    const { showContractor, showError } = this.state;

    const { type, Apartment: { CityId } } = intervention;

    let contractorId = null;

    if (showContractor) {
      contractorId = !showError
        ? (
          <Row>
            { this.renderField('ContractorId') }
          </Row>
        )
        : (
          <Alert variant="danger">
            No contractors of type
            {' '}
            <strong>{ type }</strong>
            {' '}
            found in
            {' '}
            { capitalize(CityId) }
            .
          </Alert>
        );
    }

    return (
      <div>
        <Row>
          { this.renderField('expectedStartDate') }
          { this.renderField('expectedTimeSlot') }
        </Row>
        <Row className="mb-3">
          <Col>
            <Button
              variant={ `${showContractor ? 'outline-' : ''}primary` }
              onClick={ () => this.toggleContractor() }
            >
              Change contractor
            </Button>
          </Col>
        </Row>
        { contractorId }
        <Row>
          { this.renderField('sendEmails') }
        </Row>
      </div>
    );
  }
}

export default RescheduleIntervention;
