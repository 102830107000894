import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import ForestApi from '../../utils/API';

import './style.scss';

/*
Component created using this article :
https://www.koan.co/blog/uploading-images-to-s3-from-a-react-spa
*/

function usePictureUpload({
  onUploadStart,
  onUploadReady,
  onError,
  alt,
  picturable,
  PicturableId,
}) {
  const onDrop = useCallback(async (acceptedFiles) => {
    onUploadStart();

    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted'); // eslint-disable-line
    reader.onerror = () => console.log('file reading has failed'); // eslint-disable-line
    reader.onload = () => {
      const buffer = reader.result;

      const picture = {
        url: buffer,
        alt,
        picturable,
        PicturableId,
      };

      new ForestApi().pictures()
        .create({ picture })
        .then((response) => {
          if (response.status !== 200) {
            onError();
            return;
          }

          onUploadReady(response.data);
        })
        .catch(() => onError());
    };

    acceptedFiles.forEach((file) => reader.readAsDataURL(file));
  }, [onUploadReady, onUploadStart, onError, alt, picturable, PicturableId]);

  return useDropzone({
    accept: 'image/*',
    onDrop,
  });
}

function FileUpload(props) {
  const { acceptedFiles, getRootProps, getInputProps } = usePictureUpload(props);

  const files = acceptedFiles.map((file) => (
    <li key={ file.path }>
      {file.path}
      {' '}
      -
      {file.size}
      {' '}
      bytes
    </li>
  ));

  return (
    <div className="FileUpload" { ...getRootProps() }>
      {
        files.length > 0
          ? (
            <aside className="mt-4">
              <h4>Files</h4>
              <ul>{ files }</ul>
            </aside>
          )
          : null
      }
      <div className="dropzone">
        <p>Drag 'n' drop some pictures here or click to select a file</p>
        <input { ...getInputProps() } />
      </div>
    </div>
  );
}

export default FileUpload;
