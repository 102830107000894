import React, { useState } from 'react';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import buildForestLink from '../../../utils/buildForestLink';

const INTERVENTION_WORKFLOW = [
  { label: 'Assess problem', name: 'problemAssessment', type: 'manual' },
  { label: 'Set assessed status', name: 'setAssessedStatus', type: 'auto' },
  { label: 'Scheduling', name: 'scheduling', type: 'manual' },
  { label: 'Scheduled notification', name: 'scheduledNotification', type: 'email' },
  { label: 'Awaiting start date', name: 'awaitStartDate', type: 'timer' },
  { label: 'Ongoing', name: 'ongoing', type: 'manual' },
  { label: 'Finished notification', name: 'finishedNotification', type: 'email' },
  { label: 'Invoice and payment', name: 'invoiceAndPayment', type: 'manual' },
  { label: 'Archive intervention', name: 'archiveIntervention', type: 'manual' },
];

const ICONS = {
  auto: 'fas fa-cogs',
  email: 'fas fa-envelope',
  manual: 'fas fa-user',
  timer: 'fas fa-clock',
};

function WorkflowTab(props) {
  const { workflow } = props;

  const [displayResult, toggleModal] = useState({});

  function getVariant(node) {
    const { currentNode, Activities } = workflow;

    if (node === currentNode) {
      return 'primary';
    }

    const successNodes = Activities.map((activity) => activity.nodeName);

    return successNodes.includes(node) ? 'success' : 'secondary';
  }

  function renderWorkflow() {
    return INTERVENTION_WORKFLOW.map((node) => (
      <Alert key={ node.name } variant={ getVariant(node.name) } className="workflow-node">
        <i className={ `icon ${ICONS[node.type]}` } />
        { node.label }
      </Alert>
    ));
  }

  function renderActivitiesTable() {
    const { Activities } = workflow;

    if (!Activities || Activities.length === 0) {
      return (
        <Jumbotron className="text-center">
          <h1>No activities</h1>
          <p>
            No activities created yet.
          </p>
        </Jumbotron>
      );
    }

    const rows = sortBy(Activities, 'startDate', 'desc').map((activity) => {
      const {
        id,
        endDate,
        nodeName,
        startDate,
        result,
      } = activity;

      return (
        <tr key={ id }>
          <td>{ nodeName }</td>
          <td>{ moment(startDate).format('DD/MM/YYYY HH:mm:ss') }</td>
          <td>{ moment(endDate).format('DD/MM/YYYY HH:mm:ss') }</td>
          <td>
            <Button variant="dark" size="sm" onClick={ () => toggleModal(JSON.parse(result)) }>
              <i className="fas fa-eye" />
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <Table bordered hover>
        <thead>
          <tr>
            <th>Node</th>
            <th>Start date</th>
            <th>End date</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          { rows }
        </tbody>
      </Table>
    );
  }

  const forestWorkflowLink = buildForestLink(workflow.id, 'workflow');

  return (
    <div className="workflow-tab">
      <Row>
        <Col lg="4">
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
              <strong>Workflow</strong>
              <a href={ forestWorkflowLink } rel="noopener noreferrer" target="_blank">
                <i className="fa fa-external-link-alt" />
              </a>
            </Card.Header>
            <Card.Body>
              <div className="workflow">
                <Row>
                  <Col>
                    <Alert variant="success" className="text-center workflow-node">START</Alert>
                    { renderWorkflow() }
                  </Col>
                </Row>
                <Row className="mb-3 border-bottom">
                  <Col>
                    <Alert variant={ getVariant('end') } className="text-center workflow-node">END</Alert>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Alert variant={ getVariant('rescheduledNotification') } className="workflow-node">
                      <i className="icon fas fa-users-cog" />
                      Rescheduled email
                    </Alert>
                    <Alert variant={ getVariant('cancelledNotification') } className="workflow-node">
                      <i className="icon fas fa-users-cog" />
                      Cancelled email
                    </Alert>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="8">
          <Card>
            <Card.Header>
              <strong>Activities</strong>
            </Card.Header>
            <Card.Body>
              { renderActivitiesTable() }
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={ !isEmpty(displayResult) } size="lg" onHide={ () => toggleModal('') }>
        <Modal.Header>
          <Modal.Title>Result</Modal.Title>
        </Modal.Header>
        <Modal.Body className="activities-results">
          { JSON.stringify(displayResult, null, 4) }
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default WorkflowTab;
