const initialState = {
  token: '',
  role: '',
  team: '',
  email: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        token: action.token,
        role: action.role,
        team: action.team,
        email: action.email,
      };
    default:
      return state;
  }
}
