import React from 'react';
import { Redirect } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { toast } from 'react-toastify';
import ForestApi from '../utils/API';
import './style.scss';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      displayModal: false,
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { login } = this.props;
    const { email, password } = this.state;

    login(email, password);
  }

  handlePasswordReset() {
    const { email } = this.state;

    new ForestApi().users()
      .resetPasswordRequest({ email })
      .then(() => {
        toast(`Instructions sent to ${email}`, {
          type: toast.TYPE.SUCCESS,
        });
        this.toggleModal();
      });
  }

  toggleModal() {
    const { displayModal } = this.state;

    this.setState({ displayModal: !displayModal });
  }

  render() {
    const { isAuthenticated, location } = this.props;
    const { email, displayModal } = this.state;

    if (isAuthenticated()) {
      return (
        <Redirect to={{ pathname: '/', state: { from: location } }} />
      );
    }

    return (
      <div className="container">
        <div className="LoginPage text-center">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <h4 className="text-gray-900 mb-4">Welcome !</h4>
                        </div>
                        <Form className="login mb-2" onSubmit={ this.handleSubmit }>
                          <Form.Group>
                            <Form.Control
                              type="email"
                              name="email"
                              placeholder="Email address"
                              onChange={ this.handleInputChange }
                              required
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              type="password"
                              name="password"
                              placeholder="Password"
                              onChange={ this.handleInputChange }
                              required
                            />
                          </Form.Group>
                          <Button type="submit" className="btn btn-primary btn-user btn-block">
                            Login
                          </Button>
                          {/* <a href="index.html" className="btn btn-google btn-user btn-block">
                            <i className="fab fa-google fa-fw"></i> Login with Google
                          </a> */}
                        </Form>
                        <div className="text-center">
                          <Button variant="link" onClick={ () => this.toggleModal() }>
                            I forgot my password
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal size="lg" show={ displayModal } onHide={ () => this.toggleModal() }>
          <Modal.Header>
            Password reset process
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Please provide the email address associated to your account:</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Email address"
              value={ email }
              onChange={ this.handleInputChange }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={ () => this.toggleModal() }>Cancel</Button>
            <Button onClick={ () => this.handlePasswordReset() }>
              <i className="icon fas fa-envelope" />
              Reset password
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default LoginPage;
