import React from 'react';
import { toast } from 'react-toastify';
import omit from 'lodash/omit';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import ProblemAssessed from './problemAssessed';
import ScheduleIntervention from './schedule';
import InterventionDone from './done';
import ContractorPaid from './contractorPaid';
import RescheduleIntervention from './reschedule';
import CancelIntervention from './cancel';

import ForestApi from '../../../utils/API';

const ACTIONS_WITHOUT_DATA = [
  'problemAssessed',
  'interventionDone',
  'contractorPaid',
];

class ActionModal extends React.Component {
  constructor(props) {
    super(props);

    const defaultProps = {
      ...props,
      handleDataChange: (name, value) => this.setActionData(name, value),
      handleDataRemoval: (name) => this.removeActionData(name),
      handleFormCompleted: () => this.setFormCompleted(),
      handleIncompleteForm: () => this.setFormIncomplete(),
      handleError: () => this.componentErrored(),
    };

    this.state = {
      components: {
        problemAssessed: <ProblemAssessed { ...defaultProps } />,
        scheduleIntervention: <ScheduleIntervention { ...defaultProps } />,
        interventionDone: <InterventionDone { ...defaultProps } />,
        contractorPaid: <ContractorPaid { ...defaultProps } />,
        rescheduleIntervention: <RescheduleIntervention { ...defaultProps } />,
        cancelIntervention: <CancelIntervention { ...defaultProps } />,
      },
      componentErrored: false,
      formCompleted: false,
      actionData: {},
    };
  }

  componentDidMount() {
    const { action: { name } } = this.props;

    if (ACTIONS_WITHOUT_DATA.includes(name)) {
      this.setState({ formCompleted: true });
    }
  }

  setActionData(name, value) {
    const { actionData } = this.state;

    this.setState({
      actionData: {
        ...actionData,
        [name]: value,
      },
    });
  }

  setFormCompleted() {
    this.setState({
      formCompleted: true,
    });
  }

  setFormIncomplete() {
    this.setState({
      formCompleted: false,
    });
  }

  removeActionData(name) {
    const { actionData } = this.state;

    if (name in actionData) {
      this.setState({
        actionData: omit(actionData, name),
      });
    }
  }

  triggerAction() {
    const {
      action, intervention: { id }, handleClose, fetchIntervention,
    } = this.props;
    const { actionData } = this.state;

    if (!action) {
      return null;
    }

    const data = {
      action: action.name,
      data: actionData,
    };

    return new ForestApi().interventions()
      .triggerNode(id, data)
      .then(() => {
        this.setState({
          actionData: {},
        });
        handleClose();
        fetchIntervention();
        toast('Action executed successfully!', {
          type: toast.TYPE.SUCCESS,
        });
      });
  }

  componentErrored() {
    this.setState({
      componentErrored: true,
    });
  }

  render() {
    const { action, show, handleClose } = this.props;
    const { components, componentErrored, formCompleted } = this.state;

    const component = components[action.name];

    return (
      <Modal size="lg" className="InterventionActionModal" show={ show } onHide={ () => handleClose() }>
        <Modal.Header closeButton>
          <Modal.Title>{ action.label }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            component || (
            <div className="alert alert-danger">
              Unknown action
              { action.name }
            </div>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          {
            component && !componentErrored
              ? (
                <Button variant="primary" onClick={ () => this.triggerAction() } disabled={ !formCompleted }>
                  Confirm
                </Button>
              )
              : (
                <Button variant="secondary" onClick={ () => handleClose() }>
                  Close
                </Button>
              )
          }
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ActionModal;
