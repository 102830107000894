import React from 'react';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import './style.scss';

class ContractorModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      variants: {
        draft: 'warning',
        rejected: 'dark',
        cancelled: 'danger',
        default: 'outline-primary',
      },
    };
  }

  getVariant(status) {
    const { variants } = this.state;

    return variants[status] || variants.default;
  }

  handleSelect(contractorId) {
    const { selected } = this.state;
    this.setState({ selected: selected === contractorId ? null : contractorId });
  }

  resetState() {
    this.setState({ selected: null });
  }

  render() {
    const {
      show, handleClose, handleSubmit, contractors, event,
    } = this.props;
    const { selected } = this.state;

    const { ContractorEvents } = event;
    const noRequestSent = !ContractorEvents.find(({ status }) => status === 'draft');

    const displayContractors = contractors.map((contractor) => {
      const contractorEvent = ContractorEvents.find(({ Contractor }) => Contractor.id === contractor.id);

      let variant;

      if (selected === contractor.id) {
        variant = 'primary';
      } else {
        variant = contractorEvent ? this.getVariant(contractorEvent.status) : 'outline-primary';
      }

      return (
        <Col key={ contractor.id } xs={ 12 } md={ 4 } lg={ 3 }>
          <Button
            size="lg"
            variant={ variant }
            onClick={ () => this.handleSelect(contractor.id) }
            disabled={ !noRequestSent || !/primary/.test(variant) }
            block
          >
            { contractor.firstName }
            {' '}
            { contractor.lastName }
            { contractor.company ? ` - ${contractor.company}` : '' }
          </Button>
        </Col>
      );
    });

    return (
      <Modal
        size="lg"
        className="ContractorModal"
        show={ show }
        onHide={ handleClose }
        onEnter={ () => this.resetState() }
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign a contractor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Woohoo, let's invite contractors for this Checkout!</h4>
          <p>
            Key:
            {' '}
            <Badge pill variant="primary">Not sent</Badge>
            <Badge pill variant="warning">Pending</Badge>
            <Badge pill variant="dark">Rejected</Badge>
            <Badge pill variant="danger">Cancelled</Badge>
          </p>
          <Row>
            { displayContractors }
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ handleClose }>
            Close
          </Button>
          <Button variant="primary" onClick={ () => handleSubmit([selected]) } disabled={ !selected }>
            <span className="icon far fa-envelope" />
            Invite
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ContractorModal;
