const initialState = {
  apartments: [],
  teamCompletion: [],
  count: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_COUNT':
      return { ...state, count: action.count };
    case 'SET_TEAM_COMPLETION':
      return { ...state, teamCompletion: action.teamCompletion };
    default:
      return state;
  }
}
