import React, { useCallback, useState, useEffect } from 'react';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../../../utils/API';
import EditableField from '../../../EditableField';
import RoundButton from '../../../RoundButton';

import {
  GLOBAL_APARTMENT_FEATURES,
  SPECIAL_APARTMENT_OPTIONS,
} from '../../../../utils/apartmentsFields';

const hiddenFeatures = [
  'commonAreasArea',
];

const BOOLEAN_OPTIONS = { yes: 'true', no: 'false' };

function getIcon(field) {
  const icon = field.replace('apartment-features-', '');

  return require(`../../../../assets/icons/apartment/${icon}.png`); // eslint-disable-line
}

function FeatureElement(props) {
  const {
    label,
    editMode,
    field,
    options,
    type,
    updateField,
    value,
  } = props;

  const [featureValue, setFeatureValue] = useState();

  const isBoolean = type === 'Boolean';
  const inputType = (
    (isBoolean && 'radio')
    || (type === 'Number' && 'number')
    || (type === 'Enum' && 'select')
  );
  const parsedValue = (isBoolean && value) || value;

  const [, , , fieldName] = field.split('-');
  const hasSpecialOptions = fieldName in SPECIAL_APARTMENT_OPTIONS;
  const fieldOptions = (
    (isBoolean && BOOLEAN_OPTIONS)
    || (hasSpecialOptions && SPECIAL_APARTMENT_OPTIONS[fieldName])
    || options
  );

  const htmlProps = inputType === 'number'
    ? { min: 0 }
    : null;

  const renderEditableField = editMode
    ? (
      <EditableField
        inputType={ inputType }
        name={ field }
        value={ parsedValue }
        options={ fieldOptions }
        htmlProps={ htmlProps }
        editFieldValue={ updateField }
        editMode
      />
    )
    : null;

  const hideValue = useCallback(() => {
    if ((isBoolean && !value) || value == null) {
      return setFeatureValue(<i className="fas fa-lg fa-times-circle text-danger" />);
    }

    if (isBoolean) {
      return setFeatureValue(<i className="fas fa-lg fa-check-circle text-success" />);
    }

    return setFeatureValue(<i className="fas fa-lg fa-info-circle text-success" />);
  }, [isBoolean, value]);

  const showValue = () => {
    if (isBoolean || value == null) {
      return null;
    }

    if (inputType === 'number') {
      const variant = value === 0 ? 'danger' : 'info';

      return setFeatureValue(<h5 className="m-0"><Badge variant={ variant } size="lg" pill>{ value }</Badge></h5>);
    }
    const enumValue = hasSpecialOptions && SPECIAL_APARTMENT_OPTIONS[fieldName].find((option) => value in option);
    const displayValue = (enumValue && enumValue[value]) || value;

    return setFeatureValue(<Button size="sm" variant="dark" className="btn-label" disabled>{ displayValue }</Button>);
  };

  useEffect(() => {
    hideValue();
  }, [hideValue]);

  return (
    <li
      className={ `feature-item ${editMode ? 'edit-mode' : ''}` }
      onMouseEnter={ () => showValue() }
      onMouseLeave={ () => hideValue() }
    >
      <img src={ getIcon(field) } alt={ label } className="feature-icon mr-3" />
      <div className="feature-value">
        <span className="feature-label">{ label }</span>
        <div>
          { editMode ? renderEditableField : featureValue }
        </div>
      </div>
    </li>
  );
}

function FeaturesTab(props) {
  const {
    apartment,
    fetchApartment,
  } = props;

  const { features } = apartment;
  const [editMode, setEditMode] = useState(false);
  const [fieldsToUpdate, setFieldsToUpdate] = useState({});

  const featuresPrefix = 'apartment-features-';

  function toggleEditMode() {
    if (editMode) {
      setFieldsToUpdate({});
    }

    setEditMode(!editMode);
  }

  function updateApartment() {
    if (fieldsToUpdate && !isEmpty(fieldsToUpdate)) {
      new ForestApi().apartments()
        .update(apartment.id, fieldsToUpdate)
        .then(() => {
          toggleEditMode();
          fetchApartment();
        });
    }
  }

  function updateField(name, value) {
    setFieldsToUpdate({
      ...fieldsToUpdate,
      [name]: value,
    });
  }

  function renderFeaturesList(kind) {
    const categoryFeatures = GLOBAL_APARTMENT_FEATURES[kind];

    const mappedFeatures = categoryFeatures
      .filter((name) => !hiddenFeatures.includes(name))
      .sort()
      .map((name) => {
        const feature = features.find(({ field }) => (
          field === `${featuresPrefix}${kind.replace('external', 'general')}-${name}`
        ));

        if (!feature) { return null; }

        const {
          field,
          label,
          value,
          type,
          options,
        } = feature;

        const featureValue = fieldsToUpdate[field] !== undefined
          ? fieldsToUpdate[field]
          : value;

        return (
          <FeatureElement
            key={ field }
            field={ field }
            label={ label }
            value={ featureValue }
            type={ type }
            options={ options }
            editMode={ editMode }
            updateField={ updateField }
          />
        );
      }).filter(Boolean);

    return (
      <Col lg="3" key={ kind }>
        <Card>
          <Card.Header className="d-flex align-items-center justify-content-between">
            <strong>{ capitalize(kind) }</strong>
            <Badge className="team-sales">SALES</Badge>
          </Card.Header>
          <Card.Body>
            <ul className="features">
              { mappedFeatures }
              {
                kind === 'external'
                  ? (
                    <FeatureElement
                      alt="elevator"
                      editMode={ editMode }
                      field="elevator"
                      label="Elevator"
                      type="Boolean"
                      updateField={ updateField }
                      value={ fieldsToUpdate.elevator || apartment.elevator }
                    />
                  )
                  : null
              }
            </ul>
          </Card.Body>
        </Card>
      </Col>
    );
  }

  const categories = ['general', 'kitchen', 'bathroom', 'external'];

  const renderColumns = categories.map((kind) => renderFeaturesList(kind));

  return (
    <div className="features-tab">
      <Row>
        { renderColumns }
        <RoundButton
          editMode={ editMode }
          toggleEditMode={ toggleEditMode }
          save={ updateApartment }
        />
      </Row>
    </div>
  );
}

export default FeaturesTab;
