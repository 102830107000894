import React from 'react';
import { toast } from 'react-toastify';
import capitalize from 'lodash/capitalize';
import truncate from 'lodash/truncate';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../utils/API';
import NewIntervention from './new';
import './style.scss';

class Interventions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      apartments: [],
      interventionTypes: [],
      propertyManagers: [],
      cities: [],
      displayModal: false,
    };
  }

  async componentDidMount() {
    await this.fetchTypes();
    await this.fetchCities();
    await this.fetchPropertyManagers();
  }

  async fetchTypes() {
    const response = await new ForestApi().interventions().getTypes();
    const types = response.data;

    this.setState({
      interventionTypes: types,
    });
  }

  async fetchCities() {
    const response = await new ForestApi().cities().getAll();
    const cities = response.data.map(({ id }) => id);

    this.setState({ cities });
  }

  async fetchPropertyManagers() {
    const query = 'team=property-manager';
    const response = await new ForestApi().users().getAll(query);

    this.setState({
      propertyManagers: response.data.map(({ firstName }) => firstName),
    });
  }

  handleSearch(name) {
    if (name.trim().length < 3) {
      return this.setState({ apartments: [] });
    }

    const data = `name=${name.trim()}`;

    return new ForestApi().interventions()
      .search(data)
      .then((res) => {
        this.setState({ apartments: res.data });
      });
  }

  toggleModal() {
    const { displayModal } = this.state;

    this.setState({
      displayModal: !displayModal,
    });
  }

  createIntervention(fields) {
    const data = fields.reduce((acc, field) => {
      acc[field.name] = field.value;

      return acc;
    }, {});

    this.toggleModal();

    new ForestApi().interventions()
      .create(data)
      .then((res) => {
        const intervention = res.data;

        toast('Intervention created!', {
          type: toast.TYPE.SUCCESS,
        });

        this.showIntervention(intervention.id);
      });
  }

  showIntervention(id) {
    const { history } = this.props;

    history.push(`/interventions/${id}`);
  }

  showDashboard() {
    const { history } = this.props;

    history.push('/interventions/dashboard');
  }

  renderSearchResults() {
    const { apartments } = this.state;

    const icons = {
      draft: 'fas fa-edit',
      assessed: 'fas fa-eye',
      scheduled: 'far fa-calendar-check',
      ongoing: 'fas fa-tractor',
      done: 'fas fa-check',
    };

    const interventions = apartments.reduce((acc, apartment) => (
      [...acc, ...apartment.TechnicalInterventions]
    ), []);

    return (
      <ul className="search-results">
        {
          interventions.filter(Boolean).map((intervention) => {
            const {
              ApartmentId,
              description,
              id,
              RoomId,
              status,
              type,
            } = intervention;
            const apartment = apartments.find((apart) => apart.id === ApartmentId);

            return (
              <li
                key={ id }
                className="result"
                onClick={ () => this.showIntervention(id) }
              >
                <div className="mr-4">
                  <Button
                    variant="dark"
                    size="sm"
                    className="btn-label mr-2"
                    disabled
                  >
                    { capitalize(truncate(type, { length: 15 })) }
                  </Button>
                  {
                    RoomId
                      ? (
                        <i className="icon fas fa-bed" />
                      )
                      : <i className="icon fas fa-home" />
                  }
                  <span className={ `color-${status}` }>
                    <i className={ `${icons[status]}` } />
                  </span>
                </div>
                <div className="flex-fill" style={{ overflow: 'hidden' }}>
                  <span className="mr-2">
                    { apartment.name }
                  </span>
                  <span className="text-secondary">
                    { truncate(description, { length: 30 }) }
                  </span>
                </div>
                <div className="details">
                  <i className="fas fa-arrow-right" />
                </div>
              </li>
            );
          })
        }
      </ul>
    );
  }

  render() {
    const {
      apartments,
      interventionTypes,
      propertyManagers,
      cities,
      displayModal,
    } = this.state;

    return (
      <div className="Interventions">
        <div className="d-flex justify-content-between flex-wrap
        flex-md-nowrap align-items-center pt-3 pb-2 border-bottom"
        >
          <h3>Interventions</h3>
          <Button variant="success" onClick={ () => this.toggleModal() }>
            <i className="icon fa fa-plus" />
            New
          </Button>
        </div>
        <Row className="pt-4 mt-4 pb-4 justify-content-center">
          <Col lg="6">
            <div className="search-bar">
              <div className="search-field">
                <div className="search-icon">
                  <i className="fas fa-tools" />
                </div>
                <input
                  type="text"
                  placeholder="Search by apartment name..."
                  onChange={ (e) => this.handleSearch(e.target.value) }
                />
              </div>
              {
                apartments.length > 0
                  ? this.renderSearchResults()
                  : null
              }
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="6" className="d-flex align-items-center justify-content-between">
            <div className="w-100 p-2"><hr /></div>
            <h3 className="p-2">OR</h3>
            <div className="w-100 p-2"><hr /></div>
          </Col>
        </Row>
        <Row className="mt-4 justify-content-center">
          <Col lg="6">
            <div className="dashboard-btn" onClick={ () => this.showDashboard() }>
              <div className="text-container">
                <h1>Go to dashboard</h1>
              </div>
            </div>
          </Col>
        </Row>
        {
          displayModal
            ? (
              <NewIntervention
                show={ displayModal }
                handleClose={ () => this.toggleModal() }
                handleSubmit={ (data) => this.createIntervention(data) }
                interventionTypes={ interventionTypes }
                cities={ cities }
                propertyManagers={ propertyManagers }
              />
            )
            : null
        }
      </div>
    );
  }
}

export default Interventions;
