import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import capitalize from 'lodash/capitalize';
import { CircularProgressbar } from 'react-circular-progressbar';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../utils/API';
import * as apartmentsActions from '../../redux/actions/apartmentsActions';

import NewApartment from './new';
import 'react-circular-progressbar/dist/styles.css';
import './style.scss';

function Apartments(props) {
  const { history, store, fetchStatistics } = props;
  const { count, teamCompletion } = store;

  const [apartments, setApartments] = useState([]);
  const [displayModal, toggleDisplayModal] = useState(false);

  const teamCompletionArray = Object.keys(teamCompletion);

  const globalCompletion = Object.values(teamCompletion).reduce((total, value) => total + value, 0);

  const roundedGlobalCompletion = Math.floor(globalCompletion / 3);

  function displayStat(status) {
    if (count.length > 0) {
      return count.filter((item) => item.status === status).length > 0
        ? count.find((item) => item.status === status).count
        : 0;
    }

    return null;
  }

  const totalStock = displayStat('active') + displayStat('maintenance');

  function toggleModal() {
    toggleDisplayModal(!displayModal);

    fetchStatistics();
  }

  function showApartment(id) {
    history.push(`/apartments/${id}`);
  }

  function handleSearch(name) {
    if (name.trim().length < 3) {
      return setApartments([]);
    }

    const data = `name=${name.trim()}`;

    return new ForestApi().apartments()
      .search(data)
      .then((res) => {
        setApartments(res.data);
      });
  }

  useEffect(() => {
    fetchStatistics();
  }, [fetchStatistics]);

  function renderSearchResults() {
    return (
      <ul className="search-results">
        {
          apartments.map(({
            id, CityId, name, status,
          }) => (
            <li
              key={ id }
              className="result"
              onClick={ () => showApartment(id) }
            >
              <Row>
                <Col lg="5" className="d-flex align-items-center">
                  <span className={ `mr-2 color-${status}` }>
                    <i className="fas fa-circle fa-xs" />
                  </span>
                  <span>{ name }</span>
                </Col>
                <Col>
                  <span className={ `color-${CityId}` }>
                    { capitalize(CityId) }
                  </span>
                </Col>
                <Col lg="2" className="details text-right">
                  <i className="fas fa-arrow-right" />
                </Col>
              </Row>
            </li>
          ))
        }
      </ul>
    );
  }

  return (
    <div className="Apartments">
      <div className="d-flex justify-content-between flex-wrap
      flex-md-nowrap align-items-center pt-3 pb-2 border-bottom"
      >
        <Link to="/">
          <i className="icon fas fa-arrow-circle-left" />
          Back to home
        </Link>
        <Button variant="success" onClick={ () => toggleModal() }>
          <i className="icon fa fa-plus" />
          New
        </Button>
      </div>
      <Row className="pt-4 mt-4 pb-4 justify-content-center">
        <Col lg="6">
          <div className="search-bar">
            <div className="search-field">
              <div className="search-icon">
                <i className="fas fa-building" />
              </div>
              <input
                type="text"
                placeholder="Search by apartment name..."
                onChange={ (e) => handleSearch(e.target.value) }
              />
            </div>
            {
              apartments.length > 0
                ? renderSearchResults()
                : null
            }
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xl="8" lg="10" className="d-flex align-items-center justify-content-between">
          <div className="w-100 p-2"><hr /></div>
          <h3 className="p-2">OR</h3>
          <div className="w-100 p-2"><hr /></div>
        </Col>
      </Row>
      <Row className="mt-4 justify-content-center">
        <Col xl="4" lg="5">
          <div className="pb-3 mb-4 dashboard-jumbo">
            <div className="jumbo-title">
              <h4 className="m-0">Configuration in progress</h4>
            </div>
            {
              teamCompletionArray.length > 0
                ? (
                  <>
                    <div className="jumbo-stats w-100 d-flex justify-content-around align-items-center">
                      <div className="progress-card">
                        <CircularProgressbar value={ roundedGlobalCompletion } />
                        <div className="label">
                          <h4>
                            {`${roundedGlobalCompletion} %`}
                          </h4>
                          <p>Completed</p>
                        </div>
                      </div>
                    </div>
                    <Link to="/apartments/dashboard/draft">
                      <Button variant="success" size="lg">
                        {displayStat('draft')}
                        {' '}
                        apartments
                        <i className="fas fa-angle-right fa-lg ml-3" />
                      </Button>
                    </Link>
                  </>
                )
                : (
                  <div className="h-75 w-75 d-flex justify-content-center align-items-center">
                    <i className="fas fa-spinner fa-spin fa-3x" />
                  </div>
                )
            }
          </div>
        </Col>
        <Col xl="4" lg="5">
          <div className="pb-3 mb-4 dashboard-jumbo">
            <div className="jumbo-title">
              <h4 className="m-0">Housing stock</h4>
            </div>
            {
              teamCompletionArray.length > 0
                ? (
                  <>
                    <div className="jumbo-stats stock">
                      {
                      ['active', 'maintenance'].map((item) => (
                        <div className={ `stock-card-container ${item}` } key={ `stock-card-${item}` }>
                          <Link
                            to={ `/apartments/dashboard/${item}` }
                            className={ `stock-card ${item}` }
                            style={{ height: `${(displayStat(item) / totalStock) * 100}%` }}
                            key={ `apartmentStatus ${item}` }
                          >
                            <p className={ `displayStat ${item}` }>
                              {displayStat(item)}
                              {' '}
                              {item}
                              {displayStat(item) > 1 && 's'}
                            </p>
                          </Link>
                        </div>
                      ))
                    }
                    </div>
                    <Link to="/apartments/dashboard/active">
                      <Button variant="success" size="lg">
                        {totalStock}
                        {' '}
                        apartments
                        <i className="fas fa-angle-right fa-lg ml-3" />
                      </Button>
                    </Link>
                  </>
                )
                : (
                  <div className="h-75 w-75 d-flex justify-content-center align-items-center">
                    <i className="fas fa-spinner fa-spin fa-3x" />
                  </div>
                )
            }
          </div>
        </Col>
      </Row>
      {
        displayModal
          ? (
            <NewApartment
              show={ displayModal }
              handleClose={ () => toggleModal() }
              fetchStatistics={ () => fetchStatistics() }
            />
          )
          : null
      }
    </div>
  );
}

const mapStateToProps = (store) => ({
  store: store.apartments,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStatistics: () => dispatch(apartmentsActions.fetchStatistics()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Apartments);
