import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import capitalize from 'lodash/capitalize';

import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import ForestApi from '../../utils/API';
import checkBic from '../../utils/checkBic';
import checkIban from '../../utils/checkIban';
import openDocument from '../../utils/openDocument';

const PAYMENT_KINDS = {
  card: 'card (automatic)',
  sepa: 'SEPA transfer',
  'manual-card': 'card (manual)',
  'manual-cash': 'cash (manual)',
  'manual-transfer': 'bank transfer',
  'manual-cheque': 'payment check',
};

function TransactionShow(props) {
  const {
    onHide,
    show,
    status,
    transactionId,
    userRole,
    userTeam,
  } = props;

  const [isLoading, setLoader] = useState(true);
  const [transaction, setTransaction] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!transaction) {
      new ForestApi().transactions()
        .getOne(transactionId)
        .then((response) => {
          setTransaction(response.data);
          setLoader(false);
        })
        .catch((err) => {
          setTransaction({});
          setError(err);
        });
    }
  });

  function renderError() {
    return (
      <Alert variant="danger">
        <Alert.Heading>Something went wrong.</Alert.Heading>
        <p>
          The transaction data could not be fetched.
        </p>
      </Alert>
    );
  }

  function displayPrice(amount) {
    return (amount / 100).toFixed(2);
  }

  function getItemPrice(item) {
    const { unitPrice, quantity = 1, vatRate } = item;

    return displayPrice((unitPrice * quantity) * (1 + vatRate));
  }

  function renderTransaction() {
    const beneficiary = transaction.ClientId ? 'Client' : 'Contractor';
    const renting = beneficiary === 'Client'
      ? transaction.OrderItems[0].Renting
      : null;

    const city = beneficiary === 'Client'
      ? renting.Room.Apartment.CityId
      : transaction.Contractor.CityId;

    const depositRefundsAgendaLink = `/deposit-refunds?start=${transaction.dueDate}&city=${city}`;

    const depositItem = transaction.OrderItems.find(({ label }) => label === 'Deposit Refund');
    const depositPayment = transaction.deposit.Payments[0];
    const retainedItems = transaction.OrderItems.filter(({ id }) => id !== depositItem.id);
    const totalRetainedItems = depositItem.unitPrice - transaction.amount;

    const { Payments: payments } = transaction;
    const requestedPayment = payments && payments[0];

    const isUserAuthorized = userRole === 'admin' && ['tech', 'accounting', 'customer-happiness'].includes(userTeam);
    const displayXmlDownloadLink = isUserAuthorized && requestedPayment
      && requestedPayment.type === 'manual-transfer' && transaction[beneficiary].refundXmlId;

    const beneficiaryBic = checkBic(transaction[beneficiary].bic);
    const beneficiaryIban = checkIban(transaction[beneficiary].iban);

    return (
      <>
        {
          requestedPayment
            ? (
              <Row>
                <Col>
                  <Alert variant={ status === 'paid' ? 'success' : 'warning' }>
                    The payment for this transaction was requested on
                    {' '}
                    <strong>{ moment(requestedPayment.createdAt).format('DD/MM/YYYY') }</strong>
                    {' '}
                    via
                    {' '}
                    <u>{ PAYMENT_KINDS[requestedPayment.type] }</u>
                    .
                    {
                    status === 'paid'
                      ? (
                        <>
                          <br />
                          <span>
                            This transaction was marked as paid on
                            {' '}
                            <strong>{ moment(requestedPayment.updatedAt).format('DD/MM/YYYY') }</strong>
                            .
                          </span>
                        </>
                      )
                      : null
                  }
                    {
                    displayXmlDownloadLink
                      ? (
                        <div className="mt-2 text-center">
                          <Button
                            variant="link"
                            onClick={ () => openDocument(transaction[beneficiary].refundXmlId, true) }
                          >
                            <i className="icon far fa-file-code fa-lg" />
                            View XML file
                          </Button>
                        </div>
                      )
                      : null
                  }
                  </Alert>
                </Col>
              </Row>
            )
            : null
        }
        <Row className="mb-2">
          <Col>
            <h5>Main data</h5>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <dl>
              <dt>Label</dt>
              <dd>
                <Link to={ depositRefundsAgendaLink } target="_blank">
                  { transaction.label }
                </Link>
              </dd>
            </dl>
          </Col>
          <Col>
            <dl>
              <dt>Due date</dt>
              <dd>{ moment(transaction.dueDate).format('DD/MM/YYYY') }</dd>
            </dl>
          </Col>
          <Col>
            <dl>
              <dt>Amount to pay</dt>
              <dd>
                { displayPrice(transaction.amount) }
                {' '}
                €
              </dd>
            </dl>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <dl>
              <dt>Beneficiary</dt>
              <dd>
                { transaction[beneficiary].fullName }
                {' '}
                (
                { beneficiary }
                )
              </dd>
            </dl>
          </Col>
        </Row>
        {
          renting
            ? (
              <Row className="mb-2">
                <Col>
                  <dl>
                    <dt>Location</dt>
                    <dd>
                      <Link to={ `apartments/${renting.Room.Apartment.id}` } target="_blank">
                        { renting.Room.Apartment.name }
                      </Link>
                      {' - '}
                      <Link to={ `rooms/${renting.RoomId}` } target="_blank">
                        Room #
                        { renting.Room.number }
                      </Link>
                      {' '}
                      (
                      { capitalize(city) }
                      )
                    </dd>
                  </dl>
                </Col>
              </Row>
            )
            : null
        }
        <Row className="mb-2 pt-3 border-top">
          <Col>
            <h5>Payment data</h5>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <dl>
              <dt>Account name</dt>
              <dd>{ transaction[beneficiary].refundBeneficiary || transaction[beneficiary].fullName }</dd>
            </dl>
          </Col>
          <Col>
            <dl>
              <dt>IBAN</dt>
              <dd>
                {
                  beneficiaryIban
                    ? (
                      <span className={ beneficiaryIban.isMalformed ? 'text-danger' : '' }>
                        { beneficiaryIban.iban }
                      </span>
                    )
                    : <span className="text-danger">MISSING</span>
                }
              </dd>
            </dl>
          </Col>
          <Col>
            <dl>
              <dt>BIC</dt>
              <dd>
                {
                  beneficiaryBic
                    ? (
                      <span className={ beneficiaryBic.isMalformed ? 'text-danger' : '' }>
                        { beneficiaryBic.bic }
                      </span>
                    )
                    : <span className="text-danger">MISSING</span>
                }
              </dd>
            </dl>
          </Col>
        </Row>
        {
          transaction.label === 'Deposit Refund'
            ? (
              <>
                <Row className="mb-2 pt-3 border-top">
                  <Col>
                    <h5>Details</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <dl>
                      <dt>Initial deposit</dt>
                      <dd>
                        { displayPrice(depositItem.unitPrice) }
                        {' '}
                        €
                      </dd>
                    </dl>
                  </Col>
                  <Col>
                    <dl>
                      <dt>Payment date</dt>
                      <dd>
                        {
                        depositPayment
                          ? moment(depositPayment.createdAt).format('DD/MM/YYYY HH:mm')
                          : <span className="text-danger">MISSING</span>
                      }
                      </dd>
                    </dl>
                  </Col>
                </Row>
              </>
            )
            : null
        }
        {
          retainedItems.length > 0
            ? (
              <Row>
                <Col>
                  <strong>Retained items on deposit</strong>
                  <Table size="sm" className="mt-2">
                    <thead>
                      <tr className="text-secondary">
                        <td>Category</td>
                        <td>Label</td>
                        <td>Unit price</td>
                        <td>Quantity</td>
                        <td>VAT</td>
                        <td>Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      retainedItems.map((item) => (
                        <tr key={ item.id }>
                          <td>
                            {
                              item.ProductId
                                ? (
                                  <Badge size="lg" variant="dark">
                                    { item.ProductId }
                                  </Badge>
                                )
                                : <i>No category set</i>
                            }
                          </td>
                          <td>
                            { capitalize(item.label) }
                          </td>
                          <td>
                            { displayPrice(item.unitPrice) }
                            {' '}
                            €
                          </td>
                          <td>
                            { item.quantity }
                          </td>
                          <td>
                            { item.vatRate * 100 }
                            %
                          </td>
                          <td>
                            { getItemPrice(item) }
                            {' '}
                            €
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="5">Total</td>
                        <td>
                          { displayPrice(-totalRetainedItems) }
                          {' '}
                          €
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Col>
              </Row>
            )
            : null
        }
      </>
    );
  }

  return (
    <Modal size="lg" show={ show } onHide={ () => onHide() }>
      <Modal.Header closeButton>
        Transaction #
        { transactionId }
      </Modal.Header>
      <Modal.Body>
        {
          isLoading
            ? (
              <div className="h-100 d-flex align-items-center justify-content-center">
                <i className="fas fa-spinner fa-spin fa-2x" />
              </div>
            )
            : null
        }
        {
          error
            ? renderError()
            : null
        }
        {
          !error && transaction
            ? renderTransaction()
            : null
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ () => onHide() }>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TransactionShow;
