import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { isArray } from 'util';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';

import { toast } from 'react-toastify';
import ActionModal from '../actions';
import ForestApi from '../../../utils/API';

class MainTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [{
        status: 'draft',
        name: 'problemAssessed',
        label: 'Problem assessed',
        icon: 'fas fa-eye',
        variant: 'primary',
      }, {
        status: 'assessed',
        name: 'scheduleIntervention',
        label: 'Schedule intervention',
        icon: 'far fa-calendar',
        variant: 'primary',
      }, {
        status: 'ongoing',
        name: 'interventionDone',
        label: 'Intervention done',
        icon: 'fas fa-check',
        variant: 'success',
      }, {
        status: 'done',
        name: 'contractorPaid',
        label: 'Contractor paid',
        icon: 'fas fa-hand-holding-usd',
        variant: 'success',
      }, {
        status: ['scheduled', 'ongoing'],
        name: 'rescheduleIntervention',
        label: 'Reschedule intervention',
        icon: 'far fa-calendar',
        variant: 'secondary',
      }, {
        status: ['draft', 'assessed', 'scheduled', 'ongoing'],
        name: 'cancelIntervention',
        label: 'Cancel intervention',
        icon: 'fas fa-exclamation-triangle',
        variant: 'warning',
      }],
      displayModal: false,
      modalAction: {},
      editMode: false,
      fieldsToUpdate: {},
    };
  }

  toggleEditMode() {
    const { editMode } = this.state;

    this.setState({
      editMode: !editMode,
      fieldsToUpdate: {},
    });
  }

  toggleModal(action = {}) {
    const { displayModal } = this.state;

    this.setState({
      displayModal: !displayModal,
      modalAction: action,
    });
  }

  updateIntervention() {
    const { intervention: { id }, fetchIntervention } = this.props;
    const { fieldsToUpdate } = this.state;

    if (!isEmpty(fieldsToUpdate)) {
      new ForestApi().interventions()
        .update(id, fieldsToUpdate)
        .then(() => {
          toast('Intervention updated!', {
            type: toast.TYPE.SUCCESS,
          });
          fetchIntervention();
        });
    }

    this.toggleEditMode();
  }

  editField(name, value) {
    const { fieldsToUpdate } = this.state;

    this.setState({
      fieldsToUpdate: {
        ...fieldsToUpdate,
        [name]: value,
      },
    });
  }

  renderModal() {
    const { intervention, fetchIntervention } = this.props;
    const { displayModal, modalAction } = this.state;

    if (!modalAction) {
      return null;
    }

    return (
      <ActionModal
        action={ modalAction }
        intervention={ intervention }
        show={ displayModal }
        handleClose={ () => this.toggleModal() }
        fetchIntervention={ () => fetchIntervention() }
      />
    );
  }

  renderActions() {
    const { intervention: { status } } = this.props;
    const { actions } = this.state;

    const filteredActions = actions
      .filter((action) => (isArray(action.status) ? action.status.includes(status) : action.status === status));

    if (filteredActions.length === 0) {
      return <Alert variant="info">No available action.</Alert>;
    }

    return filteredActions
      .map((action) => {
        const {
          variant, name, icon, label,
        } = action;

        return (
          <Button
            key={ name }
            variant={ variant }
            onClick={ () => this.toggleModal(action) }
            block
          >
            <i className={ `icon ${icon}` } />
            { label }
          </Button>
        );
      });
  }

  render() {
    const { intervention } = this.props;
    const { displayModal, editMode } = this.state;

    const {
      ApartmentId,
      clientPresenceNeeded,
      Contractor,
      description,
      expectedStartDate,
      expectedTimeSlot,
      notes,
      price,
      RoomId,
      subType,
    } = intervention;

    const mappedSubtypes = subType && subType.split(',').map((type) => (
      <Button
        key={ type }
        variant="dark"
        size="sm"
        className="btn-label mr-2"
        disabled
      >
        { type }
      </Button>
    ));

    const contractorFullName = Contractor
      ? (
        <span>
          { Contractor.firstName }
          {' '}
          { Contractor.lastName }
          { Contractor.company ? ` - ${Contractor.company}` : ''}
        </span>
      )
      : '';

    return (
      <div className="Intervention-MainTab">
        <Row>
          <Col lg="9">
            <Card>
              <Card.Header>
                <strong>Intervention details</strong>
              </Card.Header>
              <Card.Body>
                {
                  subType
                    ? (
                      <Row>
                        <Col>
                          <p>
                            { mappedSubtypes }
                          </p>
                        </Col>
                      </Row>
                    )
                    : null
                }
                <Row>
                  <Col>
                    <p>
                      This intervention concerns the
                      {' '}
                      {
                        RoomId
                          ? (
                            <strong>
                              room #
                              { intervention.Room.number }
                            </strong>
                          )
                          : <strong>common areas</strong>
                      }
                      {' of '}
                      <Link to={ `/apartments/${ApartmentId}` }>
                        { intervention.Apartment.name }
                      </Link>
                      .
                    </p>
                    <p>
                      The client's presence is
                      <strong>
                        { !clientPresenceNeeded ? <span> not </span> : null }
                        needed
                      </strong>
                      .
                    </p>
                    <p>
                      {
                        price
                          ? (
                            <span>
                              The intervention will be billed
                              <strong>
                                { price }
                                {' '}
                                €
                              </strong>
                              .
                            </span>
                          )
                          : (
                            <span>
                              There is
                              <strong>no price set</strong>
                              {' '}
                              for this intervention.
                            </span>
                          )
                      }
                    </p>
                  </Col>
                  <Col>
                    {
                      Contractor
                        ? (
                          <>
                            <p>
                              The intervention will be operated by
                            </p>
                            <p className="text-center">
                              <Link to={ `/contractors/${Contractor.id}` } target="_blank">
                                <strong>{ contractorFullName }</strong>
                              </Link>
                            </p>
                            <p>
                              on
                              {' '}
                              <strong>
                                { moment(expectedStartDate).format('DD/MM/YYYY') }
                                {
                                expectedTimeSlot === 'day'
                                  ? <span> during the day.</span>
                                  : (
                                    <span>
                                      {' '}
                                      in the
                                      { expectedTimeSlot.toLowerCase() }
                                      .
                                    </span>
                                  )
                              }
                              </strong>
                            </p>
                          </>
                        )
                        : (
                          <>
                            <p>
                              There is
                              {' '}
                              <strong>no contractor</strong>
                              {' '}
                              assigned to this intervention.
                            </p>
                          </>
                        )
                    }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5>Description</h5>
                    <Alert
                      variant={ description ? 'info' : 'warning' }
                      onClick={ () => !editMode && this.toggleEditMode() }
                      style={ !editMode ? { cursor: 'pointer' } : {} }
                      className="description"
                    >
                      {
                        editMode
                          ? (
                            <FormControl
                              as="textarea"
                              rows="3"
                              defaultValue={ description }
                              onChange={ (event) => this.editField('description', event.target.value) }
                            />
                          )
                          : description || <span>No description provided.</span>
                      }
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5>Notes</h5>
                    <Alert
                      variant={ notes ? 'info' : 'warning' }
                      onClick={ () => !editMode && this.toggleEditMode() }
                      style={ !editMode ? { cursor: 'pointer' } : {} }
                      className="description"
                    >
                      {
                        editMode
                          ? (
                            <FormControl
                              as="textarea"
                              rows="3"
                              defaultValue={ notes }
                              onChange={ (event) => this.editField('notes', event.target.value) }
                            />
                          )
                          : notes || <span>No notes provided.</span>
                        }
                    </Alert>
                  </Col>
                </Row>
              </Card.Body>
              {
                editMode
                  ? (
                    <Card.Footer>
                      <Button className="float-right" onClick={ () => this.updateIntervention() }>
                        <i className="icon fas fa-save" />
                        Save
                      </Button>
                      <Button variant="secondary" className="mr-2 float-right" onClick={ () => this.toggleEditMode() }>
                        Cancel
                      </Button>
                    </Card.Footer>
                  )
                  : null
              }
            </Card>
          </Col>
          <Col lg="3">
            <Card>
              <Card.Header>
                <strong>Actions</strong>
              </Card.Header>
              <Card.Body>
                <Row className="mb-2">
                  <Col>
                    { this.renderActions() }
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        { displayModal && this.renderModal() }
      </div>
    );
  }
}

export default MainTab;
