import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import arrayFilter from 'lodash/filter';
import get from 'lodash/get';
import find from 'lodash/find';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Pagination from 'react-bootstrap/Pagination';

import ForestApi from '../../utils/API';
import getQueryString from '../../utils/getQueryString';
import { renderCheckinTimeslot } from '../../utils/formatCheckinDate';
import Agenda from '../Agenda';
import ContractorModal from '../ContractorModal';
import DepositRefundModal from '../DepositRefundModal';
import EventPopover from '../EventPopover';
import './style.scss';
import getRentingArrondissement from '../../utils/getRentingArrondissement';
import ArrondissementLabel from '../ArrondissementLabel';

class UserAgenda extends React.Component {
  constructor(props) {
    super(props);

    const startDate = get(props, 'params.start', null)
      ? moment(props.params.start)
      : moment();

    const cities = ['lyon', 'paris', 'lille', 'toulouse', 'montpellier', 'bordeaux'];
    const defaultCity = get(props, 'params.city', 'lyon');
    this.eventsRefs = [];

    this.state = {
      contractors: [],
      events: [],
      startWeek: startDate.startOf('isoWeek').format('YYYY-MM-DD'),
      endWeek: startDate.endOf('isoWeek').format('YYYY-MM-DD'),
      filters: [{
        name: 'city',
        options: cities,
        currentValue: defaultCity,
      }],
      displayUnassignedOnly: false,
      isLoading: false,
      displayEventOverlay: '',
      displayModal: false,
      currentEvent: null,
      searchResults: [],
    };
  }

  componentDidMount() {
    this.fetchEvents();
    this.fetchContractors();
  }

  componentDidUpdate(prevProps, prevState) {
    const { startWeek, eventType } = this.state;

    if (
      !moment(startWeek).isSame(prevState.startWeek, 'day')
      || eventType !== prevState.eventType
    ) {
      this.fetchEvents();
    }
  }

  getButtonClass(status, city, event) {
    const btnDefault = 'btn btn-md btn-block';
    const kebabStatus = kebabCase(status);
    let opacityProperty = '';
    if (event.type === 'checkout') {
      const statusContractor = arrayFilter(event.ContractorEvents, { status: 'draft' });
      if (event.ContractorEvents.length > 0 && statusContractor.length > 0) opacityProperty = `btn-opacity-${city}`;
    }
    return `${btnDefault} btn-${kebabStatus} btn-${kebabStatus}-${city} ${opacityProperty}`;
  }

  handleWeekChange(week) {
    const { startWeek } = this.state;

    const newStartWeek = week
      ? moment(startWeek).add(week, 'week')
      : moment().startOf('isoWeek');

    const newEndWeek = moment(newStartWeek).endOf('isoWeek');

    this.setState({
      startWeek: newStartWeek.format('YYYY-MM-DD'),
      endWeek: newEndWeek.format('YYYY-MM-DD'),
    });
  }

  sendInvitations(data) {
    new ForestApi().contractors()
      .sendInvitations(data)
      .then(() => {
        toast('Invitations sent!', {
          type: toast.TYPE.SUCCESS,
        });
        this.fetchEvents();
      });
  }

  updateContractorEvent(id, status) {
    const data = { status };

    new ForestApi().contractorEvents()
      .update(id, data)
      .then(() => {
        toast(`Checkout ${status}!`, {
          type: toast.TYPE.SUCCESS,
        });
        this.fetchEvents();
      });
  }

  updateRenting(id, data) {
    new ForestApi().rentings()
      .update(id, data)
      .then(() => {
        toast('Renting updated!', {
          type: toast.TYPE.SUCCESS,
        });
        this.fetchEvents();
      });
  }

  handleFilterClick(city) {
    const { filters } = this.state;
    const cityFilter = filters.find(({ name }) => name === 'city');

    if (cityFilter.currentValue === city) {
      return;
    }

    this.setState({
      filters: [{
        ...cityFilter,
        currentValue: city,
      }],
    }, () => this.fetchEvents());
  }

  handleUnassignedFilter() {
    const { displayUnassignedOnly } = this.state;

    this.setState({ displayUnassignedOnly: !displayUnassignedOnly });
  }

  handleContractorsInvitations(ids) {
    const { currentEvent } = this.state;
    this.toggleModal();

    const data = {
      eventId: currentEvent.id,
      ids,
    };

    this.sendInvitations(data);
  }

  handleAssignmentCancelation(contractorEventId) {
    const message = 'Are you sure you wish to cancel this assigment ?';

    if (window.confirm(message)) {
      this.updateContractorEvent(contractorEventId, 'cancelled');
    }
  }

  handleAssignmentRejection(contractorEventId) {
    const message = 'Are you sure you wish to refused this assigment ?';
    if (window.confirm(message)) {
      this.updateContractorEvent(contractorEventId, 'rejected');
    }
  }

  handleAssignmentAcceptation(contractorEventId) {
    const message = 'Are you sure you wish to accept this assigment ?';

    if (window.confirm(message)) {
      this.updateContractorEvent(contractorEventId, 'accepted');
    }
  }

  updateSafeNumber(rentingId, safeNumber) {
    this.updateRenting(rentingId, { checkinSpotSafeNumber: safeNumber });
  }

  toggleModal() {
    const { currentEvent, displayModal } = this.state;

    this.setState({
      currentEvent: displayModal ? null : currentEvent,
      displayModal: !displayModal,
      displayEventOverlay: '',
    });
  }

  toggleCurrentEvent(event) {
    const { currentEvent } = this.state;

    const newEvent = (!currentEvent || currentEvent.id !== event.id)
      ? event
      : null;

    this.setState({
      currentEvent: newEvent,
      displayEventOverlay: newEvent ? event.id : '',
    });
  }

  fetchEvents() {
    const { eventType } = this.props;
    const {
      startWeek, endWeek, filters, currentEvent,
    } = this.state;

    const city = filters.find(({ name }) => name === 'city').currentValue;

    const query = getQueryString({
      startDate: startWeek,
      endDate: endWeek,
      type: eventType,
      city,
    });

    this.setState({ isLoading: true });

    new ForestApi().events()
      .getAll(query)
      .then((res) => {
        const events = res.data;
        const updatedCurrentEvent = currentEvent
          ? events.find((event) => event.id === currentEvent.id)
          : null;

        this.setState({
          isLoading: false,
          events,
          currentEvent: updatedCurrentEvent,
        });
      });
  }

  fetchContractors() {
    const { eventType } = this.props;

    if (eventType !== 'checkout') {
      return;
    }

    new ForestApi().contractors()
      .getAll('type=checkout')
      .then((res) => {
        const contractors = res.data;

        this.setState({ contractors });
      });
  }

  searchEvents(name) {
    const { eventType } = this.props;

    if (name.length < 3) {
      this.setState({ searchResults: [] });
      return;
    }

    const data = [
      `type=${eventType}`,
      `name=${name}`,
    ].join('&');

    new ForestApi().events()
      .search(data)
      .then((res) => {
        const events = res.data;

        this.setState({ searchResults: events });
      });
  }

  displayContractorTrigram(contractorEvent) {
    const { Contractor: contractor } = contractorEvent;
    const trigram = contractor.firstName.slice(0, 3).toUpperCase();

    return <span className="contractor-trigram">{ trigram }</span>;
  }

  renderUnassignedFilter(eventType) {
    if (eventType === 'deposit-refund') {
      return null;
    }

    const { displayUnassignedOnly } = this.state;

    return (
      <Button
        variant={ `${displayUnassignedOnly ? '' : 'outline-'}info` }
        onClick={ () => this.handleUnassignedFilter() }
      >
        { displayUnassignedOnly ? 'Show' : 'Hide'}
        {' '}
        confirmed events
      </Button>
    );
  }

  renderSearchResults() {
    const { eventType, history } = this.props;
    const { searchResults } = this.state;

    const regex = new RegExp(startCase(eventType), 'i');

    return (
      <ul className="search-results">
        {
          searchResults.map((event) => {
            const startDate = moment(event.startDate).format('YYYY-MM-DD');

            return (
              <li
                key={ event.id }
                className="result"
                onClick={ () => (event.city
                  ? history.push(`/${eventType}s?start=${startDate}&city=${event.city}`)
                  : history.push(`/${eventType}s?start=${startDate}&city=${event.garageCity}`)) }
              >
                <div className="description">
                  { event.summary.replace(regex, '') }
                </div>
                <div className="details">
                  { moment(event.startDate).format('DD/MM/YY') }
                </div>
              </li>
            );
          })
        }
      </ul>
    );
  }

  renderEvent(event) {
    const { displayEventOverlay, filters } = this.state;
    const {
      id,
      type,
      startDate,
      ContractorEvents,
    } = event;
    let client;
    let Checkin;
    let Room;
    let checkinSpotSafeNumber;

    const isRoomEvent = Boolean(event.Renting);

    if (event.Renting) {
      client = event.Renting.Client;
      Checkin = event.Renting.Checkin;
      Room = event.Renting.Room;
      checkinSpotSafeNumber = event.Renting.checkinSpotSafeNumber;
    } else if (event.GarageRenting) {
      client = event.GarageRenting.Client;
    }

    if (!client) {
      return null;
    }

    let eventDisplayStatus = 'notReady';

    const city = filters.find((filter) => filter.name === 'city').currentValue;
    const checkinReady = isRoomEvent && type === 'checkin' && Checkin.isCheckinReady && Checkin.depositPaid;
    const creditOrder = isRoomEvent && client.Orders.find((order) => order.type === 'credit'
      && find(order.OrderItems, { RentingId: event.Renting.id }));

    if (type === 'checkin' && checkinReady) {
      eventDisplayStatus = 'ready';
    }

    if (type === 'deposit-refund' && creditOrder && creditOrder.status === 'pending') {
      eventDisplayStatus = 'ready';
    }

    const hasSafeNumber = Boolean(checkinSpotSafeNumber);
    const checkoutConfirmed = isRoomEvent
      && type === 'checkout'
      && ContractorEvents.map(({ status }) => status).includes('accepted');

    const depositRefundConfirmed = creditOrder && type === 'deposit-refund' && creditOrder.status === 'active';

    if ((checkinReady && hasSafeNumber) || checkoutConfirmed || depositRefundConfirmed) {
      eventDisplayStatus = 'confirmed';
    }

    const confirmed = eventDisplayStatus === 'confirmed';

    const contractorEvent = isRoomEvent && type === 'checkout' && confirmed
      ? ContractorEvents.find(({ status }) => status === 'accepted')
      : null;
    const popoverProps = {
      event,
      displayStatus: eventDisplayStatus,
      contractorEvent,
      city,
      toggleModal: () => this.toggleModal(),
      handleAssignmentCancelation: (contractorEventId) => this.handleAssignmentCancelation(contractorEventId),
      handleAcceptContractor: (contractorEventId) => this.handleAssignmentAcceptation(contractorEventId),
      handleRefusedContractor: (contractorEventId) => this.handleAssignmentRejection(contractorEventId),
      updateSafeNumber: (rentingId, safeNumber) => this.updateSafeNumber(rentingId, safeNumber),
      fetchEvents: () => this.fetchEvents(),
    };
    let arrondissement;
    if (city?.toLowerCase() === 'paris' && !!event.Renting) {
      arrondissement = getRentingArrondissement(event.Renting);
    }

    return (
      <div key={ id } className="event" ref={ (ref) => { this.eventsRefs[id] = ref; } }>
        <button
          className={ this.getButtonClass(eventDisplayStatus, city, event) }
          onClick={ () => this.toggleCurrentEvent(event) }
          type="button"
        >
          <div className="d-flex justify-content-between">
            <div>
              {
                event.type === 'checkout'
                  ? moment(startDate).format('HH:mm')
                  : null
              }
              {
                event.type === 'checkin'
                  ? renderCheckinTimeslot(startDate)
                  : null
              }
            </div>
            <div>
              {
                event.type === 'checkin' && hasSafeNumber
                  ? (
                    <Badge pill variant="dark">
                      <span className="icon fa fa-key" />
                      {
                      checkinSpotSafeNumber === -1
                        ? 'KN'
                        : checkinSpotSafeNumber
                    }
                    </Badge>
                  )
                  : null
              }
              {
                event.type === 'checkout' && confirmed
                  ? (
                    <Badge pill variant="dark">
                      { this.displayContractorTrigram(contractorEvent) }
                    </Badge>
                  )
                  : null
              }
            </div>
          </div>
          <div className="label">
            { client.fullName }
          </div>
          <div className='additional-info'>
            <span>{isRoomEvent ? Room.reference : `Garage ${event.GarageRenting.Garage.name}`}</span>
          </div>
          <div className='additional-info'>
            {arrondissement &&
              <ArrondissementLabel arrondissement={arrondissement} theme={'light'} className='p-1' />}
          </div>
        </button>
        <Overlay
          flip
          onHide={ () => {} }
          placement="auto"
          rootClose
          show={ displayEventOverlay === id }
          target={ this.eventsRefs[id] }
        >
          <EventPopover { ...popoverProps } { ...this.props } />
        </Overlay>
      </div>
    );
  }

  renderFilter(filter) {
    const { name, options, currentValue } = filter;

    if (name !== 'city') {
      return null;
    }

    return options.map((city) => {
      const isActive = city === currentValue;

      return (
        <div key={ city } className="filter">
          <button
            className={ `btn btn-${isActive ? 'confirmed' : 'not-ready'}-${city}` }
            onClick={ () => this.handleFilterClick(city) }
            type="button"
          >
            { startCase(city) }
          </button>
        </div>
      );
    });
  }

  render() {
    const { eventType } = this.props;
    const {
      filters,
      displayUnassignedOnly,
      displayModal,
      currentEvent,
      events,
      isLoading,
      startWeek,
      endWeek,
      contractors,
      searchResults,
    } = this.state;

    const [currentCity] = filters;
    const query = [
      `city=${currentCity.currentValue}`,
      `start=${startWeek}`,
    ].join('&');
    window.history.replaceState(null, null, `${window.location.pathname}?${query}`);

    const active = moment().isSame(startWeek, 'week');
    const city = filters.find((filter) => filter.name === 'city').currentValue;

    const renderFilters = filters.map((filter) => this.renderFilter(filter));

    const filteredEvents = events.filter(({ Renting, GarageRenting }) => {
      const location = Renting
        ? get(Renting, 'Room.Apartment.CityId')
        : get(GarageRenting, 'Garage.Apartment.CityId');

      if (!location) {
        return null;
      }

      return location.toLowerCase() === city;
    })
      .filter(Boolean)
      .filter((event) => {
        if (displayUnassignedOnly) {
          if (event.type === 'checkout') {
            return event.ContractorEvents
              .filter((contractorEvent) => contractorEvent.status === 'accepted').length === 0;
          }

          const {
            Renting: {
              Checkin: { isCheckinReady, depositPaid },
              checkinSpotSafeNumber,
            },
          } = event;

          return !(isCheckinReady && depositPaid && checkinSpotSafeNumber);
        }

        return event;
      });

    const renderEvents = filteredEvents.length > 0
      && filteredEvents.map((event) => ({
        startDate: event.startDate,
        rendered: this.renderEvent(event),
      }));

    const filteredContractors = contractors.filter(({ CityId }) => CityId === city);

    return (
      <div className="UserAgenda">
        <div className="d-flex justify-content-between flex-wrap
        flex-md-nowrap align-items-center mt-3 pb-3 mb-3 border-bottom"
        >
          <Link to="/">
            <i className="icon fas fa-arrow-circle-left" />
            Back to home
          </Link>
          <div className="page-title">
            <h3>
              { startCase(eventType) }
              s from
              {' '}
              { moment(startWeek).format('DD/MM/YYYY') }
              {' '}
              to
              {' '}
              { moment(endWeek).format('DD/MM/YYYY') }
            </h3>
          </div>
          <div className="search-box">
            <div className="search-field">
              <i className="icon fas fa-search" />
              <input placeholder="Search by client name..." onChange={ (e) => this.searchEvents(e.target.value) } />
            </div>
            {
              searchResults.length > 0
                ? this.renderSearchResults()
                : null
            }
          </div>
          <div className="btn-toolbar">
            <Pagination size="sm">
              <Pagination.Item onClick={ () => this.handleWeekChange(-1) }>&laquo; Prev. week</Pagination.Item>
              <Pagination.Item active={ active } onClick={ () => this.handleWeekChange() }>Today</Pagination.Item>
              <Pagination.Item onClick={ () => this.handleWeekChange(1) }>Next week &raquo;</Pagination.Item>
            </Pagination>
          </div>
        </div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-3">
          <div className="filters">
            { renderFilters }
            { this.renderUnassignedFilter(eventType) }
          </div>
          <div className="refresh-button">
            <Button variant="success" onClick={ () => this.fetchEvents() }>
              <span className="fas fa-sync" />
            </Button>
          </div>
        </div>
        <Agenda isLoading={ isLoading } events={ renderEvents } startWeek={ startWeek } />
        {
          currentEvent && eventType === 'checkout' && displayModal
            ? (
              <ContractorModal
                show={ displayModal }
                handleClose={ () => this.toggleModal() }
                handleSubmit={ (ids) => this.handleContractorsInvitations(ids) }
                contractors={ filteredContractors }
                event={ currentEvent }
              />
            )
            : null
        }
        {
          currentEvent && eventType === 'deposit-refund' && displayModal
            ? (
              <DepositRefundModal
                show={ displayModal }
                handleClose={ () => this.toggleModal() }
                event={ currentEvent }
                fetchEvents={ () => this.fetchEvents() }
                isLoading={ isLoading }
              />
            )
            : null
        }
      </div>
    );
  }
}

export default UserAgenda;
