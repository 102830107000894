import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import get from 'lodash/get';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import truncate from 'lodash/truncate';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import FormCheck from 'react-bootstrap/FormCheck';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../utils/API';
import buildForestLink from '../../utils/buildForestLink';
import checkIban from '../../utils/checkIban';
import checkBic from '../../utils/checkBic';
import Dashboard from '../Dashboard';
import TransactionModal from './show';

class Transactions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      filters: {
        search: {
          type: 'search',
          placeholder: 'Filter by name...',
          currentValue: '',
        },
        beneficiary: {
          type: 'radio',
          label: 'Beneficiary',
          currentValue: 'Client',
          values: ['Client', 'Contractor'],
        },
        dueDate: {
          type: 'daterange',
          label: 'Due date',
          currentValue: [],
        },
      },
      headers: {
        label: {
          order: 1,
          sortable: false,
        },
        date: {
          order: 2,
          sortable: true,
        },
        beneficiary: {
          order: 3,
          sortable: true,
        },
        amount: {
          order: 4,
          sortable: false,
        },
        dueDate: {
          order: 5,
          sortable: true,
        },
        actions: {
          order: 6,
          sortable: false,
        },
      },
      sorting: {
        field: 'date',
        direction: 'DESC',
      },
      transactions: [],
      selectedTransactions: [],
      currentTransactionId: null,
      displayModal: false,
      status: 'unpaid',
      transactionsPaidCSV: {
        data: [],
        headers: [],
      },
    };
  }

  componentDidMount() {
    this.fetchTransactions();
  }

  getCSVdata(transactions) {
    const data = transactions
      .filter(({ Payments }) => Payments.length !== 0)
      .map(({ Client, OrderItems, Payments }) => {
        const { fullName } = Client;
        const orderItem = OrderItems.find((item) => item.label === 'Deposit Refund');
        const { Renting: { Room: { name: roomName } } } = orderItem;
        const { amount, updatedAt, type } = Payments.find((payment) => payment.OrderId === orderItem.OrderId);

        return {
          fullName,
          roomName,
          initialDeposit: Math.round(orderItem.total / 100),
          amount: Math.round(amount / 100),
          paymentDate: moment(updatedAt).format('YYYY-MM-DD'),
          type: type.split('-')[1],
        };
      });

    const headers = [
      { label: 'Full Name', key: 'fullName' },
      { label: 'Room Name', key: 'roomName' },
      { label: 'Initial deposit', key: 'initialDeposit' },
      { label: 'Amount paid', key: 'amount' },
      { label: 'Paid on', key: 'paymentDate' },
      { label: 'Payement type', key: 'type' },
    ];

    return { data, headers };
  }

  setStatus(newStatus) {
    const { status, filters } = this.state;

    if (status !== newStatus) {
      let newFilters = {};

      this.handleFilterChange('search', '');

      if (newStatus === 'paid') {
        newFilters = {
          ...filters,
          paymentDate: {
            type: 'daterange',
            label: 'Payment date',
            currentValue: [],
            pastOnly: true,
          },
        };
      } else {
        newFilters = omit(filters, 'paymentDate');
      }

      this.setState({
        filters: newFilters,
        status: newStatus,
        selectedTransactions: [],
      }, this.fetchTransactions);
    }
  }

  fetchTransactions() {
    const { status, filters } = this.state;
    const { beneficiary, dueDate, paymentDate } = filters;

    this.setState({ isLoading: true });

    const beneficiaryValue = beneficiary.currentValue.toLowerCase();
    const [startDate, endDate] = dueDate.currentValue.length > 0
      ? dueDate.currentValue.map((value) => moment(value).format('YYYY-MM-DD'))
      : [];
    const [paymentStartDate, paymentEndDate] = paymentDate && paymentDate.currentValue.length > 0
      ? paymentDate.currentValue.map((value) => moment(value).format('YYYY-MM-DD'))
      : [];

    const query = [
      `beneficiary=${beneficiaryValue}`,
      `status=${status.toLowerCase()}`,
      startDate && endDate && `startDate=${startDate}&endDate=${endDate}`,
      paymentStartDate && paymentEndDate && `paymentStartDate=${paymentStartDate}&paymentEndDate=${paymentEndDate}`,
    ].filter(Boolean).join('&');

    new ForestApi().transactions()
      .getAll(query)
      .then((res) => {
        this.setState({
          isLoading: false,
          transactions: res.data,
        });
      });
  }

  removeTransaction(id, event) {
    const { transactions } = this.state;

    event.stopPropagation();

    this.setState({ isLoading: true });

    const message = `
      Are you sure you wish to remove this transaction?
      This action is irreversible.
    `;

    if (window.confirm(message)) {
      new ForestApi().transactions()
        .delete(id)
        .then(() => {
          toast('Transaction removed!', {
            type: toast.TYPE.SUCCESS,
          });

          this.setState({
            transactions: transactions.filter((t) => t.id !== id),
          });
        });
    }

    this.setState({ isLoading: false });
  }

  generateXMLFile() {
    const { selectedTransactions } = this.state;

    if (selectedTransactions.some(({ Payments }) => Payments.length > 0)) {
      toast('Please select Ready transactions only', {
        type: toast.TYPE.ERROR,
      });
      return;
    }

    this.setState({ isLoading: true });

    const data = {
      ids: selectedTransactions.map(({ id }) => id),
    };

    new ForestApi().transactions()
      .generateXml(data)
      .then((response) => {
        const xml = response.data;
        const fileName = response.headers['content-disposition'];

        const url = window.URL.createObjectURL(new Blob([xml], {
          type: 'text/xml',
        }));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        this.setState({
          selectedTransactions: [],
        });
        this.fetchTransactions();
      })
      .catch((error) => {
        const blob = new Blob([error.response.data], { type: 'application/json' });
        const reader = new FileReader();

        reader.addEventListener('loadend', (e) => {
          e.srcElement.result.split('\n').map((errorMsg) => toast(errorMsg, { type: toast.TYPE.ERROR }));
        });

        reader.readAsText(blob);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  refundTransaction(id, refundType, event) {
    let message;

    event.stopPropagation();

    this.setState({ isLoading: true });

    if (refundType === 'manual-card') {
      message = `
        Do you confirm that this transaction was refunded using the client's credit/debit card ?
        This action is irreversible.
      `;
    } else if (refundType === 'manual-transfer') {
      message = `
        Do you confirm that this transaction was refunded using an XML file ?
        This action is irreversible.
      `;
    }

    if (window.confirm(message)) {
      new ForestApi().transactions()
        .refund(id, { refundType })
        .then(() => {
          toast('Transaction marked as paid.', {
            type: toast.TYPE.SUCCESS,
          });

          this.fetchTransactions();
        });
    }

    this.setState({ isLoading: false });
  }

  bulkRefundTransactions() {
    const { selectedTransactions } = this.state;

    this.setState({ isLoading: true });

    const data = {
      ids: selectedTransactions.map(({ id }) => id),
      refundType: 'manual-transfer',
    };

    new ForestApi().transactions()
      .bulkRefund(data)
      .then(() => {
        toast('Transactions marked as paid.', {
          type: toast.TYPE.SUCCESS,
        });

        this.setState({ selectedTransactions: [] });
        this.fetchTransactions();
      });
  }

  toggleModal(id, event) {
    const { displayModal } = this.state;

    if (event) event.stopPropagation();

    this.setState({
      displayModal: !displayModal,
      currentTransactionId: id,
    });
  }

  toggleRow(id, isSelectable) {
    const { status, transactionsPaidCSV } = this.state;

    const isPaid = status === 'paid';

    if (!isSelectable) { return null; }

    const { transactions, selectedTransactions } = this.state;

    if (selectedTransactions.find((transaction) => transaction.id === id)) {
      const newSelection = selectedTransactions.filter((selected) => selected.id !== id);

      return this.setState({
        selectedTransactions: newSelection,
        transactionsPaidCSV: isPaid ? this.getCSVdata(newSelection) : transactionsPaidCSV,
      });
    }

    const newlySelected = transactions.find((transaction) => transaction.id === id);

    if (newlySelected) {
      const newSelection = [...selectedTransactions, newlySelected];

      return this.setState({
        selectedTransactions: newSelection,
        transactionsPaidCSV: isPaid ? this.getCSVdata(newSelection) : transactionsPaidCSV,
      });
    }

    return null;
  }

  toggleAllRows() {
    const {
      filters,
      selectedTransactions,
      transactions,
      status,
      transactionsPaidCSV,
    } = this.state;

    const isPaid = status === 'paid';

    const { beneficiary } = filters;

    const selectableTransactions = transactions.filter((transaction) => {
      const { Payments, status: paymentStatus } = transaction;

      const isSepa = get(transaction, `${beneficiary.currentValue}.isSepa`, true);
      const beneficiaryBic = checkBic(get(transaction, `${beneficiary.currentValue}.bic`));
      const beneficiaryIban = checkIban(get(transaction, `${beneficiary.currentValue}.iban`));

      const beneficiaryRefundData = [
        beneficiaryBic && !beneficiaryBic.isMalformed,
        beneficiaryIban && !beneficiaryIban.isMalformed,
      ].filter(Boolean);

      if (status === 'unpaid') {
        return (
          paymentStatus === 'pending'
          && Payments.length === 0
          && isSepa !== null
          && beneficiaryRefundData.length === 2
        );
      }

      return true;
    });

    if (selectedTransactions.length === selectableTransactions.length) {
      return this.setState({
        selectedTransactions: [],
      });
    }

    return this.setState({
      selectedTransactions: selectableTransactions,
      transactionsPaidCSV: isPaid ? this.getCSVdata(selectableTransactions) : transactionsPaidCSV,
    });
  }

  handleFilterChange(name, value) {
    const { filters, status } = this.state;
    const dateFields = ['dueDate', 'paymentDate'];

    const refetch = ['beneficiary', ...dateFields].includes(name);

    const newFilters = {
      ...filters,
      [name]: {
        ...filters[name],
        currentValue: value,
      },
    };

    if (status === 'paid' && dateFields.includes(name)) {
      const emptyDateField = dateFields.find((filter) => filter !== name);

      newFilters[emptyDateField].currentValue = [];
    }

    return this.setState({
      filters: newFilters,
    }, refetch ? this.fetchTransactions : null);
  }

  handleSorting(field) {
    const { sorting } = this.state;

    const newDirection = (dir) => (dir === 'DESC' ? 'ASC' : 'DESC');

    if (sorting.field === field) {
      return this.setState({
        sorting: {
          ...sorting,
          direction: newDirection(sorting.direction),
        },
      });
    }

    return this.setState({
      sorting: {
        field,
        direction: 'DESC',
      },
    });
  }

  sortTransactions(transactionA, transactionB) {
    const { sorting, filters } = this.state;
    const { field, direction } = sorting;
    const { beneficiary } = filters;

    let valueOfA = moment(transactionA.createdAt).valueOf();
    let valueOfB = moment(transactionB.createdAt).valueOf();

    if (field === 'beneficiary') {
      valueOfA = get(transactionA, `${beneficiary.currentValue}.fullName`, '');
      valueOfB = get(transactionB, `${beneficiary.currentValue}.fullName`, '');

      if (direction === 'ASC') {
        return valueOfA.localeCompare(valueOfB);
      }

      return valueOfB.localeCompare(valueOfA);
    }

    if (field === 'dueDate') {
      valueOfA = moment(transactionA.dueDate).valueOf();
      valueOfB = moment(transactionB.dueDate).valueOf();
    }

    if (direction === 'ASC') {
      return valueOfA - valueOfB;
    }

    return valueOfB - valueOfA;
  }

  parseTransactionRow(transaction) {
    const { filters, selectedTransactions, status } = this.state;
    const { beneficiary } = filters;
    const {
      amount,
      createdAt,
      dueDate,
      id,
      label,
      Payments,
      status: paymentStatus,
    } = transaction;

    const isUnpaid = paymentStatus === 'pending' && Payments.length === 0;
    const isRunning = paymentStatus === 'pending' && Payments.length > 0;

    const fullName = get(transaction, `${beneficiary.currentValue}.fullName`, 'Undefined');
    const isSepa = get(transaction, `${beneficiary.currentValue}.isSepa`, true);
    const forestOrderUrl = buildForestLink(id, 'order');
    const isSelected = selectedTransactions.find((selected) => selected.id === id) || false;

    const beneficiaryBic = checkBic(get(transaction, `${beneficiary.currentValue}.bic`));
    const beneficiaryIban = checkIban(get(transaction, `${beneficiary.currentValue}.iban`));

    const beneficiaryRefundData = [
      beneficiaryBic && !beneficiaryBic.isMalformed,
      beneficiaryIban && !beneficiaryIban.isMalformed,
    ].filter(Boolean);

    const isRefundable = isUnpaid
      && isSepa !== null
      && (
        beneficiaryRefundData.length === 2 || !isSepa
      );

    const isSelectable = (isRefundable && isSepa) || (status !== 'unpaid');

    const className = [
      isSelected ? 'selected' : '',
      isSelectable ? 'selectable' : '',
    ].join(' ');

    const refundStatus = isRefundable ? 'success' : 'danger';

    return (
      <tr key={ id } className={ className } onClick={ () => this.toggleRow(id, isSelectable) }>
        <td>
          <FormCheck
            checked={ isSelected }
            onChange={ () => this.toggleRow(id, isSelectable) }
            disabled={ !isSelectable }
          />
        </td>
        <td>
          {
            isUnpaid
              ? (
                <i className={ `
                  icon fas fa-${refundStatus === 'success' ? 'check' : 'times'}-circle
                  text-${refundStatus}
                ` }
                />
              )
              : null
          }
          { truncate(label, { length: 25 }) }
        </td>
        <td>{ moment(createdAt).format('DD/MM/YYYY') }</td>
        <td>{ truncate(fullName, { length: 25 }) }</td>
        <td>
          { (amount / 100).toFixed(2) }
          {' '}
          €
        </td>
        <td>{ moment(dueDate).format('DD/MM/YYYY') }</td>
        <td className="text-right">
          {
            isUnpaid && isSepa === false
              ? (
                <Button
                  variant="link"
                  title="Refunded via card"
                  onClick={ (event) => this.refundTransaction(id, 'manual-card', event) }
                >
                  <i className="far fa-credit-card" />
                </Button>
              )
              : null
          }
          {
            isRunning
              ? (
                <Button
                  variant="link"
                  title="Mark as paid"
                  onClick={ (event) => this.refundTransaction(id, 'manual-transfer', event) }
                >
                  <i className="fas fa-check text-dark" />
                </Button>
              )
              : null
          }
          <Button variant="link" title="Show transaction" onClick={ (event) => this.toggleModal(id, event) }>
            <i className="fas fa-eye text-dark" />
          </Button>
          {
            isUnpaid
              ? (
                <Button
                  variant="link"
                  title="Remove transaction"
                  onClick={ (event) => this.removeTransaction(id, event) }
                >
                  <i className="fas fa-trash text-danger" />
                </Button>
              )
              : null
          }
          <a
            href={ forestOrderUrl }
            target="_blank"
            rel="noopener noreferrer"
            className="ml-2"
            onClick={ (e) => e.stopPropagation() }
          >
            <i className="fas fa-external-link-alt" />
          </a>
        </td>
      </tr>
    );
  }

  renderPlaceholder() {
    return (
      <Row>
        <Col>
          <Jumbotron className="text-center">
            <h1>No transactions</h1>
            <p>
              No transactions found matching these filters.
            </p>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  renderActionsButtons(status) {
    const { userRole, userTeam } = this.props;
    const {
      selectedTransactions,
      transactionsPaidCSV,
    } = this.state;

    const isUserAuthorized = userRole === 'admin' && ['tech', 'accounting', 'customer-happiness'].includes(userTeam);
    const disabledActionButton = selectedTransactions.length === 0;

    const timeStamp = moment().format('YYYYMMDDHHmm');

    if (status === 'unpaid') {
      return (
        <Button
          variant="link"
          onClick={ () => this.generateXMLFile() }
          disabled={ !isUserAuthorized || disabledActionButton }
        >
          <i className="icon far fa-file-excel" />
          Generate XML
          <Badge variant="primary" className="ml-2" pill>
            { selectedTransactions.length }
          </Badge>
        </Button>
      );
    }

    if (status === 'running') {
      return (
        <Button
          variant="link"
          onClick={ () => this.bulkRefundTransactions() }
          disabled={ disabledActionButton }
        >
          <i className="icon fas fa-check" />
          Mark as paid
          <Badge variant="primary" className="ml-2" pill>
            { selectedTransactions.length }
          </Badge>
        </Button>
      );
    }

    return (
      <Button
        variant="link"
        disabled={ disabledActionButton }
      >
        <CSVLink
          className={ `text-${disabledActionButton ? 'muted' : 'primary'}` }
          data={ transactionsPaidCSV.data }
          headers={ transactionsPaidCSV.headers }
          filename={ `transactions-${timeStamp}.csv` }
        >
          <i className="icon far fa-file-excel" />
          Generate CSV
        </CSVLink>
        <Badge variant="primary" className="ml-2" pill>
          { selectedTransactions.length }
        </Badge>
      </Button>
    );
  }

  render() {
    const { userRole, userTeam } = this.props;
    const {
      isLoading,
      filters,
      headers,
      sorting,
      transactions,
      selectedTransactions,
      currentTransactionId,
      displayModal,
      status,
    } = this.state;

    const tableHeader = Object.keys(headers)
      .sort((h1, h2) => headers[h1].order - headers[h2].order)
      .map((header) => {
        let icon;

        if (sorting.field === header) {
          icon = sorting.direction === 'DESC'
            ? <i className="fas fa-sort-down" />
            : <i className="fas fa-sort-up" />;
        } else {
          icon = <i className="fas fa-sort" />;
        }

        return (
          <th key={ header }>
            {
            headers[header].sortable
              ? (
                <div
                  className={ `sortable ${sorting.field === header ? 'text-info' : ''}` }
                  onClick={ () => this.handleSorting(header) }
                >
                  { startCase(header) }
                  { icon }
                </div>
              )
              : startCase(header)
          }
          </th>
        );
      });

    const filteredTransactions = transactions
      .filter((transaction) => {
        if (status === 'unpaid') {
          return transaction.Payments.length === 0;
        }

        return transaction;
      })
      .filter((transaction) => {
        const { search, beneficiary } = filters;

        const regex = new RegExp(search.currentValue, 'i');
        const fullName = get(transaction, `${beneficiary.currentValue}.fullName`, 'Undefined');

        return regex.test(fullName);
      })
      .sort((a, b) => this.sortTransactions(a, b))
      .map((transaction) => this.parseTransactionRow(transaction));

    return (
      <div className="Transactions">
        <div className="d-flex justify-content-between flex-wrap
        flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom"
        >
          <Link to="/" className="align-self-start">
            <i className="icon fas fa-arrow-circle-left" />
            Back to home
          </Link>
          <h3>Transactions</h3>
          <div className="d-flex flex-column justify-content-between align-items-end">
            <ul className="nav nav-pills">
              <li className="nav-item">
                <Button
                  variant="link"
                  className={ `nav-link ${status === 'unpaid' ? 'active' : ''}` }
                  onClick={ () => this.setStatus('unpaid') }
                >
                  <i className="icon fas fa-times" />
                  Unpaid
                </Button>
              </li>
              <li className="nav-item">
                <Button
                  variant="link"
                  className={ `nav-link ${status === 'running' ? 'active' : ''}` }
                  onClick={ () => this.setStatus('running') }
                >
                  <i className="icon far fa-clock" />
                  Running
                </Button>
              </li>
              <li className="nav-item">
                <Button
                  variant="link"
                  className={ `nav-link ${status === 'paid' ? 'active' : ''}` }
                  onClick={ () => this.setStatus('paid') }
                >
                  <i className="icon fas fa-check" />
                  Paid
                </Button>
              </li>
            </ul>
            <div className="actions mt-2">
              { this.renderActionsButtons(status) }
            </div>
          </div>
        </div>
        <Dashboard
          model="transaction"
          filters={ filters }
          tableHeader={ tableHeader }
          items={ filteredTransactions }
          layout="table"
          toggleAllRows={ () => this.toggleAllRows() }
          numberOfSelectedRows={ () => selectedTransactions.length }
          handleFilterChange={ (name, value) => this.handleFilterChange(name, value) }
          renderPlaceholder={ () => this.renderPlaceholder() }
          isLoading={ isLoading }
        />
        {
          displayModal
            ? (
              <TransactionModal
                show={ displayModal }
                transactionId={ currentTransactionId }
                status={ status }
                onHide={ () => this.toggleModal(null) }
                userRole={ userRole }
                userTeam={ userTeam }
              />
            )
            : null
        }
      </div>
    );
  }
}

export default Transactions;
