import Button from 'react-bootstrap/Button';
import React from 'react';

const GoBackDashboard = ({
  history,
  defaultUrl,
}) => (
  <Button
    variant="link"
    onClick={
      () => {
        const match = /from=([^&]*)/g.exec(window.location.search);
        if (match) {
          history.push(decodeURIComponent(match[1]));
        } else {
          history.push(defaultUrl);
        }
      }
    }
  >
    <i className="icon fas fa-arrow-circle-left" />
    Back to dashboard
  </Button>
);

export default GoBackDashboard;
