import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ isAuthenticated, component: Component, ...rest }) {
  return (
    <Route
      { ...rest }
      render={ (props) => (isAuthenticated()
        ? (<Component { ...props } />)
        : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)) }
    />
  );
}

export default PrivateRoute;
