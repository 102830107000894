import { getEmojiFlag } from 'countries-list';

export default (lang) => {
  const mapLangsToCountry = {
    en: 'GB',
    es: 'ES',
    fr: 'FR',
  };

  return getEmojiFlag(mapLangsToCountry[lang]);
};
