import React, { useState } from 'react';
import capitalize from 'lodash/capitalize';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../utils/API';
import './style.scss';

function Rooms(props) {
  const { history } = props;
  const [rooms, setRooms] = useState([]);

  function handleSearch(name) {
    if (name.trim().length < 3) {
      return setRooms([]);
    }

    const data = `name=${name.trim()}`;

    return new ForestApi().rooms()
      .search(data)
      .then((res) => setRooms(res.data));
  }

  function showDashboard() {
    return history.push('/rooms/dashboard');
  }

  function showRoom(id) {
    return history.push(`/rooms/${id}`);
  }

  function renderSearchResults() {
    return (
      <ul className="search-results">
        {
          rooms.map(({
            Apartment, id, name, status,
          }) => (
            <li
              key={ id }
              className="result"
              onClick={ () => showRoom(id) }
            >
              <Row>
                <Col>
                  <span className={ `mr-2 color-${status}` }>
                    <i className="fas fa-circle fa-xs" />
                  </span>
                  <span>{ name }</span>
                </Col>
                <Col>
                  <span className={ `color-${Apartment.CityId}` }>
                    { capitalize(Apartment.CityId) }
                  </span>
                </Col>
                <Col lg="2" className="details text-right">
                  <i className="fas fa-arrow-right" />
                </Col>
              </Row>
            </li>
          ))
        }
      </ul>
    );
  }

  return (
    <div className="Rooms">
      <div className="d-flex justify-content-between flex-wrap
      flex-md-nowrap align-items-center pt-3 pb-2 border-bottom"
      >
        <h3>Rooms</h3>
      </div>
      <Row className="pt-4 mt-4 pb-4 justify-content-center">
        <Col lg="6">
          <div className="search-bar">
            <div className="search-field">
              <div className="search-icon">
                <i className="fas fa-bed" />
              </div>
              <input
                type="text"
                placeholder="Search by room name..."
                onChange={ (e) => handleSearch(e.target.value) }
              />
            </div>
            {
              rooms.length > 0
                ? renderSearchResults()
                : null
            }
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg="6" className="d-flex align-items-center justify-content-between">
          <div className="w-100 p-2"><hr /></div>
          <h3 className="p-2">OR</h3>
          <div className="w-100 p-2"><hr /></div>
        </Col>
      </Row>
      <Row className="mt-4 justify-content-center">
        <Col lg="6">
          <div className="dashboard-btn" onClick={ () => showDashboard() }>
            <div className="text-container">
              <h1>Go to dashboard</h1>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Rooms;
