import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import EditableField from '../EditableField';

const BEDS_KINDS = [
  'double',
  'simple',
  'sofa',
  'double+sofa',
  'simple+sofa',
  'simple+simple',
];

function NewRoom(props) {
  const { show, handleClose, handleSubmit } = props;
  const [fields, setValue] = useState({
    roomNumber: 0,
    basePrice: 0,
    floorArea: 0,
    beds: '',
    twoOccupantsAllowed: false,
  });

  const defaultEditableFieldProps = {
    editFieldValue: (name, value) => setValue({ ...fields, [name]: value }),
    editMode: true,
    fieldsToUpdate: fields,
  };

  const filledFields = Object.keys(fields)
    .filter((key) => key !== 'twoOccupantsAllowed')
    .map((key) => fields[key])
    .filter(Boolean);

  return (
    <Modal size="lg" className="NewRoom" show={ show } onHide={ handleClose }>
      <Modal.Header closeButton>
        <Modal.Title>New room</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <tbody>
            <tr>
              <th>Room number *</th>
              <td>
                <EditableField
                  { ...defaultEditableFieldProps }
                  inputType="number"
                  name="roomNumber"
                  value={ fields.roomNumber }
                />
              </td>
            </tr>
            <tr>
              <th>Base price *</th>
              <td>
                <EditableField
                  { ...defaultEditableFieldProps }
                  inputType="number"
                  name="basePrice"
                  value={ fields.basePrice }
                />
              </td>
            </tr>
            <tr>
              <th>Floor area *</th>
              <td>
                <EditableField
                  { ...defaultEditableFieldProps }
                  inputType="number"
                  name="floorArea"
                  value={ fields.floorArea }
                />
              </td>
            </tr>
            <tr>
              <th>Bed(s) kind *</th>
              <td>
                <EditableField
                  { ...defaultEditableFieldProps }
                  inputType="select"
                  name="beds"
                  options={ BEDS_KINDS }
                  value={ fields.beds }
                />
              </td>
            </tr>
            <tr>
              <th>Two occupants allowed ? *</th>
              <td>
                <EditableField
                  { ...defaultEditableFieldProps }
                  inputType="radio"
                  name="twoOccupantsAllowed"
                  options={{ no: 'false', yes: 'true' }}
                  label={ fields.twoOccupantsAllowed ? 'Yes' : 'No' }
                  value={ `${fields.twoOccupantsAllowed}` }
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ handleClose }>
          Close
        </Button>
        <Button variant="primary" disabled={ filledFields.length !== 4 } onClick={ () => handleSubmit(fields) }>
          <span className="icon fa fa-check" />
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewRoom;
