import ForestApi from './API';

export default (documentId, isDownload = false) => new ForestApi().documents()
  .getOne(documentId)
  .then((res) => {
    const blobUrl = URL.createObjectURL(res.data);

    const link = document.createElement('a');

    link.href = blobUrl;
    link.target = '_blank';

    if (isDownload) {
      const fileName = res.headers['content-disposition'];

      link.download = fileName;
    }

    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  });
