import React from 'react';
import upperFirst from 'lodash/upperFirst';

import Button from 'react-bootstrap/Button';

const PAYMENT_KINDS = [
  'bank transfer',
  'direct debit',
];

const LEASE_KINDS = [
  'Loi 89',
  'Droit Civil',
];

const INCLUDED_CHARGES = [
  'cold water',
  'condominium fees',
  'hot water',
  'heating',
  'garbage collection tax',
  'boiler maintenance',
];

export const BED_KINDS = [
  'simple',
  'double',
  'queen',
];

export const GLOBAL_APARTMENT_FEATURES = {
  general: [
    'wifi',
    'washingMachine',
    'iron',
    'ironingBoard',
    'dryingRack',
    'vacuumCleaner',
    'mopAndBucket',
    'scrubBrush',
    'storage',
    'hangers',
    'sofa',
    'sofaBed',
    'armchair',
    'coffeeTable',
    'commonAreasArea',
    'commonAreasCharm',
    'livingRoom',
    'cupboard',
    'dressing',
    'laundryRoom',
  ],
  kitchen: [
    'oven',
    'microwave',
    'fridge',
    'kettle',
    'cookingPots',
    'pan',
    'utensils',
    'largePot',
    'cutlery',
    'glasses',
    'mugs',
    'bowls',
    'saladBowl',
    'colander',
    'glassBottle',
    'scissors',
    'corkscrew',
    'storage',
    'dishwasher',
    'hotPlates',
    'plates',
    'cuttingBoards',
    'diningArea',
    'diningAreaWindow',
    'stool',
    'kitchenTable',
  ],
  bathroom: [
    'bathtub',
    'shower',
    'storage',
    'bathMat',
    'mirror',
    'sink',
    'commonBathroom',
    'commonToilets',
  ],
  external: [
    'balcony',
    'terrace',
    'garden',
    'terraceFurniture',
    'caretaker',
    'bikeShelter',
    'garbageRoom',
    'cellar',
  ],
};

export const GLOBAL_ROOM_FEATURES = {
  general: [
    'sofaBed',
    'armchair',
    'coffeeTable',
    'lockedRoom',
    'privateBathroom',
    'privateToilet',
    'privateSink',
    'loggia',
    'balcony',
    'terrace',
    'garden',
    'terraceFurniture',
    'sofa',
    'view',
    'noise',
    'heating',
    'underSlopedRoofArea',
    'charm',
    'decoration',
    'brightness',
  ],
  dress: [
    'storage',
    'hangers',
    'mirror',
    'walkinWardrobe',
  ],
  work: [
    'deskAndChair',
    'lamp',
    'bookCase',
    'multiSocket',
  ],
  sleep: [
    'duvetAndPillow',
    'bedsideTable',
    'bedsideLamp',
    'curtains',
    'shutters',
    'veilCurtains',
  ],
  bathroom: [
    'sink',
    'toilet',
  ],
};

export const SPECIAL_APARTMENT_OPTIONS = {
  balcony: [
    { nonexistent: 'nonexistent' },
    { small: 'less than 5m²' },
    { big: 'more than 5m²' },
  ],
  terrace: [
    { nonexistent: 'nonexistent' },
    { small: 'less than 10m²' },
    { medium: 'between 10m² and 15 m²' },
    { big: 'more than 15m²' },
  ],
  garden: [
    { nonexistent: 'nonexistent' },
    { small: 'less than 30m²' },
    { big: 'more than 30m²' },
  ],
};

export const SPECIAL_ROOM_OPTIONS = {
  balcony: [
    { nonexistent: 'nonexistent' },
    { common: 'access to a common balcony' },
    { 'private-small': 'private - less than 5m²' },
    { 'private-big': 'private - more than 5m²' },
  ],
  terrace: [
    { nonexistent: 'nonexistent' },
    { common: 'access to a common terrace' },
    { 'private-small': 'private - less than 10m²' },
    { 'private-medium': 'private - between 10m² and 15 m²' },
    { 'private-big': 'private - more than 15m²' },
  ],
};

const SPECIAL_FEATURE_NAME = {
  commonAreasArea: 'common areas superficy',
  garden: 'garden access',
};

const BOOLEAN_OPTIONS = { yes: 'true', no: 'false' };

export const APARTMENT_FEATURES_FIELDS = Object.keys(GLOBAL_APARTMENT_FEATURES)
  .reduce((fields, category) => {
    const categoryFields = GLOBAL_APARTMENT_FEATURES[category].map((field) => (
      `apartment-features-${category}-${field}`.replace('external', 'general')
    ));

    return [
      ...fields,
      ...categoryFields,
    ];
  }, []);

export const ROOM_FEATURES_FIELDS = Object.keys(GLOBAL_ROOM_FEATURES)
  .reduce((fields, category) => {
    const categoryFields = GLOBAL_ROOM_FEATURES[category].map((field) => (
      `room-features-${category}-${field}`
    ));

    return [
      ...fields,
      ...categoryFields,
    ];
  }, []);

export const dynamicFields = {
  'garbage-room-access': 'apartment-features-general-garbageRoom',
  'bike-shelter-access': 'apartment-features-general-bikeShelter',
  mailDistribution: 'hasLetterBox',
  cellarAccess: 'apartment-features-general-cellar',
  hotWaterHeaterBrand: 'hasHotWaterHeater',
  gasMeterKind: 'hasGas',
  gasSupplier: 'hasGas',
  gasPricingOption: 'hasGas',
  gasContractStartDate: 'hasGas',
  gasMeterSerialNumber: 'hasGas',
  'gas-meter-location': 'hasGas',
  'hot-water-meter-location': 'hasHotWaterHeater',
  hotWaterMeterSerialNumber: 'waterMeterKind',
  internetCplPassword: 'internetHasCpl',
  internetCplBrand: 'internetHasCpl',
};

export const apartmentModel = {
  immo: {
    location: [
      'reference',
      'name',
      'addressStreet',
      'DistrictId',
      'cityZone',
    ],
    layout: [
      'initialTopology',
      'roomCount',
      'bedroomCount',
      'floorArea',
      'floor',
      'isPremium',
    ],
    lease: [
      'mainSignatory',
      'leaseStartDate',
      'leaseDuration',
      'leaseKind',
    ],
    initialExpenses: [
      'agencyFees',
      'deposit',
      'rentDiscount',
      'commissionPayment',
      'firstMonthRent',
      'estimatedWorkCost',
      'realEstateSimulatorLink',
      'dismantlingWorkCost',
    ],
    recurringExpenses: [
      'rentExcludingCharges',
      'rentCharges',
      'includedCharges',
      'paymentKind',
      'paymentDay',
    ],
    contacts: [
      'mainContact',
    ],
  },
  dandq: {
    apartment: [
      'furnishingCost',
      'realWorkCost',
      'knowledgeBaseUrl',
      'virtualVisitUrl',
      'apartment-access',
    ],
    garbageRoom: [
      'apartment-features-general-garbageRoom',
      'garbage-room-access',
    ],
    bikeShelter: [
      'apartment-features-general-bikeShelter',
      'bike-shelter-access',
    ],
    letterBox: [
      'hasLetterBox',
      'mailDistribution',
    ],
    cellar: [
      'apartment-features-general-cellar',
      'cellarAccess',
    ],
    electricity: [
      'elecMeterKind',
      'elecPower',
      'elecSupplier',
      'elecPricingOption',
      'elecContractStartDate',
      'elecMeterSerialNumber',
      'switchboardKind',
      'electricity-meter-location',
    ],
    gas: [
      'hasGas',
      'gasMeterKind',
      'gasSupplier',
      'gasPricingOption',
      'gasContractStartDate',
      'gasMeterSerialNumber',
      'gas-meter-location',
    ],
    heating: [
      'heatingKind',
      'boilerContractStartDate',
      'boilerSupplier',
      'boilerBrand',
    ],
    hotWaterHeater: [
      'hasHotWaterHeater',
      'hotWaterHeaterBrand',
      'hot-water-meter-location',
    ],
    water: [
      'waterMeterKind',
      'waterMeterSerialNumber',
      'waterCompanyLogin',
      'waterCompanyPassword',
      'water-meter-location',
      'hotWaterMeterSerialNumber',
    ],
    internet: [
      'internetKind',
      'internetLineNumber',
      'internetPassword',
      'internetHasCpl',
      'internet-modem-location',
      'internetCplPassword',
      'internetCplBrand',
    ],
    contacts: [
      'mainContact',
    ],
  },
  sales: {
    yield: [
      'yieldCategory',
    ],
    features: [
      'featuresCompleted',
    ],
    pictures: [
      'hasPictures',
    ],
  },
};

export function IMMO(apartment) {
  const {
    name,
    reference,
    addressStreet,
    addressZip,
    addressCity,
    addressCountry,
    District: {label},
    bedroomCount,
  } = apartment;

  const fullAdress = `${addressStreet}, ${addressZip} ${addressCity} - ${upperFirst(addressCountry)}`;

  return {
    location: [{
      defaultName: 'Reference',
      toReturn: reference,
    }, {
      defaultName: 'Name',
      toReturn: name,
    }, {
      defaultName: 'Address',
      toReturn: (
        <>
          <i className="icon fas fa-map-marker-alt" />
          {fullAdress}
        </>
      ),
    }, {
      defaultName: 'Neighborhood',
      toReturn: (
        <h5 className="m-0">
          <Button variant="dark" size="sm" className="btn-label" disabled>
            {label}
          </Button>
        </h5>
      ),
    }, {
      defaultName: 'Zone',
      name: 'cityZone',
    }],
    layout: [{
      defaultName: 'Initial topology',
      name: 'initialTopology',
    }, {
      defaultName: 'Rooms',
      inputType: 'number',
      name: 'roomCount',
    }, {
      defaultName: 'Bedrooms',
      toReturn: bedroomCount,
    }, {
      defaultName: 'Area',
      additionalText: 'm²',
      inputType: 'number',
      name: 'floorArea',
    }, {
      defaultName: 'Floor',
      inputType: 'number',
      name: 'floor',
    }, {
      defaultName: 'Category',
      inputType: 'radio',
      name: 'isPremium',
      options: { standard: 'false', premium: 'true' },
      value: `${apartment.isPremium}`,
      displayAsTag: apartment.isPremium ? 'primary' : 'dark',
    }],
    lease: [{
      defaultName: 'Main signatory',
      name: 'mainSignatory',
    }, {
      defaultName: 'Start date',
      inputType: 'date',
      name: 'leaseStartDate',
    }, {
      defaultName: 'Duration',
      inputType: 'number',
      name: 'leaseDuration',
    }, {
      defaultName: 'Kind',
      inputType: 'select',
      name: 'leaseKind',
      options: LEASE_KINDS,
    }, {
      defaultName: 'Notes',
      inputType: 'textarea',
      name: 'notes',
    }],
    initialExpenses: [{
      defaultName: 'Agency fees',
      inputType: 'number',
      name: 'agencyFees',
      isMoney: true,
    }, {
      defaultName: 'Deposit',
      inputType: 'number',
      name: 'deposit',
      isMoney: true,
    }, {
      defaultName: 'Rent discount',
      inputType: 'number',
      name: 'rentDiscount',
      isMoney: true,
    }, {
      defaultName: 'Commission payment',
      inputType: 'number',
      name: 'commissionPayment',
      isMoney: true,
    }, {
      defaultName: 'First month rent',
      inputType: 'number',
      name: 'firstMonthRent',
      isMoney: true,
    }, {
      defaultName: 'Estimated work cost',
      inputType: 'number',
      name: 'estimatedWorkCost',
      isMoney: true,
    }, {
      defaultName: 'Immo simulator link',
      name: 'realEstateSimulatorLink',
      isLink: true,
    }, {
      defaultName: 'Provision for dismantling work',
      inputType: 'number',
      name: 'dismantlingWorkCost',
      isMoney: true,
    }],
    recurringExpenses: [{
      defaultName: 'Rent excluding charges',
      inputType: 'number',
      name: 'rentExcludingCharges',
      isMoney: true,
    }, {
      defaultName: 'Charges',
      inputType: 'number',
      name: 'rentCharges',
      isMoney: true,
    }, {
      defaultName: 'Included charges',
      inputType: 'flags',
      name: 'includedCharges',
      options: INCLUDED_CHARGES,
    }, {
      defaultName: 'Payment kind',
      inputType: 'select',
      name: 'paymentKind',
      options: PAYMENT_KINDS,
    }, {
      defaultName: 'Day of payment',
      inputType: 'number',
      name: 'paymentDay',
    }],
    contacts: [{
      defaultName: 'Contacts',
    }],
  };
}

export function DANDQ(apartment) {
  const { estimatedWorkCost } = apartment;
  const { features: roomFeatures } = apartment.Rooms[0];

  const WORK_COST_DIFFERENCE = [
    'contingencies on the site',
    'contractors prices',
    'nonidentified problems during the visit',
    'delivery delay',
    'incorrect cost estimation',
    'other',
  ];
  const ELECTRIC_METER_OPTIONS = ['linky', 'old'];
  const ELEC_PRICING_OPTIONS = ['CU', 'MUDT'];
  const HEATING_OPTIONS = ['collective', 'individual electric', 'individual boiler'];
  const SWITCHBOARD_OPTIONS = ['fusible', 'breaker'];
  const WATER_METER_OPTIONS = ['cold', 'hot and cold'];
  const GAS_METER_OPTIONS = ['connected', 'old'];
  const GAS_PRICING_OPTIONS = ['T1', 'T2'];
  const INTERNET_KIND = ['fiber optic', 'vdsl'];
  const MAIL_DISTRIBUTION_KIND = ['postman', 'caretaker'];

  const { options: VIEW_OPTIONS } = roomFeatures.find(({ label }) => label === 'view') || {};
  const { options: NOISE_OPTIONS } = roomFeatures.find(({ label }) => label === 'noise') || {};
  const { options: ROOM_HEATING_OPTIONS } = roomFeatures.find(({ label }) => label === 'heating') || {};

  const apartmentHasBoiler = apartment.heatingKind === 'individual boiler';

  return {
    apartment: [{
      defaultName: 'Cost of furnishing',
      inputType: 'number',
      name: 'furnishingCost',
      isMoney: true,
    }, {
      defaultName: 'Estimated work cost',
      toReturn: `${(estimatedWorkCost / 100).toFixed(2)} €`,
    }, {
      defaultName: 'Real work cost',
      inputType: 'number',
      name: 'realWorkCost',
      isMoney: true,
    }, {
      defaultName: 'Estimated vs real work cost difference',
      inputType: 'flags',
      name: 'workCostDifference',
      options: WORK_COST_DIFFERENCE,
    }, {
      defaultName: 'KB Link',
      name: 'knowledgeBaseUrl',
      isLink: true,
    }, {
      defaultName: 'Virtual visit',
      name: 'virtualVisitUrl',
      isLink: true,
    }, {
      name: 'apartment-access',
    }],
    garbageRoom: [{
      defaultName: 'Presence',
      inputType: 'radio',
      name: 'apartment-features-general-garbageRoom',
      options: BOOLEAN_OPTIONS,
    }, {
      name: 'garbage-room-access',
    }],
    letterBox: [{
      defaultName: 'Presence',
      inputType: 'radio',
      name: 'hasLetterBox',
      options: BOOLEAN_OPTIONS,
    }, {
      defaultName: 'Distribution kind',
      inputType: 'select',
      name: 'mailDistribution',
      options: MAIL_DISTRIBUTION_KIND,
    }],
    bikeShelter: [{
      defaultName: 'Presence',
      inputType: 'radio',
      name: 'apartment-features-general-bikeShelter',
      options: BOOLEAN_OPTIONS,
    }, {
      name: 'bike-shelter-access',
    }],
    cellar: [{
      defaultName: 'Presence',
      inputType: 'radio',
      name: 'apartment-features-general-cellar',
      options: BOOLEAN_OPTIONS,
    }, {
      defaultName: 'Location',
      inputType: 'textarea',
      name: 'cellarAccess',
    }],
    electricity: [{
      defaultName: 'Meter kind',
      inputType: 'select',
      name: 'elecMeterKind',
      options: ELECTRIC_METER_OPTIONS,
    }, {
      defaultName: 'Serial number',
      name: 'elecMeterSerialNumber',
    }, {
      defaultName: 'Power',
      inputType: 'number',
      name: 'elecPower',
    }, {
      defaultName: 'Supplier',
      name: 'elecSupplier',
    }, {
      defaultName: 'Pricing option',
      inputType: 'select',
      name: 'elecPricingOption',
      options: ELEC_PRICING_OPTIONS,
    }, {
      defaultName: 'Contract start',
      inputType: 'date',
      name: 'elecContractStartDate',
    }, {
      defaultName: 'Switchboard',
      inputType: 'select',
      name: 'switchboardKind',
      options: SWITCHBOARD_OPTIONS,
    }, {
      name: 'electricity-meter-location',
    }],
    gas: [{
      defaultName: 'Presence',
      inputType: 'radio',
      name: 'hasGas',
      options: BOOLEAN_OPTIONS,
    }, {
      defaultName: 'Meter kind',
      inputType: 'select',
      name: 'gasMeterKind',
      options: GAS_METER_OPTIONS,
    }, {
      defaultName: 'Supplier',
      name: 'gasSupplier',
    }, {
      defaultName: 'Pricing option',
      inputType: 'select',
      name: 'gasPricingOption',
      options: GAS_PRICING_OPTIONS,
    }, {
      defaultName: 'Contract start date',
      inputType: 'date',
      name: 'gasContractStartDate',
    }, {
      defaultName: 'Serial number',
      name: 'gasMeterSerialNumber',
    }, {
      name: 'gas-meter-location',
    }],
    heating: [{
      defaultName: 'Kind',
      inputType: 'select',
      name: 'heatingKind',
      options: HEATING_OPTIONS,
    }, apartmentHasBoiler && {
      defaultName: 'Boiler supplier',
      name: 'boilerSupplier',
    }, apartmentHasBoiler && {
      defaultName: 'Boiler contract start',
      inputType: 'date',
      name: 'boilerContractStartDate',
    }, apartmentHasBoiler && {
      defaultName: 'Boiler brand',
      name: 'boilerBrand',
    }].filter(Boolean),
    hotWaterHeater: [{
      defaultName: 'Presence',
      inputType: 'radio',
      name: 'hasHotWaterHeater',
      options: BOOLEAN_OPTIONS,
    }, {
      defaultName: 'Brand',
      name: 'hotWaterHeaterBrand',
    }, {
      name: 'hot-water-meter-location',
    }],
    water: [{
      defaultName: 'Meter kind',
      inputType: 'select',
      name: 'waterMeterKind',
      options: WATER_METER_OPTIONS,
    }, {
      defaultName: 'Serial number (cold)',
      name: 'waterMeterSerialNumber',
    }, {
      defaultName: 'Serial number (hot)',
      name: 'hotWaterMeterSerialNumber',
    }, {
      defaultName: 'Login',
      name: 'waterCompanyLogin',
    }, {
      defaultName: 'Password',
      name: 'waterCompanyPassword',
    }, {
      name: 'water-meter-location',
    }],
    internet: [{
      defaultName: 'Line number',
      name: 'internetLineNumber',
    }, {
      defaultName: 'Password',
      name: 'internetPassword',
    }, {
      defaultName: 'CPL',
      inputType: 'radio',
      name: 'internetHasCpl',
      options: BOOLEAN_OPTIONS,
    }, {
      defaultName: 'CPL password',
      name: 'internetCplPassword',
    }, {
      defaultName: 'CPL brand',
      name: 'internetCplBrand',
    }, {
      defaultName: 'Kind',
      inputType: 'select',
      name: 'internetKind',
      options: INTERNET_KIND,
    }, {
      name: 'internet-modem-location',
    }],
    contacts: [{
      defaultName: 'Contacts',
    }],
    room: [{
      defaultName: 'Virtual visit',
      name: 'virtualVisitUrl',
      isLink: true,
    }, {
      defaultName: 'View',
      inputType: 'select',
      name: 'room-features-general-view',
      options: VIEW_OPTIONS,
      displayAsTag: 'dark',
    }, {
      defaultName: 'Noise',
      inputType: 'select',
      name: 'room-features-general-noise',
      options: NOISE_OPTIONS,
      displayAsTag: 'dark',
    }, {
      defaultName: 'Heating',
      inputType: 'select',
      name: 'room-features-general-heating',
      options: ROOM_HEATING_OPTIONS,
    }],
  };
}

export function SALES(apartment) {
  const { features } = apartment;
  const [room] = apartment.Rooms;
  const { features: roomFeatures } = room;

  const underSlopedRoofFeature = roomFeatures.find(({ field }) => (
    field.includes('underSlopedRoofArea')
  ));

  const mapInputTypes = {
    Boolean: 'radio',
    Number: 'number',
    Enum: 'select',
  };

  const mapApartmentFeaturesField = (category) => {
    const featuresCategory = `apartment-features-${category}`.replace('external', 'general');

    return GLOBAL_APARTMENT_FEATURES[category].map((field) => {
      const name = `${featuresCategory}-${field}`;

      const {
        label: defaultName,
        options = BOOLEAN_OPTIONS,
        type,
      } = features.find(({ field: featureField }) => featureField === name) || {};

      return {
        defaultName: SPECIAL_FEATURE_NAME[field] || defaultName,
        inputType: mapInputTypes[type],
        name,
        options: SPECIAL_APARTMENT_OPTIONS[field] || options,
      };
    });
  };

  const mapRoomFeaturesField = (category) => {
    const featuresCategory = `room-features-${category}`;

    return GLOBAL_ROOM_FEATURES[category].map((field) => {
      const name = `${featuresCategory}-${field}`;

      const {
        label: defaultName,
        options = BOOLEAN_OPTIONS,
        type,
      } = roomFeatures.find(({ field: featureField }) => featureField === name) || {};

      return {
        defaultName: SPECIAL_FEATURE_NAME[field] || defaultName,
        inputType: mapInputTypes[type],
        name,
        options: SPECIAL_ROOM_OPTIONS[field] || options,
      };
    });
  };

  return {
    yield: [{
      defaultName: 'Yield category',
      inputType: 'select',
      name: 'yieldCategory',
      options: ['1', '2', '3'],
    }],
    generalFeatures: mapApartmentFeaturesField('general'),
    kitchenFeatures: mapApartmentFeaturesField('kitchen'),
    bathroomFeatures: mapApartmentFeaturesField('bathroom'),
    externalFeatures: [
      ...mapApartmentFeaturesField('external'),
      {
        defaultName: 'elevator',
        inputType: 'radio',
        name: 'elevator',
        options: BOOLEAN_OPTIONS,
      },
    ],
    pictures: [],
    roomMainData: [{
      defaultName: 'Reference',
      toReturn: '',
    }, {
      defaultName: 'Floor area',
      inputType: 'number',
      name: 'floorArea',
      additionalText: 'm²',
    }, {
      defaultName: 'Two occupants allowed',
      inputType: 'radio',
      name: 'twoOccupantsAllowed',
      options: BOOLEAN_OPTIONS,
    }, {
      defaultName: upperFirst(underSlopedRoofFeature.label),
      inputType: 'select',
      name: underSlopedRoofFeature.field,
      options: underSlopedRoofFeature.options,
    }],
    roomPrice: [{
      defaultName: 'Base price',
      inputType: 'number',
      name: 'basePrice',
      isMoney: true,
    }],
    roomGeneralFeatures: mapRoomFeaturesField('general'),
    roomDressFeatures: mapRoomFeaturesField('dress'),
    roomWorkFeatures: mapRoomFeaturesField('work'),
    roomSleepFeatures: [{
      defaultName: 'Bed',
      inputType: 'select',
      name: 'beds',
      options: BED_KINDS,
      displayAsTag: 'dark',
    },
    ...mapRoomFeaturesField('sleep'),
    ],
    roomBathroomFeatures: mapRoomFeaturesField('bathroom'),
    roomPictures: [],
  };
}
