import React, { useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import FormField from '../../FormField';

function InterventionDone(props) {
  const { handleDataChange } = props;

  const defaultField = {
    type: 'checkbox',
    name: 'sendEmails',
    label: 'Send emails to the clients',
    value: true,
    required: false,
  };

  const [field, setField] = useState(defaultField);

  function handleChange(name, value) {
    setField({ ...field, value });

    handleDataChange('sendEmails', value);
  }

  return (
    <div>
      <Row>
        <Col>
          <Alert variant="info">Are you sure ?</Alert>
          <ul>
            <li>Did you check the contractor's pictures after the intervention ?</li>
            <li>Did you confirm with the clients that the problem is solved ?</li>
          </ul>
          <p>
            If so, you can go on to the
            {' '}
            <strong>billing</strong>
            {' '}
            step by clicking on the Confirm button below.
          </p>
        </Col>
      </Row>
      <Row>
        <FormField
          field={ field }
          handleChange={ (name, value) => handleChange(name, value) }
        />
      </Row>
    </div>
  );
}

export default InterventionDone;
