import React, { useEffect, useState } from 'react';
import ForestApi from '../../utils/API';
import Loader from '../Loader';
import favicon from '../../assets/icons/favicon.svg';

function useUpdateContractorEvent(id, status) {
  const [state, setState] = useState();
  useEffect(() => {
    if (!['accepted', 'rejected'].includes(status)) {
      setState(-1);
      return;
    }
    setState(0);
    new ForestApi().contractorEvents()
      .update(id, { status })
      .then((res) => setState(res.data))
      .catch(() => setState(-1));
  }, [id, status]);
  return state;
}

const renderAnsweredInvitation = ({ updated, status }) => {
  const statusMap = {
    accepted: 'accepté',
    rejected: 'refusé',
  };
  if (!updated) {
    return `Vous avez déjà ${statusMap[status]} l'invitation à cet évènement.`;
  }
  return `Vous avez ${statusMap[status]} l'invitation à cet évènement.`;
};

const ContractorEvent = (props) => {
  const { match } = props;
  const {
    id,
    status,
  } = match.params;
  const state = useUpdateContractorEvent(id, status);
  return (
    <div className="w-100 d-flex flex-column align-items-center">
      <img src={ favicon } alt="Jungle" width="30" height="30" className="icon mt-2" />
      <h1>Contractor Event</h1>
      {
        state === -1
          ? <div className="alert alert-danger">Une erreur est survenue. Veuillez réessayer plustard.</div> : ''
      }
      {
        state === 0 ? <Loader /> : ''
      }
      {
        state && state !== -1 && state !== 0 ? <div>{renderAnsweredInvitation(state)}</div> : ''
      }
    </div>
  );
};

export default ContractorEvent;
