import moment from 'moment';
import { toast } from 'react-toastify';
import ForestApi from '../../utils/API';
import history from '../../utils/history';

export const setUser = (userData) => ({
  type: 'SET_USER',
  token: userData.token,
  role: userData.role,
  team: userData.team,
  email: userData.email,
});

const setLocalStorage = (authResult) => {
  const expiresAt = JSON.stringify(moment().add(7, 'days').valueOf());

  localStorage.setItem('access_token', authResult.token);
  localStorage.setItem('role', authResult.role);
  localStorage.setItem('team', authResult.team);
  localStorage.setItem('email', authResult.email);
  localStorage.setItem('expires_at', expiresAt);
};

const emptyLocalStorage = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('role');
  localStorage.removeItem('team');
  localStorage.removeItem('email');
  localStorage.removeItem('expires_at');
};

export const setSession = (email, password) => async (dispatch) => {
  try {
    const userData = await new ForestApi().login(email, password);
    const authResult = userData.data;

    dispatch(setUser(authResult));
    setLocalStorage(authResult);

    history.push('/');
  } catch (error) {
    toast('Wrong email or password', {
      type: toast.TYPE.ERROR,
    });
  }
};

export const logout = () => (dispatch) => {
  emptyLocalStorage();
  return dispatch(setUser({
    token: '',
    role: '',
    team: '',
    email: '',
  }));
};
