import React from 'react';
import capitalize from 'lodash/capitalize';

import Button from 'react-bootstrap/Button';

function StatusLabel(props) {
  const { status, className } = props;

  return (
    <Button size="sm" className={ `btn-label btn-${status} ${className}` } disabled>
      { capitalize(status) }
    </Button>
  );
}

export default StatusLabel;
