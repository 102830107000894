import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import kebabCase from 'lodash/kebabCase';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../utils/API';
import Dashboard from '../Dashboard';
import './style.scss';
import extractParamsQuery from '../../utils/extractParamsQuery';
import fetchCitiesFilters from '../../utils/fetchCitiesFilters';
import getFromQuery from '../../utils/getFromQuery';

const ROOM_STATUS = [
  'draft',
  'stock',
  'available',
  'booked',
  'occupied',
  'maintenance',
  'archived',
];

const FEATURES = [
  'couple',
  'private bathroom',
  'private balcony',
  'living room',
];

const getDistrict = (city, {label}) => label;

class Rooms extends React.Component {
  constructor(props) {
    super(props);
    const filters = extractParamsQuery({
      search: {
        type: 'search',
        placeholder: 'Filter by name...',
        currentValue: '',
      },
      city: {
        type: 'checkbox',
        label: 'City',
        currentValue: ['lyon'],
        values: [],
      },
      neighborhood: {
        type: 'tags',
        label: 'Neighborhood',
        placeholder: 'Zip code or district...',
        currentValue: [],
        values: [],
      },
      status: {
        type: 'checkbox',
        label: 'Status',
        currentValue: ['available'],
        values: ROOM_STATUS,
      },
      features: {
        type: 'checkbox',
        label: 'Features',
        currentValue: [],
        values: FEATURES,
      },
    }, window.location.search);

    this.state = {
      isLoading: false,
      sorting: {
        nameAsc: 'Name: A->Z',
        nameDesc: 'Name: Z->A',
        priceAsc: 'Price: Low to High',
        priceDesc: 'Price: High to Low',
        availabilityAsc: 'Availability: Newest to Oldest',
        availabilityDesc: 'Availability: Oldest to Newest',
      },
      filters,
      sortBy: 'nameAsc',
      rooms: [],
    };
  }

  async componentDidMount() {
    await this.fetchCities();
    await this.fetchRooms();
  }

  async fetchRooms() {
    const { filters } = this.state;

    const { city, status, neighborhood } = filters;

    this.setState({ isLoading: true });

    const query = [
      status && `status=${status.currentValue.join(',')}`,
      city && `city=${city.currentValue.join(',')}`,
    ].join('&');

    const response = await new ForestApi().rooms().getAll(query);
    const rooms = response.data;

    const { zipCodes, neighborhoods } = rooms
      .reduce((acc, { Apartment }) => {
        const { CityId, District, addressZip } = Apartment;

        const district = getDistrict(CityId, District);

        if (!acc.neighborhoods.includes(district)) {
          acc.neighborhoods = [...acc.neighborhoods, district];
        }

        if (!acc.zipCodes.includes(addressZip)) {
          acc.zipCodes = [...acc.zipCodes, addressZip].sort((a, b) => a - b);
        }

        return acc;
      }, { zipCodes: [], neighborhoods: [] });

    this.setState({
      isLoading: false,
      filters: {
        ...filters,
        neighborhood: {
          ...neighborhood,
          values: [...zipCodes, ...neighborhoods],
        },
      },
      rooms,
    });
  }

  async fetchCities() {
    const { filters } = this.state;
    this.setState(await fetchCitiesFilters(filters));
  }

  handleFilterChange(name, value) {
    const { filters } = this.state;

    if (name === 'city' && value.length === 0) {
      return null;
    }

    const refetch = [
      'city',
      'status',
    ].includes(name);

    return this.setState({
      filters: {
        ...filters,
        [name]: {
          ...filters[name],
          currentValue: value,
        },
      },
    }, refetch ? this.fetchRooms : null);
  }

  handleSortingChange(value) {
    this.setState({
      sortBy: value,
    });
  }

  sortRooms(roomA, roomB) {
    const { sortBy } = this.state;
    const [field, direction] = kebabCase(sortBy).split('-');

    if (field === 'price') {
      if (direction === 'asc') {
        return roomA.basePrice - roomB.basePrice;
      }

      return roomB.basePrice - roomA.basePrice;
    }

    if (field === 'availability') {
      const availabilityA = moment(roomA.availableAt).valueOf();
      const availabilityB = moment(roomB.availableAt).valueOf();

      if (direction === 'asc') {
        return availabilityB - availabilityA;
      }

      return availabilityA - availabilityB;
    }

    if (field === 'name' && direction === 'desc') {
      return roomB.name.localeCompare(roomA.name);
    }

    return null;
  }

  showRoom(id) {
    const { history } = this.props;
    history.push(`/rooms/${id}?${getFromQuery(window.location)}`);
  }

  parseRoomCard(room) {
    const {
      Apartment,
      availableAt,
      basePrice,
      currentPrice,
      floorArea,
      id,
      name,
      Rentings,
      status,
      Terms,
      twoOccupantsAllowed,
    } = room;

    const {
      addressZip,
      addressCity,
      CityId,
      District: {label},
      DistrictId,
      Terms: ApartmentTerms,
    } = Apartment;

    const isAvailable = ['available', 'stock', 'maintenance'].includes(status);
    const isOccupied = ['booked', 'occupied'].includes(status);

    const cityAddress = (CityId === 'paris' && addressCity.toLowerCase() !== 'paris')
      ? `${addressZip} ${capitalize(CityId)} / ${capitalize(addressCity)}`
      : `${addressZip} ${capitalize(addressCity)}`;

    const tags = [
      <Button key={ status } className={ `btn-label btn-${status} mr-2` } size="sm" disabled>
        { capitalize(status) }
      </Button>,
      <Button key={ DistrictId } variant="dark" size="sm" className="btn-label mr-2" disabled>{ label }</Button>,
      <Button key={ floorArea } size="sm" variant="secondary" disabled>
        { floorArea }
        {' '}
        m²
      </Button>,
    ];
    const hasPrivateBathroom = Terms.find(({ name: nameTmp }) => nameTmp === 'privateBathroom');
    const hasPrivateBalcony = Terms.find(({ name: nameTmp }) => nameTmp === 'privateBalcony');
    const hasLivingRoom = (ApartmentTerms || []).find(({ name: nameTmp, value }) => nameTmp === 'livingRoom'
      && ['separated', 'hall-entrance-corridor'].includes(value));

    let price = currentPrice;

    if (isOccupied) {
      price = Rentings.length > 0 ? Rentings[0].price : 0;
    }

    const today = moment().endOf('day');
    const formattedAvailableAt = moment(availableAt).format('DD/MM/YYYY');

    const description = (
      <>
        <div className="mb-3">
          <Button
            size="sm"
            variant="light"
            title="Base price"
            className="btn-label mr-2"
            disabled
          >
            { (basePrice / 100).toFixed(2) }
            {' '}
            €
          </Button>
          {
            isAvailable || isOccupied
              ? (
                <Button
                  size="sm"
                  variant="light"
                  title="Current price"
                  className={ `btn-label text-${price >= basePrice ? 'success' : 'danger'}` }
                  disabled
                >
                  { (price / 100).toFixed(2) }
                  {' '}
                  €
                </Button>
              )
              : null
          }
        </div>
        <div className="mb-3">
          <i
            title="Two occupants"
            className={ `icon fas fa-user-friends fa-lg text-${twoOccupantsAllowed ? 'primary' : 'secondary'}` }
          />
          <i
            title="Private bathroom"
            className={ `icon fas fa-bath fa-lg text-${hasPrivateBathroom ? 'primary' : 'secondary'}` }
          />
          <i
            title="Private balcony"
            className={ `icon fas fa-store fa-lg text-${hasPrivateBalcony ? 'primary' : 'secondary'}` }
          />
          <i
            title="Private balcony"
            className={ `fas fa-couch fa-lg text-${hasLivingRoom ? 'primary' : 'secondary'}` }
          />
        </div>
        {
          isAvailable
            ? (
              <div>
                Available
                {
                moment(availableAt).isBefore(today)
                  ? <strong title={ `since ${formattedAvailableAt}` }> now</strong>
                  : (
                    <span>
                      {' '}
                      on
                      <strong>{ formattedAvailableAt }</strong>
                    </span>
                  )
              }
              </div>
            )
            : null
        }
        {
          isOccupied && Rentings.length > 0
            ? (
              <div>
                { capitalize(status) }
                {' '}
                by
                {' '}
                <strong>{ Rentings[0].Client.fullName }</strong>
              </div>
            )
            : null
        }
      </>
    );

    return {
      id,
      title: name,
      subtitle: cityAddress,
      tags,
      description,
    };
  }

  renderPlaceholder() {
    return (
      <Row>
        <Col>
          <Jumbotron className="text-center">
            <h1>No rooms</h1>
            <p>
              No rooms found matching these filters.
            </p>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  render() {
    const {
      rooms,
      filters,
      sorting,
      isLoading,
    } = this.state;

    const { userTeam } = this.props;

    const query = [
      `search=${filters.search.currentValue}`,
      `city=${filters.city.currentValue.join(',')}`,
      `status=${filters.status.currentValue.join(',')}`,
      `neighborhood=${filters.neighborhood.currentValue}`,
      `features=${filters.features.currentValue.join(',')}`,
    ].join('&');
    window.history.replaceState(null, null, `${window.location.pathname}?${query}`);

    const userHomePage = ['sales'].includes(userTeam) ? 'home' : 'search';
    const homePageUrl = userHomePage === 'home' ? '' : 'rooms';

    const filteredRooms = rooms
      .filter(({ name }) => {
        const { search } = filters;

        if (!search.currentValue) {
          return true;
        }

        const regex = new RegExp(search.currentValue, 'i');

        return regex.test(name);
      })
      .filter(({ Apartment }) => {
        const { addressZip, CityId, District } = Apartment;
        const { neighborhood } = filters;

        const selectedNeighborhoods = neighborhood.currentValue;

        if (selectedNeighborhoods.length === 0) {
          return true;
        }

        const district = getDistrict(CityId, District);

        return selectedNeighborhoods.includes(addressZip) || selectedNeighborhoods.includes(district);
      })
      .filter(({ twoOccupantsAllowed, Terms, Apartment }) => {
        const { features } = filters;

        const selectedFeatures = features.currentValue;
        let filtered = true;

        const { Terms: ApartmentTerms } = Apartment;

        const hasPrivateBathroom = Terms.find(({ name }) => name === 'privateBathroom');
        const hasPrivateBalcony = Terms.find(({ name }) => name === 'privateBalcony');
        const hasLivingRoom = (ApartmentTerms || []).find(({ name, value }) => name === 'livingRoom'
          && ['separated', 'hall-entrance-corridor'].includes(value));
        if (selectedFeatures.includes('couple')) {
          filtered = filtered && twoOccupantsAllowed;
        }

        if (selectedFeatures.includes('private bathroom')) {
          filtered = filtered && hasPrivateBathroom;
        }

        if (selectedFeatures.includes('private balcony')) {
          filtered = filtered && hasPrivateBalcony;
        }

        if (selectedFeatures.includes('living room')) {
          filtered = filtered && hasLivingRoom;
        }

        return filtered;
      })
      .sort((a, b) => this.sortRooms(a, b))
      .map(this.parseRoomCard);

    return (
      <div className="Rooms">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap
        align-items-center pt-3 pb-2 border-bottom"
        >
          <Link to={ `/${homePageUrl}` }>
            <i className="icon fas fa-arrow-circle-left" />
            {`Back to ${userHomePage}`}
          </Link>
          <h3>Rooms Dashboard</h3>
          <div />
        </div>
        <Dashboard
          model="room"
          filters={ filters }
          sorting={ sorting }
          items={ filteredRooms }
          handleFilterChange={ (name, value) => this.handleFilterChange(name, value) }
          handleSortingChange={ (value) => this.handleSortingChange(value) }
          handleElementClick={ (id) => this.showRoom(id) }
          renderPlaceholder={ () => this.renderPlaceholder() }
          isLoading={ isLoading }
        />
      </div>
    );
  }
}

export default Rooms;
