import React from 'react';
import { connect } from 'react-redux';

import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import * as userActions from '../../redux/actions/userActions';
import './style.scss';

function Navbar(props) {
  const { store: { email }, logout } = props;

  return (
    <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
      <Nav variant="pills">
        <NavDropdown title={ email } id="nav-dropdown">
          <NavDropdown.Item onSelect={ () => logout() }>
            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
            Sign out
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </nav>
  );
}

const mapStateToProps = (store) => ({
  store: store.user,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(userActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
