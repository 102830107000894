import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';

import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import buildForestLink from '../../utils/buildForestLink';
import ForestApi from '../../utils/API';
import MainTab from './tabs/main';
import HistoryTab from './tabs/history';
import TypesUpdateModal from './typesUpdateModal';
import './style.scss';
import GoBackDashboard from '../shared/GoBackDashboard';

function Show(props) {
  const { contractorId, userRole, history } = props;
  const [contractor, setContractor] = useState({});
  const [types, setTypes] = useState({});
  const [displayModal, toggleModal] = useState(false);

  function fetchContractor() {
    new ForestApi().contractors()
      .getOne(contractorId)
      .then((res) => setContractor(res.data));
  }

  function fetchTypes() {
    new ForestApi().interventions()
      .getTypes()
      .then((res) => setTypes(res.data));
  }

  useEffect(() => {
    if (isEmpty(contractor)) {
      fetchContractor();
    }

    if (isEmpty(types)) {
      fetchTypes();
    }
  });

  function updateContractor(data) {
    new ForestApi().contractors()
      .update(contractorId, data)
      .then((res) => {
        toast('Contractor updated!', {
          type: toast.TYPE.SUCCESS,
        });
        setContractor({ ...contractor, ...res.data });
      });
  }

  function activateContractor() {
    new ForestApi().contractors()
      .activate(contractorId)
      .then((res) => {
        toast('Billing info approved!', {
          type: toast.TYPE.SUCCESS,
        });
        setContractor({ ...contractor, ...res.data });
      });
  }

  const forestContractorLink = buildForestLink(contractorId, 'contractor');

  if (isEmpty(contractor)) {
    return (
      <div className="text-center">
        <h2>
          <i className="fas fa-spinner fa-spin" />
        </h2>
      </div>
    );
  }

  const typesTags = contractor.InterventionTypes.map(({ name }) => {
    const variant = name === 'checkout' ? 'primary' : 'dark';

    return (
      <Button key={ name } variant={ variant } size="sm" className="btn-label mr-2 mb-2" disabled>
        { name }
      </Button>
    );
  });

  return (
    <div className="ShowContractor mb-4">
      <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
        <GoBackDashboard history={ history } defaultLink="/contractors" />
        <div className="header-middle text-center">
          <h3>{`${contractor.firstName} ${contractor.lastName}`}</h3>
          <div className="d-flex justify-content-center">
            { typesTags }
            <span className="fa-stack" onClick={ () => toggleModal(true) }>
              <i className="fas fa-circle fa-stack-2x" />
              <i className="fas fa-pencil-alt fa-stack-1x fa-inverse" />
            </span>
          </div>
        </div>
        <div className="text-right">
          <a href={ forestContractorLink } rel="noopener noreferrer" target="_blank">
            <i className="fa fa-external-link-alt fa-lg" />
          </a>
        </div>
      </div>
      <Tabs defaultActiveKey="main" id="contractor-tabs">
        <Tab
          eventKey="main"
          title={ (
            <span>
              <i className="icon fas fa-user-cog" />
              Main data
            </span>
) }
        >
          <MainTab
            contractor={ contractor }
            activateContractor={ activateContractor }
            updateContractor={ updateContractor }
            userRole={ userRole }
          />
        </Tab>
        <Tab
          eventKey="history"
          title={ (
            <span>
              <i className="icon fas fa-history" />
              History
            </span>
) }
        >
          <HistoryTab interventions={ contractor.TechnicalInterventions } />
        </Tab>
      </Tabs>
      {
        displayModal
          ? (
            <TypesUpdateModal
              show={ displayModal }
              contractor={ contractor }
              interventionTypes={ types }
              onHide={ () => toggleModal(false) }
              save={ (data) => updateContractor(data) }
            />
          )
          : null
      }
    </div>
  );
}

export default Show;
