import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import favicon from '../../assets/icons/favicon.svg';
import './style.scss';

function isActive(pages, currentPage) {
  return pages.find((page) => {
    const regexp = new RegExp(page, 'ig');

    return regexp.test(currentPage);
  });
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    const { location } = props;

    const currentPage = location.pathname.replace('/', '');
    const isExpanded = (subPages) => isActive(subPages, currentPage);

    this.state = {
      items: [{
        label: 'Clients',
        icon: 'far fa-user',
        subItems: [{
          label: 'Dashboard',
          page: 'clients',
          icon: 'fas fa-search',
        }, {
          label: 'Bookings',
          page: 'bookings',
          icon: 'far fa-calendar',
        }, {
          label: 'Checkins',
          page: 'checkins',
          icon: 'far fa-calendar-plus',
        }, {
          label: 'Checkouts',
          page: 'checkouts',
          icon: 'far fa-calendar-minus',
        }, {
          label: 'Deposit refunds',
          page: 'deposit-refunds',
          icon: 'far fa-calendar-check',
        }],
        expanded: isExpanded(['bookings', 'checkins', 'checkouts', 'deposit-refunds']),
      }, {
        label: 'Accomodation',
        icon: 'fas fa-home',
        subItems: [{
          label: 'Apartments',
          page: 'apartments',
          icon: 'fas fa-building',
        }, {
          label: 'Rooms',
          page: 'rooms',
          icon: 'fas fa-bed',
        }],
        expanded: isExpanded(['apartments', 'rooms']),
      }, {
        label: 'Technical issues',
        icon: 'fas fa-tools',
        subItems: [{
          label: 'Interventions',
          page: 'interventions',
          icon: 'fas fa-wrench',
        }, {
          label: 'Contractors',
          page: 'contractors',
          icon: 'fas fa-user-cog',
        }],
        expanded: isExpanded(['interventions', 'contractors']),
      }, {
        label: 'Transactions',
        icon: 'fas fa-file-invoice-dollar',
        subItems: [{
          label: 'Receipts',
          page: 'receipts',
          icon: 'fas fa-receipt',
        }, {
          label: 'Payments',
          page: 'transactions',
          icon: 'fas fa-file-invoice',
        }],
        expanded: isExpanded(['interventions', 'contractors']),
      }],
    };
  }

  handleItemClick(item) {
    const { items } = this.state;

    const newItems = items.map((oldItem) => {
      if (oldItem.subItems && oldItem.subItems.length > 0) {
        if (item.label !== oldItem.label && oldItem.expanded) {
          return {
            ...oldItem,
            expanded: false,
          };
        }

        if (item.label === oldItem.label && !oldItem.expanded) {
          return {
            ...oldItem,
            expanded: true,
          };
        }
      }

      return oldItem;
    });

    this.setState({ items: newItems });
  }

  renderSubItems(item) {
    const { location } = this.props;
    const currentPage = location.pathname.replace('/', '');

    const { subItems } = item;

    const mappedSubItems = subItems.map((subItem) => {
      const isItemActive = isActive([subItem.page], currentPage);

      return (
        <li key={ subItem.label } className={ `menu-item ${isItemActive ? 'active' : ''}` }>
          <Link to={ `/${subItem.page}` } className="menu-link">
            <i className={ `menu-link--icon ${subItem.icon}` } />
            <span className="menu-link--label">
              { subItem.label }
            </span>
          </Link>
        </li>
      );
    });

    return (
      <ul className="menu-submenu">
        { mappedSubItems }
      </ul>
    );
  }

  renderContractorItems() {
    const { location } = this.props;
    const currentPage = location.pathname.replace('/', '');

    const items = [{
      label: 'Apartments',
      page: 'apartments',
      icon: 'fas fa-building',
    }];

    return items.map((item) => {
      const isItemActive = currentPage === item.page
        ? 'active'
        : '';

      return (
        <li key={ item.label } className={ `menu-item ${isItemActive}` }>
          <Link to={ `/${item.page}` } className="menu-link">
            <i className={ `menu-link--icon ${item.icon}` } />
            <span className="menu-link--label">
              { item.label }
            </span>
          </Link>
        </li>
      );
    });
  }

  renderUserItems() {
    const { location } = this.props;
    const currentPage = location.pathname.replace('/', '');

    const { items } = this.state;

    return items.map((item) => {
      const subItemsPages = item.subItems && item.subItems.map(({ page }) => page);
      const isItemActive = item.subItems
        ? isActive(subItemsPages, currentPage)
        : isActive([item.page], currentPage);

      return (
        <li
          key={ item.label }
          onClick={ () => this.handleItemClick(item) }
          className={ `menu-item ${item.expanded ? 'expanded' : ''} ${isItemActive ? 'active' : ''}` }
        >
          {
            item.subItems
              ? (
                <>
                  <Button variant="link" className="menu-link">
                    <i className={ `menu-link--icon ${item.icon}` } />
                    <span className="menu-link--label">
                      { item.label }
                    </span>
                    <i className={ `menu-link--chevron fas fa-chevron-${item.expanded ? 'down' : 'right'}` } />
                  </Button>
                  {
                  item.expanded
                    ? this.renderSubItems(item)
                    : null
                }
                </>
              )
              : (
                <Link
                  to={ `/${item.page}` }
                  className={ `menu-link ${isItemActive ? 'active' : ''}` }
                >
                  <i className={ `menu-link--icon ${item.icon}` } />
                  <span className="menu-link--label">
                    { item.label }
                  </span>
                </Link>
              )
          }
        </li>
      );
    });
  }

  render() {
    const { userRole } = this.props;

    return (
      <aside className="sidebar">
        <header className="sidebar-header">
          <a className="logo-icon" href="/">
            <img src={ favicon } alt="Jungle" width="30" height="30" className="icon" />
          </a>
          <a className="logo" href="/">
            JUNGLE
          </a>
        </header>
        <nav className="sidebar-navigation">
          <ul className="menu">
            {
              userRole === 'contractor'
                ? this.renderContractorItems()
                : this.renderUserItems()
            }
          </ul>
        </nav>
      </aside>
    );
  }
}

export default Sidebar;
