import React, { useState, useEffect } from 'react';

import find from 'lodash/find';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Modal from 'react-bootstrap/Modal';

import ForestApi from '../../utils/API';
import getLanguageFlag from '../../utils/getLanguageFlag';
import Loader from '../Loader';
import OrderItemsTable from '../OrderItemsTable';
import './style.scss';

function DepositRefundModal(props) {
  const [loadingCreateDeposit, setLoadingCreateDeposit] = useState(false);
  const {
    event, fetchEvents, show, isLoading, handleClose,
  } = props;

  const [depositRefundOrder, setDepositRefund] = useState(null);
  const [pendingChanges, setPendingChanges] = useState(false);
  const [newItems, setNewItems] = useState(null);
  const [loadingComparison, setLoadingComparison] = useState(false);

  const { Client: client } = event.Renting;

  useEffect(() => {
    const depositRefund = client.Orders.find((order) => order.type === 'credit'
      && order.label === 'Deposit Refund'
      && find(order.OrderItems, { RentingId: event.Renting.id }));

    setDepositRefund(depositRefund);
  }, [event, client.Orders]);

  function handleModalClose() {
    const warningMessage = `
      There are some pending changes.
      If you continue, all changes will be lost.
    `;

    if (pendingChanges && !window.confirm(warningMessage)) {
      return;
    }

    handleClose();
  }

  async function createDepositRefund() {
    await new ForestApi().depositRefunds()
      .create({ eventId: event.id })
      .then(() => {
        fetchEvents();
      });
  }

  function handleFinishedComparison() {
    const message = `
      This action is irreversible.
      It will be impossible to modify once this action is complete.
      Are you sure ?
    `;

    if (window.confirm(message)) {
      setLoadingComparison(true);
      new ForestApi().depositRefunds()
        .activate(depositRefundOrder.id)
        .then(() => {
          handleClose();
          fetchEvents();
          setLoadingComparison(false);
        }).catch(() => {
        setLoadingComparison(false);
      });
    }
  }

  function isOrderInComparison(order) {
    if (!depositRefundOrder) {
      return false;
    }

    const itemsToCompare = order.OrderItems;

    return itemsToCompare.filter((item) => {
      const similarDepositItem = depositRefundOrder.OrderItems.find((depositItem) => (
        item.ProductId === depositItem.ProductId
        && item.unitPrice === -1 * depositItem.unitPrice
      ));

      return Boolean(similarDepositItem);
    }).length === itemsToCompare.length;
  }

  function addOrder(order) {
    if (!isOrderInComparison(order)) {
      setPendingChanges(true);
      setNewItems(order.OrderItems);
    }
  }

  const flag = getLanguageFlag(client.preferredLanguage);

  const unpaidOrders = client.Orders.filter(({ type, receiptNumber }) => type === 'debit' && !receiptNumber);

  const mappedUnpaidOrders = unpaidOrders.map((order) => {
    const { label, amount } = order;

    const labelAndAmount = `${label}, ${amount / 100} €`;

    return (
      <li key={ order.id }>
        <span>{ labelAndAmount }</span>
        {
          isOrderInComparison(order)
            ? (
              <Button variant="link" size="sm" className="btn-label text-dark" disabled>
                <i className="icon fas fa-check" />
                Added
              </Button>
            )
            : (
              <Button variant="link" size="sm" onClick={ () => addOrder(order) }>
                Add to comparison
              </Button>
            )
        }
      </li>
    );
  });

  const unpaidOrdersAlert = unpaidOrders.length === 0
    ? (
      <Alert variant="success">
        <i className="icon fas fa-check" />
        The client has paid all of his orders.
      </Alert>
    )
    : (
      <Alert variant="warning">
        <i className="icon fas fa-exclamation-triangle" />
        Some orders are left unpaid :
        <ul>
          { mappedUnpaidOrders}
        </ul>
      </Alert>
    );

  return (
    <Modal size="lg" className="DepositRefundModal" show={ show } onHide={ () => handleModalClose() }>
      <Modal.Header closeButton>
        { client.fullName }
        {' '}
        { flag }
      </Modal.Header>
      <Modal.Body>
        {
        !isLoading
          ? (
            <div>
              {
              depositRefundOrder
                ? (
                  <div>
                    { unpaidOrdersAlert }
                    <OrderItemsTable
                      order={ depositRefundOrder }
                      preferredLanguage={ client.preferredLanguage }
                      pendingChanges={ pendingChanges }
                      setPendingChanges={ setPendingChanges }
                      syncChanges={ fetchEvents }
                      newItems={ newItems }
                      newItemsAdded={ setNewItems }
                    />
                  </div>
                )
                : (
                  <Jumbotron className="text-center">
                    <h1 className="h3">
                      No refund order found.
                    </h1>
                    <Button
                      onClick={ () => {
                        setLoadingCreateDeposit(true);
                        createDepositRefund().then(() => {
                          setLoadingCreateDeposit(false);
                        })
                      }}
                      disabled={loadingCreateDeposit}
                    >
                      Create a refund order
                    </Button>
                  </Jumbotron>
                )
            }
            </div>
          )
          : <Loader />
      }
      </Modal.Body>
      <Modal.Footer>
        {
          depositRefundOrder
            ? (
              <Button variant="success" disabled={ pendingChanges || loadingComparison } onClick={ () => handleFinishedComparison() }>
                <i className="icon fas fa-check" />
                Finished
              </Button>
            )
            : null
        }
      </Modal.Footer>
    </Modal>
  );
}

export default DepositRefundModal;
