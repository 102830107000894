import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import FormField from '../FormField';

function TypesUpdateModal(props) {
  const {
    contractor, show, onHide, save, interventionTypes,
  } = props;
  const initialTypes = contractor.InterventionTypes.map((t) => t.name);

  const [value, setValue] = useState(initialTypes.join(','));

  const { firstName, lastName } = contractor;
  const fullName = `${firstName} ${lastName}`;

  const field = {
    type: 'tags',
    name: 'types',
    options: interventionTypes,
    label: 'Types',
    value,
    required: true,
  };

  function handleChange(name, val) {
    return setValue(val);
  }

  function handleSave() {
    onHide();
    save({ types: value });
  }

  const disabled = value === initialTypes.join(',');

  return (
    <Modal size="lg" className="Contractors-TypesUpdateModal" show={ show } onHide={ () => onHide() }>
      <Modal.Header closeButton>
        <strong>
          Add / remove types for
          { fullName }
        </strong>
      </Modal.Header>
      <Modal.Body>
        <FormField
          field={ field }
          handleChange={ handleChange }
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={ () => onHide() }>Cancel</Button>
        <Button variant="primary" onClick={ () => handleSave() } disabled={ disabled }>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TypesUpdateModal;
