const contractorRules = [
  'apartments',
];

const userRules = [
  'all',
  'bookings',
  'checkins',
  'checkouts',
  'clients',
  'deposit-refunds',
  'apartments',
  'rooms',
  'interventions',
  'contractors',
  'transactions',
  'receipts',
];

const rules = {
  contractor: {
    static: contractorRules,
  },
  user: {
    static: userRules,
  },
  admin: {
    static: userRules,
  },
};

export default rules;
