import ForestApi from './API';

export default async function fetchCitiesFilters(filters) {
  const { city } = filters;

  const response = await new ForestApi().cities().getAll();
  const cities = response.data.map(({ id }) => id);
  return {
    filters: {
      ...filters,
      city: {
        ...city,
        values: cities,
      },
    },
  };
}
