import React from 'react';
import moment from 'moment';

import Alert from 'react-bootstrap/Alert';

import './style.scss';

class Agenda extends React.Component {
  renderDay(day, dayEvents) {
    const formattedDay = day.format('dddd DD/MM');
    const isPast = day.startOf('day').isBefore(moment().startOf('day'));

    return (
      <div key={ formattedDay } className={ `day ${isPast ? 'past' : ''}` }>
        <div className="header">
          <strong>{ formattedDay }</strong>
        </div>
        <div className="body">
          { dayEvents }
        </div>
      </div>
    );
  }

  render() {
    const { isLoading, events, startWeek } = this.props;

    if (isLoading) {
      return (
        <div className="Agenda text-center">
          <i className="fas fa-spinner fa-spin fa-3x" />
        </div>
      );
    }

    if (!events || events.length === 0) {
      return (
        <div className="Agenda">
          <Alert variant="warning">No events found for this week.</Alert>
        </div>
      );
    }

    const days = [];
    for (let i = 0; i < 7; i += 1) {
      days.push(moment(startWeek).add(i, 'days'));
    }

    const renderDays = days.map((day) => {
      const dayEvents = events
        .filter(({ startDate }) => day.isSame(moment(startDate), 'day'))
        .sort((a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf())
        .map(({ rendered }) => rendered);

      return this.renderDay(day, dayEvents);
    });

    return (
      <div className="Agenda">
        <div className="days">
          { renderDays }
        </div>
      </div>
    );
  }
}

export default Agenda;
