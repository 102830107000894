import React, { useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../utils/API';
import SearchBar from '../SearchBar';
import './style.scss';

function Clients(props) {
  const { history } = props;
  const [clients, setClients] = useState([]);

  function showDashboard() {
    history.push('/clients/dashboard');
  }

  function showClient(id) {
    history.push(`/clients/${id}`);
  }

  function parseClientResult(client) {
    const {
      id, firstName, lastName, email, status,
    } = client;

    const rowData = (
      <Row className="d-flex align-items-center">
        <Col lg="5" className="d-flex align-items-center">
          <span className={ `mr-2 color-${status}` }>
            <i className="fas fa-circle fa-xs" />
          </span>
          <span>
            { firstName }
            {' '}
            { lastName}
          </span>
        </Col>
        <Col lg="5">
          <span className="text-secondary">{ email }</span>
        </Col>
        <Col lg="2" className="details text-right">
          <i className="fas fa-arrow-right" />
        </Col>
      </Row>
    );

    return {
      id,
      rowData,
    };
  }

  function handleSearch(name) {
    const trimmedName = name.trim();

    if (trimmedName.length < 3) {
      return setClients([]);
    }

    const data = `name=${trimmedName}`;

    return new ForestApi().clients()
      .search(data)
      .then((res) => {
        const clientList = res.data.map(parseClientResult);

        setClients(clientList);
      });
  }

  return (
    <div className="Clients">
      <div className="pt-3 pb-2 border-bottom">
        <h3>Clients</h3>
      </div>
      <Row className="pt-4 mt-4 pb-4 justify-content-center">
        <Col lg="6">
          <SearchBar
            icon="fas fa-user"
            placeholder="Search by first name, last name or email..."
            results={ clients }
            handleSearch={ handleSearch }
            handleClick={ showClient }
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg="6" className="d-flex align-items-center justify-content-between">
          <div className="w-100 p-2"><hr /></div>
          <h3 className="p-2">OR</h3>
          <div className="w-100 p-2"><hr /></div>
        </Col>
      </Row>
      <Row className="mt-4 justify-content-center">
        <Col lg="6">
          <div className="dashboard-btn" onClick={ () => showDashboard() }>
            <div className="text-container">
              <h1>Go to dashboard</h1>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Clients;
