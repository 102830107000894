import React, { useEffect, useState } from 'react';

import './style.scss';

function SearchBar(props) {
  const {
    icon,
    placeholder,
    results,
    handleSearch,
    handleClick,
    refresh,
  } = props;

  const [value, setValue] = useState('');

  useEffect(() => {
    if (refresh && !refresh.current) {
      setValue('');
    }
  }, [refresh, setValue]);

  function renderSearchResults() {
    return (
      <ul className="search-results">
        {
          results.map(({ id, rowData }) => (
            <li
              key={ id }
              className="result"
              onClick={ () => handleClick(id) }
            >
              { rowData }
            </li>
          ))
        }
      </ul>
    );
  }

  return (
    <div className="search-bar">
      <div className="search-field">
        <div className="search-icon">
          <i className={ icon } />
        </div>
        <input
          type="text"
          placeholder={ placeholder }
          onChange={ (e) => { handleSearch(e.target.value); setValue(e.target.value); } }
          value={ value }
        />
      </div>
      {
        results.length > 0
          ? renderSearchResults()
          : null
      }
    </div>
  );
}

export default SearchBar;
