import React from 'react';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import ForestApi from '../../../../utils/API';
import FormField from '../../../FormField';
import { EMAIL_REGEX } from '../../../../utils/emailsHelpers';

const CONTACT_TYPES = ['landlord', 'property-manager', 'building-manager', 'caretaker', 'other'];

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    const existingOrNew = props.contact ? 'new' : 'existing';

    const defaultValues = {
      firstName: '',
      lastName: '',
      gender: '',
      email: '',
      phoneNumber: '',
      postalAddress: '',
      company: '',
      notes: '',
    };

    const contact = get(props.contact, 'contact', defaultValues);
    const type = get(props.contact, 'type', '');
    const { mainContact } = props;
    const mainContactDefaultValue = mainContact === contact.id ? 'yes' : 'no';

    this.state = {
      existingOrNew,
      contact,
      type,
      mainContactDefaultValue,
      error: null,
      contacts: [],
      currentContact: null,
      isSearchDone: false,
      fields: [{
        type: 'text',
        name: 'firstName',
        label: 'First name',
        value: contact.firstName,
        required: true,
      }, {
        type: 'text',
        name: 'lastName',
        label: 'Last name',
        value: contact.lastName,
        required: true,
      }, {
        type: 'radio',
        name: 'gender',
        options: ['male', 'female'],
        label: 'Gender',
        value: contact.gender,
      }, {
        type: 'tag',
        name: 'type',
        label: 'Type',
        options: CONTACT_TYPES,
        value: type,
        required: true,
      }, {
        type: 'email',
        name: 'email',
        label: 'Email',
        value: contact.email,
      }, {
        type: 'text',
        name: 'phoneNumber',
        label: 'Phone number',
        value: contact.phoneNumber,
      }, {
        type: 'text',
        name: 'postalAddress',
        label: 'Postal address',
        value: contact.postalAddress,
      }, {
        type: 'text',
        name: 'company',
        label: 'Company name (if any)',
        value: contact.company,
      }, {
        type: 'textarea',
        name: 'notes',
        label: 'Notes',
        value: contact.notes,
      }, {
        type: 'radio',
        name: 'mainContact',
        options: ['yes', 'no'],
        label: 'Main contact',
        value: mainContactDefaultValue,
        required: true,
      }],
    };
  }

  async setCurrentContactFields(contactId) {
    const { fields } = this.state;

    const response = await new ForestApi().contacts().getOne(contactId);
    const contact = response.data;

    const newFields = fields.map((field) => {
      const contactFieldValue = contact[field.name] || '';

      return {
        ...field,
        value: contactFieldValue,
      };
    });

    this.setState({
      fields: newFields,
      currentContact: contact,
      existingOrNew: 'new',
    });
  }

  isRequired(field) {
    const { fields } = this.state;

    const requiredFields = {
      firstName: ['company'],
      lastName: ['company'],
      company: ['firstName', 'lastName'],
      email: ['phoneNumber'],
      phoneNumber: ['email'],
    };

    const fieldName = field.name;

    if (fieldName in requiredFields) {
      return requiredFields[fieldName]
        .reduce((acc, requiredField) => acc && isEmpty(fields.find((f) => f.name === requiredField).value), true);
    }

    return field.required;
  }

  handleChoice(choice) {
    this.setState({
      existingOrNew: choice,
    });
  }

  handleChange(name, value) {
    const { fields } = this.state;

    const field = fields.find((f) => f.name === name);

    const otherFields = fields
      .filter(({ name: fieldName }) => fieldName !== name)
      .map((otherField) => ({
        ...otherField,
        required: this.isRequired(otherField),
      }));

    const newField = {
      ...field,
      value,
      required: this.isRequired(field),
    };

    this.setState({
      fields: [...otherFields, newField],
    });
  }

  checkChanges(contact) {
    const { type, mainContactDefaultValue, fields } = this.state;

    return fields.find((field) => {
      if (field.name === 'type') {
        return field.value !== type;
      }

      if (field.name === 'mainContact') {
        return field.value !== mainContactDefaultValue;
      }

      return field.value !== contact[field.name];
    });
  }

  handleSearch(name) {
    const { apartmentId } = this.props;

    if (name.trim().length < 2) {
      return this.setState({
        contacts: [],
        error: 'lengthError',
        isSearchDone: false,
      });
    }

    this.setState({ error: null });

    const queryString = `name=${name.trim()}`;

    return new ForestApi().contacts()
      .search(queryString)
      .then((response) => {
        const contacts = response.data.filter((contact) => {
          const apartments = contact.ApartmentContacts.map(({ id }) => id);

          return !apartments.includes(apartmentId);
        });

        this.setState({
          contacts,
          isSearchDone: true,
        });
      })
      .catch(() => {
        this.setState({
          contacts: [],
          isSearchDone: true,
        });
      });
  }

  parseContactFields(fields) {
    return fields.reduce((acc, field) => {
      acc[field.name] = field.value;

      return acc;
    }, {});
  }

  async createContact(fields) {
    const { apartmentId } = this.props;
    const data = this.parseContactFields(fields);

    data.apartmentId = apartmentId;

    const response = await new ForestApi().contacts().create(data);

    await this.assignMainContact(response.data);
  }

  async assignMainContact(contact) {
    const { apartmentId, mainContact } = this.props;
    const { fields } = this.state;
    const newMainContact = fields.find((field) => field.name === 'mainContact').value;

    if (newMainContact === 'yes') {
      return contact.id !== mainContact
        ? new ForestApi().apartments().update(apartmentId, { mainContact: contact.id })
        : null;
    }

    return contact.id === mainContact
      ? new ForestApi().apartments().update(apartmentId, { mainContact: null })
      : null;
  }

  async assignContact() {
    const { apartmentId } = this.props;
    const { currentContact, fields } = this.state;
    const type = fields.find((field) => field.name === 'type').value;
    const contactId = currentContact.id;

    const data = { contactId, type };

    if (this.checkChanges(currentContact)) {
      await this.updateContact(fields, currentContact);
    }

    await new ForestApi().apartments().assignContact(apartmentId, data);
  }

  async updateContact(fields, contact) {
    const { apartmentId } = this.props;
    const data = this.parseContactFields(fields.filter((field) => field.name !== 'type'));

    const contactId = contact.id;

    data.apartmentId = apartmentId;

    await new ForestApi().contacts().update(contactId, data);

    await this.assignMainContact(contact);
  }

  async sendForm(fields) {
    const { handleClose, fetchApartment, contact: newContact } = this.props;
    const { currentContact, contact } = this.state;

    if (currentContact) {
      await this.assignContact();
      toast('Contact assigned!', {
        type: toast.TYPE.SUCCESS,
      });
    } else if (!newContact) {
      await this.createContact(fields);
      toast('Contact assigned!', {
        type: toast.TYPE.SUCCESS,
      });
    } else {
      await this.updateContact(fields, contact);
      toast('Contact updated!', {
        type: toast.TYPE.SUCCESS,
      });
    }
    fetchApartment();
    handleClose();
  }

  isButtonDisabled() {
    const { contact, fields } = this.state;

    const requiredFields = fields.filter(({ required }) => required);
    const requiredFieldsFilled = requiredFields.every(({ value }) => Boolean(value));

    const emailField = fields.find((f) => f.name === 'email');
    const isEmailValid = emailField.value.length > 0 ? EMAIL_REGEX.test(emailField.value) : true;

    const phoneField = fields.find((f) => f.name === 'phoneNumber');
    const isPhoneValid = phoneField.value.length > 0 ? phoneField.value.replace(/\s+/g, '').length >= 5 : true;

    return !(requiredFieldsFilled && this.checkChanges(contact) && isEmailValid && isPhoneValid);
  }

  renderField(name) {
    const { fields } = this.state;
    const field = fields.find((f) => f.name === name);

    return (
      <FormField
        field={ field }
        handleChange={ (fieldName, value) => this.handleChange(fieldName, value) }
      />
    );
  }

  renderBackButton() {
    return (
      <Button variant="link" onClick={ () => this.handleChoice('existing') } className="mb-2">
        <i className="icon fas fa-arrow-circle-left" />
        Back
      </Button>
    );
  }

  renderContactType() {
    const { type } = this.state;

    return (
      <Form.Group as={ Col }>
        <Form.Label className="mr-2">Type</Form.Label>
        <Button size="sm" variant="success" className="btn-label ml-3" disabled>
          <span className="mr-4">{ type }</span>
        </Button>
      </Form.Group>
    );
  }

  renderNewContactForm() {
    const { contact } = this.props;

    return (
      <>
        { !contact ? this.renderBackButton() : null }
        <Form>
          <Form.Row>
            {
              contact
                ? this.renderContactType()
                : this.renderField('type')
            }
          </Form.Row>
          <Form.Row>
            { this.renderField('firstName') }
            { this.renderField('lastName') }
          </Form.Row>
          <div className="w-100 mb-2 d-flex align-items-center">
            <hr className="w-50" />
            <em className="mx-3">OR</em>
            <hr className="w-50" />
          </div>
          <Form.Row>
            { this.renderField('company') }
          </Form.Row>
          <Form.Row>
            { this.renderField('mainContact')}
            { this.renderField('gender')}
          </Form.Row>
          <Form.Row>
            { this.renderField('phoneNumber') }
            <em className="mx-3 my-auto">OR</em>
            { this.renderField('email') }
          </Form.Row>
          <Form.Row>
            { this.renderField('postalAddress') }
          </Form.Row>
          <Form.Row>
            { this.renderField('notes') }
          </Form.Row>
        </Form>
      </>
    );
  }

  renderContacts() {
    const { contacts } = this.state;

    const headers = ['Name', 'Company', 'Action'];
    const mappedHeaders = headers.map((header) => <th key={ header }>{ header }</th>);

    const mappedContacts = contacts.map((contact) => (
      <tr key={ contact.id }>
        <td>{`${contact.firstName} ${contact.lastName}`}</td>
        <td>{ contact.company }</td>
        <td>
          <Button variant="primary" onClick={ () => this.setCurrentContactFields(contact.id) }>
            <span className="icon fa fa-check" />
            Associate a type
          </Button>
        </td>
      </tr>
    ));

    return (
      <Table className="mt-3">
        <thead>
          <tr>{ mappedHeaders }</tr>
        </thead>
        <tbody>
          { mappedContacts }
        </tbody>
      </Table>
    );
  }

  renderExistingForm() {
    const { contacts, error, isSearchDone } = this.state;

    return (
      <>
        <div className="mt-3">
          <Form.Control
            placeholder="Search by first name, last name or company"
            aria-label="Search by first name, last name or company"
            onChange={ (e) => this.handleSearch(e.target.value) }
            autoFocus
          />
        </div>
        <p className="mt-3">
          { contacts.length }
          {' '}
          contact(s) found
        </p>
        {
          error && error === 'lengthError'
            ? (
              <Alert variant="info">Please type at least 2 characters</Alert>
            )
            : null
        }
        {
          contacts.length > 0
            ? this.renderContacts()
            : null
        }
        <div className="text-center">
          <Button disabled={ !isSearchDone } onClick={ () => this.handleChoice('new') }>Create new contact</Button>
        </div>
      </>
    );
  }

  render() {
    const { show, contact, handleClose } = this.props;
    const { existingOrNew, fields } = this.state;

    return (
      <Modal size="lg" className="NewContact" show={ show } onHide={ handleClose }>
        <Modal.Header>
          <Modal.Title>
            { contact ? 'Edit ' : 'New ' }
            contact
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            existingOrNew === 'new'
              ? this.renderNewContactForm()
              : null
          }
          {
            existingOrNew === 'existing'
              ? this.renderExistingForm()
              : null
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ handleClose }>
            Close
          </Button>
          {
            existingOrNew === 'new'
              ? (
                <Button variant="primary" disabled={ this.isButtonDisabled() } onClick={ () => this.sendForm(fields) }>
                  <span className="icon fa fa-check" />
                  { contact ? 'Update' : 'Add'}
                </Button>
              )
              : null
          }
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ContactForm;
