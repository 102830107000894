const initialState = {
  cities: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_CITIES':
      return { cities: action.cities };
    default:
      return state;
  }
}
