import React from 'react';
import capitalize from 'lodash/capitalize';

import Button from 'react-bootstrap/Button';

function ArrondissementLabel({ arrondissement, className, theme }) {
  return (
    <>
      {
        arrondissement
          ? (
            <Button size='sm'
                    className={`btn-label ${theme === 'light' ? 'btn-ready-paris' : 'btn-confirmed-paris'} ${className}`}
                    disabled>
              {capitalize(arrondissement)}
            </Button>
          )
          : null
      }
    </>
  );
}

export default ArrondissementLabel;
