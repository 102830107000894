export default function checkBic(bic) {
  if (!bic) {
    return null;
  }

  const parseBic = bic.trim().replace(/\s/g, '').toUpperCase();

  return {
    bic: parseBic,
    isMalformed: (parseBic.length !== 8 && parseBic.length !== 11),
  };
}
