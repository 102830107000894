import React, { useState } from 'react';
import capitalize from 'lodash/capitalize';
import truncate from 'lodash/truncate';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Spinner from 'react-bootstrap/Spinner';

import pluralize from '../../utils/pluralize';
import './style.scss';

function ColumnCardsContainer(props) {
  const {
    title,
    cards,
    model,
    toggle,
    filters,
    handleFilterChange,
    icon,
    style,
    isLoading,
  } = props;

  const [displayAllCards, toggleAll] = useState(toggle);

  const renderFiltersModal = () => {
    const { currentValue, values } = filters;

    const selectAllValues = () => {
      const isActive = currentValue.length === values.length;
      const newValue = (!isActive && values) || [];

      return (
        <div className="filter all" onClick={ () => handleFilterChange(newValue) }>
          <Button
            variant="link"
          >
            {
              isActive
                ? 'Clear All'
                : 'Select All'
            }
          </Button>
        </div>
      );
    };

    const mappedValues = values.map((value) => {
      const isActive = currentValue.includes(value);

      const newValue = isActive
        ? currentValue.filter((v) => v !== value)
        : [...currentValue, value];

      return (
        <div key={ `${value}` } className="filter" onClick={ () => handleFilterChange(newValue) }>
          {
            isActive
              ? <i className="icon far fa-check-circle checked" />
              : <i className="icon far fa-circle" />
          }
          <Button
            variant="link"
            className={ isActive ? 'checked' : '' }
          >
            { `${capitalize(value)}` }
          </Button>
        </div>
      );
    });

    const mappedSelectedFilters = () => {
      const selectedFilters = currentValue
        .filter((filter, i) => i <= 2)
        .map((filter) => {
          const newValue = currentValue.filter((v) => v !== filter);

          return (
            <p key={ filter } className="remove-filter" onClick={ () => handleFilterChange(newValue) }>
              { truncate(filter, { length: 12 }) }
              <i className="fas fa-times ml-2" />
            </p>
          );
        });

      if (currentValue.length > 3) {
        selectedFilters.push(
          <p key={ title } className="text-nowrap text-white">
            { currentValue.length - 3 }
            {' '}
            more...
          </p>,
        );
      }

      return selectedFilters;
    };

    const renderSelectedFilters = (
      <div className="selectedFilters">
        {
          currentValue.length === 0
            ? <p className="no-filter">No filter selected</p>
            : mappedSelectedFilters()
        }
      </div>
    );

    return (
      <div className="d-flex align-items-center">
        <DropdownButton
          id="dropdown-basic-button"
          title={ <i className="fas fa-filter fa-sm" /> }
          size="lg"
          drop="down"
        >
          { mappedValues }
          { selectAllValues() }
        </DropdownButton>
        { renderSelectedFilters }
      </div>
    );
  };

  const renderCardCounter = () => {
    if (isLoading) {
      return <Spinner animation="border" variant="light" />;
    }

    return cards.length > 0
      ? (
        <Button variant="link" onClick={ () => toggleAll(!displayAllCards) }>
          { displayAllCards ? 'Hide' : 'Show all' }
          {' '}
          <i className={ `fas fa-chevron-${displayAllCards ? 'up' : 'down'}` } />
        </Button>
      )
      : (
        <Button variant="link" className="btn-label text-success" disabled>
          <i className="icon far fa-check-circle" />
          Nothing to do
        </Button>
      );
  };

  const headerIcon = (
    <>
      <i className={ `${icon} card-counter-container` }>
        <Badge pill variant="danger">
          { cards.length }
        </Badge>
      </i>
    </>
  );

  return (
    <div className={ `column-wrapper ${style ? 'custom' : ''}` } style={{ position: 'relative' }}>
      <div className="column-content">
        <div className="column-header">
          <div className="d-flex align-items-center mb-2 column-title">
            {
              icon
                ? headerIcon
                : null
            }
            <h4>{ title }</h4>
          </div>
          {
            filters
              ? renderFiltersModal()
              : null
          }
          {
            model
              ? pluralize(cards.length, model)
              : null
          }
          <div className="text-center">
            { renderCardCounter() }
          </div>
        </div>
        {
          displayAllCards
            ? (
              <div className="column-body">
                { cards }
              </div>
            )
            : null
        }
      </div>
    </div>
  );
}

export default ColumnCardsContainer;
