import React from 'react';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';

import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../../utils/API';
import FormField from '../../FormField';

const TIME_SLOTS = [{
  value: 'morning',
  label: 'Morning',
}, {
  value: 'afternoon',
  label: 'Afternoon',
}, {
  value: 'day',
  label: 'All day',
}];

class ScheduleIntervention extends React.Component {
  constructor(props) {
    super(props);
    const { type } = props;

    this.state = {
      fields: [{
        type: 'select',
        name: 'ContractorId',
        options: [],
        label: `${capitalize(type)} Contractor`,
        value: '',
        required: true,
      }, {
        type: 'date',
        name: 'expectedStartDate',
        label: 'Expected start date',
        value: '',
        required: true,
      }, {
        type: 'select',
        name: 'expectedTimeSlot',
        options: TIME_SLOTS,
        label: 'Expected time slot',
        value: '',
        required: true,
      }, {
        type: 'checkbox',
        name: 'sendEmails',
        label: 'Send emails to the clients',
        value: true,
        required: false,
      }],
      showError: false,
    };
  }

  componentDidMount() {
    this.getContractorsByType();
  }

  setOptions(name, options) {
    const { fields } = this.state;

    const field = fields.find((f) => f.name === name);
    const otherFields = fields.filter((f) => f.name !== name);

    const newField = { ...field, options };

    this.setState({ fields: [...otherFields, newField] });
  }

  getContractorsByType() {
    const { intervention: { type, Apartment }, handleError } = this.props;
    const { CityId } = Apartment;

    const queryParams = [
      `type=${type}`,
      `city=${CityId}`,
    ].join('&');

    new ForestApi().contractors()
      .getAll(queryParams)
      .then((response) => {
        const contractors = response.data;

        if (contractors.length === 0) {
          handleError();
          return;
        }

        const options = sortBy(contractors, 'firstName').map((contractor) => ({
          value: contractor.id,
          label: `${contractor.fullName} ${contractor.company ? `- ${contractor.company}` : ''}`,
        }));

        this.setOptions('ContractorId', options);
      });
  }

  handleChange(name, value) {
    const { handleFormCompleted, handleIncompleteForm, handleDataChange } = this.props;
    const { fields } = this.state;

    const field = fields.find((f) => f.name === name);
    const otherFields = fields.filter((f) => f.name !== name);
    let fieldValue = value;

    const newFields = [
      ...otherFields,
      { ...field, value: fieldValue },
    ];

    this.setState({
      fields: newFields,
    });

    const requiredFields = newFields.filter((f) => f.required);

    if (requiredFields.every((f) => Boolean(f.value))) {
      handleFormCompleted();
    } else {
      handleIncompleteForm();
    }

    if (name === 'expectedStartDate') {
      fieldValue = moment(value).toISOString();
    }

    handleDataChange(name, fieldValue);
  }

  renderField(name) {
    const { fields } = this.state;
    const field = fields.find((f) => f.name === name);

    return (
      <FormField
        field={ field }
        handleChange={ (fieldName, value) => this.handleChange(fieldName, value) }
      />
    );
  }

  render() {
    const { intervention: { type } } = this.props;
    const { showError } = this.state;

    if (showError) {
      return (
        <Alert variant="danger">
          No contractors of type
          {' '}
          <strong>{ type }</strong>
          {' '}
          found.
        </Alert>
      );
    }

    return (
      <div>
        <Row>
          { this.renderField('ContractorId') }
        </Row>
        <Row>
          { this.renderField('expectedStartDate') }
          { this.renderField('expectedTimeSlot') }
        </Row>
        <Row>
          { this.renderField('sendEmails') }
        </Row>
      </div>
    );
  }
}

export default ScheduleIntervention;
