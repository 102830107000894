import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import truncate from 'lodash/truncate';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../utils/API';
import SearchBar from '../SearchBar';
import './style.scss';

function HomePage(props) {
  const {
    history,
    template,
  } = props;

  const {
    model,
    pages,
    icon,
    placeholder,
  } = template;

  const [results, setResults] = useState([]);

  function showResult(id) {
    history.push(`/${model}/${id}`);
  }

  function getApartmentsRows(result) {
    const {
      CityId,
      name,
      status,
    } = result;

    const rowData = (
      <Row className="d-flex align-items-center">
        <Col sm="5" className="d-flex align-items-center">
          <span className={ `mr-2 color-${status}` }>
            <i className="fas fa-circle fa-xs" />
          </span>
          <span>
            { name }
          </span>
        </Col>
        <Col sm="2">
          <span className="text-secondary">{ capitalize(CityId) }</span>
        </Col>
        <Col sm="5" className="details text-right">
          <i className="fas fa-arrow-right" />
        </Col>
      </Row>
    );

    return rowData;
  }

  function getInterventionsRows(result, response) {
    const {
      ApartmentId,
      description,
      RoomId,
      status,
      type,
    } = result;

    const icons = {
      draft: 'fas fa-edit',
      assessed: 'fas fa-eye',
      scheduled: 'far fa-calendar-check',
      ongoing: 'fas fa-tractor',
      done: 'fas fa-check',
    };

    const apartment = response.find((apart) => apart.id === ApartmentId);

    const rowData = (
      <div className="d-flex">
        <div className="mr-4">
          <Button
            variant="dark"
            size="sm"
            className="btn-label mr-2"
            disabled
          >
            { capitalize(truncate(type, { length: 15 })) }
          </Button>
          {
            RoomId
              ? <i className="icon fas fa-bed" />
              : <i className="icon fas fa-home" />
          }
          <span className={ `color-${status}` }>
            <i className={ `${icons[status]}` } />
          </span>
        </div>
        <div className="flex-fill" style={{ overflow: 'hidden' }}>
          <span className="mr-2">
            { apartment.name }
          </span>
          <span className="text-secondary">
            { truncate(description, { length: 30 }) }
          </span>
        </div>
        <div className="details">
          <i className="fas fa-arrow-right" />
        </div>
      </div>
    );

    return rowData;
  }

  function getClientsRows(result) {
    const {
      firstName,
      lastName,
      email,
      status,
    } = result;

    const rowData = (
      <Row className="d-flex align-items-center">
        <Col lg="5" className="d-flex align-items-center">
          <span className={ `mr-2 color-${status}` }>
            <i className="fas fa-circle fa-xs" />
          </span>
          <span>
            {`${firstName} ${lastName}`}
          </span>
        </Col>
        <Col lg="5">
          <span className="text-secondary">{ email }</span>
        </Col>
        <Col lg="2" className="details text-right">
          <i className="fas fa-arrow-right" />
        </Col>
      </Row>
    );

    return rowData;
  }

  function parseResults(result, response) {
    const { id } = result;
    let rowData = getClientsRows(result);

    if (model === 'apartments') {
      rowData = getApartmentsRows(result);
    }

    if (model === 'interventions') {
      rowData = getInterventionsRows(result, response);
    }

    return {
      id,
      rowData,
    };
  }

  function fetchApartments(data) {
    new ForestApi().apartments()
      .search(data)
      .then((res) => {
        const apartments = res.data
          .map((apartment) => parseResults(apartment, res.data));

        setResults(apartments);
      });
  }

  function fetchInterventions(data) {
    new ForestApi().interventions()
      .search(data)
      .then((res) => {
        const interventions = res.data
          .filter(({ TechnicalInterventions }) => Boolean(TechnicalInterventions))
          .reduce((acc, apartment) => [...acc, ...apartment.TechnicalInterventions], [])
          .map((intervention) => parseResults(intervention, res.data));

        setResults(interventions);
      });
  }

  function fetchClients(data) {
    new ForestApi().clients()
      .search(data)
      .then((res) => {
        const clients = res.data
          .map((client) => parseResults(client, res.data));

        setResults(clients);
      });
  }

  function handleSearch(name) {
    const trimmedName = name.trim();

    if (trimmedName.length < 3) {
      return setResults([]);
    }

    const data = `name=${trimmedName}`;

    if (model === 'apartments') {
      return fetchApartments(data);
    }

    if (model === 'interventions') {
      return fetchInterventions(data);
    }

    return fetchClients(data);
  }

  function renderAccessButtons() {
    const routeMatching = {
      rooms: 'rooms/dashboard',
      'draft-apartments': 'apartments/dashboard/draft',
      'stock-apartments': 'apartments/dashboard/active',
      payments: 'transactions',
      interventions: 'interventions/dashboard',
    };

    const isSeveralPages = pages.length > 1;

    const accessButtons = pages.map((page) => {
      const route = page in routeMatching ? routeMatching[page] : page;

      return (
        <div className={ `dashboard-btn ${page}` } key={ page }>
          <Link to={ `/${route}` }>
            <div className="text-container">
              <h1>{ capitalize(page).replace('-', ' ') }</h1>
            </div>
          </Link>
        </div>
      );
    });

    return (
      <>
        { accessButtons }
        {
          isSeveralPages
            ? (
              [...Array(pages.length - 2).keys()].map((item) => (
                <div key={ `empty-${item}` } className="filling-empty-space-children" />
              ))
            )
            : null
        }
      </>
    );
  }

  return (
    <>
      <Row className="pt-4 mt-4 pb-4 justify-content-center">
        <Col lg="5">
          <SearchBar
            icon={ `fas fa-${icon}` }
            placeholder={ `Search by ${placeholder}...` }
            results={ results }
            handleSearch={ handleSearch }
            handleClick={ showResult }
          />
        </Col>
        {
          model === 'clients'
            ? (
              <>
                <Col lg="1" className="d-flex align-items-center justify-content-between">
                  <h3 className="w-100 p-2 text-center">OR</h3>
                </Col>
                <Col lg="5">
                  <Link to={ `/${model}/dashboard` }>
                    <Button variant="success" size="lg" className="w-100 h-100">
                      Go to dashboard
                    </Button>
                  </Link>
                </Col>
              </>
            )
            : <Col lg="6" />
        }

      </Row>
      <div className="h-75 w-100 d-flex">
        <div
          className="buttons-row w-100"
          style={{ maxWidth: `calc(${pages.length} * 21rem)` }}
        >
          { renderAccessButtons() }
        </div>
      </div>
    </>
  );
}

export default HomePage;
