import React from 'react';
import sortBy from 'lodash/sortBy';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import ForestApi from '../../utils/API';
import FormField from '../FormField';

class NewIntervention extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: [{
        type: 'tag',
        name: 'type',
        options: props.interventionTypes,
        label: 'What is the type of this intervention ?',
        value: '',
        required: true,
      }, {
        type: 'tags',
        name: 'subType',
        options: [],
        label: 'What are the sub types for this intervention (not mandatory) ?',
        value: '',
        required: false,
      }, {
        type: 'tag',
        name: 'CityId',
        options: [],
        label: 'In what city is the apartment ?',
        value: '',
        required: true,
      }, {
        type: 'select',
        name: 'ApartmentId',
        options: [],
        label: 'Apartment',
        value: '',
        required: true,
      }, {
        type: 'select',
        name: 'RoomId',
        options: [],
        label: 'Room',
        value: null,
        required: false,
      }, {
        name: 'clientPresenceNeeded',
        label: 'Client presence needed',
        value: false,
        required: false,
      }, {
        type: 'tag',
        name: 'propertyManagerName',
        options: props.propertyManagers,
        label: 'Property manager',
        value: '',
        required: true,
      }, {
        type: 'textarea',
        name: 'description',
        label: 'Description',
        value: '',
        required: true,
      }],
      commonAreas: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { fields } = this.state;
    const { interventionTypes, cities } = this.props;
    const { fields: previousFields } = prevState;

    const typeField = fields.find((f) => f.name === 'type');

    if (
      interventionTypes.length !== 0
      && typeField.options.length === 0
    ) {
      this.setOptions('type', interventionTypes);
    }

    const cityField = fields.find((f) => f.name === 'CityId');

    if (
      cities.length > 0
      && cityField.options.length === 0
    ) {
      this.setOptions('CityId', cities);
    }

    const typeValue = fields.find(({ name }) => name === 'type').value;
    const prevTypeValue = previousFields.find(({ name }) => name === 'type').value;

    if (Boolean(prevTypeValue) && !typeValue) {
      this.setOptions('subType', []);
      this.handleChange('subType', '');
      return;
    }

    if (typeValue !== prevTypeValue) {
      this.fetchSubTypes();
    }
  }

  setOptions(name, options) {
    const { fields } = this.state;

    const field = fields.find((f) => f.name === name);
    const otherFields = fields.filter((f) => f.name !== name);

    const newField = { ...field, options };

    this.setState({ fields: [...otherFields, newField] });
  }

  fetchSubTypes() {
    const { fields } = this.state;
    const type = fields.find(({ name }) => name === 'type').value;

    new ForestApi().interventions()
      .getTypes(`type=${type}`)
      .then((res) => {
        const subTypes = res.data;

        this.setOptions('subType', subTypes);
      });
  }

  fetchApartments(city) {
    if (!city) {
      return null;
    }

    return new ForestApi().cities()
      .getApartments(city)
      .then((response) => {
        const apartments = response.data;

        const options = sortBy(apartments, 'name')
          .map((apt) => ({
            value: apt.id,
            label: apt.name,
          }));

        this.setOptions('ApartmentId', options);
      });
  }

  fetchRooms(apartmentId) {
    new ForestApi().apartments()
      .getRooms(apartmentId)
      .then((response) => {
        const rooms = response.data;

        const options = rooms.map((room) => ({
          value: room.id,
          label: room.name,
        }));

        this.setOptions('RoomId', options);
      });
  }

  handleChange(name, value) {
    const { fields } = this.state;
    const fieldsToUpdate = { [name]: value };

    if (name === 'type') {
      fieldsToUpdate.CityId = null;
      fieldsToUpdate.ApartmentId = null;
      fieldsToUpdate.RoomId = null;
    }

    if (name === 'CityId') {
      fieldsToUpdate.ApartmentId = null;
      fieldsToUpdate.RoomId = null;

      if (value) {
        this.fetchApartments(value);
      }
    }

    if (name === 'ApartmentId') {
      fieldsToUpdate.RoomId = null;

      if (value) {
        this.fetchRooms(value);
      }
    }

    const isFieldToUpdate = (fieldName) => Object.keys(fieldsToUpdate).includes(fieldName);

    const otherFields = fields.filter(({ name: fieldName }) => !isFieldToUpdate(fieldName));

    const newFields = fields
      .filter(({ name: fieldName }) => isFieldToUpdate(fieldName))
      .map((field) => ({
        ...field,
        value: fieldsToUpdate[field.name],
      }));

    this.setState({
      fields: [...otherFields, ...newFields],
    });
  }

  toggleCommonAreas() {
    const { commonAreas } = this.state;

    if (commonAreas) {
      this.handleChange('RoomId', null);
    }

    this.setState({ commonAreas: !commonAreas });
  }

  toggleClientPresence() {
    const { fields } = this.state;

    const clientPresenceNeeded = fields.find(({ name }) => name === 'clientPresenceNeeded').value;

    this.handleChange('clientPresenceNeeded', !clientPresenceNeeded);
  }

  renderField(name) {
    const { fields } = this.state;
    const field = fields.find(({ name: fieldName }) => fieldName === name);

    return (
      <FormField
        field={ field }
        handleChange={ (fieldName, value) => this.handleChange(fieldName, value) }
      />
    );
  }

  render() {
    const { show, handleClose, handleSubmit } = this.props;
    const { fields, commonAreas } = this.state;

    const selectedType = fields.find(({ name }) => name === 'type').value;
    const city = fields.find(({ name }) => name === 'CityId').value;
    const apartment = fields.find(({ name }) => name === 'ApartmentId').value;
    const clientPresenceNeeded = fields.find(({ name }) => name === 'clientPresenceNeeded').value;

    const requiredFields = fields.filter(({ required }) => required);
    const requiredFieldsFilled = requiredFields.filter(({ value }) => Boolean(value)).length === requiredFields.length;

    return (
      <Modal size="lg" className="NewIntervention" show={ show } onHide={ handleClose }>
        <Modal.Header closeButton>
          <Modal.Title>New intervention</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              { this.renderField('type') }
            </Form.Row>
            {
              selectedType
                ? (
                  <>
                    <Form.Row>
                      { this.renderField('subType') }
                    </Form.Row>
                    <Form.Row>
                      { this.renderField('CityId') }
                    </Form.Row>
                  </>
                )
                : null
            }
            {
              city
                ? (
                  <Form.Row>
                    { this.renderField('ApartmentId')}
                  </Form.Row>
                )
                : null
            }
            {
              apartment
                ? (
                  <>
                    <Form.Row>
                      <Col lg="6">
                        <Form.Group>
                          <div className="form-check">
                            <input
                              id="commonAreas"
                              className="form-check-input"
                              type="checkbox"
                              onChange={ () => this.toggleCommonAreas() }
                              checked={ commonAreas }
                            />
                            <label className="form-check-label" htmlFor="commonAreas">
                              Common areas
                            </label>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg="6">
                        {
                        apartment && !commonAreas
                          ? this.renderField('RoomId')
                          : null
                      }
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={ Col }>
                        <div className="form-check">
                          <input
                            id="clientPresenceNeeded"
                            className="form-check-input"
                            type="checkbox"
                            onChange={ () => this.toggleClientPresence() }
                            checked={ clientPresenceNeeded }
                          />
                          <label className="form-check-label" htmlFor="clientPresenceNeeded">
                            Client presence needed
                          </label>
                        </div>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      { this.renderField('propertyManagerName') }
                    </Form.Row>
                    <Form.Row>
                      { this.renderField('description') }
                    </Form.Row>
                  </>
                )
                : null
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ handleClose }>
            Close
          </Button>
          <Button variant="primary" disabled={ !requiredFieldsFilled } onClick={ () => handleSubmit(fields) }>
            <span className="icon fa fa-check" />
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default NewIntervention;
