import React from 'react';
import Cleave from 'cleave.js/react';
import capitalize from 'lodash/capitalize';
import moment from 'moment';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import FormCheck from 'react-bootstrap/FormCheck';
import FormControl from 'react-bootstrap/FormControl';

import prefixingUrl from '../../utils/prefixingUrl';

function handleFlags(props, event, checkedFlags) {
  const { editFieldValue, name } = props;
  const { checked, name: flag } = event.target;

  if (checked) {
    const newValue = checkedFlags
      ? (
        checkedFlags.split(',')
          .concat(flag)
          .sort((a, b) => a - b)
          .join(',')
      )
      : flag;

    return editFieldValue(name, newValue);
  }
  const newValue = checkedFlags
    .split(',')
    .filter((checkedFlag) => checkedFlag !== flag)
    .join(',');

  return editFieldValue(name, newValue);
}

function renderInput(props) {
  const {
    editFieldValue,
    inputType = 'text',
    name,
    options = [],
    value,
    placeholder,
    fieldsToUpdate,
    isMoney,
    required,
    htmlProps = {},
  } = props;

  let initialValue = value !== null ? value : '';

  if (inputType === 'flags') {
    const checked = fieldsToUpdate[name] ? fieldsToUpdate[name] : initialValue;

    const checkboxes = options.map((v) => {
      const regex = new RegExp(v, 'i');

      return (
        <FormCheck
          key={ v }
          type="checkbox"
          name={ v }
          label={ v }
          defaultChecked={ regex.test(checked) }
          onClick={ (event) => handleFlags(props, event, checked) }
          required={ required }
        />
      );
    });

    return checkboxes;
  }

  if (inputType === 'select') {
    const mappedOptions = options.map((option) => {
      let label = '';
      let optionValue = '';

      if (typeof option === 'string') {
        label = option;
        optionValue = option;
      }

      if (typeof option === 'object') {
        [[optionValue, label]] = Object.entries(option);
      }

      return (
        <option key={ label } value={ optionValue }>
          { capitalize(label) }
        </option>
      );
    });

    return (
      <FormControl
        as="select"
        value={ initialValue }
        onChange={ (e) => editFieldValue(name, e.target.value) }
        required={ required }
      >
        {
          !initialValue
            ? <option>Pick one...</option>
            : null
        }
        { mappedOptions }
      </FormControl>
    );
  }

  if (inputType === 'radio') {
    return Object.keys(options).map((key) => {
      let returnValue = options[key];

      if (['true', 'false'].includes(options[key])) {
        returnValue = JSON.parse(options[key]);
      }

      const checked = initialValue
        ? key === initialValue || options[key] === initialValue.toString()
        : returnValue === false;

      return (
        <div key={ key } className="form-check form-check-inline">
          <input
            id={ `${name}-${key}` }
            type="radio"
            name={ name }
            value={ options[key] }
            checked={ checked }
            className="form-check-input"
            onChange={ () => editFieldValue(name, returnValue) }
          />
          <label className="form-check-label" htmlFor={ `${name}-${key}` }>
            { capitalize(key) }
          </label>
        </div>
      );
    });
  }

  if (inputType === 'iban') {
    return (
      <Cleave
        options={{ blocks: [4, 4, 4, 4, 4, 4, 3] }}
        value={ value }
        className="form-control"
        onChange={ (e) => editFieldValue(name, e.target.rawValue) }
      />
    );
  }

  if (inputType === 'textarea') {
    return (
      <FormControl
        as="textarea"
        rows="4"
        value={ initialValue }
        onChange={ (e) => editFieldValue(name, e.target.value) }
      />
    );
  }

  if (inputType === 'number' && isMoney) {
    return (
      <FormControl
        type="number"
        value={ initialValue }
        placeholder={ placeholder }
        step="0.01"
        onChange={ (e) => editFieldValue(name, e.target.value) }
        required={ required }
      />
    );
  }

  if (inputType === 'date') {
    initialValue = moment(value).format('YYYY-MM-DD');
  }

  return (
    <FormControl
      type={ inputType }
      value={ initialValue }
      placeholder={ placeholder }
      onChange={ (e) => editFieldValue(name, e.target.value) }
      required={ required }
      { ...htmlProps }
    />
  );
}

function displayTags({ value, options }) {
  return options.map((option) => {
    const regex = new RegExp(option, 'i');
    const iconAndColor = regex.test(value)
      ? 'fa-check text-success'
      : 'fa-times text-danger';

    return (
      <li key={ option }>
        <i className={ `icon fas ${iconAndColor}` } />
        { option }
      </li>
    );
  });
}

function displayValue(props) {
  const {
    additionalText,
    inputType = 'text',
    isMoney = false,
    isLink = false,
    value,
    options,
    displayAsTag,
  } = props;

  if (inputType === 'flags') {
    return (
      <ul className="fa-ul ml-0">
        { displayTags(props) }
      </ul>
    );
  }

  let display = value;

  if (inputType === 'radio') {
    const option = Object.keys(options).find((key) => (
      options[key] === `${value}`
    ));

    display = option;
  }

  if (inputType === 'date' && value) {
    display = moment(value).format('DD/MM/YYYY');
  }

  if (inputType === 'number' && value === 0) {
    display = '0';
  }

  if (isLink && value) {
    display = (
      <a href={ prefixingUrl(value) } target="_blank" rel="noopener noreferrer">
        Click here
      </a>
    );
  }

  if (displayAsTag && display) {
    return (
      <Button variant={ displayAsTag } size="sm" className="btn-label" disabled>
        { display }
      </Button>
    );
  }

  if (isMoney && (value || value === 0)) {
    display = value.toFixed(2);
  }

  return (
    <span>
      {
        display || <span className="text-danger">Not specified</span>
      }
      { display && isMoney ? ' €' : null }
      { display && additionalText ? ` ${additionalText}` : null }
    </span>
  );
}

function EditableField(props) {
  const { editMode, team } = props;

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="flex-grow-1">
        {
          editMode
            ? renderInput(props)
            : displayValue(props)
        }
      </div>
      {
        team
          ? (
            <div className="ml-4">
              <Badge className={ `team-${team.replace('&', 'and').toLowerCase()}` }>{ team }</Badge>
            </div>
          )
          : null
      }
    </div>
  );
}

export default EditableField;
