import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../../../utils/API';
import ImageViewer from '../../../ImageViewer';

const APTS_PICTURES_TYPES = [
  'kitchen',
  'bathroom',
  'hall',
  'wc',
  'balcony',
  'patio',
  'garden',
  'loggia',
  'livingroom',
  'diningroom',
  'building',
  'cellar',
  'dressing',
  'floorplan',
  'watermeter',
  'electricitymeter',
  'circuitbreaker',
  'switchboard',
];

const ROOMS_PICTURES_TYPES = [
  'overview',
  'privatebathroom',
  'privatetoilet',
  'privatebalcony',
  'privateterrace',
  'privategarden',
  'privatedressing',
  'privateloggia',
];

const UNSAVED_CHANGES = `
  You have unsaved changes.
  Click OK if you want to continue anyway.
  Your changes will be lost.
`;

function Pictures(props) {
  const {
    apartmentId,
    fetchApartment,
    pictures,
    room,
  } = props;

  const [editMode, setEditMode] = useState(null);
  const [picturesOrder, updateOrder] = useState([]);

  function updatePicturesOrder() {
    if (picturesOrder.length === 0) {
      setEditMode(null);
      return;
    }

    new ForestApi().pictures()
      .bulkUpdate({ pictures: picturesOrder })
      .then(() => {
        toast('Pictures order saved!', {
          type: toast.TYPE.SUCCESS,
        });
        setEditMode(null);
        updateOrder([]);
        fetchApartment();
      });
  }

  function cancelOrderUpdate() {
    if (picturesOrder.length > 0 && window.confirm(UNSAVED_CHANGES)) {
      updateOrder([]);
    }

    setEditMode(null);
  }

  function setOrderUpdateMode(picturableId) {
    if (editMode && picturesOrder.length > 0 && window.confirm(UNSAVED_CHANGES)) {
      updateOrder([]);
    }

    setEditMode(picturableId);
  }

  function renderPicturesRow() {
    const isRoom = Boolean(room);
    const PicturableId = isRoom ? room.id : apartmentId;
    const picturesTypes = isRoom ? ROOMS_PICTURES_TYPES : APTS_PICTURES_TYPES;

    const rowPictures = isRoom ? room.Pictures : pictures;
    const sortedPictures = rowPictures
      .filter(({ order }) => order)
      .sort((a, b) => a.order - b.order)
      .concat(rowPictures.filter(({ order }) => !order));

    return (
      <Row key={ PicturableId }>
        <Col>
          <Card className="m-0 rounded-0">
            <Card.Body>
              <div className="mb-2">
                {
                  editMode === PicturableId
                    ? (
                      <>
                        <Button variant="link" onClick={ () => cancelOrderUpdate() } className="mr-2">
                          Cancel
                        </Button>
                        <Button onClick={ () => updatePicturesOrder() } disabled={ isEmpty(picturesOrder) }>
                          <i className="icon fas fa-save" />
                          Save
                        </Button>
                      </>
                    )
                    : (
                      <Button
                        variant="link"
                        onClick={ () => setOrderUpdateMode(PicturableId) }
                        disabled={ sortedPictures.length === 0 }
                      >
                        <i className="icon fas fa-edit" />
                        Change order
                      </Button>
                    )
                }
              </div>
              <ImageViewer
                pictures={ sortedPictures }
                types={ picturesTypes }
                picturable={ isRoom ? 'Room' : 'Apartment' }
                PicturableId={ PicturableId }
                fetchData={ () => fetchApartment() }
                editMode={ editMode === PicturableId }
                updateOrder={ updateOrder }
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <div className="pictures-tab">
      { renderPicturesRow() }
    </div>
  );
}

export default Pictures;
