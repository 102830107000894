const roomModel = {
  immo: {},
  dandq: {
    room: [
      'virtualVisitUrl',
      'room-features-general-view',
      'room-features-general-noise',
      'room-features-general-heating',
    ],
  },
  sales: {
    roomMainData: [
      'reference',
      'floorArea',
      'twoOccupantsAllowed',
    ],
    roomPrice: [
      'basePrice',
    ],
    roomFeatures: [
      'featuresChecked',
    ],
    roomPictures: [
      'roomHasPictures',
    ],
  },
};

export { roomModel as default };
