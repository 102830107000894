import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';

import HomePage from './homePage';
import NewApartment from '../Apartments/new';

import './style.scss';

function Home(props) {
  const { history, userTeam } = props;

  const [displayModal, toggleDisplayModal] = useState(false);

  const template = {
    'business-development': {
      model: 'apartments',
      pages: [
        'draft-apartments',
      ],
      icon: 'building',
      placeholder: 'apartment name',
    },
    'customer-hapiness': {
      model: 'clients',
      pages: [
        'bookings',
        'checkins',
        'checkouts',
        'deposit-refunds',
        'payments',
        'receipts',
      ],
      icon: 'user',
      placeholder: 'first name, last name or email',
    },
    'design-and-quality': {
      model: 'apartments',
      pages: [
        'draft-apartments',
        'stock-apartments',
      ],
      icon: 'building',
      placeholder: 'apartment name',
    },
    'property-manager': {
      model: 'interventions',
      pages: [
        'apartments',
        'contractors',
        'interventions',
      ],
      icon: 'tools',
      placeholder: 'apartment name',
    },
    sales: {
      model: 'apartments',
      pages: [
        'rooms',
        'draft-apartments',
        'stock-apartments',
      ],
      icon: 'building',
      placeholder: 'apartment name',
    },
  };

  function toggleModal() {
    toggleDisplayModal(!displayModal);
  }

  return (
    <div className="Home">
      <div className="pt-3 pb-2 border-bottom d-flex justify-content-between">
        <h3>Home</h3>
        {
          userTeam === 'business-development'
            ? (
              <Button variant="success" onClick={ () => toggleModal() }>
                <i className="icon fa fa-plus" />
                New
              </Button>
            ) : null
        }
      </div>
      <HomePage
        history={ history }
        template={ template[userTeam] || template['customer-hapiness'] }
      />
      {
        displayModal
          ? (
            <NewApartment
              show={ displayModal }
              handleClose={ () => toggleModal() }
              history={ history }
              showApartment
            />
          )
          : null
      }
    </div>
  );
}

export default Home;
