import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import capitalize from 'lodash/capitalize';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';

function History(props) {
  const { interventions } = props;

  const [displayModal, toggleModal] = useState(false);
  const [modalDescription, setDescription] = useState('');
  const [modalNotes, setNotes] = useState('');
  const [sortDirectionDesc, setDirection] = useState(true);

  function showDescription(description, notes) {
    setDescription(description);
    setNotes(notes);
    toggleModal(!displayModal);
  }

  if (!interventions || interventions.length === 0) {
    return (
      <Row>
        <Col>
          <Jumbotron className="text-center">
            <h1>No interventions</h1>
            <p>
              There are no interventions for this contractor yet.
            </p>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  const interventionsRows = interventions
    .sort((a, b) => {
      const valueOfA = moment(a.expectedStartDate).valueOf();
      const valueOfB = moment(b.expectedStartDate).valueOf();

      if (sortDirectionDesc) {
        return valueOfB - valueOfA;
      }

      return valueOfA - valueOfB;
    })
    .map((intervention) => {
      const {
        Apartment,
        description,
        expectedStartDate,
        id,
        notes,
        Room,
        status,
        subType,
        type,
      } = intervention;

      const mappedSubtypes = subType && subType.split(',').map((sub) => (
        <Button
          key={ sub }
          variant="dark"
          size="sm"
          className="btn-label mr-2"
          disabled
        >
          { sub }
        </Button>
      ));

      return (
        <tr key={ id }>
          <td>
            <Link to={ `/apartments/${Apartment.id}` } rel="noopener noreferrer" target="_blank">
              { Apartment.name }
            </Link>
          </td>
          <td>{ Room ? `Room #${Room.number}` : 'Common Areas' }</td>
          <td>
            <Button key={ type } variant="dark" size="sm" className="btn-label" disabled>
              { type }
            </Button>
          </td>
          <td>
            {
            subType
              ? mappedSubtypes
              : 'None'
          }
          </td>
          <td>
            <Button size="sm" className={ `btn-label btn-${status}` } disabled>
              { capitalize(status) }
            </Button>
          </td>
          <td>{ moment(expectedStartDate).format('DD/MM/YYYY') }</td>
          <td>
            <Button size="sm" variant="dark" className="mr-2" onClick={ () => showDescription(description, notes) }>
              <i className="icon fas fa-eye" />
              Details
            </Button>
            <Link
              to={ `/interventions/${id}` }
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn-sm btn-primary"
            >
              <i className="icon fa fa-external-link-alt" />
              Go to intervention
            </Link>
          </td>
        </tr>
      );
    });

  const directionIcon = sortDirectionDesc
    ? <i className="fas fa-chevron-down" />
    : <i className="fas fa-chevron-up" />;

  return (
    <div className="history-tab">
      <Row>
        <Col>
          <Card>
            <Table hover>
              <thead>
                <tr>
                  <th>Apartment</th>
                  <th>Area</th>
                  <th>Type</th>
                  <th>Sub Type</th>
                  <th>Status</th>
                  <th onClick={ () => setDirection(!sortDirectionDesc) } className="sortable">
                    Expected Date
                    {' '}
                    { directionIcon }
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                { interventionsRows }
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
      {
        displayModal
          ? (
            <Modal size="lg" show={ displayModal } onHide={ () => showDescription('', '') }>
              <Modal.Header>
                <Modal.Title>Description & Notes</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h4>Description</h4>
                <p style={{ whiteSpace: 'pre-line' }}>
                  { modalDescription || 'None.' }
                </p>
                <h4>Notes</h4>
                <p>
                  { modalNotes || 'None.' }
                </p>
              </Modal.Body>
            </Modal>
          )
          : null
      }
    </div>
  );
}

export default History;
