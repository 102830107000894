import React from 'react';
import capitalize from 'lodash/capitalize';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import ForestApi from '../../utils/API';
import buildForestLink from '../../utils/buildForestLink';
import { getApartmentCompletion } from '../../utils/getCompletion';

import DraftConfigurationTab from './tabs/draft/configuration';
import ContactsTab from './tabs/stock/contacts';
import FeaturesTab from './tabs/stock/features';
import GarageTab from './tabs/stock/garage';
import InterventionsTab from './tabs/stock/interventions';
import MainTab from './tabs/stock/main';
import PicturesTab from './tabs/stock/pictures';
import RoomsTab from './tabs/stock/rooms';
import StockConfigurationTab from './tabs/stock/configuration';
import GoBackDashboard from '../shared/GoBackDashboard';

class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      apartment: {},
      rentings: [],
      bookedRentings: [],
      currentDraftTab: 'immo',
    };
  }

  componentDidMount() {
    this.fetchApartment();
  }

  fetchApartment() {
    const { apartmentId } = this.props;

    new ForestApi().apartments()
      .getOne(apartmentId, true)
      .then((res) => {
        const apartment = (res.data && res.data.apartment) || res.data;
        const rentings = res.data && res.data.rentings;
        const bookedRentings = res.data && res.data.booked;
        const isApartmentDraft = apartment.status === 'draft';

        if (isApartmentDraft) {
          return this.setState({
            apartment: {
              ...apartment,
              ...getApartmentCompletion(apartment),
            },
          });
        }
        return this.setState({ apartment, rentings, bookedRentings });
      });
  }

  toggleDraftTab(newTab) {
    this.setState({ currentDraftTab: newTab });
  }

  renderDefaultTabs() {
    const { apartment, rentings, bookedRentings } = this.state;
    const parsedContacts = apartment.ApartmentContacts.map(({ type, Contact }) => ({ ...Contact, type }));

    return (
      <Tabs defaultActiveKey="main" id="apartment-tabs">
        <Tab
          eventKey="main"
          title={ (
            <span>
              <i className="icon fas fa-building" />
              Main data
            </span>
) }
        >
          <MainTab apartment={ apartment } fetchApartment={ () => this.fetchApartment() } />
        </Tab>
        <Tab
          eventKey="configuration"
          title={ (
            <span>
              <i className="icon fas fa-cogs" />
              Configuration
            </span>
) }
        >
          <StockConfigurationTab apartment={ apartment } fetchApartment={ () => this.fetchApartment() } />
        </Tab>
        <Tab
          eventKey="features"
          title={ (
            <span>
              <i className="icon fas fa-check" />
              Features
            </span>
) }
        >
          <FeaturesTab apartment={ apartment } fetchApartment={ () => this.fetchApartment() } />
        </Tab>
        <Tab
          eventKey="pictures"
          title={ (
            <span>
              <i className="icon far fa-image" />
              Pictures
            </span>
) }
        >
          <PicturesTab
            pictures={ apartment.Pictures }
            apartmentId={ apartment.id }
            rooms={ apartment.Rooms }
            fetchApartment={ () => this.fetchApartment() }
          />
        </Tab>
        <Tab
          eventKey="rooms"
          title={ (
            <span>
              <i className="icon fas fa-bed" />
              Rooms
            </span>
) }
        >
          <RoomsTab
            rooms={ apartment.Rooms }
            rentings={ rentings }
            bookedRentings={ bookedRentings }
            apartmentId={ apartment.id }
            fetchApartment={ () => this.fetchApartment() }
          />
        </Tab>
        <Tab
          eventKey="garage"
          title={ (
            <span>
              <i className="icon fas fa-car" />
              Garage
            </span>
) }
        >
          <GarageTab garage={ apartment.Garages[0] } />
        </Tab>
        <Tab
          eventKey="interventions"
          title={ (
            <span>
              <i className="icon fas fa-tools" />
              Interventions
            </span>
) }
        >
          <InterventionsTab interventions={ apartment.TechnicalInterventions } />
        </Tab>
        <Tab
          eventKey="contacts"
          title={ (
            <span>
              <i className="icon fas fa-user" />
              Contacts
            </span>
) }
        >
          <ContactsTab
            contacts={ parsedContacts }
            mainContact={ apartment.mainContact }
            apartmentId={ apartment.id }
            fetchApartment={ () => this.fetchApartment() }
          />
        </Tab>
      </Tabs>
    );
  }

  renderDraftTabs() {
    const { apartment, currentDraftTab } = this.state;
    const teams = [
      'IMMO',
      'D&Q',
      'SALES',
    ];

    const mappedTabs = teams.map((team) => {
      const formattedTeamName = team.toLowerCase().replace('&', 'and');

      return (
        <Tab
          eventKey={ formattedTeamName }
          key={ formattedTeamName }
          title={ <span><Badge className={ `team-${formattedTeamName}` }>{ team }</Badge></span> }
        >
          {
            formattedTeamName === currentDraftTab
              ? (
                <DraftConfigurationTab
                  apartment={ apartment }
                  team={ team }
                  fetchApartment={ () => this.fetchApartment() }
                />
              )
              : <div />
          }
        </Tab>
      );
    });

    return (
      <Tabs id="apartment-tabs" defaultActiveKey={ currentDraftTab } onSelect={ (tab) => this.toggleDraftTab(tab) }>
        { mappedTabs }
      </Tabs>
    );
  }

  render() {
    const { apartment } = this.state;
    const { history } = this.props;

    if (!('name' in apartment)) {
      return (
        <div className="text-center">
          <h2>
            <i className="fas fa-spinner fa-spin" />
          </h2>
        </div>
      );
    }

    const forestApartmentLink = buildForestLink(apartment.id, 'apartment');

    return (
      <div className="ShowApartment mb-4">
        <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
          <GoBackDashboard history={ history } defaultLink={ `/apartments/dashboard/${apartment.status}` } />
          <div className="text-center">
            <h3>
              { apartment.reference }
              {' '}
              -
              { apartment.name }
            </h3>
            <Button size="sm" className={ `btn-label btn-confirmed-${apartment.CityId} mr-2` } disabled>
              { capitalize(apartment.CityId) }
            </Button>
            <Button size="sm" className={ `btn-label btn-${apartment.status}` } disabled>
              { capitalize(apartment.status) }
            </Button>
              {apartment.keyTypes.find(({value}) => value === 'salto') && (
                 <Button size="sm" className={`btn-label btn-active ml-2`} disabled>
                     Salto
                 </Button>
              )}
          </div>
          <div className="text-right">
            <a href={ forestApartmentLink } rel="noopener noreferrer" target="_blank">
              <i className="icon fa fa-external-link-alt fa-lg" />
            </a>
          </div>
        </div>
        {
          apartment.status !== 'draft'
            ? this.renderDefaultTabs()
            : this.renderDraftTabs()
        }
      </div>
    );
  }
}

export default Show;
