import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import { checkValidity } from '../../../utils/emailsHelpers';
import EditableField from '../../EditableField';
import RoundButton from '../../RoundButton';

const TRANSPORTATIONS = [
  'bike',
  'scooter',
  'car',
  'truck',
  'public transports',
];

function Main(props) {
  const {
    contractor, activateContractor, updateContractor, userRole,
  } = props;
  const {
    CityId,
    InterventionTypes,
    TechnicalInterventions,
  } = contractor;

  const [displayModal, toggleModal] = useState(false);
  const [modalDescription, setDescription] = useState('');
  const [modalNotes, setNotes] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [fieldsToUpdate, editField] = useState({});

  function showDescription(description, notes) {
    setDescription(description);
    setNotes(notes);
    toggleModal(!displayModal);
  }

  function toggleEditMode() {
    editField({});
    setEditMode(!editMode);
  }

  function validateFields() {
    const requiredFields = ['firstName', 'lastName', 'gender', 'email'];

    let isValid = requiredFields.map((fieldName) => {
      if (fieldName in fieldsToUpdate) {
        return !isEmpty(fieldsToUpdate[fieldName]);
      }

      return true;
    }).every(Boolean);

    if ('email' in fieldsToUpdate) {
      isValid = isValid && checkValidity(fieldsToUpdate.email);
    }

    return isValid;
  }

  function editContractor() {
    if (!validateFields()) {
      toast('Some required fields are missing.', {
        type: toast.TYPE.ERROR,
      });
      return;
    }

    if (!isEmpty(fieldsToUpdate)) {
      updateContractor(fieldsToUpdate);
    }

    toggleEditMode();
  }

  const responsibleTeam = InterventionTypes.length === 1 && InterventionTypes.map(({ name }) => name)[0] === 'checkout'
    ? 'HAPPI'
    : 'PROP';

  const defaultEditableFieldProps = {
    editFieldValue: (name, value) => editField({ ...fieldsToUpdate, [name]: value }),
    editMode,
    fieldsToUpdate,
    team: responsibleTeam,
  };

  function getValue(fieldName) {
    if (editMode && fieldName in fieldsToUpdate) {
      return fieldsToUpdate[fieldName];
    }

    return contractor[fieldName];
  }

  function formatIban() {
    const value = getValue('iban');

    if (contractor.status === 'draft' && !value) {
      return '';
    }

    const rIban = /(\w{2}\d{2})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})(\d{3})/;
    const matchIban = value.match(rIban);

    return matchIban
      ? matchIban.slice(1).join(' ')
      : value;
  }

  const approvalNeeded = !isEmpty(getValue('iban'))
    && !isEmpty(getValue('bic'))
    && contractor.status === 'draft'
    && userRole === 'admin';

  const activeInterventions = TechnicalInterventions
    .filter(({ status }) => ['scheduled', 'ongoing'].includes(status))
    .sort((a, b) => b.status - a.status)
    .map((intervention) => {
      const {
        Apartment,
        description,
        expectedStartDate,
        id,
        notes,
        Room,
        status,
        subType,
        type,
      } = intervention;

      const mappedSubtypes = subType && subType.split(',').map((sub) => (
        <Button
          key={ sub }
          variant="dark"
          size="sm"
          className="btn-label mr-2"
          disabled
        >
          { sub }
        </Button>
      ));

      return (
        <tr key={ id }>
          <td>
            <Link to={ `/apartments/${Apartment.id}` } rel="noopener noreferrer" target="_blank">
              { Apartment.name }
            </Link>
          </td>
          <td>{ Room ? `Room #${Room.number}` : 'Common Areas' }</td>
          <td>
            <Button key={ type } variant="dark" size="sm" className="btn-label" disabled>
              { type }
            </Button>
          </td>
          <td>
            {
              subType
                ? mappedSubtypes
                : 'None'
            }
          </td>
          <td>
            <Button size="sm" className={ `btn-label btn-${status}` } disabled>
              { capitalize(status) }
            </Button>
          </td>
          <td>{ moment(expectedStartDate).format('DD/MM/YYYY') }</td>
          <td className="text-right">
            <Button size="sm" variant="dark" className="mr-2" onClick={ () => showDescription(description, notes) }>
              <i className="icon fas fa-eye" />
              Details
            </Button>
            <Link
              to={ `/interventions/${id}` }
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn-sm btn-primary"
            >
              Go to intervention
              <i className="fa fa-arrow-right ml-2" />
            </Link>
          </td>
        </tr>
      );
    });

  return (
    <div className="main-tab">
      <Row>
        <Col>
          <Card className="table-card">
            <Card.Header>
              <strong>Personal information</strong>
            </Card.Header>
            <Card.Body>
              <Table>
                <tbody>
                  <tr>
                    <th>
                      First name
                      { editMode && '*' }
                    </th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="firstName"
                        value={ getValue('firstName') }
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Last name
                      { editMode && '*' }
                    </th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="lastName"
                        value={ getValue('lastName') }
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Gender
                      { editMode && '*' }
                    </th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="select"
                        name="gender"
                        options={ ['male', 'female'] }
                        value={ capitalize(getValue('gender')) }
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Company</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="company"
                        value={ getValue('company') }
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="table-card">
            <Card.Header>
              <strong>Contact information</strong>
            </Card.Header>
            <Card.Body>
              <Table>
                <tbody>
                  <tr>
                    <th>City</th>
                    <td>
                      <Button size="sm" className={ `btn-label btn-confirmed-${CityId}` } disabled>
                        { capitalize(CityId) }
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Email
                      { editMode && '*' }
                    </th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="email"
                        name="email"
                        value={ getValue('email') }
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Phone number</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        name="phoneNumber"
                        value={ getValue('phoneNumber') }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Transportation</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        inputType="flags"
                        name="transportation"
                        options={ TRANSPORTATIONS }
                        value={ getValue('transportation') }
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <Card className="table-card">
            <Card.Header>
              <strong>Payment details</strong>
            </Card.Header>
            <Card.Body>
              <Table>
                <tbody>
                  <tr>
                    <th>IBAN</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        editMode={ editMode && contractor.status === 'draft' }
                        inputType="iban"
                        name="iban"
                        value={ formatIban() }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>BIC</th>
                    <td>
                      <EditableField
                        { ...defaultEditableFieldProps }
                        editMode={ editMode && contractor.status === 'draft' }
                        name="bic"
                        value={ getValue('bic') }
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
              {/* TODO: do not use user role here, use user team instead */}
              {/* (we currently do not store the user's team...) */}
              {
                approvalNeeded && !editMode
                  ? (
                    <div className="mt-2 mb-4 text-center">
                      <Button variant="success" onClick={ activateContractor }>
                        <i className="icon fas fa-check" />
                        Approve billing info
                      </Button>
                    </div>
                  )
                  : null
              }
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6">
          <Card>
            <Card.Header>
              <strong>Notes</strong>
            </Card.Header>
            <Card.Body>
              <EditableField
                { ...defaultEditableFieldProps }
                inputType="textarea"
                name="notes"
                value={ getValue('notes') }
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="table-card">
            <Card.Header>
              <strong>Scheduled and Ongoing interventions</strong>
            </Card.Header>
            {
              activeInterventions && activeInterventions.length !== 0
                ? (
                  <Card.Body>
                    <Table>
                      <thead>
                        <tr>
                          <th>Apartment</th>
                          <th>Area</th>
                          <th>Type</th>
                          <th>Sub Type</th>
                          <th>Status</th>
                          <th>Expected Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        { activeInterventions }
                      </tbody>
                    </Table>
                  </Card.Body>
                )
                : (
                  <Card.Body>
                    <Jumbotron className="text-center">
                      <h1>No interventions</h1>
                      <p>
                        There are currently no scheduled or ongoing interventions for this contractor.
                      </p>
                    </Jumbotron>
                  </Card.Body>
                )
            }
          </Card>
        </Col>
      </Row>
      <RoundButton
        editMode={ editMode }
        toggleEditMode={ toggleEditMode }
        save={ editContractor }
      />
      {
        displayModal
          ? (
            <Modal size="lg" show={ displayModal } onHide={ () => showDescription('', '') }>
              <Modal.Header>
                <Modal.Title>Description & Notes</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h4>Description</h4>
                <p style={{ whiteSpace: 'pre-line' }}>
                  { modalDescription || 'None.' }
                </p>
                <h4>Notes</h4>
                <p>
                  { modalNotes || 'None.' }
                </p>
              </Modal.Body>
            </Modal>
          )
          : null
      }
    </div>
  );
}

export default Main;
