import React, { useEffect, useState } from 'react';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';

import ForestApi from '../../../utils/API';
import CityLabel from '../../CityLabel';
import { renderCheckinDateFormat } from '../../../utils/formatCheckinDate';

const getTenancyFileProps = (guarantorCount) => {
  const noTenancyFileNeeded = {
    title: 'No tenancy file needed',
    status: {
      done: '',
    },
    icon: 'fas fa-clipboard-check',
    documents: [],
  };

  const tenancyFileProp = {
    0: {
      title: 'Tenancy file',
      icon: 'fas fa-clipboard-check',
      documents: ['client-finances'],
    },
    1: {
      title: 'Single guarantor',
      icon: 'fas fa-user-tie',
      documents: ['client-guarantor-finances', 'client-guarantor-identity'],
    },
    3: {
      title: 'Garant|me',
      icon: 'fab fa-google',
      documents: ['client-garantme-finances'],
    },
  };

  return tenancyFileProp[guarantorCount] || noTenancyFileNeeded;
};

const EMERGENCY_CONTACT_FIELDS = [
  'emergencyContactKind',
  'emergencyEmail',
  'emergencyFullName',
  'emergencyPhoneNumber',
];

const iconStatus = {
  done: 'check-circle text-success',
  onGoing: 'hourglass-half text-warning',
  notDone: 'times-circle text-danger',
};

function ApplicationTab(props) {
  const { client } = props;

  const { Rentings } = client;

  const renting = Rentings.find(renting => renting.status === 'booked');

  const {
    Checkin,
    checkinDate,
    checkinDateApproved,
    checkinSpotSafeNumber,
    Room: {
      Apartment: { CityId },
      name,
    },
  } = renting;

  const [secondaryDocuments, getSecondaryDocuments] = useState([]);

  useEffect(() => {
    const { SecondaryClientId } = renting;

    if (SecondaryClientId) {
      const query = `documentable=Client&DocumentableId=${SecondaryClientId}`;

      new ForestApi().documents()
        .getAll(query)
        .then((res) => getSecondaryDocuments(res.data));
    }
  }, [renting]);

  const tenancyFileApproved = getTenancyFileProps(client.guarantorCount);

  const getKeyNumber = checkinSpotSafeNumber === -1
    ? 'keynest'
    : `key ${checkinSpotSafeNumber}`;

  const checkinSteps = [{
    field: 'idChecked',
    title: `${renting.hasTwoOccupants ? '2x IDs' : 'ID'} & selfie`,
    status: {
      done: ' approved',
      onGoing: ' uploaded',
      notDone: ' missing',
    },
    icon: `fas fa-${renting.hasTwoOccupants ? 'clone' : 'address-card'}`,
    documents: ['client-selfie', 'client-identity'],
  }, {
    field: 'tenancyFileApproved',
    status: {
      done: ' approved',
      onGoing: ' uploaded',
      notDone: ' missing',
    },
    ...tenancyFileApproved,
  }, {
    field: 'emergencyContactChecked',
    title: 'Emergency contact',
    status: {
      done: ' approved',
      onGoing: ' uploaded',
      notDone: ' missing',
    },
    icon: 'fas fa-first-aid',
    documents: [],
  }, {
    field: 'leaseSent',
    title: 'Lease',
    status: {
      done: ' sent',
      onGoing: ' not sent',
      notDone: ' not sent',
    },
    icon: 'fas fa-file-export',
    documents: [],
  }, {
    field: 'leaseSigned',
    title: 'Lease',
    status: {
      done: ' signed',
      onGoing: ' not signed',
      notDone: ' not signed',
    },
    icon: 'fas fa-file-signature',
    documents: [],
  }, {
    field: 'rentsPaid',
    title: 'Rents',
    status: {
      done: ' paid',
      notDone: ' not paid',
    },
    icon: 'fas fa-file-invoice-dollar',
    documents: [],
  }, {
    field: Checkin.depositPaid,
    title: 'Deposit',
    status: {
      done: ' paid',
      notDone: ' not paid',
    },
    icon: 'fas fa-file-invoice-dollar',
    documents: [],
  }, {
    field: 'checkinSpotSafeNumber',
    title: checkinSpotSafeNumber ? getKeyNumber : 'keys',
    status: {
      done: ' assigned',
      onGoing: ' waiting for spot number',
      notDone: ' not assigned',
    },
    icon: 'fas fa-key',
    documents: [],
  }];

  function findDocument(field, documents) {
    const uploadedDocuments = client.Documents
      .filter(({ type }) => documents.includes(type)).length >= documents.length;

    if (field === 'idChecked' && renting.hasTwoOccupants) {
      return uploadedDocuments && secondaryDocuments.find(({ type }) => type === 'client-identity');
    }
    return uploadedDocuments;
  }

  function getStatus(field, check, documents) {
    const firstOrder = renting.OrderItems[0].Order;
    if (documents.length > 0) {
      if (!findDocument(field, documents)) {
        return 'notDone';
      } else if (!check) {
        return 'onGoing';
      }
      return 'done';
    }

    if (field === 'emergencyContactChecked' && !check) {
      return !EMERGENCY_CONTACT_FIELDS.find((label) => client[label] === null)
        ? 'onGoing'
        : 'notDone';
    }

    if (field === 'leaseSent' && !check) {
      return (
        renting.idChecked
        && renting.tenancyFileApproved
        && Checkin.depositPaid
        && firstOrder?.Amount?.amount === firstOrder?.TotalPaid?.totalPaid
      )
        ? 'onGoing'
        : 'notDone';
    }

    if (field === 'leaseSigned' && !check) {
      return renting.leaseSent
        ? 'onGoing'
        : 'notDone';
    }

    if (field === 'checkinSpotSafeNumber' && !check) {
      if (renting.checkinDateApproved) return 'onGoing';
      if (renting.checkinSpotSafeNumber) return 'done';
      if (!renting.checkinDateApproved) return 'notDone';
    }

    if (field === 'rentsPaid' && !check) {
      return firstOrder?.Amount?.amount === firstOrder?.TotalPaid?.totalPaid
        ? 'done'
        : 'notDone';
    }

    return check ? 'done' : 'notDone';
  }

  function getCompletion(status) {
    const count = checkinSteps.reduce((acc, { field, documents }) => {
      const isCheck = typeof field === 'string' ? renting[field] : field;

      const checkinStepStatus = getStatus(field, isCheck, documents);

      if (checkinStepStatus === status) {
        return acc + 1;
      }

      return acc;
    }, 0);

    return (count / checkinSteps.length) * 100;
  }

  function renderIcon({ icon, title, status }) {
    return (
      <div key={ title } className="checkin-process-icon">
        <i
          className={ `${icon} fa-2x mb-4` }
          data-toggle="tooltip"
          title={ title }
        />
        <i className={ `fas fa-${status} fa-lg` } />
      </div>
    );
  }

  const icons = checkinSteps.map((step) => {
    const {
      field, title, status, icon, documents,
    } = step;

    const rentingField = typeof field === 'string' ? renting[field] : field;

    const checkinStepTitle = title + status[getStatus(field, rentingField, documents)];

    const checkinStepStatus = iconStatus[getStatus(field, rentingField, documents)];

    return renderIcon({ icon, title: checkinStepTitle, status: checkinStepStatus });
  });

  const doneCompletion = getCompletion('done');
  const onGoingCompletion = getCompletion('onGoing');

  return (
    <div className="client-application">
      <div className="application-header">
        <div className="renting-data">
          <CityLabel city={ CityId } />
          <Button variant="dark" size="sm" className="btn-label ml-2" disabled>{ name }</Button>
        </div>
        <div className="checkin-date">
          <Badge variant="dark" className="mr-2">C/I</Badge>
          {
          checkinDate
            ? renderCheckinDateFormat(checkinDate, checkinDateApproved)
            : <span> Not specified </span>
        }
        </div>
        <div className="progress-card">
          <ProgressBar>
            <ProgressBar
              animated
              variant="success"
              now={ doneCompletion }
              label={ `${doneCompletion}%` }
              key={ 1 }
            />
            <ProgressBar
              animated
              variant="warning"
              now={ onGoingCompletion }
              label={ `${onGoingCompletion}%` }
              key={ 2 }
            />
          </ProgressBar>
        </div>
      </div>
      <div className="checkin-process-row">
        { icons }
      </div>
    </div>
  );
}

export default ApplicationTab;
