import React from 'react';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import truncate from 'lodash/truncate';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import FormCheck from 'react-bootstrap/FormCheck';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import pluralize from '../../utils/pluralize';
import './style.scss';

function CheckboxFilter(props) {
  const { name, filter, handleFilterChange } = props;

  const selectAllValues = () => {
    const isActive = filter.currentValue.length === filter.values.length;
    const newValue = isActive
      ? filter.values.slice(0, 1)
      : filter.values;

    return (
      <div className="filter all">
        <Button
          variant="link"
          onClick={ () => handleFilterChange(name, newValue) }
        >
          {
              isActive
                ? 'Clear All'
                : 'Select All'
            }
        </Button>
      </div>
    );
  };

  const mappedValues = filter.values.map((value) => {
    const isActive = filter.currentValue.includes(value);
    let newValue = [];

    if (isActive) {
      newValue = filter.currentValue.filter((v) => v !== value);
    } else {
      newValue = [...filter.currentValue, value];
    }

    return (
      <div key={ `${filter.label}-${value}` } className="filter">
        {
          isActive
            ? <i className="icon far fa-check-circle checked" />
            : <i className="icon far fa-circle" />
        }
        <Button
          variant="link"
          className={ isActive ? 'checked' : '' }
          onClick={ () => handleFilterChange(name, newValue) }
        >
          { `${capitalize(truncate(value, { length: 15 }))}` }
        </Button>
      </div>
    );
  });

  return (
    <>
      <div className="filters-section-title">{ capitalize(filter.label) }</div>
      { mappedValues }
      { selectAllValues() }
    </>
  );
}

function DateFilter(props) {
  const { name, filter, handleFilterChange } = props;

  const parseDate = (value) => (value ? moment(value) : null);

  return (
    <>
      {
        filter.label
          ? <div className="filters-section-title">{ capitalize(filter.label) }</div>
          : null
      }
      <div className="date-filter">
        <div className="date-icon">
          <i className="fas fa-calendar-alt" />
        </div>
        <input
          type="date"
          onChange={ (e) => handleFilterChange(name, parseDate(e.target.value)) }
        />
      </div>
    </>
  );
}

function DateRangeFilter(props) {
  const { name, filter, handleFilterChange } = props;

  const locale = {
    format: 'DD/MM/YYYY',
    firstDay: 1,
  };

  const [startDate, endDate] = filter.currentValue.map((value) => value.format('DD/MM/YYYY'));

  const today = moment();

  const ranges = {
    Today: [today, today],
    'Last 7 Days': [moment().subtract(6, 'days'), today],
    'Last 30 Days': [moment().subtract(29, 'days'), today],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    ...(!filter.pastOnly && {
      'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
    }),
  };

  const handlePickerChange = (picker) => handleFilterChange(name, [picker.startDate, picker.endDate]);

  const displayRange = startDate && endDate ? `${startDate} - ${endDate}` : '';

  return (
    <>
      <div className="filters-section-title d-flex align-items-center justify-content-between">
        { capitalize(filter.label) }
        {
          displayRange
            ? <Button size="sm" variant="link" onClick={ () => handleFilterChange(name, []) }>Reset</Button>
            : null
        }
      </div>
      <DateRangePicker
        locale={ locale }
        startDate={ startDate }
        endDate={ endDate }
        ranges={ ranges }
        onApply={ (event, picker) => handlePickerChange(picker) }
      >
        <div className="date-filter">
          <div className="date-icon">
            <i className="fas fa-calendar-alt" />
          </div>
          <input type="text" value={ displayRange } readOnly />
        </div>
      </DateRangePicker>
    </>
  );
}

function RadioFilter(props) {
  const { name, filter, handleFilterChange } = props;

  const mappedValues = filter.values.map((value) => {
    const isActive = filter.currentValue === value;

    return (
      <div
        key={ `${filter.label}-${value}` }
        className="filter"
        onClick={ () => handleFilterChange(name, value) }
      >
        {
          isActive
            ? <i className="icon far fa-check-circle checked" />
            : <i className="icon far fa-circle" />
        }
        <Button
          variant="link"
          className={ isActive ? 'checked' : '' }
        >
          { `${capitalize(truncate(value, { length: 15 }))}` }
        </Button>
      </div>
    );
  });

  return (
    <>
      <div className="filters-section-title">{ capitalize(filter.label) }</div>
      { mappedValues }
    </>
  );
}

function SearchFilter(props) {
  const { name, filter, handleFilterChange } = props;

  return (
    <div className="search-bar">
      <div className="search-icon">
        <i className="fas fa-search" />
      </div>
      <input
        type="text"
        placeholder={ filter.placeholder }
        defaultValue={ filter.currentValue }
        onChange={ (e) => handleFilterChange(name, e.target.value) }
      />
    </div>
  );
}

function TagsFilter(props) {
  const { name, filter, handleFilterChange } = props;

  const mappedOptions = filter.values
    .filter((value) => !filter.currentValue.includes(value))
    .map((value) => (
      <option key={ `${name}-${value}` }>
        { value }
      </option>
    ));

  const mappedValues = filter.currentValue
    .map((value) => (
      <div key={ `${name}-${value}` } className="filter">
        <i className="icon far fa-check-circle checked" />
        <Button
          variant="link"
          className="checked"
          onClick={ () => handleFilterChange(name, filter.currentValue.filter((v) => v !== value)) }
        >
          { capitalize(truncate(value, { length: 20 })) }
        </Button>
      </div>
    ));

  return (
    <div className="tags-filter">
      <div className="filters-section-title mb-2">{ capitalize(filter.label) }</div>
      <select
        className="form-control"
        onChange={ (e) => handleFilterChange(name, [...filter.currentValue, e.target.value]) }
      >
        <option value="">Pick one...</option>
        { mappedOptions }
      </select>
      { mappedValues }
    </div>
  );
}

class Dashboard extends React.Component {
  getFilter(name, filter) {
    const { handleFilterChange } = this.props;
    const { label } = filter;

    const filterProps = {
      name,
      filter,
      handleFilterChange,
    };

    const filterTypes = {
      checkbox: <CheckboxFilter key={ label } { ...filterProps } />,
      date: <DateFilter key={ label } { ...filterProps } />,
      daterange: <DateRangeFilter key={ label } { ...filterProps } />,
      radio: <RadioFilter key={ label } { ...filterProps } />,
      search: <SearchFilter key={ label } { ...filterProps } />,
      tags: <TagsFilter key={ label } { ...filterProps } />,
    };

    return filterTypes[filter.type];
  }

  renderSidebarFilters() {
    const { filters } = this.props;

    return Object.keys(filters).map((name) => (
      <div key={ name } className="filters-section">
        { this.getFilter(name, filters[name]) }
      </div>
    ));
  }

  renderTable() {
    const {
      tableHeader,
      items,
      toggleAllRows,
      renderPlaceholder,
    } = this.props;

    return (
      <>
        {
          (items && items.length === 0)
            ? renderPlaceholder()
            : (
              <Col>
                <Table size="sm">
                  <thead>
                    <tr>
                      <th>
                        <FormCheck
                          aria-label="Select/Unselect all"
                          onClick={ () => toggleAllRows() }
                        />
                      </th>
                      { tableHeader }
                    </tr>
                  </thead>
                  <tbody>
                    { items }
                  </tbody>
                </Table>
              </Col>
            )
        }
      </>
    );
  }

  renderCards() {
    const {
      sorting,
      items,
      handleSortingChange,
      handleElementClick,
      renderPlaceholder,
    } = this.props;

    const sortingRow = sorting
      ? (
        <Row className="pt-4 mb-3">
          <Col lg="1" className="d-flex align-items-center">
            Sort by:
          </Col>
          <Col lg="3">
            <FormControl as="select" onChange={ (e) => handleSortingChange(e.target.value) }>
              {
                Object.keys(sorting).map((key) => <option key={ key } value={ key }>{ sorting[key] }</option>)
              }
            </FormControl>
          </Col>
        </Row>
      )
      : null;

    const mappedData = items.map((card) => {
      const {
        id,
        description,
        tags,
        subtitle,
        title,
      } = card;

      return (
        <Col key={ id } xl="4" lg="6" className="mt-4 d-flex align-items-stretch">
          <Card className="dashboard-card" onClick={ () => handleElementClick(id) }>
            <Card.Body>
              <Card.Title>{ title }</Card.Title>
              {
                subtitle
                  ? <Card.Subtitle className="mb-3">{ subtitle }</Card.Subtitle>
                  : null
              }
              {
                tags
                  ? (
                    <Card.Subtitle className="mb-3">
                      <div className="tags">
                        { tags }
                      </div>
                    </Card.Subtitle>
                  )
                  : null
              }
              <div className="card-text">{ description }</div>
            </Card.Body>
          </Card>
        </Col>
      );
    });

    return (
      <>
        { sortingRow }
        {
          (items && items.length === 0)
            ? renderPlaceholder()
            : (
              <Row>
                { mappedData }
              </Row>
            )
        }
      </>
    );
  }

  render() {
    const {
      model,
      items,
      layout = 'cards',
      isLoading,
    } = this.props;

    const renderLayout = layout === 'cards'
      ? this.renderCards()
      : this.renderTable();

    return (
      <div className="Dashboard container-fluid">
        <Row className="h-100">
          <Col lg="2" className="filters-sidebar">
            <div className="filters-section">
              <div className="results-count">
                { pluralize(items.length, model.toLowerCase()) }
              </div>
            </div>
            { this.renderSidebarFilters() }
          </Col>
          <Col lg="10" className="items-container">
            {
              isLoading
                ? (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    <i className="fas fa-spinner fa-spin fa-3x" />
                  </div>
                )
                : renderLayout
            }
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
