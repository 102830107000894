import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import capitalize from 'lodash/capitalize';

import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import buildForestLink from '../../utils/buildForestLink';
import ForestApi from '../../utils/API';
import FeaturesTab from './tabs/features';
import MainTab from './tabs/main';
import PicturesTab from './tabs/pictures';
import './style.scss';
import GoBackDashboard from '../shared/GoBackDashboard';

function Show(props) {
  const {
    roomId,
    history,
  } = props;
  const [room, setRoom] = useState({});
  const {
    Apartment,
  } = room;

  function fetchRoom() {
    new ForestApi().rooms()
      .getOne(roomId)
      .then((res) => setRoom(res.data));
  }

  useEffect(() => {
    let mounted = true;

    if (!room.id) {
      new ForestApi().rooms()
        .getOne(roomId)
        .then((res) => { if (mounted)setRoom(res.data); });
    }

    return () => { mounted = false; };
  }, [room.id, roomId]);

  function updateRoom(data) {
    new ForestApi().rooms()
      .update(roomId, data)
      .then((res) => {
        toast('Room updated!', {
          type: toast.TYPE.SUCCESS,
        });

        setRoom({
          ...room,
          ...res.data,
        });
      });
  }

  const forestRoomLink = buildForestLink(room.id, 'room');

  if (!('id' in room)) {
    return (
      <div className="text-center">
        <h2>
          <i className="fas fa-spinner fa-spin" />
        </h2>
      </div>
    );
  }

  const { twoOccupantsAllowed } = room;
  const hasPrivateBathroom = room.Terms.find(({ name }) => name === 'privateBathroom');
  const hasPrivateBalcony = room.Terms.find(({ name }) => name === 'privateBalcony');

  return (
    <div className="ShowRoom mb-4">
      <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
        <GoBackDashboard history={ history } defaultLink="/rooms/dashboard" />
        <div className="text-center">
          <h3>
            { room.name }
          </h3>
          <button
            className={ `btn btn-sm btn-label btn-confirmed-${Apartment.CityId} mr-2` }
            disabled
            type="button"
          >
            { capitalize(Apartment.CityId) }
          </button>
          <Button
            size="sm"
            className={ `btn-label btn-${room.status} mr-2` }
            disabled
          >
            { capitalize(room.status) }
          </Button>
          <i
            title="Two occupants"
            className={ `icon fas fa-user-friends fa-lg text-${twoOccupantsAllowed ? 'primary' : 'secondary'}` }
          />
          <i
            title="Private bathroom"
            className={ `icon fas fa-bath fa-lg text-${hasPrivateBathroom ? 'primary' : 'secondary'}` }
          />
          <i
            title="Private balcony"
            className={ `fas fa-store fa-lg text-${hasPrivateBalcony ? 'primary' : 'secondary'}` }
          />
        </div>
        <div className="text-right">
          <a href={ forestRoomLink } rel="noopener noreferrer" target="_blank">
            <i className="icon fa fa-external-link-alt fa-lg" />
          </a>
        </div>
      </div>
      <Tabs defaultActiveKey="main" id="room-tabs">
        <Tab
          eventKey="main"
          title={ (
            <span>
              <i className="icon fas fa-bed" />
              Main data
            </span>
          ) }
        >
          <MainTab room={ room } fetchRoom={ fetchRoom } updateRoom={ updateRoom } />
        </Tab>
        <Tab
          eventKey="pictures"
          title={ (
            <span>
              <i className="icon far fa-image" />
              Pictures
            </span>
          ) }
        >
          <PicturesTab pictures={ room.Pictures } />
        </Tab>
        <Tab
          eventKey="features"
          title={ (
            <span>
              <i className="icon fas fa-check" />
              Features
            </span>
          ) }
        >
          <FeaturesTab
            room={ room }
            updateRoom={ updateRoom }
          />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Show;
