import React from 'react';
import _ from 'lodash';

import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import ForestApi from '../../utils/API';
import buildForestLink from '../../utils/buildForestLink';
import EmailsTab from './tabs/emails';
import MainTab from './tabs/main';
import PicturesTab from './tabs/pictures';
import WorkflowTab from './tabs/workflow';
import GoBackDashboard from '../shared/GoBackDashboard';

class ShowIntervention extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      intervention: {},
    };
  }

  componentDidMount() {
    this.fetchIntervention();
  }

  fetchIntervention() {
    const { interventionId } = this.props;

    new ForestApi().interventions()
      .getOne(interventionId)
      .then((res) => {
        const intervention = res.data;

        this.setState({ intervention });
      });
  }

  render() {
    const { userRole, history } = this.props;
    const { intervention } = this.state;

    if (!('type' in intervention)) {
      return (
        <div className="text-center">
          <h2>
            <i className="fas fa-spinner fa-spin" />
          </h2>
        </div>
      );
    }

    const {
      Apartment,
      Emails,
      id,
      Pictures,
      propertyManagerName,
      status,
      type,
      Workflow,
    } = intervention;

    const forestInterventionLink = buildForestLink(id, 'intervention');

    return (
      <div className="ShowIntervention mb-4">
        <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
          <GoBackDashboard history={ history } defaultLink="/interventions/dashboard" />
          <div className="text-center">
            <h3>
              { Apartment.name }
              {' '}
              -
              { _.capitalize(Apartment.CityId) }
            </h3>
            <Button size="sm" className={ `btn-label btn-${status} mr-2` } disabled>{ _.capitalize(status) }</Button>
            <Button size="sm" variant="dark" className="btn-label mr-2" disabled>{ _.capitalize(type) }</Button>
            <Button size="sm" variant="secondary" className="btn-label" disabled>
              { _.capitalize(propertyManagerName) }
            </Button>
          </div>
          <div className="text-right">
            <a href={ forestInterventionLink } rel="noopener noreferrer" target="_blank">
              <i className="icon fa fa-external-link-alt fa-lg" />
            </a>
          </div>
        </div>
        <Tabs defaultActiveKey="main" id="intervention-tabs">
          <Tab
            eventKey="main"
            title={ (
              <span>
                <i className="icon fas fa-tools" />
                Main data
              </span>
) }
          >
            <MainTab intervention={ intervention } fetchIntervention={ () => this.fetchIntervention() } />
          </Tab>
          <Tab
            eventKey="pictures"
            title={ (
              <span>
                <i className="icon far fa-image" />
                Pictures
              </span>
) }
          >
            <PicturesTab
              pictures={ Pictures }
              interventionId={ intervention.id }
              fetchIntervention={ () => this.fetchIntervention() }
            />
          </Tab>
          <Tab
            eventKey="emails"
            title={ (
              <span>
                <i className="icon fas fa-envelope" />
                Emails
              </span>
) }
          >
            <EmailsTab workflow={ Workflow } emails={ Emails } />
          </Tab>
          {
            userRole === 'admin'
              ? (
                <Tab
                  eventKey="workflow"
                  title={ (
                    <span>
                      <i className="icon fas fa-cogs" />
                      Workflow
                    </span>
) }
                >
                  <WorkflowTab workflow={ Workflow } />
                </Tab>
              )
              : null
          }
        </Tabs>
      </div>
    );
  }
}

export default ShowIntervention;
