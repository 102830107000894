import React from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ImageViewer from '../../ImageViewer';

const PICTURES_TYPES = [
  'before',
  'after',
];

function PicturesTab(props) {
  const { pictures, interventionId, fetchIntervention } = props;

  return (
    <div className="Intervention-PicturesTab">
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <strong>Before / after pictures</strong>
            </Card.Header>
            <Card.Body>
              <ImageViewer
                pictures={ pictures }
                types={ PICTURES_TYPES }
                picturable="TechnicalIntervention"
                PicturableId={ interventionId }
                fetchData={ () => fetchIntervention() }
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PicturesTab;
