import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import ForestApi from '../../utils/API';

const getTenancyFileProps = (guarantorCount) => {
  let title = 'Tenancy file approved';
  let icon = 'fas fa-clipboard-check';

  if (guarantorCount == null) {
    title = 'No tenancy file needed';
  }

  if (guarantorCount === 1) {
    title = 'Single guarantor approved';
    icon = 'fas fa-user-tie';
  }

  if (guarantorCount === 3) {
    title = 'Garant|me approved';
    icon = 'fab fa-google';
  }

  return {
    title,
    icon,
    disabled: guarantorCount == null,
  };
};

class CheckinSteps extends React.Component {
  constructor(props) {
    super(props);

    const { renting } = props;
    const { Client, hasTwoOccupants } = renting;
    const tenancyFileApproved = getTenancyFileProps(Client.guarantorCount);

    this.state = {
      checkinSteps: [{
        field: 'idChecked',
        title: `${hasTwoOccupants ? '2x IDs' : 'ID'} & selfie approved`,
        icon: `fas fa-${hasTwoOccupants ? 'clone' : 'address-card'}`,
      }, {
        field: 'tenancyFileApproved',
        ...tenancyFileApproved,
      }, {
        field: 'emergencyContactChecked',
        title: 'Emergency contact approved',
        icon: 'fas fa-first-aid',
      }, {
        field: 'leaseSent',
        title: 'Lease sent',
        icon: 'fas fa-file-export',
      }, {
        field: 'leaseSigned',
        title: 'Lease signed',
        icon: 'fas fa-file-signature',
      }],
    };
  }

  async handleCheckinStepClick(rentingId, step, currentValue) {
    const { updateView } = this.props;
    const newValue = currentValue == null ? true : !currentValue;
    const data = {
      [step.field]: newValue,
    };

    await new ForestApi().rentings().update(rentingId, data);

    toast(`${step.title}: ${newValue ? 'checked' : 'unchecked'}`, {
      type: toast.TYPE.SUCCESS,
    });
    updateView(rentingId, data);
  }

  renderRentsPopover() {
    const { renting: { Client, OrderItems } } = this.props;
    let orderItems = OrderItems;

    if (!OrderItems) {
      orderItems = Client.Orders.reduce((items, order) => {
        const rentItem = order.OrderItems.find(({ ProductId }) => ProductId === 'rent');

        if (rentItem) {
          Object.assign(rentItem, { Order: order });
          items.push(rentItem);
        }

        return items;
      }, []);
    }

    const paidIcon = (receiptNumber) => (receiptNumber
      ? 'fa-check text-success'
      : 'fa-times text-danger');

    const displayRents = orderItems
      .sort((a, b) => moment(b.Order.dueDate) - moment(a.Order.dueDate))
      .map((item) => (
        <li key={ item.id }>
          <span className="fa-li">
            <span className={ `fas ${paidIcon(item.Order.receiptNumber)}` } />
          </span>
          { item.Order.label }
        </li>
      ));

    return (
      <Popover>
        <Popover.Title>
          Last rents
        </Popover.Title>
        <Popover.Content>
          <ul className="fa-ul">
            { displayRents }
          </ul>
        </Popover.Content>
      </Popover>
    );
  }

  renderIcon({ icon, title, color }) {
    return (
      <i
        className={ `${icon} fa-lg ${color}` }
        data-toggle="tooltip"
        title={ title }
      />
    );
  }

  render() {
    const { renting } = this.props;
    const { checkinSteps } = this.state;

    const getIconColor = (check) => (check ? 'text-success' : 'text-secondary');

    const buttons = checkinSteps.map((step) => {
      const {
        field, title, icon, disabled,
      } = step;
      const rentingField = renting[field];

      const color = disabled ? 'text-dark' : getIconColor(rentingField);

      return (
        <Button
          key={ field }
          variant="link"
          onClick={ () => !disabled && this.handleCheckinStepClick(renting.id, step, rentingField) }
        >
          { this.renderIcon({ icon, title, color }) }
        </Button>
      );
    });

    const rentsPaidStep = {
      field: 'rentsPaid',
      icon: 'fas fa-file-invoice-dollar',
      title: 'Rents paid',
    };

    const depositPaidStep = {
      icon: 'fas fa-file-invoice-dollar',
      title: 'Deposit paid',
    };

    const rentsPaidIcon = this.renderIcon({
      icon: rentsPaidStep.icon,
      title: rentsPaidStep.title,
      color: getIconColor(renting[rentsPaidStep.field]),
    });

    const depositPaidIcon = this.renderIcon({
      icon: depositPaidStep.icon,
      title: depositPaidStep.title,
      color: getIconColor(renting.Checkin.depositPaid),
    });

    return (
      <div className="CheckinSteps">
        { buttons }
        <OverlayTrigger
          placement="top"
          overlay={ this.renderRentsPopover() }
        >
          <Button
            variant="link"
            onClick={ () => this.handleCheckinStepClick(renting.id, rentsPaidStep, renting.rentsPaid) }
          >
            { rentsPaidIcon }
          </Button>
        </OverlayTrigger>
        <Button variant="link">
          { depositPaidIcon }
        </Button>
      </div>
    );
  }
}

export default CheckinSteps;
