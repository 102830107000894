import React from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import range from 'lodash/range';
import startCase from 'lodash/startCase';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Table from 'react-bootstrap/Table';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

import ForestApi from '../../utils/API';
import copyText from '../../utils/copyText';
import getLanguageFlag from '../../utils/getLanguageFlag';
import Loader from '../Loader';

import './style.scss';
import StatusLabel from '../StatusLabel';
import ArrondissementLabel from "../ArrondissementLabel";
import getRentingArrondissement from "../../utils/getRentingArrondissement";
import CityLabel from "../CityLabel";

const CITIES = ['lyon', 'paris', 'lille', 'toulouse', 'montpellier', 'bordeaux'];

const DEFAULT_ORDERS = CITIES.reduce((acc, city) => ({...acc, [city]: []}), {});

const DEFAULT_HEADERS = [
    'client',
    'status',
    {label: 'city', sort: false},
    'accommodation',
    'label',
    'amount',
    'dueDate',
];

const getPaymentDelay = (order) => {
    const isRent = order.OrderItems.find(({ProductId}) => ProductId === 'rent');

    if (!isRent) {
        return 0;
    }

    const {Client: client} = order;
    const paymentDelayMetadata = client.Metadata.find((data) => data.name === 'payment-delay');

    return paymentDelayMetadata ? paymentDelayMetadata.value : 0;
};

class Receipts extends React.Component {
    constructor(props) {
        super(props);

        const defaultCity = get(props, 'params.city', 'all');

        this.state = {
            selectedMonth: moment(),
            orders: DEFAULT_ORDERS,
            paymentStatus: 'unpaid',
            isLoading: false,
            filters: [{
                name: 'city',
                options: CITIES,
                currentValue: defaultCity,
            }],
            sorting: {
                field: 'client',
                direction: 'ASC',
            },
        };
    }

    componentDidMount() {
        this.fetchOrders();
    }

    fetchOrders() {
        const {selectedMonth} = this.state;
        const dueDate = moment(selectedMonth).format('YYYY-MM-DD');

        this.setState({isLoading: true});

        new ForestApi().orders()
            .getAll(`dueDate=${dueDate}`)
            .then((res) => {
                const newOrders = res.data.reduce((acc, order) => {
                    const isGarage = !!order.OrderItems[0].GarageRentingId;
                    const CityId = get(order,
                        `OrderItems.0.${isGarage ? 'GarageRenting.Garage' : 'Renting.Room'}.Apartment.CityId`, null);

                    if (CityId) {
                        return {
                            ...acc,
                            [CityId]: [...acc[CityId], {...order, isGarage}],
                        };
                    }

                    return acc;
                }, DEFAULT_ORDERS);

                this.setState({
                    orders: newOrders,
                    isLoading: false,
                });
            });
    }

    handleSelectedMonth(monthNumber) {
        const {selectedMonth} = this.state;

        const newDate = `${selectedMonth.format('Y')}-${monthNumber}-01`;

        this.setState({
            selectedMonth: moment(newDate),
        });
    }

    handleSelectedYear(newYear) {
        const {selectedMonth} = this.state;

        this.setState({
            selectedMonth: moment(selectedMonth).set('year', newYear),
        });
    }

    handlePaymentStatus(status) {
        this.setState({paymentStatus: status});
    }

    handleFilterChange(city) {
        const {filters} = this.state;
        const cityFilter = filters.find(({name}) => name === 'city');

        this.setState({
            filters: [{
                ...cityFilter,
                currentValue: city,
            }],
        });
    }

    handleSorting(field) {
        const {sorting} = this.state;

        const newDirection = (dir) => (dir === 'DESC' ? 'ASC' : 'DESC');

        if (sorting.field === field) {
            return this.setState({
                sorting: {
                    ...sorting,
                    direction: newDirection(sorting.direction),
                },
            });
        }

        return this.setState({
            sorting: {
                field,
                direction: 'ASC',
            },
        });
    }

    sortOrders(orderA, orderB) {
        const {sorting} = this.state;
        let valueOfA;
        let
            valueOfB;
        const accommodationA = orderA.isGarage
            ? orderA.OrderItems[0].GarageRenting.Garage : orderA.OrderItems[0].Renting.Room;
        const accommodationB = orderB.isGarage
            ? orderB.OrderItems[0].GarageRenting.Garage : orderB.OrderItems[0].Renting.Room;

        if (sorting.field === 'paymentDate') {
            const paymentA = orderA.Payments[0];
            const paymentB = orderB.Payments[0];

            valueOfA = paymentA && moment(paymentA.createdAt).valueOf();
            valueOfB = paymentB && moment(paymentB.createdAt).valueOf();

            if (!paymentA && !paymentB) {
                return 0;
            }

            if (paymentA && !paymentB) {
                return 1;
            }

            if (!paymentA && paymentB) {
                return -1;
            }

            if (sorting.direction === 'ASC') {
                return valueOfA - valueOfB;
            }

            return valueOfB - valueOfA;
        }

        if (sorting.field === 'dueDate') {
            const paymentDelayA = getPaymentDelay(orderA);
            const paymentDelayB = getPaymentDelay(orderB);

            valueOfA = moment(orderA.dueDate).add(paymentDelayA, 'days').valueOf();
            valueOfB = moment(orderB.dueDate).add(paymentDelayB, 'days').valueOf();

            if (sorting.direction === 'ASC') {
                return valueOfA - valueOfB;
            }

            return valueOfB - valueOfA;
        }

        if (sorting.field === 'amount') {
            valueOfA = orderA.amount;
            valueOfB = orderB.amount;

            if (sorting.direction === 'ASC') {
                return valueOfA - valueOfB;
            }

            return valueOfB - valueOfA;
        }

        if (sorting.field === 'client') {
            valueOfA = `${orderA.Client.lastName} ${orderA.Client.firstName}`;
            valueOfB = `${orderB.Client.lastName} ${orderB.Client.firstName}`;
        }

        if (sorting.field === 'status') {
            valueOfA = orderA.Client.status;
            valueOfB = orderB.Client.status;
        }

        if (sorting.field === 'accommodation') {
            valueOfA = accommodationA.name;
            valueOfB = accommodationB.name;
        }

        if (sorting.field === 'label') {
            valueOfA = orderA.label;
            valueOfB = orderB.label;
        }

        if (sorting.direction === 'ASC') {
            return valueOfA.localeCompare(valueOfB);
        }

        return valueOfB.localeCompare(valueOfA);
    }

    renderDateSelector() {
        const {selectedMonth} = this.state;

        const displayMonth = moment(selectedMonth).format('MMMM Y');

        const currentYear = new Date().getFullYear();
        const selectedMonthNumber = moment(selectedMonth).toDate().getMonth() + 1;
        const selectedYear = moment(selectedMonth).format('Y');

        const months = moment.months().map((month, i) => <option key={month} value={i + 1}>{month}</option>);
        const years = range(2017, currentYear + 1, 1).map((year) => <option key={year}>{year}</option>);

        return (
            <OverlayTrigger
                trigger="click"
                placement="auto"
                overlay={(
                    <Popover>
                        <Popover.Title as="h3">Change month</Popover.Title>
                        <Popover.Content>
                            <div>
                                <div className="d-flex align-items-center mb-3">
                                    <FormControl
                                        as="select"
                                        value={selectedMonthNumber}
                                        onChange={(e) => this.handleSelectedMonth(e.target.value)}
                                    >
                                        {months}
                                    </FormControl>
                                    <FormControl
                                        as="select"
                                        value={selectedYear}
                                        onChange={(e) => this.handleSelectedYear(e.target.value)}
                                    >
                                        {years}
                                    </FormControl>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button size="sm" variant="success" onClick={() => this.fetchOrders()}>
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        </Popover.Content>
                    </Popover>
                )}
                rootClose
            >
                <div className="ml-4 date-selector">
                    <i className="icon far fa-calendar-alt"/>
                    {displayMonth}
                </div>
            </OverlayTrigger>
        );
    }

    renderFilters() {
        const {orders, filters, paymentStatus} = this.state;
        const cityFilter = filters.find(({name}) => name === 'city');

        const {options, currentValue} = cityFilter;

        return options.map((city) => {
            const isActive = city === currentValue;
            const variant = `${isActive ? 'confirmed' : 'not-ready'}-${city}`;
            const ordersCount = orders[city]
                .filter((order) => {
                    const isPaid = Boolean(order.receiptNumber);

                    return paymentStatus === 'paid' ? isPaid : !isPaid;
                })
                .length;

            return (
                <div key={city} className="mr-2">
                    <button
                        className={`btn btn-${variant}`}
                        onClick={() => this.handleFilterChange(city)}
                        type="button"
                    >
                        {capitalize(city)}
                        {' '}
                        <Badge variant="light" className={isActive ? 'active' : ''} pill>
                            {ordersCount}
                        </Badge>
                    </button>
                </div>
            );
        });
    }

    renderSortingHeader(field) {
        const {sorting} = this.state;

        const isFieldSelected = sorting.field === field;
        const sortDirection = sorting.direction === 'DESC' ? 'down' : 'up';

        return (
            <div className={`sortable ${isFieldSelected ? 'text-info' : ''}`}>
                {startCase(field)}
                {
                    isFieldSelected
                        ? <i className={`fas fa-sort-${sortDirection}`}/>
                        : <i className="fas fa-sort"/>
                }
            </div>
        );
    }

    renderOrderRow(order) {
        const {paymentStatus} = this.state;
        const {
            amount,
            Client: client,
            dueDate,
            id: orderId,
            label,
            OrderItems: orderItems,
            Payments: payments,
            paymentPageUrl,
            pdfInvoiceUrl,
            isGarage,
        } = order;

        const {
            id: clientId,
            email,
            firstName,
            lastName,
            phoneNumber,
            preferredLanguage,
        } = client;
        const renting = isGarage ? orderItems[0].GarageRenting : orderItems[0].Renting;
        const accommodation = isGarage ? renting.Garage : renting.Room;

        const paymentDelay = getPaymentDelay(order);
        const dueDateWithExtension = moment(dueDate).add(paymentDelay, 'days');
        const isOverdue = moment().isAfter(dueDateWithExtension);
        const dueDateColor = paymentStatus === 'unpaid' && isOverdue ? 'text-danger' : '';
        let arrondissement;
        if (accommodation.Apartment.CityId?.toLowerCase() === 'paris') {
            arrondissement = getRentingArrondissement(renting, isGarage);
        }
        return (
            <tr key={orderId}>
                <td className="d-flex align-items-center justify-content-between">
                    <div>
                        <Link to={`/clients/${clientId}`} target="_blank">
                            {firstName}
                            {' '}
                            {lastName}
                        </Link>
                    </div>
                    <div className="d-flex align-items-center">
                        <Button variant="link" size="sm" title="Copy email" onClick={() => copyText(email)}>
                            <i className="fas fa-envelope"/>
                        </Button>
                        {
                            phoneNumber
                                ? (
                                    <Button variant="link" size="sm" title="Copy phone number"
                                            onClick={() => copyText(phoneNumber)}>
                                        <i className="fas fa-phone"/>
                                    </Button>
                                )
                                : null
                        }
                        <Button variant="link" size="sm" title="Preferred language" className="btn-label" disabled>
                            {getLanguageFlag(preferredLanguage)}
                        </Button>
                    </div>
                </td>
                <td><StatusLabel status={client.status} className="mb-2"/></td>
                <td>
                    <CityLabel city={accommodation.Apartment.CityId}/>
                    {arrondissement && <ArrondissementLabel arrondissement={arrondissement}/>}
                </td>
                <td>{(isGarage ? 'Garage - ' : 'Room - ') + accommodation.name}</td>
                <td>{label}</td>
                <td className="text-right">
                    {amount / 100}
                    {' '}
                    €
                </td>
                <td className={`text-center ${dueDateColor}`}>
                    {dueDateWithExtension.format('DD/MM/YYYY')}
                </td>
                {
                    paymentStatus === 'paid'
                        ? (
                            <td className="text-center">
                                {
                                    payments && payments.length > 0
                                        ? moment(payments[0].createdAt).format('DD/MM/YYYY HH:mm')
                                        : null
                                }
                            </td>
                        )
                        : null
                }
                <td>
                    {
                        paymentStatus === 'paid'
                            ? (
                                <a href={pdfInvoiceUrl} target="_blank" rel="noopener noreferrer">
                                    <Button size="sm" variant="dark" title="Download invoice (PDF)" className="icon">
                                        <i className="fas fa-cloud-download-alt"/>
                                    </Button>
                                </a>
                            )
                            : null
                    }
                    <a href={paymentPageUrl} target="_blank" rel="noopener noreferrer">
                        <Button size="sm" variant="dark" title="Payment page">
                            <i className="fas fa-external-link-alt"/>
                        </Button>
                    </a>
                </td>
            </tr>
        );
    }

    render() {
        const {
            orders, paymentStatus, isLoading, filters,
        } = this.state;

        if (isLoading) {
            return <Loader/>;
        }

        const city = filters.find(({name}) => name === 'city').currentValue;
        const isAllSelected = city === 'all';
        let filteredOrders = [];

        if (isAllSelected) {
            filteredOrders = Object.keys(orders)
                .reduce((acc, cityKey) => [
                    ...acc,
                    ...orders[cityKey],
                ], []);
        } else {
            filteredOrders = orders[city];
        }

        const mappedOrders = filteredOrders
            .filter(({receiptNumber}) => {
                const isPaid = Boolean(receiptNumber);

                return paymentStatus === 'paid' ? isPaid : !isPaid;
            })
            .sort((a, b) => this.sortOrders(a, b))
            .map((order) => this.renderOrderRow(order));

        const selectAllCitiesButtonVariant = isAllSelected
            ? 'primary'
            : 'link';
        const paymentStatusSwitchVariant = (status) => (paymentStatus === status
            ? 'primary'
            : 'outline-primary');

        const headers = DEFAULT_HEADERS.map((header) => (
            <th key={typeof header === 'string' ? header : header.label}
                onClick={() => typeof header === 'string' || header.sort ?
                    this.handleSorting(typeof header === 'string' ? header : header.label) : null}>
                {this.renderSortingHeader(typeof header === 'string' ? header : header.label)}
            </th>
        ));

        return (
            <div className="Receipts">
                <div className="d-flex justify-content-between pt-3 pb-2 mb-3 border-bottom">
                    <Link to="/">
                        <i className="icon fas fa-arrow-circle-left"/>
                        Back to home
                    </Link>
                    <h3>
                        <div className="d-flex align-items-center">
                            <span>Orders for</span>
                            {this.renderDateSelector()}
                        </div>
                    </h3>
                    <h4>
                        Total:
                        {' '}
                        {mappedOrders.length}
                    </h4>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                        {this.renderFilters()}
                        <Button
                            variant={selectAllCitiesButtonVariant}
                            onClick={() => this.handleFilterChange('all')}
                        >
                            All cities
                        </Button>
                    </div>
                    <ToggleButtonGroup type="radio" name="paymentStatus">
                        <Button
                            variant={paymentStatusSwitchVariant('unpaid')}
                            onClick={() => this.handlePaymentStatus('unpaid')}
                        >
                            <i className="icon fas fa-times"/>
                            Unpaid
                        </Button>
                        <Button
                            variant={paymentStatusSwitchVariant('paid')}
                            onClick={() => this.handlePaymentStatus('paid')}
                        >
                            <i className="icon fas fa-check"/>
                            Paid
                        </Button>
                    </ToggleButtonGroup>
                </div>
                <div className="OrdersTable">
                    <Table bordered hover>
                        <thead>
                        <tr>
                            {headers}
                            {
                                paymentStatus === 'paid'
                                    ? (
                                        <th onClick={() => this.handleSorting('paymentDate')}>
                                            {this.renderSortingHeader('paymentDate')}
                                        </th>
                                    )
                                    : null
                            }
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {mappedOrders}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}

export default Receipts;
