import React from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';

import DraggableCard from './DraggableCard';
import PicturesModal from './PicturesModal';
import UploadPicturesModal from './UploadPicturesModal';
import './style.scss';

class ImageViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayModal: false,
      displayUploadModal: false,
      currentPicture: null,
      cards: [],
    };
  }

  componentDidMount() {
    const { pictures } = this.props;

    this.setState({
      cards: pictures.filter(({ order }) => order),
    });
  }

  componentDidUpdate(prevProps) {
    const { pictures: prevPictures, editMode: prevEditMode } = prevProps;
    const { pictures, editMode } = this.props;

    if (prevPictures.length !== pictures.length || (editMode && !prevEditMode)) {
      this.setState({
        cards: pictures.filter(({ order }) => order),
      });
    }
  }

  toggleModal(pictureId) {
    const { displayModal } = this.state;

    this.setState({
      displayModal: !displayModal,
      currentPicture: pictureId,
    });
  }

  toggleUploadModal() {
    const { displayUploadModal } = this.state;

    this.setState({ displayUploadModal: !displayUploadModal });
  }

  pushToCards(picture) {
    const { updateOrder } = this.props;
    const { cards } = this.state;

    const newCards = [...cards, picture];

    this.setState(
      { cards: newCards },
      () => updateOrder(
        newCards.map(({ id }, index) => ({ id, order: index + 1 })),
      ),
    );
  }

  moveCard(dragIndex, hoverIndex) {
    const { updateOrder } = this.props;
    const { cards } = this.state;

    const newCards = [...cards];
    const dragCard = newCards[dragIndex];

    newCards[dragIndex] = newCards[hoverIndex];
    newCards[hoverIndex] = dragCard;

    this.setState(
      { cards: newCards },
      () => updateOrder(
        newCards.map(({ id }, index) => ({ id, order: index + 1 })),
      ),
    );
  }

  renderPlaceholder() {
    return (
      <Jumbotron className="text-center">
        <h1>No pictures</h1>
        <p>
          <Button variant="success" onClick={ () => this.toggleUploadModal() }>
            <i className="icon fas fa-plus" />
            Upload new pictures
          </Button>
        </p>
      </Jumbotron>
    );
  }

  renderImageContainer(picture) {
    const {
      id, url, alt, order,
    } = picture;

    return (
      <div key={ id } className="image-container" onClick={ () => this.toggleModal(id) }>
        <div className="picture">
          <img src={ url } alt={ alt } />
        </div>
        <div className="metadata">
          { order || null }
          {
            alt
              ? (
                <Button variant="dark" className="btn-label" disabled>
                  { alt }
                </Button>
              )
              : null
          }
        </div>
      </div>
    );
  }

  render() {
    const {
      pictures,
      editMode,
      showOnly = false,
    } = this.props;
    const {
      cards, displayModal, displayUploadModal, currentPicture,
    } = this.state;

    const mappedPictures = pictures.map((picture) => this.renderImageContainer(picture));

    const unorderedPictures = pictures
      .filter((picture) => !cards.includes(picture) && !picture.order)
      .map((picture) => (
        <div key={ picture.id } className="image-container">
          <div className="picture">
            <img src={ picture.url } alt={ picture.alt } />
            <div className="metadata">
              <Button variant="success" onClick={ () => this.pushToCards(picture) }>
                <i className="icon fas fa-plus" />
                Add order
              </Button>
            </div>
          </div>
        </div>
      ));

    const draggableCards = cards.map((picture, index) => (
      <DraggableCard
        key={ picture.id }
        picture={ picture }
        index={ index }
        moveCard={ (dragIndex, hoverIndex) => this.moveCard(dragIndex, hoverIndex) }
      />
    ));

    return (
      <div className="ImageViewer">
        {
          (!pictures || pictures.length === 0)
            ? this.renderPlaceholder()
            : (
              <>
                {
                editMode
                  ? (
                    <Row className="mb-4">
                      <Col>
                        <Alert variant="warning">
                          You can change the order of the pictures using drag & drop.
                        </Alert>
                      </Col>
                    </Row>
                  )
                  : null
              }
                <Row>
                  <Col>
                    <div className="images">
                      {
                    editMode
                      ? (
                        <>
                          { draggableCards }
                          { unorderedPictures }
                        </>
                      )
                      : (
                        <>
                          { mappedPictures }

                          {
                          !showOnly
                            ? (
                              <div className="image-container upload-button" onClick={ () => this.toggleUploadModal() }>
                                <div className="metadata">
                                  <i className="fas fa-plus fa-3x" />
                                  <p>Click here to upload new pictures</p>
                                </div>
                              </div>
                            )
                            : null
                        }
                        </>
                      )
                  }
                    </div>
                  </Col>
                </Row>
                {
                displayModal
                  ? (
                    <PicturesModal
                      show={ displayModal }
                      onHide={ () => this.toggleModal() }
                      pictures={ pictures }
                      pictureId={ currentPicture }
                      { ...this.props }
                    />
                  )
                  : null
              }
              </>
            )
        }
        {
          displayUploadModal
            ? (
              <UploadPicturesModal
                show={ displayUploadModal }
                onHide={ () => this.toggleUploadModal() }
                { ...this.props }
              />
            )
            : null
        }
      </div>
    );
  }
}

export default ImageViewer;
