import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

function DraggableCard(props) {
  const { picture, index, moveCard } = props;
  const { id, url, alt } = picture;

  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'card',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex; // eslint-disable-line
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'card',
      id,
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <div ref={ ref } className="image-container draggable" style={{ opacity }}>
      <div className="picture">
        <img src={ url } alt={ alt } />
        <div className="metadata">{ index + 1 }</div>
      </div>
    </div>
  );
}

export default DraggableCard;
