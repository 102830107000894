import React from 'react';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import ForestApi from '../../../../utils/API';
import EditableField from '../../../EditableField';
import RoundButton from '../../../RoundButton';

const PAYMENT_KINDS = [
  'bank transfer',
  'direct debit',
];

const LEASE_KINDS = [
  'Loi 89',
  'Droit Civil',
];

const INCLUDED_CHARGES = [
  'cold water',
  'condominium fees',
  'hot water',
  'heating',
  'garbage collection tax',
  'boiler maintenance',
];

const MONEY_FIELDS = [
  'agencyFees',
  'deposit',
  'rentDiscount',
  'commissionPayment',
  'firstMonthRent',
  'rentExcludingCharges',
  'rentCharges',
  'estimatedWorkCost',
  'furnishingCost',
  'realWorkCost',
  'dismantlingWorkCost',
];

const WORK_COST_DIFFERENCE = [
  'contingencies on the site',
  'contractors prices',
  'nonidentified problems during the visit',
  'delivery delay',
  'incorrect cost estimation',
  'other',
];

const FEATURES = ['apartment-features-general-commonAreasArea'];

class mainTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      fieldsToUpdate: {},
    };
  }

  getValue(field) {
    const { apartment } = this.props;
    const { editMode, fieldsToUpdate } = this.state;

    if (editMode && field in fieldsToUpdate) {
      return fieldsToUpdate[field];
    }

    if (FEATURES.includes(field)) {
      const featureValue = apartment.features.find((feature) => feature.field === field).value;

      return featureValue === '0'
        ? null
        : featureValue;
    }

    return apartment[field];
  }

  editApartment() {
    const { apartment: { id }, fetchApartment } = this.props;
    const { fieldsToUpdate } = this.state;

    if (!isEmpty(fieldsToUpdate)) {
      new ForestApi().apartments()
        .update(id, fieldsToUpdate)
        .then(() => {
          toast('Apartment updated!', {
            type: toast.TYPE.SUCCESS,
          });
          fetchApartment();
        });
    }

    this.toggleEditMode();
  }

  toggleEditMode() {
    const { editMode } = this.state;

    this.setState({
      editMode: !editMode,
      fieldsToUpdate: {},
    });
  }

  editField(name, value) {
    const { fieldsToUpdate } = this.state;
    let newValue = value;

    if (MONEY_FIELDS.includes(name)) {
      newValue = (Math.round(Number(value) * 100));
    }

    this.setState({
      fieldsToUpdate: {
        ...fieldsToUpdate,
        [name]: newValue,
      },
    });
  }

  renderCards() {
    const { apartment } = this.props;
    const { editMode, fieldsToUpdate } = this.state;
    const {
      name,
      reference,
      addressStreet,
      addressZip,
      addressCity,
      addressCountry,
      District: {label},
    } = apartment;

    const fullAddress = `${addressStreet}, ${addressZip} ${addressCity} - ${upperFirst(addressCountry)}`;
    const district = label;

    const isPremium = this.getValue('isPremium');

    const defaultEditableFieldProps = {
      editFieldValue: (fieldName, value) => this.editField(fieldName, value),
      editMode,
      fieldsToUpdate,
      team: 'IMMO',
    };

    return (
      <>
        <Row>
          <Col>
            <Card className="table-card">
              <Card.Header>
                <strong>Location</strong>
              </Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    <tr>
                      <th>Reference</th>
                      <td>{ reference }</td>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <td>{ name }</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>
                        <i className="icon fas fa-map-marker-alt" />
                        { fullAddress }
                      </td>
                    </tr>
                    <tr>
                      <th>Neighborhood</th>
                      <td>
                        <h5>
                          <Button variant="dark" size="sm" className="btn-label" disabled>
                            { district }
                          </Button>
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <th>Zone</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          name="cityZone"
                          value={ this.getValue('cityZone') }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="table-card">
              <Card.Header>
                <strong>Layout</strong>
              </Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    <tr>
                      <th>Initial topology</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          name="initialTopology"
                          value={ this.getValue('initialTopology') }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Rooms</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          name="roomCount"
                          value={ this.getValue('roomCount') }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Bedrooms</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          name="bedroomCount"
                          value={ this.getValue('bedroomCount') }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Area</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          additionalText="m²"
                          inputType="number"
                          name="floorArea"
                          value={ this.getValue('floorArea') }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Common areas superficy</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          additionalText="m²"
                          inputType="number"
                          name="apartment-features-general-commonAreasArea"
                          value={ this.getValue('apartment-features-general-commonAreasArea') }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Floor</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          name="floor"
                          value={ this.getValue('floor') }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Category</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="radio"
                          name="isPremium"
                          label={ isPremium ? 'Premium' : 'Standard' }
                          options={{ standard: 'false', premium: 'true' }}
                          value={ `${isPremium}` }
                          displayAsTag={ isPremium ? 'primary' : 'dark' }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="table-card">
              <Card.Header>
                <strong>Initial Expenses</strong>
              </Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    <tr>
                      <th>Agency fees</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          name="agencyFees"
                          value={ this.getValue('agencyFees') / 100 }
                          isMoney
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Deposit</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          name="deposit"
                          value={ this.getValue('deposit') / 100 }
                          isMoney
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Rent discount</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          name="rentDiscount"
                          value={ this.getValue('rentDiscount') / 100 }
                          isMoney
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Commission payment</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          name="commissionPayment"
                          value={ this.getValue('commissionPayment') / 100 }
                          isMoney
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>First month rent</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          name="firstMonthRent"
                          value={ this.getValue('firstMonthRent') / 100 }
                          isMoney
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="table-card">
              <Card.Header>
                <strong>Expenses</strong>
              </Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    <tr>
                      <th>Rent excluding charges</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          name="rentExcludingCharges"
                          value={ this.getValue('rentExcludingCharges') / 100 }
                          isMoney
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Charges</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          name="rentCharges"
                          value={ this.getValue('rentCharges') / 100 }
                          isMoney
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Included charges</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="flags"
                          name="includedCharges"
                          options={ INCLUDED_CHARGES }
                          value={ this.getValue('includedCharges') }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Payment kind</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="select"
                          name="paymentKind"
                          options={ PAYMENT_KINDS }
                          value={ this.getValue('paymentKind') }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Day of payment</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          name="paymentDay"
                          value={ this.getValue('paymentDay') }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="table-card">
              <Card.Header>
                <strong>Lease</strong>
              </Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    <tr>
                      <th>Main signatory</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          name="mainSignatory"
                          value={ this.getValue('mainSignatory') }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Start date</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="date"
                          name="leaseStartDate"
                          value={ this.getValue('leaseStartDate') }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Duration</th>
                      <td>
                        <EditableField
                          additionalText="years"
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          name="leaseDuration"
                          value={ this.getValue('leaseDuration') }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Kind</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="select"
                          name="leaseKind"
                          options={ LEASE_KINDS }
                          value={ this.getValue('leaseKind') }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="table-card">
              <Card.Header>
                <strong>Refurbishment</strong>
              </Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    <tr>
                      <th>Forecast rating</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          name="forecastRating"
                          value={ this.getValue('forecastRating') }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Estimated work cost</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          name="estimatedWorkCost"
                          value={ this.getValue('estimatedWorkCost') / 100 }
                          isMoney
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Real work cost</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          team="D&Q"
                          name="realWorkCost"
                          value={ this.getValue('realWorkCost') / 100 }
                          isMoney
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Estimated vs real work cost difference</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="flags"
                          team="D&Q"
                          name="workCostDifference"
                          value={ this.getValue('workCostDifference') }
                          options={ WORK_COST_DIFFERENCE }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Immo simulator link</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          team="D&Q"
                          name="realEstateSimulatorLink"
                          value={ this.getValue('realEstateSimulatorLink') }
                          isLink
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Provision for dismantling work</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          team="IMMO"
                          name="dismantlingWorkCost"
                          value={ this.getValue('dismantlingWorkCost') / 100 }
                          isMoney
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Cost of furnishing</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          team="D&Q"
                          name="furnishingCost"
                          value={ this.getValue('furnishingCost') / 100 }
                          isMoney
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Final rating</th>
                      <td>
                        <EditableField
                          { ...defaultEditableFieldProps }
                          inputType="number"
                          team="ACCO"
                          name="finalRating"
                          value={ this.getValue('finalRating') }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { editMode } = this.state;

    return (
      <div className="main-tab">
        { this.renderCards() }
        <RoundButton
          editMode={ editMode }
          toggleEditMode={ () => this.toggleEditMode() }
          save={ () => this.editApartment() }
        />
      </div>
    );
  }
}

export default mainTab;
