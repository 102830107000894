import React from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function CategoryCard(props) {
  const {
    activeCard,
    category,
    checkFeatures,
    children,
    header,
    isCompleted,
    isRoom,
    roomId,
    setActiveCard,
  } = props;

  const completedIcon = (() => {
    const icon = isCompleted ? 'fa-check-circle' : 'fa-times-circle';
    const color = isCompleted ? 'text-success' : 'text-danger';

    return <i className={ `fas ${icon} fa-lg mr-3 ${color}` } />;
  })();

  const isRoomActive = Boolean(activeCard.roomId)
    && roomId === activeCard.roomId;

  const isActive = activeCard.name === category
    && (!isRoom || isRoomActive);

  const activeStatus = (() => {
    if (activeCard.name == null) {
      return null;
    }

    return isActive ? 'isActive' : 'isNotActive';
  })();

  const eventKey = `${category}${isRoom ? roomId : ''}`;

  const toggleFeaturesButton = /generalFeatures/i.test(category)
    ? (
      <div className={ `d-flex align-items-center mt-4 mb-2 toggle-features ${activeStatus}` }>
        <Button variant="link pr-0" onClick={ () => checkFeatures() }>
          <i className={ `icon fas fa-lg fa-toggle-${isCompleted ? 'on' : 'off'}` } />
        </Button>
        <em>Features checked</em>
      </div>
    )
    : null;

  const isPicture = /pictures/i.test(category);

  function renderAccordionContent() {
    return (
      <div key={ `card${header}${roomId}` } className={ isPicture ? 'mt-5 mb-5' : '' }>
        { toggleFeaturesButton }
        <Card className={ `m-0 ${activeStatus}` }>
          <Accordion.Toggle
            as={ Card.Header }
            eventKey={ eventKey }
            className={ `d-flex align-items-center ${activeStatus}` }
            onClick={ () => setActiveCard(!isActive ? category : null) }
          >
            { completedIcon }
            <strong>{ header }</strong>
            <i className={ `fas ${isActive ? 'fa-angle-up' : 'fa-angle-down'} fa-lg ml-auto` } />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={ eventKey }>
            { isActive ? children : <div /> }
          </Accordion.Collapse>
        </Card>
      </div>
    );
  }

  return renderAccordionContent();
}

export default CategoryCard;
