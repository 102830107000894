import React from 'react';
import rules from '../../usersRules';

const check = (rule, role, action) => {
  const permissions = rule[role];

  if (!permissions) {
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    return true;
  }

  return false;
};

const CheckRole = (props) => {
  const {
    to, action, component: Component, ...rest
  } = props;

  const checkAction = action ? action.replace('/', '') : '';

  return (
    check(rules, to, checkAction)
      ? <Component { ...rest } />
      : null
  );
};

export default CheckRole;
