import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Modal from 'react-bootstrap/Modal';

import ForestApi from '../../utils/API';

function PicturesModal(props) {
  const {
    show,
    onHide,
    pictures,
    pictureId,
    types,
    fetchData,
    showOnly,
  } = props;

  const [isLoading, setLoading] = useState(false);
  const [currentPicture, setCurrentPicture] = useState(pictureId);
  const [newTag, setNewTag] = useState('');

  function updatePicture() {
    setLoading(true);

    const data = { alt: newTag };

    new ForestApi().pictures()
      .update(currentPicture, data)
      .then(() => {
        setLoading(false);
        setNewTag('');

        toast('Picture updated!', {
          type: toast.TYPE.SUCCESS,
        });

        fetchData();
      });
  }

  function changePicture(reverse = false) {
    const index = pictures.map(({ id }) => id).indexOf(currentPicture);
    let nextIndex = reverse ? index - 1 : index + 1;

    if (reverse && nextIndex < 0) {
      nextIndex = pictures.length - 1;
    } else if (nextIndex === pictures.length) {
      nextIndex = 0;
    }

    setNewTag('');
    setCurrentPicture(pictures[nextIndex].id);
  }

  function removePicture() {
    setLoading(true);

    const initialLength = pictures.length;

    new ForestApi().pictures()
      .delete(currentPicture)
      .then(() => {
        setLoading(false);

        toast('Picture deleted!', {
          type: toast.TYPE.SUCCESS,
        });

        fetchData();

        if (initialLength === 1) {
          onHide();
        } else {
          changePicture();
        }
      });
  }

  const picture = pictures.find(({ id }) => id === currentPicture);
  const { url, alt, createdAt } = picture;
  const currentIndex = pictures.indexOf(picture);

  const mappedTypes = types.map((type) => <option key={ type }>{ type }</option>);

  const isTagChanged = newTag && newTag !== alt;

  return (
    <Modal className="ImageViewer-modal" show={ show } onHide={ () => onHide() }>
      <div className="picture">
        <img src={ url } alt={ alt } />
      </div>
      <div className="picture-sidebar">
        <div className="container">
          <div className="mb-4 d-flex flex-row-reverse">
            <Button variant="link" className="close-button" onClick={ () => onHide() }>
              <i className="fa fa-times" />
            </Button>
          </div>
          <div className="buttons">
            <Button size="sm" variant="secondary" onClick={ () => changePicture(true) }>
              &laquo; Previous
            </Button>
            <span>
              { currentIndex + 1 }
              {' '}
              /
              { pictures.length }
            </span>
            <Button size="sm" variant="secondary" onClick={ () => changePicture() }>Next &raquo;</Button>
          </div>
          {
            !showOnly
              ? (
                <div className="mb-4">
                  <FormControl as="select" value={ newTag || alt || '' } onChange={ (e) => setNewTag(e.target.value) }>
                    <option value="">Pick a tag...</option>
                    { mappedTypes }
                  </FormControl>
                </div>
              )
              : null
          }
          <p>
            Uploaded on
            {' '}
            { moment(createdAt).format('DD/MM/YYYY [at] HH:mm') }
          </p>
          {
            !showOnly
              ? (
                <div className="d-flex justify-content-end">
                  <Button
                    variant={ isTagChanged ? 'primary' : 'light' }
                    onClick={ () => updatePicture() }
                    disabled={ !isTagChanged }
                  >
                    <i className="icon fas fa-save" />
                    Save changes
                  </Button>
                </div>
              )
              : null
          }
        </div>
        <div className="container text-center mb-4">
          <Button
            variant="danger"
            onClick={ () => {
              if (window.confirm('Are you sure you wish to delete this item?')) removePicture();
            } }
            disabled={ isLoading }
          >
            {
              isLoading
                ? <span>Deleting...</span>
                : (
                  <>
                    <i className="icon fas fa-trash" />
                    <span>Delete</span>
                  </>
                )
            }
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default PicturesModal;
