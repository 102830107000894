import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import { toast } from 'react-toastify';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';

import ForestApi from '../../utils/API';
import pluralize from '../../utils/pluralize';
import CityLabel from '../CityLabel';
import Dashboard from '../Dashboard';
import NewContractor from './new';
import extractParamsQuery from '../../utils/extractParamsQuery';
import fetchCitiesFilters from '../../utils/fetchCitiesFilters';
import getFromQuery from '../../utils/getFromQuery';

class Contractors extends React.Component {
  constructor(props) {
    super(props);

    const filters = extractParamsQuery({
      search: {
        type: 'search',
        placeholder: 'Filter by name...',
        currentValue: '',
      },
      date: {
        type: 'date',
        label: 'Intervention date',
        currentValue: null,
      },
      city: {
        type: 'checkbox',
        label: 'City',
        currentValue: ['lyon'],
        values: [],
      },
      interventionType: {
        type: 'checkbox',
        label: 'Intervention',
        currentValue: [],
        values: [],
      },
    }, window.location.search);

    this.state = {
      isLoading: false,
      filters,
      contractors: [],
      cities: [],
      interventionTypes: [],
      displayModal: false,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.fetchTypes();
    await this.fetchCities();
    await this.fetchContractors();
    this.setState({ isLoading: false });
  }

  async fetchContractors() {
    const { filters, isLoading } = this.state;
    const { city } = filters;

    if (!isLoading) {
      this.setState({ isLoading: true });
    }

    const query = `city=${city.currentValue.join(',')}`;

    const response = await new ForestApi().contractors().getAll(query);
    const contractors = response.data;

    this.setState({ contractors, isLoading: false });
  }

  async fetchTypes() {
    const { filters } = this.state;
    const { interventionType } = filters;

    const response = await new ForestApi().interventions().getTypes();
    const types = response.data;

    this.setState({
      interventionTypes: types,
      filters: {
        ...filters,
        interventionType: {
          ...interventionType,
          values: types,
        },
      },
    });
  }

  async fetchCities() {
    const { filters } = this.state;
    this.setState(await fetchCitiesFilters(filters));
  }

  handleFilterChange(name, value) {
    const { filters } = this.state;

    if (name === 'city' && value.length === 0) {
      return null;
    }

    const refetch = name === 'city';

    return this.setState({
      filters: {
        ...filters,
        [name]: {
          ...filters[name],
          currentValue: value,
        },
      },
    }, refetch ? this.fetchContractors : null);
  }

  toggleModal() {
    const { displayModal } = this.state;

    this.setState({ displayModal: !displayModal });
  }

  createContractor(fields) {
    const data = fields.reduce((acc, field) => {
      acc[field.name] = field.value;

      return acc;
    }, {});

    this.toggleModal();

    new ForestApi().contractors()
      .create(data)
      .then(() => {
        toast('Contractor created!', {
          type: toast.TYPE.SUCCESS,
        });
        // We want to display the new Contractor in the Dashboard
        // so we simulate a click on the corresponding City filter
        this.handleFilterClick(data.CityId);
      });
  }

  showContractor(id) {
    const { history } = this.props;
    history.push(`/contractors/${id}?${getFromQuery(window.location)}`);
  }

  parseContractorCard(contractor) {
    const { filters } = this.state;
    const {
      id,
      firstName,
      lastName,
      CityId,
      email,
      phoneNumber,
      company,
      InterventionTypes,
      TechnicalInterventions,
    } = contractor;

    const { date } = filters;

    const interventionsTags = InterventionTypes.map((type) => (
      <Button
        key={ type.id }
        variant={ type.name === 'checkout' ? 'primary' : 'dark' }
        className="btn-label mr-2 mb-2"
        size="sm"
        disabled
      >
        { capitalize(type.name) }
      </Button>
    ));

    const interventionDiff = interventionsTags.length > 5 ? interventionsTags.length - 5 : null;

    const tags = [
      <CityLabel key={ CityId } city={ CityId } className="mr-2 mb-2" />,
      <hr key={ `${id} tab` } className="my-2 border-0" />,
      ...interventionsTags.slice(0, 5),
      interventionDiff
        ? <span className="text-nowrap" key={ `${id} diff` }>{ `${interventionDiff} more...` }</span>
        : null,
    ];

    const filteredInterventions = date.currentValue
      ? (
        TechnicalInterventions
          .filter(({ expectedStartDate }) => moment(expectedStartDate).isSame(date.currentValue, 'day'))
      )
      : TechnicalInterventions;

    const activeIcon = filteredInterventions.length > 0
      ? <i className="icon far fa-clock text-success" />
      : <i className="icon fas fa-clock" />;

    const scheduledInterventions = (
      <span>
        { activeIcon }
        { pluralize(filteredInterventions.length, 'intervention') }
        { date.currentValue ? ` on ${date.currentValue.format('DD/MM/YYYY')}` : null }
      </span>
    );

    const description = (
      <>
        {
          email
            ? (
              <span className="d-block mb-2">
                <i className="icon fas fa-envelope" />
                { email }
              </span>
            )
            : null
        }
        {
          phoneNumber
            ? (
              <span className="d-block mb-2">
                <i className="icon fas fa-phone" />
                { phoneNumber }
              </span>
            )
            : null
        }
        {
          company
            ? (
              <span className="d-block mb-2">
                <i className="icon fas fa-industry" />
                { company }
              </span>
            )
            : null
        }
      </>
    );

    return {
      id,
      title: `${firstName} ${lastName}`,
      subtitle: scheduledInterventions,
      tags,
      description,
    };
  }

  renderPlaceholder() {
    return (
      <Row>
        <Col>
          <Jumbotron className="text-center">
            <h1>No contractors</h1>
            <p>
              No contractors found.
            </p>
            <p>
              <Button variant="success" onClick={ () => this.toggleModal() }>
                <i className="icon fa fa-plus" />
                Create a new one
              </Button>
            </p>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  render() {
    const {
      contractors,
      interventionTypes,
      cities,
      filters,
      isLoading,
      displayModal,
    } = this.state;

    const { userTeam } = this.props;

    const query = [
      `search=${filters.search.currentValue}`,
      `city=${filters.city.currentValue.join(',')}`,
      `interventionType=${filters.interventionType.currentValue.join(',')}`,
    ].join('&');
    window.history.replaceState(null, null, `${window.location.pathname}?${query}`);

    const filteredContractors = contractors
      .filter(({ firstName, lastName }) => {
        const { search } = filters;

        if (!search.currentValue) {
          return true;
        }

        const regex = new RegExp(search.currentValue, 'i');

        return regex.test(firstName) || regex.test(lastName);
      })
      .filter((contractor) => {
        const { interventionType } = filters;

        if (!interventionType.currentValue || interventionType.currentValue.length === 0) {
          return true;
        }

        return contractor.InterventionTypes
          .map((intType) => intType.name)
          .filter((intType) => interventionType.currentValue.includes(intType))
          .length > 0;
      })
      .sort((a, b) => {
        const { date } = filters;

        if (!date.currentValue) {
          return b.TechnicalInterventions.length - a.TechnicalInterventions.length;
        }

        const startDateFilter = ({ expectedStartDate }) => moment(expectedStartDate).isSame(date.currentValue, 'day');
        const nbOfinterventionsA = a.TechnicalInterventions.filter(startDateFilter).length;
        const nbOfinterventionsB = b.TechnicalInterventions.filter(startDateFilter).length;

        return nbOfinterventionsB - nbOfinterventionsA;
      })
      .map((contractor) => this.parseContractorCard(contractor));

    return (
      <div className="Contractors">
        <div className="d-flex justify-content-between flex-wrap
        flex-md-nowrap align-items-center pt-3 pb-2 border-bottom"
        >
          {
            userTeam === 'property-manager'
              ? (
                <Link to="/">
                  <i className="icon fas fa-arrow-circle-left" />
                  Back to home
                </Link>
              )
              : <div />
          }
          <h3>Contractors Dashboard</h3>
          <Button variant="success" onClick={ () => this.toggleModal() }>
            <i className="icon fa fa-plus" />
            New
          </Button>
        </div>
        <Dashboard
          model="contractor"
          filters={ filters }
          items={ filteredContractors }
          handleFilterChange={ (name, value) => this.handleFilterChange(name, value) }
          handleElementClick={ (id) => this.showContractor(id) }
          renderPlaceholder={ () => this.renderPlaceholder() }
          isLoading={ isLoading }
        />
        {
          displayModal
            ? (
              <NewContractor
                show={ displayModal }
                interventionTypes={ interventionTypes }
                cities={ cities }
                handleClose={ () => this.toggleModal() }
                handleSubmit={ (fields) => this.createContractor(fields) }
              />
            )
            : null
        }
      </div>
    );
  }
}

export default Contractors;
