import React from 'react';
import capitalize from 'lodash/capitalize';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import buildForestLink from '../../../../utils/buildForestLink';

function GarageTab(props) {
  const { garage } = props;

  if (!garage) {
    return (
      <Row>
        <Col>
          <Jumbotron className="text-center">
            <h1>No garage</h1>
            <p>
              There are no garage for this apartment yet.
            </p>
            <p>
              {/* <Button variant="success" onClick={() => toggleModal(true)}>
                <i className="icon fa fa-plus" />
                Create the first one
              </Button> */}
            </p>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  const {
    id,
    name,
    type,
    status,
    accessType,
    spotNumber,
    level,
    basePrice,
    floorArea,
    notes,
  } = garage;

  const forestGarageLink = buildForestLink(id, 'garage');

  function displayField(value, isLabel = false) {
    if (!value) {
      return (
        <span className="text-secondary">
          Undefined
        </span>
      );
    }

    if (isLabel) {
      return (
        <Button size="sm" variant="dark" className="btn-label" disabled>
          { value }
        </Button>
      );
    }

    return value;
  }

  return (
    <div className="garage-tab">
      <Row>
        <Col lg="8">
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
              <strong>Garage details</strong>
              <div>
                <a href={ forestGarageLink } rel="noopener noreferrer" target="_blank">
                  <i className="fa fa-external-link-alt" />
                </a>
              </div>
            </Card.Header>
            <Card.Body>
              <Table>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>{ displayField(name) }</td>
                  </tr>
                  <tr>
                    <th>Type</th>
                    <td>{ displayField(type, true) }</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>
                      <Button size="sm" className={ `btn-label btn-${status}` } disabled>
                        { capitalize(status) }
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <th>Access type</th>
                    <td>{ displayField(accessType, true) }</td>
                  </tr>
                  <tr>
                    <th>Spot number</th>
                    <td>{ displayField(spotNumber) }</td>
                  </tr>
                  <tr>
                    <th>Level</th>
                    <td>{ displayField(level) }</td>
                  </tr>
                  <tr>
                    <th>Rent</th>
                    <td>
                      { basePrice / 100 }
                      {' '}
                      €
                    </td>
                  </tr>
                  <tr>
                    <th>Floor area</th>
                    <td>{ displayField(floorArea) }</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="4">
          <Card>
            <Card.Header>
              <strong>Notes</strong>
            </Card.Header>
            <Card.Body>
              { notes }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default GarageTab;
