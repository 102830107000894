import React from 'react';
import isEmpty from 'lodash/isEmpty';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function ContactCard(props) {
  const {
    ApartmentContacts, mainContact, handleEditContact, unassignContact,
  } = props;

  const hasMainContact = ApartmentContacts.find(({ Contact }) => Contact.id === mainContact);

  const renderContactList = () => ApartmentContacts
    .map(({ type, Contact }, i) => {
      const contactFullName = `${Contact.firstName} ${Contact.lastName}`;
      const hasNote = Boolean(Contact.notes.trim());
      const isMainContact = Contact.id === mainContact;

      return (
        <tr key={ `contact - ${Contact}` }>
          <td className="w-100 d-flex">
            <Button
              variant="link"
              size="sm"
              className="w-50 text-left"
              onClick={ () => handleEditContact(Contact, type) }
            >
              { isEmpty(contactFullName.trim()) ? Contact.company : contactFullName.trim() }
            </Button>
            <div className="w-50 d-flex">
              <Button size="sm" variant="dark" className="btn-label" disabled>
                { type }
              </Button>
              {
                  isMainContact
                    ? (
                      <Button size="sm" variant="success" className="btn-label ml-3" disabled>
                        Main contact
                      </Button>
                    )
                    : null
                }
              {
                  hasNote
                    ? (
                      <OverlayTrigger
                        overlay={ <Tooltip id="tooltip-disabled">{ Contact.notes }</Tooltip> }
                      >
                        <span className="d-inline-block ml-auto mr-2">
                          <Button
                            size="sm"
                            variant="dark"
                            disabled
                          >
                            Notes
                          </Button>
                        </span>
                      </OverlayTrigger>
                    )
                    : null
                }
              <Button
                size="sm"
                variant="danger"
                title="Unassign contact"
                onClick={ () => unassignContact(Contact.id, type) }
                className={ !hasNote ? 'ml-auto' : null }
              >
                <i className="fas fa-user-minus" />
              </Button>
            </div>
          </td>
        </tr>
      );
    });

  return (
    <React.Fragment key="contacts">
      {
        !hasMainContact
          ? (
            <tr>
              <td className="text-danger">
                <i className="fas fa-exclamation-circle text-danger mr-2" />
                <em>Main contact not specified</em>
              </td>
            </tr>
          )
          : null
      }
      { renderContactList() }
      <tr>
        <td>
          <Button variant="link" size="sm" onClick={ () => handleEditContact() }>
            Add a contact
          </Button>
        </td>
      </tr>
    </React.Fragment>
  );
}

export default ContactCard;
