import isEmpty from 'lodash/isEmpty';
import { apartmentModel, dynamicFields } from './apartmentsFields';
import roomModel from './roomsFields';

const TEAMS = [
  'immo',
  'dandq',
  'sales',
];

const LYON_FIELDS = [
  'waterCompanyLogin',
  'waterCompanyPassword',
];

const I18NS = [
  'apartment-access',
  'bike-shelter-access',
  'circuit-breaker-location',
  'electricity-meter-location',
  'garbage-room-access',
  'gas-meter-location',
  'internet-modem-location',
  'switchboard-location',
  'water-meter-location',
  'hot-water-meter-location',
];

const FEATURES_FIELDS = [
  'apartment-features-general-bikeShelter',
  'apartment-features-general-garbageRoom',
  'apartment-features-general-cellar',
];

const getRatio = (completedCount) => Object.keys(completedCount)
  .reduce((acc, category) => ({
    completed: acc.completed + completedCount[category].completed,
    total: acc.total + completedCount[category].total,
  }), { completed: 0, total: 0 });

const filterDynamicApartmentFields = (field, apartment) => {
  const dynamicField = dynamicFields[field];

  if (dynamicField) {
    if (FEATURES_FIELDS.includes(dynamicField) && apartment.features) {
      return Boolean(apartment.features.find((item) => item.field === dynamicField).value);
    }
    if (field === 'hotWaterMeterSerialNumber') {
      return apartment[dynamicField] === 'hot and cold';
    }
    if (field === 'heatingKind') {
      return true;
    }

    return Boolean(apartment[dynamicField]);
  }

  return true;
};

const getApartmentTemplate = (model, apartment) => {
  const filteredApartmentModel = apartment.CityId !== 'lyon'
    ? (
      Object.keys(model).reduce((acc, category) => {
        const categoryFields = model[category].filter((field) => !LYON_FIELDS.includes(field));

        return {
          ...acc,
          [category]: categoryFields,
        };
      }, {})
    )
    : model;

  return Object.keys(filteredApartmentModel).reduce((acc, category) => {
    const categoryFields = filteredApartmentModel[category]
      .filter((field) => filterDynamicApartmentFields(field, apartment));

    return {
      ...acc,
      [category]: categoryFields,
    };
  }, {});
};

const apartmentFields = (field, apartment) => {
  if (I18NS.includes(field)) {
    return apartment.I18ns
      .filter(({ key, value }) => key === field && !isEmpty(value)).length === 3;
  }

  if (field === 'hasPictures') {
    return 'picturesCount' in apartment
      ? apartment.picturesCount >= 3
      : apartment.Pictures.length >= 3;
  }

  if (field === 'featuresCompleted') {
    return apartment.featuresCompleted;
  }

  if (field.startsWith('boiler')) {
    return apartment.heatingKind === 'individual boiler'
      ? !isEmpty(apartment[field])
      : Boolean(apartment.heatingKind);
  }

  return apartment[field] !== null && !isEmpty(`${apartment[field]}`);
};

const checkApartmentFieldsValue = (categoryFields, apartment) => ({
  completed: categoryFields.filter((field) => apartmentFields(field, apartment)).length,
  total: categoryFields.length,
});

const apartmentCategoryRatio = (team, apartment) => {
  const apartmentTemplate = getApartmentTemplate(apartmentModel[team], apartment);

  return Object.keys(apartmentTemplate).reduce((obj, category) => ({
    ...obj,
    [category]: checkApartmentFieldsValue(apartmentTemplate[category], apartment),
  }), {});
};

const roomFields = (field, room) => {
  if (room.features && field.includes('room-features-')) {
    const feature = room.features.find((roomFeature) => roomFeature.field === field);

    return !isEmpty(`${feature.value}`);
  }

  if (field === 'featuresChecked') {
    return room.featuresChecked;
  }

  if (field === 'roomHasPictures') {
    return 'picturesCount' in room
      ? room.picturesCount >= 1
      : room.Pictures.length >= 1;
  }

  return room[field] !== null && !isEmpty(`${room[field]}`);
};

const checkRoomFieldsValue = (category, room) => ({
  completed: category.filter((field) => roomFields(field, room)).length,
  total: category.length,
});

const roomCategoryRatio = (team, room) => {
  const roomTemplate = roomModel[team];

  return Object.keys(roomTemplate).reduce((acc, category) => ({
    ...acc,
    [category]: checkRoomFieldsValue(roomTemplate[category], room),
  }), {});
};

export const getCategoryCompletion = (team, obj) => {
  const categoryCompletion = 'CityId' in obj
    ? apartmentCategoryRatio(team, obj)
    : roomCategoryRatio(team, obj);

  return Object.keys(categoryCompletion).reduce((acc, category) => {
    const { completed, total } = categoryCompletion[category];
    const completionValue = Math.floor((completed / total) * 100);

    return {
      ...acc,
      [category]: completionValue,
    };
  }, {});
};

export const getApartmentCompletion = (apartment) => TEAMS.reduce((acc, team) => {
  const apartmentRatio = getRatio(apartmentCategoryRatio(team, apartment));

  const roomsRatio = apartment.Rooms.map((room) => getRatio(roomCategoryRatio(team, room)));

  const { completed, total } = getRatio([apartmentRatio, ...roomsRatio]);

  return {
    ...acc,
    [team]: Math.floor((completed / total) * 100),
  };
}, {});

export const getGlobalCompletion = (apartments) => {
  const completionSum = apartments.reduce((sum, apartment) => {
    const apartmentCompletion = getApartmentCompletion(apartment);

    return {
      immo: sum.immo + apartmentCompletion.immo,
      dandq: sum.dandq + apartmentCompletion.dandq,
      sales: sum.sales + apartmentCompletion.sales,
    };
  }, { immo: 0, dandq: 0, sales: 0 });

  return Object.keys(completionSum).reduce((acc, team) => {
    const total = Math.floor(completionSum[team] / apartments.length);

    return {
      ...acc,
      [team]: total,
    };
  }, {});
};
