import { toast } from 'react-toastify';

export default function (text) {
  const listener = (e) => {
    e.clipboardData.setData('text/html', text);
    e.clipboardData.setData('text/plain', text);
    e.preventDefault();
    toast('Copied!', {
      type: toast.TYPE.SUCCESS,
    });
  };
  document.addEventListener('copy', listener);
  document.execCommand('copy');
  document.removeEventListener('copy', listener);
}
